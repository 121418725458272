// Services
import { getToken } from "src/shared/services/general"

// Models
import {  ReferenceModel, LocalityModel } from "offiziersmesser/lib/models"

type loadUnactivatedUserDataProps = {
	taxpayer: string
	// contact: PhoneModel
	// setContact: (value: PhoneModel) => void
	// sefaz: SefazModel
	// setSefaz: (value: SefazModel) => void
	setByname: (value: string) => void
	setCompany: (value: string) => void
	// setName: (value: string) => void
	// setSurname: (value: string) => void
	// setTitle: (value: string) => void
	// setEmail: (value: string) => void
	// setPhone: (value: PhoneModel) => void
	setReference: (value: ReferenceModel) => void
	// setArea: (value: string) => void
	// setRoad: (value: string) => void
	// setCity: (value: string) => void
	// setExtra: (value: string) => void
	// setNumber: (value: string) => void
	// setRegion: (value: string) => void
	// setCountry: (value: string) => void
	// setPostal: (value: string) => void
	setLocality: (value: LocalityModel) => void
}

export default async function loadUnactivatedUserData({
	taxpayer,
	// contact, setContact,
	// sefaz, setSefaz,
	setByname,
	setCompany,
	// setName,
	// setSurname,
	// setTitle,
	// setEmail,
	// setPhone,
	setReference,
	// setArea,
	// setRoad,
	// setCity,
	// setExtra,
	// setNumber,
	// setRegion,
	// setCountry,
	// setPostal,
	setLocality,

}: loadUnactivatedUserDataProps) {

	const token = getToken()
	const response = await fetch(`${process.env.REACT_APP_API_URL}/user/unactivated/taxpayer/administrative`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,},
		body: JSON.stringify({
			taxpayer
		}),
	})

	if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

	if(response.ok){
		const data = await response.json()
		// setContact({
		// 	...contact,
		// 	destination: data.administrative.reference.phone.destination,
		// 	subscriber: data.administrative.reference.phone.subscriber
		// })

		// Sefaz
		// setSefaz({
		// 	...sefaz,
		// 	municipalRegistration: data.administrative.miscellaneous.sefaz.municipalRegistration,
		// 	stateRegistration: data.administrative.miscellaneous.sefaz.stateRegistration
		// })
		setByname(data.administrative.byname)
		setCompany(data.administrative.company)

		// Reference
		setReference(data.administrative.reference)
		// setName(data.administrative.reference.name)
		// setSurname(data.administrative.reference.surname)
		// setTitle(data.administrative.reference.title)
		// setEmail(data.administrative.reference.email)
		// setPhone(data.administrative.reference.phone)

		// Locality
		setLocality(data.administrative.locality)
		// setArea(data.administrative.locality.area)
		// setRoad(data.administrative.locality.road)
		// setCity(data.administrative.locality.city)
		// setExtra(data.administrative.locality.extra)
		// setNumber(data.administrative.locality.number)
		// setRegion(data.administrative.locality.region)
		// setCountry(data.administrative.locality.country)
		// setPostal(data.administrative.locality.postal)
	}

	// axios({
	// 	method: "post",
	// 	url: `${process.env.REACT_APP_API_URL}/user/unactivated/taxpayer/administrative`,
	// 	headers: { Authorization: `Bearer ${token}` },
	// 	data
	// })
	// 	.then(response => {
	// 		setContact({
	// 			...contact,
	// 			destination: response.data.administrative.data?.contacts?.phones[0].destination,
	// 			subscriber: response.data.administrative.data?.contacts?.phones[0].subscriber
	// 		})

	// 		// Sefaz
	// 		setSefaz({
	// 			...sefaz,
	// 			municipalRegistration: response.data.administrative.miscellaneous.sefaz.municipalRegistration,
	// 			stateRegistration: response.data.administrative.miscellaneous.sefaz.stateRegistration
	// 		})
	// 		setByname(response.data.administrative.administrative.byname)
	// 		setCompany(response.data.administrative.administrative.company)

	// 		// Reference
	// 		setReference(response.data.administrative.administrative.reference)
	// 		// setName(response.data.administrative.administrative.reference.name)
	// 		// setSurname(response.data.administrative.administrative.reference.surname)
	// 		// setTitle(response.data.administrative.administrative.reference.title)
	// 		// setEmail(response.data.administrative.administrative.reference.email)
	// 		// setPhone(response.data.administrative.administrative.reference.phone)

	// 		// Locality
	// 		setLocality(response.data.administrative.administrative.locality)
	// 		// setArea(response.data.administrative.administrative.locality.area)
	// 		// setRoad(response.data.administrative.administrative.locality.road)
	// 		// setCity(response.data.administrative.administrative.locality.city)
	// 		// setExtra(response.data.administrative.administrative.locality.extra)
	// 		// setNumber(response.data.administrative.administrative.locality.number)
	// 		// setRegion(response.data.administrative.administrative.locality.region)
	// 		// setCountry(response.data.administrative.administrative.locality.country)
	// 		// setPostal(response.data.administrative.administrative.locality.postal)
	// 	})
	// 	.catch(error => console.error(error))
}
