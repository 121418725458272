import { createSlice, current } from "@reduxjs/toolkit"

// Models

const contactsSlice = createSlice({
	name: "assessments",
	initialState: [] as any[], // FIXME model peding
	reducers: {
		setAssessments: (state, action) => {
			return state = action.payload
		},
		addAssessment: (state, action) => {
			return state = [...state, action.payload]
		},
		removeAssessment: (state, action) => {
			return state = current(state).filter(state => state.target !== action.payload)
		},
		resetAssessments: state => {
			return state = []
		},
	}
})

export default contactsSlice.reducer
export const { setAssessments, resetAssessments, removeAssessment } = contactsSlice.actions
