import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"


// Store


// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle, faBoxOpen } from "@fortawesome/free-solid-svg-icons"

// Components



// Styles
import "./StoreCustomer.css"
import { updateStore } from "src/shared/store/slices/storeSlice"
import { setCart } from "src/shared/store/slices/cartSlice"

import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import TextInputBarcode from "src/shared/components/custom/TextInputBarcode/TextInputBarcode"
import { createToast } from "src/shared/store/slices/toastsSlice"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"
import Notice from "src/shared/components/custom/Notice/Notice"

import { useParams } from "react-router-dom"
import StoreItemCard from "src/modules/seller/store/StoreItemCard/StoreItemCard"
import StoreCartNavbarCustomer from "./cart/StoreCartNavbarCustomer"
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

export default function StoreCustomer() {

	let {alias} = useParams()
	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()
	// Store
	const store = useSelector(state => state.store)
	const cart = useSelector(state => state.cart)

	// States
	const [loading, setLoading] = useState(true)

	const [keywords, setKeywords] = useState("")

	// Handlers

	function search() {
		const filteredItems = store.data.items.filter(
			(row) =>
			row.control.description.toLowerCase().indexOf(keywords.toLowerCase()) > -1 ||
			row.control.sku.toLowerCase().indexOf(keywords.toLowerCase()) > -1 ||
			row.control.barcode.toLowerCase().indexOf(keywords.toLowerCase())
		);

		const sortedItems = [...filteredItems].sort((a, b) => {
			if (a.control.quantity === 0 && b.control.quantity === 0) {
				return 0;
			} else if (a.control.quantity === 0) {
				return 1;
			} else if (b.control.quantity === 0) {
				return -1;
			}
		  return 0;
		});

		return sortedItems;
	}

	const getStore = async () => {
		try{
			const response = await fetch(process.env.REACT_APP_API_URL + "/store/" + alias)
			responseCodeHandler(response)

			if(response.ok){
				const res = await response.json()
				dispatch(
					updateStore({
						alias: alias,
						data: res,
					})
				)
				// Updates some guest"s session data if not done previously
				dispatch(
					setCart({
						...cart,
						data: {
							...cart.data,
							seller: {
								...cart.data.seller,
								identifier: "GUEST",
							},
							delivery: {
								...cart.data.delivery,
								shipping: res.shipping,
							},
						},
					})
				)
			} else {
				throw new Error(`HTTP error status ${response.status}`)
			}
			setLoading(false)
		}catch(error){
			console.error(error)
		}
	}

	// Effects
	useEffect(() => {
		getStore()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alias])


	return (

		<StoreCartNavbarCustomer>
			<div className="grid medium-gap">

				<PageTitle title="Catálogo">
					<TextInputBarcode
						placeholder="Busque por um produto ou escaneie um código de barras"
						value={keywords}
						onChange={e => setKeywords(e.target.value)}
						onScan={e => setKeywords(e.target.value)}
						onError={() =>
							dispatch(createToast({
								tone: "failure",
								miliseconds: 2000,
								message: "Algo deu errado, tente novamente.",
								dismissable: true,
						}))}
					/>
				</PageTitle>

				{(loading === true) ? (
					<ComponentLoader />
				) : (store === null) ? (
					<Notice>
						<FontAwesomeIcon icon={faBoxOpen} />
						<span>Desculpe, a loja {alias} não foi encontrada.</span>
					</Notice>
				) : (
					(store.data.items === null) ? (
						<Notice tone="warning">
							<div className="grid-columns medium-gap left-items">
								<FontAwesomeIcon className="fa-icon" icon={faExclamationTriangle} />
								<div className="grid small-gap">
									<span className="small">Parece que não há produtos disponíveis para venda.</span>
									<div className="bold-font tiny">
										<span>Confira a situação do estoque e tente novamente.</span>
									</div>
								</div>
							</div>
						</Notice>
					) : (
						<div className="store-items-container grid small-gap fill-large center-items wide-children">
							{search().map((item, index) => (
								<StoreItemCard
									key={index}
									storeAlias={alias}
									storeItem={item}
									element={cart}
									storeCheckout={store.data.ecommerce.checkout}
								/>
							))}
						</div>
					)
				)}
			</div>
		</StoreCartNavbarCustomer>
	)
}
