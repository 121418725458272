import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setAssessments } from "src/shared/store/slices/assessmentsSlice"

// Components
import SearchBar from "src/shared/components/custom/SearchBar/SearchBar"


export default function AssessmentBlockSearch({
	searchValue,
	setSearchValue,
	assessmentFound,
	setAssessmentFound,
	placeholder,
	disabled = false
}) {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const assessments = useSelector(state => state.assessments)

	// States
	const [searchResults, setSearchResults] = useState([])
	const [searchResultsValues, setSearchResultsValues] = useState([])

	// Handlers
	/**
	 * The function "handleSelection" sets the search value, clears the search
	 * results, and sets a flag indicating that an assessment was found.
	 * @param result - The "result" parameter is the value that is selected or chosen
	 * by the user.
	 */
	function handleSelection(result) {
		setSearchValue(result)
		setSearchResultsValues([])
		setAssessmentFound(true)
	}
	/**
	 * The function `handleSearch` is used to handle a search event in a JavaScript
	 * application, filtering and displaying search results based on the user's input.
	 * @param event - The `event` parameter is an object that represents the event
	 * that triggered the search. It contains information about the event, such as the
	 * target element and the value of the input field that triggered the event.
	 */
	function handleSearch(event) {

		const searchValue = event.target.value.toLowerCase()

		setSearchValue(() => {

			setAssessmentFound(false)

			if (searchValue === "") {
				setSearchResults([])
				setSearchResultsValues([])
				setAssessmentFound(false)
				return ""
			}

			assessments.map((assessment) => {

				const assessmentName = assessment.general.administrative.reference.name + " " + assessment.general.administrative.reference.surname

				if (
					assessment.taxpayer === searchValue
					|| assessmentName === searchValue
					|| assessment.general.administrative.company === searchValue
					|| assessment.general.administrative.byname === searchValue
				) {
					setAssessmentFound(true)
					handleSelection(assessment.taxpayer)
					setSearchResultsValues([])
				}
				return null
			})

			return event.taxpayer
		})

		setSearchResults(
			assessments.filter(assessment => {

				const assessmentName = assessment.general.administrative.reference.name + " " + assessment.general.administrative.reference.surname

				if (!!searchValue
					&& (assessment.taxpayer.includes(searchValue)
						|| assessmentName.toLowerCase().includes(searchValue)
						|| assessment.general.administrative.company.includes(searchValue)
						|| assessment.general.administrative.byname.includes(searchValue))
				) {
					return [].push(assessment)
				}
				return ""
			})
		)
	}

	// Effects
	useEffect(() => {
		(async () => {
			try {
				const token = getToken()

				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/resource/supplier/assessments`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})

				responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				const assessments = await response.json()

				dispatch(setAssessments(assessments))
			} catch (error) {
				console.error("assessment SEARCH RELATIONSHIPS REQUEST ERROR - ", error)
			}
		})()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	/*  In this case, the `useEffect` hook is used to update the `searchResultsValues`
	state whenever the `searchResults` or `assessmentFound` state changes. */
	useEffect(() => {
		setSearchResultsValues((prevState) => {
			if (assessmentFound) {
				return prevState
			}

			return searchResults.map((assessment) => ({
				valueIndentifier: assessment.taxpayer,
				value: `${assessment.general.administrative.reference.name} ${assessment.general.administrative.reference.surname} | ${assessment.general.administrative.byname} | ${assessment.taxpayer}`,
				returnedValue: assessment.taxpayer
			}))
		})
	}, [searchResults, assessmentFound])

	return (
		<SearchBar
			value={searchValue}
			onChange={(event) => handleSearch(event)}
			results={searchResultsValues}
			handleSelection={handleSelection}
			placeholder={placeholder}
			disabled={disabled}
		/>
	)
}
