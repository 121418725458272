import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setTransactions } from "src/shared/store/slices/transactionsSlice"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faExclamationCircle,
	faExternalLinkSquareAlt,
	faFileInvoiceDollar,
	faPlus
} from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import LinkButton from "src/shared/components/standard/LinkButton"
import FormTabs from "src/shared/components/custom/FormTabs/FormTabs"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import RadioButtonInput from "src/shared/components/standard/RadioButtonInput"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"
import AssessmentBlockSearch from "../components/UserBlockSearch"
import TransactionsTable from "../components/tables/TransactionsTable"
// import GlobalSearchBar from "src/shared/components/custom/GlobalSearchBar/GlobalSearchBar"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

// Utils
import { TransactionModel } from "offiziersmesser/lib/models"

export default function SupplierPayablesReceivables() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// States
	const [loading, setLoading] = useState(true)

	const [openTransactions, setOpenTransactions] = useState(true)

	const [payables, setPayables] = useState<TransactionModel[]>([])
	const [Paidayables, setPaidPayables] = useState<TransactionModel[]>([])
	const [receivables, setReceivables] = useState<TransactionModel[]>([])
	const [PaidReceivables, setPaidReceivables] = useState<TransactionModel[]>([])
	const [userSearchValue, setUserSearchValue] = useState("")
	const [assessmentFound, setAssessmentFound ] = useState(false)

	// Handlers
	function getPaid(transactions: TransactionModel[]) {
		return transactions?.filter(
			transaction => transaction.done === true
		)
	}

	function getNotPaid(transactions: TransactionModel[]) {
		return transactions?.filter(
			transaction => transaction.done === false
		)
	}

	const filterUserPayable = (array:  TransactionModel[]) =>{
		return array.filter((r) => {
			if (userSearchValue != ""){
				if (userSearchValue  && (r.addressee) && (r.addressee.taxpayer === userSearchValue)){
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		})
	}

	const filterUserReceivable = (array:  TransactionModel[]) =>{
		return array.filter((r) => {
			if (userSearchValue != ""){
				if (userSearchValue && r.issuer && r.issuer.taxpayer === userSearchValue) {
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		})
	}

	const getPayablesReceivable = async () => {

			try {

				const token = getToken()

				const [receivablesRequest, payablesRequest] = await Promise.all([
					await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/transactions/receivables`, {
						headers: { Authorization: `Bearer ${token}` }
					}),
					await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/transactions/payables`, {
						headers: { Authorization: `Bearer ${token}` }
					}),
				])
				responseCodeHandler(receivablesRequest)
				if (!receivablesRequest.ok) throw new Error(`HTTP error status ${receivablesRequest.status}`)

				responseCodeHandler(payablesRequest)
				if (!payablesRequest.ok) throw new Error(`HTTP error status ${payablesRequest.status}`)


				const [receivablesData, payablesData] = await Promise.all([
					await receivablesRequest.json(),
					await payablesRequest.json(),
				])

				setReceivables(receivablesData)
				setPayables(payablesData)
				dispatch(setTransactions([...receivablesData, ...payablesData]))
			} catch (error) {
				console.error(error)
			} finally {
				setLoading(false)
			}
	}

	// Effects
	useEffect(() => {
		getPayablesReceivable()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		setPaidPayables(getPaid(payables))
		setPaidReceivables(getPaid(receivables))
	}, [payables, receivables])

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap large-margin-bottom">
				{(loading === true) ? (
					<>
						<PageTitle title="Transações" />
						<ComponentLoader />
					</>
				) : (payables.length > 0 || receivables.length > 0) ? (
					<>
						<PageTitle title="Transações">
							{/* <GlobalSearchBar
								value=""
								onChange={() => ""}
								placeholder="Busque uma transação"
							/> */}
						</PageTitle>

						<FormTabs tabs={["A pagar", "A receber"]}>
							<div className="grid medium-gap">

								<div className="flex small-gap">
									<LinkButton to="/supplier/transactions/new">
										<FontAwesomeIcon className="fa-icon" icon={faPlus} />
										<span>Criar transação</span>
									</LinkButton>
									<LinkButton
										to="/supplier/transactions/report"
										hierarchy="low"
									>
										<FontAwesomeIcon className="fa-icon" icon={faFileInvoiceDollar} />
										<span>Emitir relatório</span>
									</LinkButton>
								</div>

								<div className="flex">
									<div className="element-border flex flex-column small-gap">
										<span className="bold-font medium">Transações</span>
										<div className="flex medium-gap flex-wrap">
											<RadioButtonInput
												name="transactions"
												label="Abertas"
												value="0"
												defaultChecked={(openTransactions === true)}
												onChange={() => setOpenTransactions(true)}
											/>
											<RadioButtonInput
												name="transactions"
												label="Finalizadas"
												value="1"
												defaultChecked={(openTransactions === false)}
												onChange={() => setOpenTransactions(false)}
											/>
										</div>
									</div>
									<div className="element-border flex flex-column small-gap small-margin-left">
										<span className="bold-font small flex small-margin-bottom">
											Fornecedor
										</span>
										<AssessmentBlockSearch
											searchValue={userSearchValue}
											setSearchValue={setUserSearchValue}
											assessmentFound={assessmentFound}
											setAssessmentFound ={setAssessmentFound}
											placeholder={`Busque pelo fornecedor`}
										/>
									</div>
								</div>


								<div>
								{openTransactions === true ? (
									<TransactionsTable transactions={getNotPaid(filterUserPayable(payables))} to="addressee" />
								) : (
									<TransactionsTable transactions={filterUserPayable(Paidayables)} to="addressee" />
								)}
								</div>
							</div>

							<div className="grid medium-gap">
								<div className="flex small-gap">
									<LinkButton to="/supplier/transactions/new">
										<FontAwesomeIcon className="fa-icon" icon={faPlus} />
										<span>Criar transação</span>
									</LinkButton>
									<LinkButton
										to="/supplier/transactions/report"
										hierarchy="low"
									>
										<FontAwesomeIcon className="fa-icon" icon={faFileInvoiceDollar} />
										<span>Emitir relatório</span>
									</LinkButton>
								</div>

								<div className="flex">
									<div className="element-border flex flex-column small-gap">
										<span className="bold-font medium">Transações</span>
										<div className="flex medium-gap flex-wrap">
											<RadioButtonInput
												name="transactions"
												label="Abertas"
												value="0"
												defaultChecked={(openTransactions === true)}
												onChange={() => setOpenTransactions(true)}
											/>
											<RadioButtonInput
												name="transactions"
												label="Finalizadas"
												value="1"
												defaultChecked={(openTransactions === false)}
												onChange={() => setOpenTransactions(false)}
											/>
										</div>
									</div>
									<div className="element-border flex flex-column small-gap small-margin-left">
										<span className="bold-font small flex small-margin-bottom">
											Cliente
										</span>
										<AssessmentBlockSearch
											searchValue={userSearchValue}
											setSearchValue={setUserSearchValue}
											assessmentFound={assessmentFound}
											setAssessmentFound ={setAssessmentFound}
											placeholder={`Busque pelo cliente`}
										/>
									</div>
								</div>

								{openTransactions === true ? (
									<TransactionsTable transactions={getNotPaid(filterUserReceivable(receivables))} to="issuer" />
								) : (
									<TransactionsTable transactions={filterUserReceivable(PaidReceivables)} to="issuer" />
								)}
							</div>
						</FormTabs>
					</>
				) : (
					<>
						<PageTitle title="Transações" />

						<Notice tone="info" to="/supplier/transactions/new">
							<div className="grid-columns medium-gap left-items">
								<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle} />
								<div className="grid small-gap">
									<span className="small">Adeus caderninho! O controle definitivo de todos os pagáveis e recebíveis que houverem entre seus fornecedores, clientes e você, num só lugar.</span>
									<div className="grid-columns small-gap left-items bold-font tiny">
										<span>Clique aqui para criar sua primeira transação</span>
										<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt} />
									</div>
								</div>
							</div>
						</Notice>
					</>
				)}
			</div>
		</SupplierRoleNavbar>
	)
}
