import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { sumTotal } from "offiziersmesser/lib/helpers"
import { PAYMENT_METHODS, toFixedImproved } from "offiziersmesser/lib/utils"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import TextInput from "src/shared/components/standard/TextInput"



export default function SupplierFinalizedTransactionsStepTwo({
	indexPayment,
	method,
	deduction,
	extra,
	partial,
	parts,
	setParts,
}) {

	// Store
	const transaction = useSelector(state => state.transactions)

	// States
	const [part, ] = useState({})
	const [received, setReceived] = useState(transaction?.payments === undefined ?0:(0 - sumTotal(0, transaction.payments[indexPayment].parts)))
	const [createdAt, setCreatedAt] = useState(Date.now())
	const [loading, setLoading] = useState(true)

	const total = (transaction?.payments === undefined ?0:Number(sumTotal(
		transaction.payments[indexPayment].total + (transaction.payments[indexPayment].other[0] ?? 0 - transaction.payments[indexPayment].other[1] ?? 0),
		transaction.payments[indexPayment].parts
	)) + Number(extra)) - Number(deduction)
	// Variables
	const diferenceTotal = sumTotal(total, parts)
	const paidParts = (0 - sumTotal(0, parts))

	// Effects
	useEffect(() => {

		// when the transaction has one part go to first conditional.
		if (parts.length === 0) {

			const value = total

			setParts([{
				createdAt: 0, // Manual set if transaction is being custom-created, auto if its a heir
				value, // Partial (of full) value paid
				method // Lookup table storing how the payment was done (Cash, credit card, etc)
			}])

			setReceived(value)

		}

		setLoading(false);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setCreatedAt(new Date())
	}, [part])

	return (
		!loading && (
			<div className="flex flex-column medium-gap">

				<div className="grid fill-normal small-gap">
					<TextInput
						label="Descrição"
						value={transaction.note}
						disabled
					/>
					<TextInput
						label="Valor do Documento"
						value={toFixedImproved(total, 2)}
						disabled
					/>
					<TextInput
						label="Valor restante do Documento"
						value={toFixedImproved(diferenceTotal, 2)}
						disabled
					/>
					<TextInput
						label="Valor de Desconto"
						value={toFixedImproved(deduction, 2)}
						disabled
					/>
					<TextInput
						label="Valor de Acréscimos"
						value={toFixedImproved(extra, 2)}
						disabled
					/>
				</div>

				<div className="flex flex-column small-gap">
					<div className="grid fill-huge-x small-gap">
						<div className="flex flex-column small-gap element-border medium-padding">
							<span className="bold-font big">Valores recebidos</span>

							<ol className="flex flex-column small-gap medium-padding left-items">
							{transaction?.payments != undefined && transaction.payments[indexPayment].parts.map((part) => {
									return (
										<li>
											<span><b>valor: </b></span>R$ {
												toFixedImproved(part.value, 2)
											} | <span><b>Dia do Recebimento: </b></span>
											{`${createdAt.getDate() < 10 ? "0" + createdAt.getDate() : createdAt.getDate()}/${(createdAt.getMonth() + 1) < 10 ? "0" + (createdAt.getMonth() + 1) : (createdAt.getMonth() + 1)}/${createdAt.getFullYear()}`}
										</li>
									)
								})}
							</ol>
							<p>
								<b>Total Recebido: </b> R$ {toFixedImproved(received, 2)}
							</p>
						</div>
						<div className="flex flex-column small-gap element-border medium-padding">

							<span className="bold-font big">Valores que serão quitados:</span>

							<ol className="flex flex-column small-gap medium-padding">
								{parts.map((part) => {
									return (
										<li> <span><b>valor: </b></span>{toFixedImproved(part.value, 2)} | <span><b>Dia do Recebimento: </b></span> {`${createdAt.getDate() < 10 ? "0" + createdAt.getDate() : createdAt.getDate()}/${(createdAt.getMonth() + 1) < 10 ? "0" + (createdAt.getMonth() + 1) : (createdAt.getMonth() + 1)}/${createdAt.getFullYear()}`} Método: {PAYMENT_METHODS[part.method].name}</li>
									)
								})}
							</ol>
							<p>
								<b>Total que será Quitado: </b> {toFixedImproved(paidParts, 2)}
							</p>

						</div>
					</div>
					<div className="flex flex-column small-gap medium-margin-top">
						{(paidParts === total)
							? (
								<span className="big bold-font green flex small-gap align-items-center">
									<FontAwesomeIcon className="fa-icon" icon={faCheckCircle} />
									Conta Quitada!
								</span>
							) : (
								<>
									<span className="big bold-font red flex small-gap align-items-center">
										<FontAwesomeIcon className="fa-icon" icon={faTimesCircle} />
										Conta Quitada Parcialmente!
									</span>
									<span className="big bold-font">
										Valor restante: R$ {toFixedImproved((total - paidParts), 2)}
									</span>
								</>
							)
						}
					</div>
				</div>
			</div>
		)
	)
}
