import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PAYMENT_METHODS, toFixedImproved } from "offiziersmesser/lib/utils"
import MainButton from "src/shared/components/standard/MainButton"
import NumberInput from "src/shared/components/standard/NumberInput"
import SelectInput from "src/shared/components/standard/SelectInput"


export default function PaymentCardTransaction({
	setParts,
	parts,
	element,
	deleteable,
	index,
}) {

	return (
		<div className="checkout-payment-card-container element-border">

			<div className="options flex small-gap">
				<div className="checkout-payment-card-value">
					<NumberInput
						label="Valor"
						value={element.value}
						onChange={event => {

							const value = event.target.valueAsNumber

							setParts(parts => parts.map((part, idx) => {
								if (idx === index) {
									return { ...part, value }
								}
								return part
							}))
						}}
					/>
				</div>

				<div className="checkout-payment-card-method">
					<SelectInput
						label="Método"
						name="method"
						value={PAYMENT_METHODS[element.method].name}
						onChange={event => {
							// selectedIndex is equivalent of valueAsNumber of number inputs
							setParts(parts => parts.map((part, idx) => {
								if (idx === index) {
									return { ...part, method: event.target.selectedIndex }
								}
								return part
							}))
						}}
					>
						{PAYMENT_METHODS.map((entry, index) => (
							<option key={index} value={entry.name}>{entry.name}</option>
						))}
					</SelectInput>
				</div>
			</div>

			<div className="payment flex large-gap">
				<div className="checkout-payment-card-partial grid center-items">
					<span className="big bold-font">
						{"R$ " + toFixedImproved(element.value, 2)}
					</span>
				</div>

				<div className="checkout-payment-card-remove">
					<MainButton
						hierarchy="low"
						disabled={(deleteable === false)}
						onClick={() => setParts(parts.filter((part, partIndex) => (index !== partIndex)))}
					>
						<FontAwesomeIcon className="fa-icon" icon={faTimes} />
					</MainButton>
				</div>
			</div>
		</div>
	)
}

// 0 -> Cash
// 1 -> Debit card
// 2 -> Credit card
// 3 -> Cheque
// 4 -> TED/DOC
// 5 -> PIX
// 6 -> Boleto
// 7 -> Loan
// 8 -> Voucher
// 9 -> Mixed
