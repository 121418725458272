const variations = [
	"Vendidos",
	"Código",
	"Descrição",
	"Fabricante",
	"Categoria",
	"Retornados",
]

export default function ManageOrderTableHeader() {

	return (
		<tr>
			{variations.map((header, index) => <th key={index}>{header}</th>)}
		</tr>
	)
}
