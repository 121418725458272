import {  ChangeEvent, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Utils
import { buildS3URL } from "offiziersmesser/lib/utils"
import { EncodedFile, encodeFile } from "offiziersmesser/lib/utils"

// Store
import { RootState } from "src/shared/store/reducers"
import { updateWorkableImages, updateWorkableItem } from 'src/shared/store/slices/workableItemsSlice'

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import {
	faPlus
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Image from "src/shared/components/standard/Image"
import FileInput from "src/shared/components/standard/FileInput"
import MainButton from "src/shared/components/standard/MainButton"
import updateItemImage from "../../services/updateItemImage"

export default function MediaHandler() {

	const dispatch = useDispatch()

	// Store
	const user = useSelector((state: RootState) => state.user)
	const workableItem = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)
	const workableImages = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.images)
	const [encodedImages, setEncodedImages] = useState<string[]>([])
	const limit = 5

	// Handlers
	async function handleImportImage(event: ChangeEvent<HTMLInputElement>) {
		try {
			const files = event.target.files as FileList

			const encodedImagesContent = []
			const filesArray = []

			for (let index = 0; index < files.length; index++) {
				const file = files[index]
				filesArray.push(file)
				const encodedImage = await encodeFile(file) as EncodedFile

				encodedImagesContent.push(encodedImage.content)
			}
			setEncodedImages([...encodedImages,...encodedImagesContent])

			dispatch(updateWorkableImages([...workableImages, ...filesArray]))

		} catch (err) {
			console.error(err)
		}
	}
	function handleRemoveUploadedImage(imageIndex: number) {
		setEncodedImages(encodedImages.filter((image, index) => index !== imageIndex))
		dispatch(updateWorkableItem({
			...workableItem,
			media: {
				...workableItem.media,
				images: workableItem.media.images.filter((image, index) => index !== imageIndex)
			}
		}))
	}

	async function handleRemoveWorkableImage(imageIndex: number) {
		const images  = [workableItem.media.images[imageIndex]]
		updateItemImage(workableItem.item,images)
		dispatch(updateWorkableImages(workableImages.filter((image, index) => index !== imageIndex)))
	}

	return (
		workableItem &&
		<div className="grid medium-gap left-items left-text big bold-font">
			<div className="flex flex-column medium-gap">

				<span className="big-x bold-font">Mídias</span>

				{workableItem.media.images?.length > 0 && (
					<>
						<span className="big bold-font">Imagems atuais</span>

						<div className="flex flex-wrap small-gap">
							{workableItem.media.images.map((image, index) =>
								<div className="tiny-margin grid small-gap center-items element-border" key={index}>
									<Image
										className="preview"
										src={buildS3URL([user.user, "items", workableItem.item,"images" ,image])}
									/>
									<div className="wide wide-children">
										<MainButton
											hierarchy="inferior"
											onClick={() => { handleRemoveWorkableImage(index) }}
										>
											<span>Excluir</span>
										</MainButton>
									</div>
								</div>
							)}
						</div>
					</>
				)}

				{encodedImages.length > 0 && (
					<>
						<span className="big bold-font">Imagens a serem adicionadas</span>

						<div className="flex flex-wrap small-gap">
							{encodedImages.map((image, index) =>
								<div className="tiny-margin grid small-gap center-items element-border" key={index}>
									<Image
										className="preview"
										src={image}
									/>
									<div className="wide wide-children">
										<MainButton
											hierarchy="inferior"
											onClick={() => { handleRemoveUploadedImage(index) }}
										>
											<span>Excluir</span>
										</MainButton>
									</div>
								</div>
							)}
						</div>
					</>
				)}

				<div className="flex">
					<FileInput
						onChange={e => { handleImportImage(e) }}
						multiple={false}
						disabled={(workableImages.length + workableItem.media.images?.length >= limit)}
						accept="image/*"
					>
						<FontAwesomeIcon className="fa-icon" icon={faPlus as Icon} />
						<span>Adicionar imagem</span>
					</FileInput>
				</div>
			</div>
		</div>
	)
}
