// import { createIntl, createIntlCache } from "react-intl"

// // Messages
// import en from "./messages/en"
// import pt from "./messages/pt"

// const cache = createIntlCache()

// const messages = {
// 	en: en,
// 	pt: pt
// }

// const locale = "" //navigator.language.startsWith("pt") ? "pt" : "en"

// export const intl = createIntl({
// 	locale,
// 	messages: messages[locale],
// }, cache)

import en from "./translations/en"
import pt from "./translations/pt"

const messages = {
	en: en,
	pt: pt
}

export default messages