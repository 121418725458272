import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Icons
import {
	faTimes,
	faCheck,
	faExclamationCircle
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import {
	WEEKDAYS,
	UNITS_TABLE_BR,
	toUnsignedFloat
} from "offiziersmesser/lib/utils"

// Store
import { setUser } from "src/shared/store/slices/userSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"

// Components
import Form from "src/shared/components/standard/Form"
import Modal from "src/shared/components/custom/Modal/Modal"
import Notice from "src/shared/components/custom/Notice/Notice"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import MainButton from "src/shared/components/standard/MainButton"
import NumberInput from "src/shared/components/standard/NumberInput"
import SelectInput from "src/shared/components/standard/SelectInput"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

export default function Delivery() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()
	// Store
	const user = useSelector(state => state.user)

	// States
	const [shipping, setShipping] = useState(user.shipping)

	// Variables
	// TODO create an util for this
	let hoursOptions = []
	let minutesOptions = []

	for (let i = 0; i < 24; i++)
		hoursOptions.push(
			<option key={i} value={i}>
				{i < 10 ? "0" + i : i}
			</option>
		)
	for (let i = 0; i < 60; i++)
		minutesOptions.push(
			<option key={i} value={i}>
				{i < 10 ? "0" + i : i}
			</option>
		)

	const [renderModal, setRenderModal] = useState(false)

	const [currentWeekday, setCurrentWeekday] = useState("")
	const [currentWeekdayIndex, setCurrentWeekdayIndex] = useState(0)
	const [currentWeekdayValue, setCurrentWeekdayValue] = useState(null)

	const handleUpdateDelivery = async () => {
		try {
			const updatedDay = {}
			updatedDay[currentWeekday] = currentWeekdayValue

			const token = getToken()
			const updatedDelivery = {
				...user.delivery,
				week: { ...user.delivery.week, ...updatedDay }
			}

			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/supplier/delivery", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ delivery: updatedDelivery }),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({ ...user, delivery: updatedDelivery }))
			dispatch(createToast({ tone: "success", miliseconds: 3000, message: "Atualizado com sucesso" }))
		} catch (error) {
			console.error(error)
			dispatch(createToast({ tone: "failure", miliseconds: 5000, message: "Ocorreu algum erro, tente novamente" }))
		} finally {
			setRenderModal(false)
		}
	}

	const handleUpdateShipping = async () => {
		try {
			const token = getToken()

			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/supplier/shipping", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ shipping: shipping }),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({ ...user, shipping: shipping }))
			dispatch(createToast({ tone: "success", miliseconds: 3000, message: "Atualizado com sucesso" }))
		} catch (error) {
			console.error(error)
			dispatch(createToast({ tone: "failure", miliseconds: 5000, message: "Ocorreu algum erro, tente novamente" }))
		}
	}

	return (
		<SupplierRoleNavbar>

			<PageTitle title="Entregas" />

			{Object.keys(user.delivery.week).some(day => user.delivery.week[day].active === true) === false &&
				<div className="medium-margin-vertical">
					<Notice tone="info">
						<div className="grid-columns medium-gap left-items">
							<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle} />
							<div className="grid small-gap">
								<span className="small">Mais clientes satisfeitos, mais vendas. Se seu negócio oferta frete próprio, use essa página para informá-los dos horários disponíveis.</span>
								<div className="bold-font tiny">
									<span>Clique em algum dos dias da semana abaixo para atualizá-lo.</span>
								</div>
							</div>
						</div>
					</Notice>
				</div>
			}

			{renderModal && (
				<Modal onClose={() => setRenderModal(false)}>
					<div className="modal-container">
						<div className="header">
							<span className="big-x bold-font left-text">
								{`Alterar entregas de ${WEEKDAYS[currentWeekdayIndex].toLowerCase()}`}
							</span>
						</div>

						<CheckboxInput
							label="Permitir entregas no dia"
							value={currentWeekdayValue.active}
							defaultChecked={currentWeekdayValue.active}
							onChange={() => setCurrentWeekdayValue({ ...currentWeekdayValue, active: !currentWeekdayValue.active })}
						/>

						<div className="grid medium-gap fill-normal wide-children">
							{Object.keys(currentWeekdayValue["schedule"]).map(operation => {

								const value = currentWeekdayValue["schedule"][operation]
								const hours = parseInt(value / 60)
								const minutes = value % 60

								return (
									<div
										key={operation}
										className="grid small-gap"
									>
										<span className="big bold-font">
											{operation === "opening" ? "Início" : "Fim"}
										</span>
										<div className="flex small-gap wide wide-children">
											<SelectInput
												label="hora"
												value={hours}
												disabled={currentWeekdayValue.active === false}
												onChange={e => {
													let updatedWeekday = { ...currentWeekdayValue, schedule: { ...currentWeekdayValue["schedule"] } }
													updatedWeekday["schedule"][operation] = Number(e.target.value) * 60 + minutes
													setCurrentWeekdayValue(updatedWeekday)
												}}
											>
												{hoursOptions}
											</SelectInput>
											<SelectInput
												label="minuto"
												value={minutes}
												disabled={currentWeekdayValue.active === false}
												onChange={e => {
													let updatedWeekday = { ...currentWeekdayValue, schedule: { ...currentWeekdayValue["schedule"] } }
													updatedWeekday["schedule"][operation] = hours * 60 + Number(e.target.value)
													setCurrentWeekdayValue(updatedWeekday)
												}}
											>
												{minutesOptions}
											</SelectInput>
										</div>
									</div>
								)
							})}
						</div>

						<div className="wide wide-children medium-margin-top">
							<MainButton
								disabled={currentWeekdayValue.active === false && ((currentWeekdayValue.schedule.closing - currentWeekdayValue.schedule.opening) <= 0)}
								onClick={handleUpdateDelivery}
							>
								<span>{`Alterar ${WEEKDAYS[currentWeekdayIndex].toLowerCase()}`}</span>
							</MainButton>
						</div>
					</div>
				</Modal>
			)}

			<div className="medium-margin-vertical grid fill-large medium-gap">
				{Object.keys(user.delivery.week).map((day, index) => {

					const calculateSchedule = () => {
						const openingTime = user.delivery.week[day]["schedule"]["opening"]
						const closingTime = user.delivery.week[day]["schedule"]["closing"]
						const openingHours = parseInt(openingTime / 60)
						const closingHours = parseInt(closingTime / 60)
						const openingMinutes = openingTime % 60
						const closingMinutes = closingTime % 60

						return `das ${openingHours}:${openingMinutes < 10 ? "0" + openingMinutes : openingMinutes} às ${closingHours}:${closingMinutes < 10 ? "0" + closingMinutes : closingMinutes}`
					}

					return (
						<MainButton
							key={day}
							hierarchy="inferior"
							onClick={() => {
								setCurrentWeekdayValue({
									...user.delivery.week[day],
									schedule: {
										...user.delivery.week[day]["schedule"]
									}
								})
								setCurrentWeekdayIndex(index)
								setCurrentWeekday(day)
								setRenderModal(true)
							}}
						>
							<FontAwesomeIcon className="fa-icon" icon={user.delivery.week[day].active === false ? faTimes : faCheck} />
							<span>
							{`${WEEKDAYS[index]} ${
								user.delivery.week[day].active === false
								? "sem"
								: calculateSchedule()
							}`}
							</span>
						</MainButton>
					)
				})}
			</div>


			<Form className="grid medium-gap medium-margin-vertical">

				<span className="big-x bold-font">Definições de frete</span>

				<CheckboxInput
					label="Fretes ativados"
					checked={shipping.active === true}
					onChange={() => setShipping({ ...shipping, active: !shipping.active })}
				/>
				<CheckboxInput
					label="Personalizável pelo vendedor"
					checked={shipping.custom === true}
					onChange={() => setShipping({ ...shipping, custom: !shipping.custom })}
				/>

				<div className="grid fill-normal medium-gap left-items">
					<NumberInput
						label="Custo fixo"
						value={shipping.ante}
						onChange={e => {
							const ante = e.target.valueAsNumber
							setShipping({ ...shipping, ante: ante < 0 || isNaN(ante) ? 0 : toUnsignedFloat(ante, 2) })
						}}
					/>

					<NumberInput
						label="Taxa"
						value={shipping.fee}
						onChange={e => {
							const fee = e.target.valueAsNumber
							setShipping({ ...shipping, fee: fee < 0 || isNaN(fee) ? 0 : toUnsignedFloat(fee, 2) })
						}}
					/>

					<SelectInput
						label="Unidade"
						value={shipping.unit}
						disabled={true}
						onChange={e => setShipping({ ...shipping, unit: e.target.value })}
					>
						<option value={34}>{UNITS_TABLE_BR[34]}</option>
					</SelectInput>
				</div>

				<div className="medium-margin-vertical">
					<MainButton	onClick={handleUpdateShipping}>
						<span>Alterar definições de frete</span>
					</MainButton>
				</div>
			</Form>
		</SupplierRoleNavbar>
	)
}
