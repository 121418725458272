// Components
import NumberInput from "../../../../../shared/components/standard/NumberInput"
import DatePickerSelect from "../../../../../shared/components/custom/DatePickerSelect/DatePickerSelect"

export default function PaymentBlock({
	issueDate,
	setIssueDate,
	expirationDate,
	setExpirationDate,
	value,
	setValue,
	payments,
	setPayments,
	activeInstallments,
	setActiveInstallments,
}) {
	return (
		<>
			<div className="grid small-gap fill-normal normal-margin-top medium-margin-bottom wide-children center-items">
				<NumberInput
					name={value}
					onChange={e => setValue(Number(e.target.value))}
					label="Valor"
					disabled={activeInstallments}
				/>

				<div className="tiny-margin grid medium-gap center-items element-border">
					<span className="bold-font medium">Data de pagamento</span>
					<DatePickerSelect
						value={expirationDate}
						timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
						setDate={(date) =>
							setExpirationDate(
								new Date(
									date.year,
									date.month - 1,
									date.day
								).getTime() / 1000
							)
						}
					/>
				</div>
			</div>
		</>
	)
}
