import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setItems } from "../../../../../../shared/store/slices/itemsSlice"

// Components
import ProductsBlockSearch from "./ProductsBlockSearch"
import MainButton from "../../../../../../shared/components/standard/MainButton"
import CardItemInvoices from "./CardItemInvoices"

// Styles
import "../../../Invoices.css"

export default function ProductsBlock() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const items = useSelector(state => state.items)

	// States
	const [searchValue, setSearchValue] = useState("")

	const [selectedItems, setSelectedItems] = useState([])
	const [itemFound, setItemFound] = useState(false)

	function addItem() {

		let alreadySelected = false

		setSelectedItems((prevState) => {
			alreadySelected = false

			const newItems = prevState
			const selectedItem = items.filter(
				(item) => item.control.sku === searchValue
			)

			newItems.map((item) => {
				if (item.control.sku === searchValue) {
					return alreadySelected = true
				}
				return null
			})
			setSearchValue("")
			setItemFound(false)

			if (alreadySelected) return prevState
			newItems.push(selectedItem[0])
			return newItems
		})
	}
	const getItems = async () => {
		try{
			const token = getToken()
			const response = await fetch(
				process.env.REACT_APP_API_URL + "/resource/supplier/items",
				{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()
			dispatch(setItems(data))
		}catch(error){
			console.error(error)
		}
	}
	// Effects
	useEffect(() => {
		getItems()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="normal-padding item-full-height normal-margin-top">
			<div className="ClientSearch">
				<ProductsBlockSearch
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					selectedItems={selectedItems}
					setSelectedItems={setSelectedItems}
					itemFound={itemFound}
					setItemFound={setItemFound}
				/>

				{itemFound === true ? (
					<MainButton onClick={() => addItem()}>
						<span>Adicionar produto</span>
					</MainButton>
				) : (
					<MainButton hierarchy="low">
						<span>Cadastrar produto</span>
					</MainButton>
				)}
			</div>
			<div>
				{selectedItems.length > 0 &&
					selectedItems.map((item) => (
						<CardItemInvoices item={item} />
					))}
			</div>
		</div>
	)
}
