import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import { printContent } from "offiziersmesser/lib/utils"
import { renderTransactionsReportTemplateBR } from "offiziersmesser/lib/renderers"
import { expirationDateFilter, taxpayerFilter, issueDateFilter, getInvoiceInfo, splitPaid, paymentDateFilter } from "offiziersmesser/lib/helpers"

// Store
import { resetTransactions } from "src/shared/store/slices/transactionsSlice"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

// Components
import TextInput from "src/shared/components/standard/TextInput"
import LinkButton from "src/shared/components/standard/LinkButton"
import MainButton from "src/shared/components/standard/MainButton"
import SelectInput from "src/shared/components/standard/SelectInput"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"
import DatePickerSelect from "src/shared/components/custom/DatePickerSelect/DatePickerSelect"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import handleTransactions from "./handlers/handleTransactions"

export default function SupplierIssueReport() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	dispatch(resetTransactions())

	// States
	const [transactionsPayables, setTransactionPayables] = useState([])
	const [transactionsPayablesPaid, setTransactionPayablesPaid] = useState([])
	const [transactionsReceivables, setTransactionReceivables] = useState([])
	const [transactionsReceivablesPaid, setTransactionReceivablesPaid] = useState([])

	const [reportType, setReportType] = useState(0)
	const [noTransactionsAvailable, setNoTransactionsAvailable] = useState(false)
	const [invalidTaxpayer, setInvalidTaxpayer] = useState(false)

	const [activeTaxpayerFilter, setActiveTaxpayerFilter] = useState(false)
	const [by, setBy] = useState("")

	const [issueDate, setIssueDate] = useState("")
	const [issueDateFinal, setIssueDateFinal] = useState("")
	const [activeIssueDateFilter, setActiveIssueDateFilter] = useState(false)
	const [activeIssueDatePeriodFilter, setActiveIssueDatePeriodFilter] = useState(false)

	const [dueDate, setDueDate] = useState("")
	const [dueDateFinal, setDueDateFinal] = useState("")
	const [activeDueDateFilter, setActiveDueDateFilter] = useState(false)
	const [activeDueDatePeriodFilter, setActiveDueDatePeriodFilter] = useState(false)

	const [paymentDate, setPaymentDate] = useState("")
	const [paymentDateFinal, setPaymentDateFinal] = useState("")
	const [activePaymentFilter, setActivePaymentFilter] = useState(false)
	const [activePaymentPeriodFilter, setActivePaymentPeriodFilter] = useState(false)

	// Handlers
	function handlePrintReport() {

		let payablesArray = transactionsPayables
		let payablesArrayPaid = transactionsPayablesPaid
		let receivablesArray = transactionsReceivables
		let receivablesArrayPaid = transactionsReceivablesPaid

		// Filters
		if (typeof dueDate === "object") {
			switch (reportType) {
				case 0: payablesArray = expirationDateFilter(payablesArray, dueDate, dueDateFinal); break;
				case 1: receivablesArray = expirationDateFilter(receivablesArray, dueDate, dueDateFinal); break;
				case 2: payablesArrayPaid = expirationDateFilter(payablesArrayPaid, dueDate, dueDateFinal); break;
				case 3: receivablesArrayPaid = expirationDateFilter(receivablesArrayPaid, dueDate, dueDateFinal); break;
				default: break;
			}
		}
		if (typeof issueDate === "object") {
			switch (reportType) {
				case 0: payablesArray = issueDateFilter(payablesArray, issueDate, issueDateFinal); break;
				case 1: receivablesArray = issueDateFilter(receivablesArray, issueDate, issueDateFinal); break;
				case 2: payablesArrayPaid = issueDateFilter(payablesArrayPaid, issueDate, issueDateFinal); break;
				case 3: receivablesArrayPaid = issueDateFilter(receivablesArrayPaid, issueDate, issueDateFinal); break;
				default: break;
			}
		}
		if (typeof by === "string" && by.length > 0 && (by.length !== 11 && by.length !== 14)) {
			setInvalidTaxpayer(true)
			return;
		}
		if (typeof by === "string" && (by.length === 11 || by.length === 14)) {
			switch (reportType) {
				case 0: payablesArray = taxpayerFilter(payablesArray, by, true); break;
				case 1: receivablesArray = taxpayerFilter(receivablesArray, by, false); break;
				case 2: payablesArrayPaid = taxpayerFilter(payablesArrayPaid, by, true); break;
				case 3: receivablesArrayPaid = taxpayerFilter(receivablesArrayPaid, by, false); break;
				default: break;
			}
		}
		if (typeof paymentDate === "object") {
			switch (reportType) {
				case 2: payablesArrayPaid = paymentDateFilter(payablesArrayPaid, paymentDate, paymentDateFinal); break;
				case 3: receivablesArrayPaid = paymentDateFilter(receivablesArrayPaid, paymentDate, paymentDateFinal); break;
				default: break;
			}
		}

		// Generate template and print
		switch (reportType) {
			case 0:
				// if there are no transactions show an error message
				if (payablesArray.length === 0) { setNoTransactionsAvailable(true); return; }

				/* Printing the payables report. */
				const payables = handleTransactions(payablesArray, "addressee")
				printContent(renderTransactionsReportTemplateBR(getInvoiceInfo(payables, "issuer", true)))
				break;
			case 1:
				if (receivablesArray.length === 0) { setNoTransactionsAvailable(true); return; }

				/* Printing the receivables report. */
				const receivables = handleTransactions(transactionsReceivables, "issuer")
				printContent(renderTransactionsReportTemplateBR(getInvoiceInfo(receivables, "addressee", true)))
				break;
			case 2:
				if (payablesArrayPaid.length === 0) { setNoTransactionsAvailable(true); return; }

				/* Printing the payables paid report. */
				const payablesPaid = handleTransactions(transactionsPayablesPaid, "addressee")
				printContent(renderTransactionsReportTemplateBR(getInvoiceInfo(payablesPaid, "issuer", false)))
				break;
			case 3:
				if (receivablesArrayPaid.length === 0) { setNoTransactionsAvailable(true); return; }

				/* Printing the receivables paid report. */
				const receivablesPaid = handleTransactions(transactionsReceivablesPaid, "issuer")
				printContent(renderTransactionsReportTemplateBR(getInvoiceInfo(receivablesPaid, "addressee", false)))
				break;
			default: break;
		}
	}

	const getTransactions = async () => {

		try {
			const token = getToken()

			const payablesRequest = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/transactions/payables`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			const receivablesRequest = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/transactions/receivables`, {
				headers: { Authorization: `Bearer ${token}` }
			})

			responseCodeHandler(payablesRequest)
			if (!payablesRequest.ok) throw new Error(`HTTP error status ${payablesRequest.status}`)

			responseCodeHandler(receivablesRequest)
			if (!receivablesRequest.ok) throw new Error(`HTTP error status ${receivablesRequest.status}`)

			const payables = splitPaid(await payablesRequest.json())
			const receivables = splitPaid(await receivablesRequest.json())

			setTransactionPayables(payables.toBePaid)
			setTransactionPayablesPaid(payables.paid)
			setTransactionReceivables(receivables.toBePaid)
			setTransactionReceivablesPaid(receivables.paid)
		} catch (error) {
			console.error(error)
		}
	}

	// Effects
	useEffect(() => {
		getTransactions()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {

		setInvalidTaxpayer(false)
		setNoTransactionsAvailable(false)

		if (!activeTaxpayerFilter) { setBy("") }
		if (!activeDueDateFilter) { setDueDate("") }
		if (!activeDueDatePeriodFilter) { setDueDateFinal("") }
		if (!activeIssueDateFilter) { setIssueDate("") }
		if (!activeIssueDatePeriodFilter) { setIssueDateFinal("") }
		if (!activePaymentFilter) { setPaymentDate("") }
		if (!activePaymentPeriodFilter) { setPaymentDateFinal("") }

	}, [activeTaxpayerFilter, activeIssueDateFilter, activeDueDateFilter, activePaymentFilter, activeIssueDatePeriodFilter, activeDueDatePeriodFilter, activePaymentPeriodFilter])

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap">
				<div className="flex flex-column align-items-flex-start tiny-gap">
					<LinkButton to="/supplier/transactions" hierarchy="inferior">
						<FontAwesomeIcon icon={faArrowLeft} />
						<span>Transações</span>
					</LinkButton>
					<PageTitle title="Emitir Relatório" />
				</div>

				<div className="grid small-gap fill-normal">
					<SelectInput
						label="Tipo de relatório"
						name="reportType"
						value={reportType}
						onChange={event => setReportType(Number(event.target.value))}
					>
						<option value={0}>Contas a pagar</option>
						<option value={1}>Contas a receber</option>
						<option value={2}>Contas pagas</option>
						<option value={3}>Contas recebidas</option>
					</SelectInput>
				</div>

				{noTransactionsAvailable === true && (
					<span className="small bold-font red">Nenhuma transação disponivel!</span>
				)}

				<div className="flex flex-column small-gap">

					<CheckboxInput
						label="Fornecedor ou cliente"
						name="filter"
						value={activeTaxpayerFilter}
						onChange={() => setActiveTaxpayerFilter(prevState => !prevState)}
					/>

					{activeTaxpayerFilter === true && (
						<div className="grid fill-normal small-gap">
							<TextInput
								value={by}
								onChange={event => setBy(event.target.value.trim().replace(/\D/g, ""))}
							/>
						</div>
					)}

					{invalidTaxpayer === true && (
						<span className="red bold-font small">Fornecedor ou cliente não encontrado!</span>
					)}

					<CheckboxInput
						label="Data de emissão"
						name="filter"
						value={activeIssueDateFilter}
						onChange={() => setActiveIssueDateFilter(prevState => !prevState)}
					/>

					<CheckboxInput
						label="Data de vencimento"
						name="filter"
						value={activeDueDateFilter}
						onChange={() => setActiveDueDateFilter(prevState => !prevState)}
					/>

					{(reportType === 2 || reportType === 3) && (
						<CheckboxInput
							label="Data de pagamento"
							value={activePaymentFilter}
							onChange={() => setActivePaymentFilter(prevState => !prevState)}
						/>
					)}

					<div className="flex tiny-gap align-items-flex-start">

						{activeIssueDateFilter === true && (

							<div className="tiny-margin grid medium-gap  left-items element-border">

								<span className="bold-font medium">
									{activeIssueDatePeriodFilter === false ? "Data de emissão" : "Data de emissão inicial"}
								</span>

								<DatePickerSelect
									value={issueDate}
									timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
									setDate={(date) =>
										setIssueDate(
											new Date(
												date.year,
												date.month - 1,
												date.day
											)
										)
									}
								/>

								{activeIssueDatePeriodFilter === true && (
									<>
										<span className="bold-font medium">Data de emissão final</span>
										<DatePickerSelect
											value={issueDateFinal}
											timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
											setDate={(date) => setIssueDateFinal(
												new Date(
													date.year,
													date.month - 1,
													date.day
												)
											)}
										/>
									</>
								)}

								<CheckboxInput
									label="Filtrar por período"
									value={activeIssueDatePeriodFilter}
									onChange={() => setActiveIssueDatePeriodFilter(prevState => !prevState)}
								/>

							</div>
						)}

						{activeDueDateFilter === true && (
							<div className="tiny-margin grid medium-gap  left-items element-border">

								<span className="bold-font medium">
									{activeDueDatePeriodFilter === false ? "Data de vencimento" : "Data de vencimento inicial"}
								</span>

								<DatePickerSelect
									value={dueDate}
									timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
									setDate={(date) =>
										setDueDate(
											new Date(
												date.year,
												date.month - 1,
												date.day
											)
										)
									}
								/>
								{activeDueDatePeriodFilter === true && (
									<>
										<span className="bold-font medium">Data de vencimento final</span>
										<DatePickerSelect
											value={dueDateFinal}
											timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
											setDate={(date) => setDueDateFinal(
												new Date(
													date.year,
													date.month - 1,
													date.day
												)
											)}
										/>
									</>
								)}

								<CheckboxInput
									label="Filtrar por período"
									value={activeDueDatePeriodFilter}
									onChange={() => setActiveDueDatePeriodFilter(prevState => !prevState)}
								/>
							</div>
						)}

						{((activePaymentFilter === true) && ((reportType === 2) || (reportType === 3))) && (
							<div className="tiny-margin grid medium-gap  left-items element-border">

								<span className="bold-font medium">
									{activePaymentPeriodFilter === false ? "Data de pagamento" : "Data de pagamento inicial"}
								</span>

								<DatePickerSelect
									value={paymentDate}
									timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
									setDate={(date) =>
										setPaymentDate(
											new Date(
												date.year,
												date.month - 1,
												date.day
											)
										)
									}
								/>

								{activePaymentPeriodFilter === true &&
									<>
										<span className="bold-font medium">Data de pagamento final</span>
										<DatePickerSelect
											value={paymentDateFinal}
											timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
											setDate={(date) => setPaymentDateFinal(
												new Date(
													date.year,
													date.month - 1,
													date.day
												)
											)}
										/>
									</>
								}

								<CheckboxInput
									label="Filtrar por período"
									value={activePaymentPeriodFilter}
									onChange={() => setActivePaymentPeriodFilter(prevState => !prevState)}
								/>
							</div>
						)}
					</div>
				</div>

				<div>
					<MainButton onClick={() => handlePrintReport()}>
						<span>Emitir relatório</span>
					</MainButton>
				</div>
			</div>
		</SupplierRoleNavbar>
	)
}
