// Utils
import { REGION_LIST_BR } from "offiziersmesser/lib/utils"

// Models
import { LocalityModel } from "../../../../models/user"

// Components
import TextInput from "../../../standard/TextInput"
import SelectInput from "../../../standard/SelectInput"
import TextInputPostal from "../../../custom/TextInputPostal/TextInputPostal"

type DisableableFields = Array<"postal" | "road" | "number" | "area" | "extra" | "city" | "region">

type LocalityFormProps = {
	formTitle?: string,
	disabledFields?: DisableableFields,
	locality: LocalityModel,
	setLocality: (value: any) => void,
}

export default function LocalityForm({
	formTitle = "Endereço",
	disabledFields,
	locality,
	setLocality,
}: LocalityFormProps) {

	// Handlers
	/**
	 * This function updates the user's locality information and triggers a form
	 * change event.
	 * @param {string} target - a string representing the property of the user object
	 * that needs to be updated.
	 * @param {string} value - The value that will be assigned to the specified target
	 * property in the user object.
	 */
	function handleSetLocality(
		target: string,
		value: string,
	) {
		setLocality({
			...locality,
			[target]: value
		})
	}

	return (
		<div className="grid medium-gap">
			<span className="bold-font big-x">
				{formTitle}
			</span>

			<div className="grid small-gap fill-normal">
				<TextInputPostal
					name="postal"
					value={locality?.postal}
					label="CEP"
					autofill={true}
					onChange={(values: LocalityModel) => setLocality({ ...locality, ...values })}
					disabled={disabledFields?.includes("postal")}
				/>
				<TextInput
					name="number"
					label="Número"
					placeholder="101"
					value={locality?.number}
					onChange={e => handleSetLocality(e.target.name, e.target.value.replace(/\D/g, ""))}
					disabled={disabledFields?.includes("number")}
				/>
				<TextInput
					name="road"
					label="Rua"
					placeholder="Getúlio Vargas"
					value={locality?.road}
					onChange={e => handleSetLocality(e.target.name, e.target.value)}
					disabled={disabledFields?.includes("road")}
				/>
				<TextInput
					name="area"
					label="Bairro"
					placeholder="Centro"
					value={locality?.area}
					onChange={e => handleSetLocality(e.target.name, e.target.value)}
					disabled={disabledFields?.includes("area")}
				/>
				<TextInput
					name="extra"
					label="Complemento"
					value={locality?.extra}
					onChange={e => handleSetLocality(e.target.name, e.target.value)}
					disabled={disabledFields?.includes("extra")}
				/>
				<TextInput
					name="city"
					label="Cidade"
					placeholder="Rio de Janeiro"
					value={locality?.city}
					onChange={e => handleSetLocality(e.target.name, e.target.value)}
					disabled={disabledFields?.includes("city")}
				/>
				<SelectInput
					name="region"
					label="Estado"
					value={locality?.region}
					onChange={e => handleSetLocality(e.target.name, e.target.value)}
					disabled={disabledFields?.includes("region")}
				>
					<option key={0} disabled={true} value="—"></option>,
					{REGION_LIST_BR.map((entry, index) => (
						<option key={index + 1} value={entry.region}>
							{entry.region}
						</option>
					))}
				</SelectInput>
			</div>
		</div>
	)
}
