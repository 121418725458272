import { useSelector } from "react-redux"

// Components
// import HorizontalSellerNavbar from "../../../HorizontalSellerNavbar"
import { BalancesCardRow } from "./BalancesCardRow"
import LinkButton from "../../../../../shared/components/standard/LinkButton"

export default function DetailsOutFlow() {
	const balance = useSelector(state => state.balances)

	return (
		<>
			<LinkButton to="/seller/balances">
				<span>Caixa</span>
			</LinkButton>

			<BalancesCardRow transactions={balance.transactions.outflow} />
		</>
	)
}
