// Models
import { UserModel /*, SefazModel*/ } from "offiziersmesser/lib/models"

export default async function finishAuthorization(
	user: UserModel,
	passphrase: string,
	characters: string[],
	// sefaz?: SefazModel
) {
	const response = await fetch(`${process.env.REACT_APP_API_URL}/user/activated`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			administrative: user.administrative,
			// inscricoes: {
			// 	municipal: sefaz?.inscricaoMunicipal,
			// 	estadual: sefaz?.inscricaoEstadual,
			// 	estadualSt: sefaz?.inscricaoEstadualST
			// },
			code: characters.join(""),
			passphrase: passphrase,
			taxpayer: user.taxpayer,
		}),
	})

	if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

	const result = await response.json()
	return result
}
