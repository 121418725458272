// Components
import MainButton from "src/shared/components/standard/MainButton"
import MultipleSingleCharacterInputGroup from "src/shared/components/custom/MultipleSingleCharacterInputGroup/MultipleSingleCharacterInputGroup"

// Models
import { PhoneModel } from "offiziersmesser/lib/models"

// Services
import beginPhoneVerification from "../services/beginPhoneVerification"

type PhoneValidationFormProps = {
	characters: string[]
	phone: PhoneModel
	isCodeSent: boolean
	isCodeSentTwice: boolean
	setCharacters: (characters: string[]) => void
	setIsCodeSent: (isCodeSent: boolean) => void
	setIsCodeSentTwice: (isCodeSent: boolean) => void
}

function PhoneValidationForm({
	characters,
	phone,
	isCodeSent,
	isCodeSentTwice,
	setCharacters,
	setIsCodeSent,
	setIsCodeSentTwice,
}: PhoneValidationFormProps) {
	return (
		<div className="grid huge-gap">

			<div className="grid fill-huge huge-gap">
				{/*<span className="big bold-font">Verificação de celular</span>*/}
				<span>Enviamos um código para o seu celular, use-o para preencher os campos a seguir.</span>

				<div className="grid-columns small-gap left-items">
					<span>Não recebeu?</span>
					<MainButton
						hierarchy="minimal"
						disabled={isCodeSentTwice}
						onClick={
							() => beginPhoneVerification({
								phone,
								isCodeSent,
								setIsCodeSent,
								setIsCodeSentTwice
							})
						}>
						<span>{(isCodeSentTwice === false) ? "Enviar de novo" : "Já enviamos de novo"}</span>
					</MainButton>
				</div>

			</div>

			<MultipleSingleCharacterInputGroup
				characters={characters}
				setCharacters={(characters: string[]) => setCharacters(characters)}
			/>

		</div>
	)
}

export default PhoneValidationForm
