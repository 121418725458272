import { useMemo, useState,useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { XMLParser } from "fast-xml-parser"

import { createCanvas } from "canvas"
import JsBarcode from "jsbarcode"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import {
	getPostalData,
	REGION_LIST_BR,
	validateCnpj,
	decodeBase64Url,
	printContent
} from "offiziersmesser/lib/utils"

import { RenderDANFE } from "offiziersmesser/lib/renderers"

// Icons
import { faArrowLeft, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import MainButton from "src/shared/components/standard/MainButton"
import FormTabs from "src/shared/components/custom/FormTabs/FormTabs"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import ClientBlock from "./InsertInvoiceTabs/ClientBlock/ClientBlock"
import InvoiceBlock from "./InsertInvoiceTabs/InvoiceBlock"
import ProductsBlock from "./InsertInvoiceTabs/ProductsBlock/ProductsBlock"
import DeliveryBlock from "./InsertInvoiceTabs/DeliveryBlock"
import PaymentBlock from "./InsertInvoiceTabs/PaymentBlock"
import TaxBlock from "./InsertInvoiceTabs/TaxBlock"

// Styles
import "../Invoices.css"

export default function SellerInsertInvoice() {

	const navigate = useNavigate()
	const responseCodeHandler = useResponseCodeHandler()

	let {record} = useParams()

	// States
	const [availableCep, setAvailableCep] = useState(false)
	const [xmlError, setXmlError] = useState("")

	const [loadingData, setLoadingData] = useState(true)

	const [supplierData, setSupplierData] = useState()

	// ====================Invoices Block===============================
	const [tipoNota, setTipoNota] = useState("2")
	const [ambienteEmissao, setAmbienteEmissao] = useState("Producao")
	const [naturezaOperacao, setNaturezaOperacao] = useState("Venda")
	const [tipoOperacao, setTipoOperacao] = useState("1")
	const [nfeReferenciada, setNfeReferenciada] = useState([])
	const [finalidade, setFinalidade] = useState("Normal")
	// const [indPresenca, setIndPresenca] = useState()
	const [order, setOrder] = useState("")

	// =====================Payment Block================================
	// const [activeInstallments, setActiveInstallments] = useState(false)
	// const [expirationDate, setExpirationDate] = useState(Date.now())
	// const [issueDate, setIssueDate] = useState(Date.now())
	// const [payments, setPayments] = useState([])
	// const [value, setValue] = useState(0)

	// =====================Customer Block================================
	const [customerTaxpayer, setCustomerTaxpayer] = useState("")
	const [customerIe, setCustomerIe] = useState("")
	const [customerIeSt, setCustomerIeSt] = useState("")
	const [customerName, setCustomerName] = useState("")
	const [customerSurname, setCustomerSurname] = useState("")
 	const [customerCompany, setCustomerCompany] = useState("")
 	const [customerByname, setCustomerByname] = useState("")
	const [customerEmail, setCustomerEmail] = useState("")
	const [customerPhone, setCustomerPhone] = useState({
		prefix: "55",
		subscriber: "",
		destination: "",
	})
	const [customerArea, setCustomerArea] = useState("")
	const [customerCity, setCustomerCity] = useState("")
	const [customerRoad, setCustomerRoad] = useState("")
	const [customerExtra, setCustomerExtra] = useState("")
	const [customerNumber, setCustomerNumber] = useState("")
	const [customerPostal, setCustomerPostal] = useState("")
	const [customerRegion, setCustomerRegion] = useState("")
	//const country = "BR"

	// ===============Transportation not implemented yet===========
	const [transportSpecified, ] = useState(false)
	const [transporte, setTransporte] = useState({
		// frete: {
		//     "modalidade": "PorContaDoEmitente",
		//     "modalidade": "SemFrete",
		//     "valor": 0.0
		// },
		enderecoEntrega: {
			cpfCnpjDestinatario: "",
			inscriçãoEstadual: "",
			pais: "BR",
			uf: "MG",
			cidade: "",
			logradouro: "",
			numero: "",
			complemento: "",
			bairro: "",
			cep: "",
		},
		transportadora: {
			usarDadosEmitente: false,
			tipoPessoa: "J",
			cpfCnpj: "",
			nome: "",
			inscricaoEstadual: "",
			enderecoCompleto: "",
			cidade: "",
			uf: "MG",
		},
		veiculo: {
			placa: "",
			uf: "MG",
			rntc: "",
		},
		volume: {
			quantidade: 0.0,
			especie: "",
			marca: "",
			numeracao: "",
			pesoLiquido: 0.0,
			pesoBruto: 0.0,
		},
	})

	// ==========Find the supplier's UF code================

	const ufCode = REGION_LIST_BR.find(uf => !!supplierData ? supplierData.administrative.locality.region === uf.region : "")

	// ===================================================================
	const details = {
		model: "55",
		tipo: tipoOperacao, 			// 	Tipo de operação (0 - entrada, 1 - saida)
		natureza: naturezaOperacao, 	//	Defined in the description of taxation_br
		impressão: "1", 				// 	Hardcode because we'll only output portrait format at the moment
		idDest: "1", 					// 	Hardcode

		uf: !!supplierData ? supplierData.administrative.locality.region : "",		//	Supplier's UF
		cuf: !!ufCode ? ufCode.cod : "",										// 	Supplier's UF code
		municipio:!!supplierData ? supplierData.administrative.locality.city : "", 	//	Supplier's City

		ambiente: "2",
		serie:"1",			     // Serie da nota fiscal 	|
		lote: "1", 			     // Lote da nota fiscal    	|-> lote -> série -> número (000.000)

		emissão: "1",
		finalidade: "1", //Não será hardcode, terá nas regras
		indFinal: "1",//Não será hardcode, terá nas regras
		indPresenca: "1",
	}

	let customerData

	// const taxationRule = {
	// 	rule: {
	// 		type: tipoNota,
	// 		internal: {
	// 			modalidade: "",
	// 			aliquota: "",
	// 			aliquotaSt: "",
	// 			cfop: "5102",
	// 			cstCsosn: "102",
	// 		}
	// 	}
	// }
	// Parser variables
	const options = useMemo(() => ({ ignoreAttributes: false }), [])
	const parser = useMemo(() => new XMLParser(options), [options])

	const [nfeXML, setNfeXML] = useState("") // Xml without parser

	const [nfeXMLObj, setNfeXMLObj] = useState(null) //Parsed object

	// Variables that need to be reformated for NFe emission
	const [accessKey, setAccessKey] = useState("")

	// const [dateEmission, setDateEmission] = useState("")
	// const [hourEmission, setHourEmission] = useState("")

	// const [dateOut, setDateOut] = useState("")
	// const [hourOut, setHourOut] = useState("")

	const [printDanfe, setPrintDanfe] = useState(false)

	// Handlers
	function handlerCustomerData() {
		if (validateCnpj(customerTaxpayer) === true) {
			customerData = {
				taxpayer: customerTaxpayer,
				ie: customerIe,
				iest: customerIeSt,
				byname: customerByname,
				company: customerCompany,
				area: customerArea,
				city: customerCity,
				road: customerRoad,
				number: customerNumber,
				postal: customerPostal,
				region: customerRegion,
				extra: customerExtra,
				phone: [customerPhone.subscriber + customerPhone.destination]
			}
		} else {
			customerData = {
				taxpayer: customerTaxpayer,
				ie: customerIe, //***
				iest: customerIeSt, //***
				name: customerName,
				surname: customerSurname,
				area: customerArea,
				city: customerCity,
				road: customerRoad,
				number: customerNumber,
				postal: customerPostal,
				region: customerRegion,
				extra: customerExtra,
				phone: [customerPhone.subscriber + customerPhone.destination]
			}
		}

	}
	// // Format date coming from sefaz to renderNF-e format
	// function splitDateXML(datetime) {
	// 	const parts = datetime.split("T");
	// 	return parts[0];
	// }
	// // Format date coming from sefaz to renderNF-e format
	// function splitTimeXML(datetime) {
	// 	const parts = datetime.split("T");
	// 	return parts[1].slice(0, -6);
	// }
	// Removes NFe tag from access key
	function reformatNFeAccessKey(key) {
		const result = key.replace("NFe", "");
		return result
	}
	async function handlerNfeEmission () {

		handlerCustomerData()

		try{
			const token = getToken()
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/resource/supplier/invoice`,
				{
				method: "post",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body:JSON.stringify({
					order,
					details,
					customerData
				}),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()
			setNfeXML(decodeBase64Url(data.xml))
			setPrintDanfe(true)
			setXmlError(data.errorMessage)

		}catch(error){
			console.error(error)
		}
	}

	async function getOrderDetails(order) {

		try{
			const token = sessionStorage.getItem('token')
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/seller/order/${order}`, {
				headers: { Authorization: `Bearer ${token}` },
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const orderDetails = await response.json()
			return orderDetails

		}catch(error){
			console.error(error)
		}
	}

	// Effects
	useEffect(() => {

		(async () => {

			if (record !== null) {
				try {
					setLoadingData(true)

					const orderDetails = await getOrderDetails(record)

					setTipoNota("1")

					setOrder(orderDetails.record)

					setCustomerName(orderDetails.customer.reference.name)
					setCustomerSurname(orderDetails.customer.reference.surname)

					setCustomerCompany(orderDetails.customer.company)
					setCustomerByname(orderDetails.customer.byname)

					setCustomerEmail(orderDetails.customer.reference.email)

					setCustomerPhone(orderDetails.customer.reference.phone)

					setCustomerArea(orderDetails.customer.locality.area)
					setCustomerCity(orderDetails.customer.locality.city)
					setCustomerRoad(orderDetails.customer.locality.road)
					setCustomerExtra(orderDetails.customer.locality.extra)
					setCustomerNumber(orderDetails.customer.locality.number)
					setCustomerPostal(orderDetails.customer.locality.postal)
					setCustomerRegion(orderDetails.customer.locality.region)
				} catch (error)  { console.error(error)}
			}
		})()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [record])

	useEffect(() => {
		if (customerPostal.replaceAll(/-|_/g, "").length !== 8) {
			return
		}

		(async () => {
			const postalData = await getPostalData(customerPostal)
			setAvailableCep(true)
			setCustomerPostal(customerPostal)
			setCustomerArea(postalData.bairro)
			setCustomerRoad(postalData.logradouro)
			setCustomerCity(postalData.localidade)
			setCustomerRegion(postalData.uf)
		})()
	}, [customerPostal])

	// UseEffect to parser XML and set variables that need to be formated for NFe emission
	useEffect(() => {

		if (!nfeXML) return

		//parser xml to json
		const xml = parser.parse(nfeXML)

		if (xml) {
			return(
				setNfeXMLObj(xml),
				setAccessKey(reformatNFeAccessKey(xml.nfeProc.NFe.infNFe['@_Id']))
				// setDateEmission(splitDateXML(xml.nfeProc.NFe.infNFe.ide.dhEmi)),
				// setHourEmission(splitTimeXML(xml.nfeProc.NFe.infNFe.ide.dhEmi)),
				// setDateOut(splitDateXML(xml.nfeProc.NFe.infNFe.ide.dhSaiEnt)),
				// setHourOut(splitTimeXML(xml.nfeProc.NFe.infNFe.ide.dhSaiEnt))
			)
		}

	}, [nfeXML, parser])


	// UseEffect to bypass promisse delay
	useEffect(() => {
		if(printDanfe && nfeXMLObj?.nfeProc !== undefined) {
			const canvaBarcode = createCanvas()
			JsBarcode(canvaBarcode,accessKey,{ format: "CODE128", displayValue: false })
			const dateOut = new Date(nfeXMLObj.nfeProc.NFe.infNFe.ide.dhSaiEnt)
			const dateEmission = new Date(nfeXMLObj.nfeProc.NFe.infNFe.ide.dhEmi)
			printContent(RenderDANFE(nfeXMLObj, dateEmission.toLocaleDateString(), dateEmission.toLocaleDateString(), dateOut.toLocaleDateString(), dateOut.toLocaleDateString(), accessKey, canvaBarcode.toDataURL()))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nfeXMLObj, printDanfe])

	const getSupplierData = async () => {
		try{
			const token = getToken()
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/resource/seller/supplier/data`,
				{
				headers: {
					"Authorization": `Bearer ${token}`
				}
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()

			setSupplierData(data)
			setLoadingData(false)

		}catch(error){
			console.error(error)
		}
	}
    useEffect(() => {
		getSupplierData()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap">
				{loadingData === true ? (
					<>
						<MainButton hierarchy="inferior" onClick={() => navigate(-1)} >
							<FontAwesomeIcon icon={faArrowLeft} />
							<span>Pedidos</span>
						</MainButton>
						<ComponentLoader />
					</>
				) : (
					<>
						<div className="flex">
							<MainButton hierarchy="inferior" onClick={() => navigate(-1)} >
								<FontAwesomeIcon icon={faArrowLeft} />
								<span>Pedidos</span>
							</MainButton>
						</div>

                        <Notice tone="info">
                            <span> <FontAwesomeIcon icon={faExclamationCircle} /> Todas as notas fiscais deverão ser emitidas para<b> o mesmo estado.</b> Logo mais ficará disponível emissões para o Brasil todo!.</span>
                        </Notice>

						{(supplierData.taxation.nfSettings.ambiente === "2") && (
							<Notice tone="warning" to="/supplier/account">
								<span><FontAwesomeIcon icon={faExclamationTriangle} /> <b>Você esta em ambiente de homologação</b>. Para que suas notas tenham valor fiscal:</span>
							</Notice>
						)}

                        {(supplierData.taxation.csc.producao.code === "" || supplierData.taxation.csc.producao.token === "") && (supplierData.taxation.nfSettings.ambiente === "1") && (
							<Notice tone="warning" to="/supplier/account">
                                    <span> <FontAwesomeIcon icon={faExclamationTriangle} /> <b>É necessário cadastrar token e código do CSC de produção para prosseguir</b>.</span>
							</Notice>
						)}

                        {(supplierData.taxation.csc.homologacao.code === "" || supplierData.taxation.csc.homologacao.token === "") && (supplierData.taxation.nfSettings.ambiente === "2") && (
							<Notice tone="warning" to="/supplier/account">
								<span><FontAwesomeIcon icon={faExclamationTriangle} /><b>É necessário cadastrar token e código do CSC de homologação para prosseguir</b>.</span>
							</Notice>
						)}

                        {(xmlError !== "") && (
							<Notice tone="failure">
								<span><FontAwesomeIcon icon={faTimesCircle} /> {xmlError}</span>
							</Notice>
						)}
						<PageTitle title="Criar Nota" />

						{transportSpecified ? (
							<FormTabs
								tabs={[
									"Categorização",
									"Cliente",
									"Transporte",
									"Produtos",
									"Pagamento",
									"Impostos",
								]}
							>
								<InvoiceBlock
									tipoNota={tipoNota}                     setTipoNota={setTipoNota}
									ambienteEmissao={ambienteEmissao}       setAmbienteEmissao={setAmbienteEmissao}
									naturezaOperacao={naturezaOperacao}     setNaturezaOperacao={setNaturezaOperacao}
									tipoOperacao={tipoOperacao}             setTipoOperacao={setTipoOperacao}
									nfeReferenciada={nfeReferenciada}       setNfeReferenciada={setNfeReferenciada}
									finalidade={finalidade}                 setFinalidade={setFinalidade}
									order={order}                           setOrder={setOrder}
								/>
								<ClientBlock
									customerName={customerName}             setCustomerName={setCustomerName}
									customerSurname={customerSurname}       setCustomerSurname={setCustomerSurname}
									customerEmail={customerEmail}           setCustomerEmail={setCustomerEmail}
									customerPhone={customerPhone}           setCustomerPhone={setCustomerPhone}
									customerPostal={customerPostal}         setCustomerPostal={setCustomerPostal}
									customerArea={customerArea}             setCustomerArea={setCustomerArea}
									customerCity={customerCity}             setCustomerCity={setCustomerCity}
									customerRoad={customerRoad}             setCustomerRoad={setCustomerRoad}
									customerExtra={customerExtra}           setCustomerExtra={setCustomerExtra}
									customerNumber={customerNumber}         setCustomerNumber={setCustomerNumber}
									customerRegion={customerRegion}         setCustomerRegion={setCustomerRegion}
                                    customerTaxpayer={customerTaxpayer}     setCustomerTaxpayer={setCustomerTaxpayer}
									customerIe={customerIe}                 setCustomerIe={setCustomerIe}
									customerIeSt={customerIeSt}             setCustomerIeSt={setCustomerIeSt}
									customerCompany={customerCompany}       setCustomerCompany={setCustomerCompany}
									customerByname={customerByname}         setCustomerByname={setCustomerByname}

									availableCep={availableCep}
								/>
								<DeliveryBlock
									transporte={transporte}
									setTransporte={setTransporte}
								/>
								<ProductsBlock />
								<PaymentBlock />
								<TaxBlock />
							</FormTabs>
						) : (
							<FormTabs tabs={[ "Categorização", "Cliente" ]} >
								<InvoiceBlock
									tipoNota={tipoNota}                         setTipoNota={setTipoNota}
									ambienteEmissao={ambienteEmissao}           setAmbienteEmissao={setAmbienteEmissao}
									naturezaOperacao={naturezaOperacao}         setNaturezaOperacao={setNaturezaOperacao}
									tipoOperacao={tipoOperacao}                 setTipoOperacao={setTipoOperacao}
									nfeReferenciada={nfeReferenciada}           setNfeReferenciada={setNfeReferenciada}
									finalidade={finalidade}                     setFinalidade={setFinalidade}
									order={order}                               setOrder={setOrder}
								/>
								<ClientBlock
									customerName={customerName}                 setCustomerName={setCustomerName}
									customerSurname={customerSurname}           setCustomerSurname={setCustomerSurname}
									customerEmail={customerEmail}               setCustomerEmail={setCustomerEmail}
									customerPhone={customerPhone}               setCustomerPhone={setCustomerPhone}
									customerPostal={customerPostal}             setCustomerPostal={setCustomerPostal}
									customerArea={customerArea}                 setCustomerArea={setCustomerArea}
									customerCity={customerCity}                 setCustomerCity={setCustomerCity}
									customerRoad={customerRoad}                 setCustomerRoad={setCustomerRoad}
									customerExtra={customerExtra}               setCustomerExtra={setCustomerExtra}
									customerNumber={customerNumber}             setCustomerNumber={setCustomerNumber}
									customerRegion={customerRegion}             setCustomerRegion={setCustomerRegion}
									customerTaxpayer={customerTaxpayer}         setCustomerTaxpayer={setCustomerTaxpayer}
									customerIe={customerIe}                     setCustomerIe={setCustomerIe}
									customerIeSt={customerIeSt}                 setCustomerIeSt={setCustomerIeSt}
									customerCompany={customerCompany}           setCustomerCompany={setCustomerCompany}
									customerByname={customerByname}             setCustomerByname={setCustomerByname}
									availableCep={availableCep}
								/>
							</FormTabs>
						)}

						<div className="grid fill-normal">
							<MainButton onClick={() => handlerNfeEmission()}>
								<span>Emitir nota</span>
							</MainButton>
						</div>
					</>
				)}
			</div>
		</SupplierRoleNavbar>
	)
}
