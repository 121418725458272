/* eslint-disable react-hooks/exhaustive-deps */
// Library imports
import { useState, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"

// Icons
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Utils
import {
	calculateOrderPaymentCovered,
	calculateOrderSumPlusShipping,
} from "offiziersmesser/lib/helpers"
import {
	toFixedImproved,
	toUnsignedFloat,
} from "offiziersmesser/lib/utils"

// Store
import { setCart } from "../../../../../shared/store/slices/cartSlice"

// Components
import CheckoutPaymentCard from "./CheckoutPaymentCard"
import MainButton from "../../../../../shared/components/standard/MainButton"
import CheckboxInput from "../../../../../shared/components/standard/CheckboxInput"
import NumberInput from "../../../../../shared/components/standard/NumberInput"
import HideableBlock from "../../../../../shared/components/custom/HideableBlock/HideableBlock"
import Notice from "../../../../../shared/components/custom/Notice/Notice"

export default function CheckoutPaymentList({
	onCredit,
	setOnCredit,
	onCreditTotal,
	setOnCreditTotal
}) {

	const dispatch = useDispatch()

	// Store
	const cart = useSelector(state => state.cart)
	const guest = useSelector(state => state.guest)

	// States
	const [totalPayment, setTotalPayment] = useState(cart.payment.total)
	const total = calculateOrderSumPlusShipping(cart.data)
	const [additionArray, setAdditionArray] = useState([])
	const [additionTotal, setAdditionTotal] = useState(0)
	const [discount, setDiscount] = useState(0.0)



	// sum parts of payment
	function sumParts(cart) {
		let sum = 0

		if (cart.payment[0].parts.length === 0) return sum

		cart.payment[0].parts.map((part) => {
			return sum += part.value
		})

		return sum
	}

	const [covered, setCovered] = useState(sumParts(cart))

	// initial state part
	const part = useMemo(() => (
		{
			createdAt: 0, // Manual set if transaction is being custom-created, auto if its a heir
			value: toUnsignedFloat(total - covered - discount, 2), // Partial (of full) value paid
			method: 0, // Lookup table storing how the payment was done (Cash, credit card, etc)
			deduction: 0,
			extra: 0,
		}
	), [covered, discount, total])

	const handleOnCredit = async () => {
		setOnCreditTotal(onCreditTotal => !onCreditTotal)
	}

	// Effects

	// Create initial state of parts
	useEffect(() => {
		if (cart.payment[0].parts.length === 0) {
			dispatch(
				setCart({
					...cart,
					payment: [
						{
							...cart.payment[0],
							total,
							other: [0, 0],
							parts: [...cart.payment[0].parts, part],
						},
					],
					done: true,
				})
			)
			setAdditionArray([...additionArray, 0])
		}
	}, [])
	// apply and sum array addition
	useEffect(() => {

		if (cart.payment[0].parts.length !== 0) {

			setAdditionTotal(
				additionArray
					.map((addition) => addition)
					.reduce((prev, curr) => prev + curr, 0)
			)

			dispatch(
				setCart({
					...cart,
					payment: [
						{
							...cart.payment[0],
							total,
							other: [additionArray
								.map((addition) => addition)
								.reduce((prev, curr) => prev + curr, 0), toUnsignedFloat(discount, 2)]
						},
					],
				})
			)
		}
	}, [additionArray, discount])

	// set on credit partial cart
	useEffect(() => {
		if (onCredit) {
			dispatch(
				setCart({
					...cart,
					done: false,
				})
			)
		} else {
			dispatch(
				setCart({
					...cart,
					done: true,
				})
			)
		}
	}, [onCredit])
	// set total on credit cart
	useEffect(() => {
		if (onCreditTotal) {
			dispatch(
				setCart({
					...cart,
					payment: [
						{
							...cart.payment[0],
							total,
							parts: [],
						},
					],
					done: false,
				})
			)
			setAdditionArray([])
		} else {
			dispatch(
				setCart({
					...cart,
					payment: [
						{
							...cart.payment[0],
							total,
							parts: [
								{
									...part,
									value: total
								}],
						},
					],
					done: false,
				})
			)
		}
	}, [onCreditTotal])
	// Update covered
	useEffect(() => {
		let sum = 0
		cart.payment[0].parts.map((part) => {
			return sum += part.value
		})
		setCovered(sum + sumParts(cart))
		setTotalPayment(calculateOrderSumPlusShipping(cart.data))
		setCovered(calculateOrderPaymentCovered(cart))
	}, [cart])

	return (
		<div className="grid medium-gap">
			<div>
				{covered < totalPayment && covered !== 0 ? (

					<div className="media-title-relationships medium-margin-bottom">
						<span className="big-x bold-font">
							Especifique como pagar R${toFixedImproved(total - discount, 2)}
						</span>
					</div>
				) : (
					<span className="big-x bold-font">
						Especifique como pagar R${toFixedImproved(total - discount, 2)}
					</span>
				)}
			</div>

			<div className="CheckoutPaymentList-container">

				{!onCreditTotal && (
					<>
						{cart.payment[0].parts.length !== 0 && (
							cart.payment[0].parts.map((element, index) => (
								<CheckoutPaymentCard
									key={index}
									element={element}
									index={index}
									addition={additionArray}
									setAdditionArray={setAdditionArray}
									onCredit={onCredit}
									total={total}
									discount={discount}
									deleteable={cart.payment[0].parts.length > 1}
									disabled = {cart.payment[0].parts.length-1 != index}
								/>
							))
						)}

						<div>
							{covered < totalPayment - discount ? (
								<div className="grid medium-gap">
									<div className="flex">
										<MainButton
											onClick={() => {
												dispatch(
													setCart({
														...cart,
														payment: [
															{
																...cart.payment[0],
																parts: [
																	...cart
																		.payment[0]
																		.parts,
																	part,
																],
															},
														],
													})
												)
												setAdditionArray([
													...additionArray,
													0,
												])
											}}
											disabled={
												cart.payment[0].parts.some(
													(element) => element.value === 0 || isNaN(element.value)
												) === true || onCredit === true
											} // Do not let a new instance to be created if any existing one has a value of zero
										>
											Adicionar outro pagamento
										</MainButton>
									</div>

									<Notice tone="info">
										<div className="big bold-font flex align-items-center small-gap">
											<FontAwesomeIcon className="fa-icon" icon={faTimesCircle} />
											<span>Restam {(totalPayment - covered).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
										</div>
									</Notice>
								</div>
							) : (covered === (totalPayment - discount)) ? (
								<Notice tone="success">
									<div className="big bold-font flex align-items-center small-gap">
										<FontAwesomeIcon className="fa-icon" icon={faCheckCircle} />
										<span>Pagamento especificado</span>
									</div>
								</Notice>

							) : (
								<Notice tone="warning">
									<div className="big bold-font flex align-items-center small-gap">
										<FontAwesomeIcon className="fa-icon" icon={faTimesCircle} />
										<span>Pagamento excedendo fatura - Troco R$ {toFixedImproved(covered - totalPayment, 2)}</span>
									</div>
								</Notice>
							)}
						</div>

						<div className="grid-columns small-gap element-border payment-resume-card">
							<div className="grid-columns small-gap center-items center-text big bold-font yeloow">
								<span>Acrécimos: R$ {toFixedImproved(additionTotal, 2)}</span>
							</div>
							<div className="grid-columns small-gap center-items center-text big bold-font yeloow">
								<span>Desconto: R$ {toFixedImproved(discount, 2)}</span>
							</div>
							<div className="grid-columns small-gap center-items center-text big bold-font orange">
								<span>Total: R$  {toFixedImproved(additionTotal + total - discount, 2)}</span>
							</div>
						</div>

						{covered < (total - discount) && (
							<CheckboxInput
								label="Restante à prazo"
								disabled={covered === total}
								checked={onCredit === true}
								onChange={() => setOnCredit(onCredit => !onCredit)}
							/>
						)}
					</>
				)}
				{guest.assessments.clearence &&
					<CheckboxInput
						label="Pagamento totalmente à prazo"
						checked={onCreditTotal === true}
						onChange={() => handleOnCredit()}
					/>
				}

				<HideableBlock blockTitle="Aplicar desconto">
					<div className="grid fill-normal medium-margin-horizontal large-margin-bottom">
						<NumberInput
							label="Valor"
							value={discount}
							onChange={(e) =>
								setDiscount(
									e.target.value === ""
										? 0
										: Number(e.target.value)
								)
							}
						/>
					</div>
				</HideableBlock>
			</div>
		</div>
	)
}
