import { useEffect } from "react"

// Components
import TextInputPassphrase from "../TextInputPassphrase/TextInputPassphrase"

export default function PassphraseInputValidationStatusesGroup({
	passphrase,
	setPassphrase,
	setIsPassphraseStrong
}) {
	const getUnmetConditions = () => {
		const unmetConditions = []

		if (!/[A-Za-z\d@$!%*?&]{8,}/.test(passphrase))
			unmetConditions.push("8 caracteres")
		if (!/(?=.*\d)/.test(passphrase))
			unmetConditions.push("um número")
		if (!/(?=.*[@$!%*?&])/.test(passphrase))
			unmetConditions.push("um caractere especial")
		if (!/(?=.*[a-z])(?=.*[A-Z])/.test(passphrase))
			unmetConditions.push("uma letra maiúscula e uma minúscula")

		return unmetConditions
	}
	const unmetConditions = getUnmetConditions()

	const getIsPassphraseStrong = () => unmetConditions.length === 0
	const isPassphraseStrong = getIsPassphraseStrong()

	useEffect(() => {
		setIsPassphraseStrong(isPassphraseStrong)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passphrase])

	const formatUnmetConditions = unmetConditions => {
		if (unmetConditions.length > 1) {
			const lastCondition = unmetConditions.pop()
			return `${unmetConditions.join(", ")} e ${lastCondition}`
		}
		return unmetConditions
	}

	return (
		<TextInputPassphrase
			label="Nova senha"
			value={passphrase}
			hint={passphrase !== "" ? (!isPassphraseStrong ? `A senha deve possuir ao menos ${formatUnmetConditions(unmetConditions)}` : "Sua senha é forte") : undefined}
			tone={passphrase !== "" ? (!isPassphraseStrong ? "failure" : "success") : undefined}
			onChange={event => setPassphrase(event.target.value)}
		/>
	)
}
