import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { resetBalances } from "../../../../shared/store/slices/balancesSlice"

// Utils
import {
	createArrayParts,
	createTotalParts,
} from "offiziersmesser/lib/helpers"

import { printContent } from "offiziersmesser/lib/utils"
import { RenderReportBalances } from "offiziersmesser/lib/renderers"

import { createToast } from "../../../../shared/store/slices/toastsSlice"

// Icons
import { faDollarSign, faReceipt, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import MainButton from "../../../../shared/components/standard/MainButton"
import RadioButtonInput from "../../../../shared/components/standard/RadioButtonInput"

import BalanceEntry from "./entry/BalanceEntry"
import BalanceOutflow from "./outflow/BalanceOutflow"
import PageTitle from "../../../../shared/components/custom/PageTitle/PageTitle"
import SellerBalancesInsertEntryOutflowModal from "../editor/SellerBalancesInsertEntryOutflowModal/SellerBalancesInsertEntryOutflowModal"

export default function BalancesResume({
	transactions,
	cashbox
}) {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()
	// Store
	const balances = useSelector(state => state.balances)

	// States
	const [balancesView, setBalancesView] = useState("opened") // <"opened", "closed">

	const [totalEntry, setTotalEntry] = useState(0)
	const [totalOutFlow, setTotalOutFlow] = useState(0)
	const [partsEntry, setPartsEntry] = useState([])
	const [partsOutFlow, setPartsOutFlow] = useState([])

	const [renderInsertCashModal, setRenderInsertCashModal] = useState(false)

	// Handlers
	async function handleCloseBalance() {

		const token = getToken()

		const response = await fetch(
			process.env.REACT_APP_API_URL + "/resource/seller/balance/active",
			{
			method: "patch",
			data: {
				cashbox,
				active: false,
			},
			headers: { Authorization: `Bearer ${token}` },
		})

		responseCodeHandler(response)
		if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

		dispatch(resetBalances())


	}
	function onRenderInsertCashModal() {
		setRenderInsertCashModal(prevState => !prevState)
	}

	// Effects
	// | sum total parts of balance
	useEffect(() => {

		if (transactions) {

			let total = 0
			let totalParts = []

			if (transactions?.entry?.length > 0) {

				setPartsEntry(createArrayParts(transactions?.entry))

				totalParts = createTotalParts(createArrayParts(transactions?.entry))

				totalParts.map((part) => {
					return total += part.value
				})
				setTotalEntry(total)
			}

			if (transactions?.outflow?.length > 0) {
				setPartsOutFlow(transactions?.outflow)

				total = 0
				let parts = []

				transactions?.outflow.map((transaction) => {
					return parts = [...parts, ...transaction.payments[0].parts]
				})
				totalParts = createTotalParts(parts)

				totalParts.map((part) => {
					return total += part.value
				})

				setTotalOutFlow(total)
			}
		}
	}, [transactions])

	return (
		<div className="flex flex-column medium-gap wide high">
			{renderInsertCashModal === true && (
				<SellerBalancesInsertEntryOutflowModal
					renderModal={renderInsertCashModal}
					onRenderModal={() => onRenderInsertCashModal()}
					balances={balances}
				/>
			)}

			<PageTitle
				title="Caixa aberto"
				description={`Você possui um total de ${!!transactions?.entry ? transactions?.entry.length : 0} entradas totalizando ${totalEntry.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} e ${!!transactions?.outflow ? transactions?.outflow.length : 0} saídas totalizando - ${totalOutFlow.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}
			/>

			<div className="grid small-gap fill-large">
				<MainButton onClick={() => handleCloseBalance()}>
					<FontAwesomeIcon className="fa-icon" icon={faTimes} />
					<span>Fechar caixa</span>
				</MainButton>

				<MainButton
					hierarchy="low"
					onClick={() => onRenderInsertCashModal()}
				>
					<FontAwesomeIcon icon={faDollarSign} />
					<span>Adicionar entrada ou saída</span>
				</MainButton>

				<MainButton
					hierarchy="low"
					onClick={() => {

						if (balances.transactions.entry?.length > 0
							|| balances.transactions.outflow?.length > 0) {
							printContent(
								RenderReportBalances(
									balances.transactions?.entry,
									balances.transactions?.outflow,
									balances,
									balances.createdAt
								)
							)
						} else {
							dispatch(createToast({
								tone: "warning",
								miliseconds: 2000,
								message: "Não existem saídas ou entradas!",
								dismissable: true,
							}))
						}
					}}
				>
					<FontAwesomeIcon className="fa-icon" icon={faReceipt} />
					<span>Emitir resumo do caixa</span>
				</MainButton>
			</div>

			<div className="flex">
				<div className="grid fill-normal small-gap element-border">
					<span className="bold-font">Exibir</span>

					<div className="flex small-gap">
						<RadioButtonInput
							name="transactions"
							label="Entradas"
							value="0"
							defaultChecked={balancesView === "opened"}
							onChange={() => setBalancesView("opened")}
						/>
						<RadioButtonInput
							name="transactions"
							label="Saídas"
							defaultChecked={balancesView === "closed"}
							value="1"
							onChange={() => setBalancesView("closed")}
						/>
					</div>
				</div>
			</div>

			{(balancesView === "opened") ? (
				<BalanceEntry payments={partsEntry} />
			) : (
				<BalanceOutflow payments={partsOutFlow} />
			)}
		</div>
	)
}
