import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { resetGuest, setGuest } from "src/shared/store/slices/guestSlice"
import { setCartDeliveryAddress } from "src/shared/store/slices/cartSlice"

// Utils
import {
	cleanTaxpayer,
	validateTaxpayer,
	validateCnpj,
} from "offiziersmesser/lib/utils"

// Components
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"

// Styles
import "../Checkout.css"

export default function CustomerBlock({
	setAccountType,
	isTaxPayerValid,
	setIsTaxPayerValid,
	userExists,
	setUserExists,
	setIsCnpj,
}) {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const guest = useSelector(state => state.guest)

	// States
	const [guestName, setGuestName] = useState()

	// Handler
	async function handleAssociateClient() {
		const token = getToken()

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/seller/customer/taxpayer/addresses`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json"
				},
				body:JSON.stringify({taxpayer:guest.taxpayer})
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()


			dispatch(setGuest(data))
			setGuestName(data.administrative.reference.name)

			// props.setLoaded(true)
			setAccountType(2)
		} catch (error) {
			console.error(error)
		}
	}

	function handleSetIfExists(event) {
		dispatch(
			setGuest({
				...guest,
				taxpayer: cleanTaxpayer(event.target.value),
			})
		)
		setIsTaxPayerValid(validateTaxpayer(event.target.value))
		setIsCnpj(validateCnpj(event.target.value))
	}

	// Effects
	useEffect(() => {
		dispatch(resetGuest())
		dispatch(
			setCartDeliveryAddress({
					reference: {
						name: "",
						surname: "",
						phone: {
							prefix: "55",
							destination: "",
							subscriber: "11"
						},
						company: "",
						email: ""
					},
					locality: {
						road: "",
						number: "",
						extra: "",
						area: "",
						city: "",
						postal: "",
						region: "",
						country: ""
					}
			})
		)
		setAccountType(0)
		setIsTaxPayerValid(false)
		setUserExists(false)
		setIsCnpj(false)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (guest.taxpayer.length < 11) return

		setGuestName("")
		setUserExists(false)

		const fetchData = async () => {
			const token = getToken()

			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/seller/customer/taxpayer/addresses`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"content-type": "application/json"
					},
					body:JSON.stringify({taxpayer:guest.taxpayer})
				})

				responseCodeHandler(response)
				if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				const data = await response.json()
				setGuestName(data.administrative.reference.name)
			} catch (error) {
				console.error(error)
				setUserExists(false)
			}
		}

		fetchData()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [guest, setUserExists])

	useEffect(() => {
		if (guest.taxpayer.length !== 11 || guest.taxpayer.length !== 14) { setUserExists(false) }
	}, [guest.taxpayer, setUserExists])

	return (
		<div className="CostumerBlockContainer">

			<div className="grid center-items small-gap wide-children">

				<span className="big bold-font center-text medium-margin-bottom">
					Venda rápida
				</span>

				<div className="grid-columns fill-normal small-gap wide-children">
					<div className="medium-margin-top grid center-items wide-children tiny-margin-bottom">

						<TextInput
							label="CPF"
							value={guest.taxpayer}
							placeholder="Exemplo: 999.999.999-99"
							onChange={e => handleSetIfExists(e)}
						/>

						{(isTaxPayerValid !== true)
							&& (typeof guest.taxpayer === "string")
							&& (guest.taxpayer.length === 11 || guest.taxpayer.length === 14) && (
								<span className="center-text regular-font tiny red small-margin-top">
									Por favor, informe um CPF válido!
								</span>
							)
						}

						{userExists === true && (guest.taxpayer.length === 11 || guest.taxpayer.length === 14) && (
							<div className="grid center-items small-margin-top wide-children">
								<MainButton
									disabled={guest.taxpayer === ""}
									onClick={() => handleAssociateClient()}
								>
									<span>Associar Cliente {guestName}</span>
								</MainButton>
								<spa className="center-text small-margin-top">ou</spa>
							</div>
						)}
					</div>
				</div>

				{userExists === false && isTaxPayerValid === true && (
					<>
						<MainButton onClick={() => setAccountType(1)}>
							<span>Criar conta</span>
						</MainButton>
						<span className="center-text small-margin-top">ou</span>
					</>
				)}

				<MainButton
					hierarchy="low"
					onClick={() => {
						setAccountType(2)
						dispatch(setGuest({ ...guest, registered: false }))
					}}
				>
					<span>Continuar sem conta</span>
				</MainButton>
			</div>
		</div>
	)
}
