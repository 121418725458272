import { createSlice } from "@reduxjs/toolkit"

// Models
import ItemModel from "../../models/item"

const initialState: ItemModel[] = []

const itemsSlice = createSlice({
	name: "items",
	initialState,
	reducers: {
		setItems: (state, action) => {
			return state = action.payload
		}
	}
})

export default itemsSlice.reducer
export const { setItems } = itemsSlice.actions
