import Header from "./Header"
import { Rows } from "./Rows"

interface Order  {
    record: string;
    createdAt: number;
    statuses: Array<{ bias: number; note: string }>;
	contents:[]
}

interface PropsType  {
    orders: Order[]
    setSelectedOrders: any
    selectedOrders: any
}

export function Table({orders, setSelectedOrders,selectedOrders}:PropsType) {

    return(
        <div className="table-wrapper">
            <table>
                <tbody>
                <Header />
					{orders.map((order, index) => (
						<Rows
							order={order}
                            setSelectedOrders={setSelectedOrders}
                            selectedOrders={selectedOrders}
							key={index}
						/>
					))}
                </tbody>
            </table>
        </div>
    )
}
