import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"

// Utils
import insertItem from "../../services/insertItem"
import updateItem from "../../services/updateItem"
import validateEssentialsFields from "../../validators/validateEssentialsFields"

// Store
import { RootState } from "src/shared/store/reducers"
import { resetWorkableItems } from "src/shared/store/slices/workableItemsSlice"

// Components
import LinkButton from "src/shared/components/standard/LinkButton"
import MainButton from "src/shared/components/standard/MainButton"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"

import ItemsForm from "./ItemsForm"

type InsertUpdateItemProps = {
	update: boolean
}

export default function InsertUpdateItemHandler({
	update,
}: InsertUpdateItemProps) {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	// Store
	const workableItem = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)
	const workableImages = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.images)
	const user = useSelector((state: RootState) => state.user)

	// Handlers
	async function handleInsertUpdate() {
		try {
			if (update === true)
				await updateItem(
					workableItem,
					workableImages,
					workableItem.accounting.taxation,
					{
						ncm: workableItem.accounting?.details.ncm || "",
						cest: workableItem.accounting?.details.cest || "",
						origem: workableItem.accounting?.details.origin || "0"
					},
					user.alias
				)
			else
				await insertItem(
					workableItem,
					workableImages,
					workableItem.accounting.taxation,
					{
						ncm: workableItem.accounting?.details.ncm || "",
						cest: workableItem.accounting?.details.cest || "",
						origem: workableItem.accounting?.details.origin || "0"
					},
					user.alias
				)
			navigate("/supplier/items")
		}
		catch (error) {
			console.error("Failed to insert or update item", error)
		}
		finally {
			dispatch(resetWorkableItems())
		}
	}

	return (
		<div className="grid medium-gap">
			<div>
				<LinkButton hierarchy="inferior" to="/supplier/items">
					<FontAwesomeIcon
						className="fa-icon"
						icon={faLongArrowAltLeft as Icon}
					/>
					<span>Itens</span>
				</LinkButton>
			</div>

			<div className="grid-columns space-between-items">
				<PageTitle title={update === true ? `${workableItem?.control?.description}` : "Novo item"} />

				<MainButton onClick={() => handleInsertUpdate()} disabled={!validateEssentialsFields(workableItem)}>
					<span>{update === true ? "Salvar item" : "Criar item"}</span>
				</MainButton>
			</div>

			<ItemsForm />
		</div>
	)
}


		// <div className="grid medium-gap">
		// 	<div className="grid-columns space-between-items">
		// 		<div className="flex small-gap left-items">
		// 			<LinkButton hierarchy="inferior" to="/supplier/items">
		// 				<FontAwesomeIcon
		// 					className="fa-icon"
		// 					icon={faLongArrowAltLeft as Icon}
		// 				/>
		// 				<span>Itens</span>
		// 			</LinkButton>

		// 			<PageTitle title={update === true ? `${workableItem?.control?.description}` : "Novo item"} />
		// 		</div>

		// 		<MainButton onClick={() => handleInsertUpdate()} disabled={!validateEssentialsFields(workableItem)}>
		// 			<span>{update === true ? "Salvar item" : "Criar item"}</span>
		// 		</MainButton>
		// 	</div>

		// 	<ItemsForm />
		// </div>
