import { createSlice } from "@reduxjs/toolkit"

// Models

const initialState = {} as any

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			return state = action.payload
		},
		resetUser: state => {
			return state = initialState
		},
	}
})

export default userSlice.reducer
export const { setUser, resetUser } = userSlice.actions
