import { useEffect, useState } from "react"

// Icons
import {
	faAngleDown,
	faAngleRight,
	faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Utils
import { toFixedImproved } from "offiziersmesser/lib/utils"
import { calculateItemPrice } from "offiziersmesser/lib/helpers"

// Components
import MainButton from "src/shared/components/standard/MainButton"
import HideableBlock from "src/shared/components/custom/HideableBlock/HideableBlock"
import NumberInput from "src/shared/components/standard/NumberInput"

import "../../../../../seller/store/cart/CheckoutItemCard/CheckoutItemCard"

export default function CardItemInvoices({ item }) {

	// Store
	const [discount, setDiscount] = useState(0)
	const [quantity, setAmount] = useState(1)
	const [, setPrice] = useState()

	const [expand, setExpand] = useState(false)

	// Effects
	useEffect(() => {
		if (discount > 0) {
			setPrice(
				toFixedImproved(
					calculateItemPrice(item) * quantity, 2)
				- (toFixedImproved(calculateItemPrice(item) * quantity, 2)
					* discount / 100
				)
			)
		} else {
			setPrice(toFixedImproved(calculateItemPrice(item) * quantity, 2))
		}
	}, [discount, quantity, item])

	return (
		<div className="grid center-items small-margin-bottom">
			<div className="card-items-container element-border">
				<div className="grid-columns space-between-items medium-gap">

					<button className="card-items-expand" onClick={() => setExpand(!expand)}>
						<FontAwesomeIcon
							className="fa-icon medium"
							icon={expand === false ? faAngleDown : faAngleRight}
						/>
					</button>

					<p className="black bold-font">{quantity}</p>
					<p className="bold-font tiny card-items-description">{item.control.description}</p>

					<div className="grid-columns space-between-items">
						{discount > 0 ? (
							<div className="small-gap">
								<span className="line-through-text midgrey tiny">
									R$ {toFixedImproved(calculateItemPrice(item) * quantity, 2)}
								</span>
								<span className="orange bold-font">
									R$ {(toFixedImproved(calculateItemPrice(item) * quantity, 2)
										- (toFixedImproved(calculateItemPrice(item) * quantity, 2) * discount / 100))}
								</span>
							</div>
						) : (
							<span className="orange bold-font">
								R$ {toFixedImproved(calculateItemPrice(item) * quantity, 2)}
							</span>
						)}

					</div>

					<MainButton
						hierarchy="inferior"
					// className="card-items-button"
					>
						<FontAwesomeIcon className="fa-icon medium" icon={faTimes} />
					</MainButton>
				</div>
			</div>


			{expand === true && (
				<div className="card-items-expand-container">

					<HideableBlock blockTitle="Redefinir quantidades">
						<div className="grid-columns tiny-gap fill-small">
							<NumberInput
								label="Quantidade"
								value={quantity}
								onChange={event => {
									const value = Number(event.target.value)
									const maxQuantity = (item.discount.quota === 0 ? item.control.quantity : item.discount.quota)
									// control.available === false means the item can be ordered at ad infinitum number
									setAmount(
										(item.control.fractionable === false && value < 1)
											|| isNaN(value) ? 1 : item.control.available === false ? (value) : (value > maxQuantity ? maxQuantity : value)
									)
								}}
							/>
						</div>
					</HideableBlock>

					{item.discount.custom === true && (

						<HideableBlock blockTitle="Desconto">
							<NumberInput
								label="Percentual"
								value={discount}
								disabled={(item.discount.custom === false)}
								onChange={event => {
									const discount = event.target.valueAsNumber
									setDiscount(
										discount < 0 || isNaN(discount) ? 0 : discount
									)
								}}
							/>
						</HideableBlock>
					)}
				</div>
			)}
		</div>
	)
}

