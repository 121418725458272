import { useSelector, useDispatch } from "react-redux"

// Store
import { setCart } from "../../../../../shared/store/slices/cartSlice"

// Utils
import { toUnsignedFloat, UNITS_TABLE_BR } from "offiziersmesser/lib/utils"

// Components
import NumberInput from "../../../../../shared/components/standard/NumberInput"
import SelectInput from "../../../../../shared/components/standard/SelectInput"

export default function CheckoutShipping() {

	const dispatch = useDispatch()

	// Store
	const cart = useSelector(state => state.cart)

	return (
		<div className="flex flex-column medium-gap">
			<div className="medium-margin-top medium-margin-bottom">
				<span className="big-x bold-font small-margin-bottom">Defina os detalhes do frete</span>
				<span>O valor do frete é o custo fixo + custo variável taxa × unidade de distância percorrida</span>
			</div>

			<div className="medium-margin-top grid medium-gap fill-normal center-items center-text wide-children">

				<NumberInput
					label="Custo fixo"
					value={cart.data.delivery.shipping.ante}
					disabled={cart.data.delivery.shipping.custom === false}
					onChange={e => {
						const ante = e.target.valueAsNumber
						dispatch(
							setCart({
								...cart,
								data: {
									...cart.data,
									delivery: {
										...cart.data.delivery,
										shipping: {
											...cart.data.delivery.shipping,
											ante:
												ante < 0 || isNaN(ante)
													? 0
													: toUnsignedFloat(ante, 2), // Ante must be any big-x than 0
										},
									},
								},
							})
						)
					}}
				/>

				<NumberInput
					label="Taxa"
					value={cart.data.delivery.shipping.fee}
					disabled={cart.data.delivery.shipping.custom === false}
					onChange={e => {
						const fee = e.target.valueAsNumber
						dispatch(
							setCart({
								...cart,
								data: {
									...cart.data,
									delivery: {
										...cart.data.delivery,
										shipping: {
											...cart.data.delivery.shipping,
											fee:
												fee < 0 || isNaN(fee)
													? 0
													: toUnsignedFloat(fee, 2), // Fee must be positive
										},
									},
								},
							})
						)
					}}
				/>

				<SelectInput
					label="Unidade"
					value={cart.data.delivery.shipping.unit}
					disabled={cart.data.delivery.shipping.custom === false}
					onChange={e =>
						dispatch(
							setCart({
								...cart,
								data: {
									...cart.data,
									delivery: {
										...cart.data.delivery,
										shipping: {
											...cart.data.delivery.shipping,
											unit: e.target.valueAsNumber,
										},
									},
								},
							})
						)
					}
				>
					<option value={34}>{UNITS_TABLE_BR[34]}</option>
				</SelectInput>
			</div>
		</div>
	)
}
