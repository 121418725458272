import { createSlice, current } from "@reduxjs/toolkit"

const initialState = {
	data: {
		alias: "",
		seller: {
			internal: true,
			taxpayer: ""
		},
		buyer: {
			taxpayer: "",
			note: ""
		},
		contents: [],
		delivery: {
			atSupplier: true,
			shipping: {
				active: false,
				custom: false,
				fee: 0,
				ante: 0,
				unit: 0
			},
			address: {
				reference: {
					name: "",
					surname: "",
					phone: {
						prefix: "55",
						destination: "",
						subscriber: ""
					},
					title: "",
					company: "",
					email: ""
				},
				locality: {
					road: "",
					number: "",
					extra: "",
					area: "",
					city: "",
					postal: "",
					region: "",
					country: ""
				}
			}
		},
		status: 0
	},
	payment: [
		{
			total: 0,
			installment: {
				custom: false, // created in transactions
				period: 0,
				timespan: {
					unit: "day",
					value: 0 // In days
				},
				Interest: {
					compound: false,
					percentage: 0.0
				}
			},
			heir: null,
			parts: [],
			other: [0,0]
		}
	]

	// // 0 -> paid & waiting for supplier confirmation
	// // 1 -> paid & (but) canceled
	// // 2 -> paid & under preparation
	// // 3 -> paid & ready for taking out at supplier
	// // 4 -> paid & ongoing delivery
	// // 5 -> paid & delivery stalled/facing issues
	// // 6 -> paid & order closed with success

	// // user: xyx, // Customer uuid
	// // supplier: xyy, // Company uuid
	// contents: [], // Stores the list of items and the information assigned to it for later purchase
	// delivery: false,
	// address: {},
	// note: ""

	// 0 -> Cash
	// 1 -> Debit card
	// 2 -> Credit card
	// 3 -> Cheque
	// 4 -> TED/DOC
	// 5 -> PIX
	//
}

function createOrIncrementContent(prevState, action) {

	if (action.payload.amount < 0) return // Just to be sure that no negative numbers will be processed

	const state = current(prevState)

	// Searches if the action.payload already exists
	return (state.data.contents.filter((item) => item.element.item === action.payload.element.item).length > 0)
		? state.data.contents.map(item => {
			if (item.element.item === action.payload.element.item) {

				return {
					...item,
					amount: ((item.amount >= item.element.control.amount)
						|| item.amount + action.payload.amount >= item.element.control.amount)
						? item.element.control?.amount
						: item.amount + action.payload.amount
				}
			}

			return item
		})
		: [...state.data.contents, action.payload]
}
function updateContent(prevState, action) {

	const state = current(prevState)

	return (state.data.contents.filter((item) => item.element.item === action.payload.element.item).length > 0) // Searches if the action.payload already
		? (state.data.contents.map((item) => {
			if (item.element.item === action.payload.element.item) {
				item = action.payload
			}
			return item
		}))
		: state.data.contents
}
function decrementContent(state, action) {

	if (action.payload.amount < 0) return // Just to be sure that no negative (amount) numbers are coming in

	// Searches if the action.payload already exists
	return (state.data.contents.filter((item) => item.element.item === action.payload.item.item).length > 0)
		? (state.data.contents.map((item) => {
			if (item.element.item === action.payload.item.item) {

				const difference = item.amount - action.payload.amount

				if (difference > 0) {
					// Not possible to decrease to a negative number
					if (difference > 0 && difference < 1) {

						// Between 0 and 1 only if the item is able to be fractionable
						if (item.element.control.fractionable === true) {
							item.amount = difference
						}
						// Otherwise the minimal value for it must be the minimum int value after 0
						else {
							item.amount = 1
						}
					} else {
						item.amount = difference
					}
				}
				return item
			}
			return null
		}))
		: state.data.contents
}
function removeContent(state, action) {
	return state.data.contents.filter((item) => item.element.item !== action.payload.element.item)
}

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		setCart: (state, action) => {
			return state = action.payload
		},
		resetCart: state => {
			return state = initialState
		},
		createOrIncrementCartContent: (state, action) => {
			return state = {
				...state,
				data: {
					...state.data,
					contents: createOrIncrementContent(state, action)
				}
			}
		},
		updateCartContent: (state, action) => {
			return state = {
				...state,
				data: { ...state.data, contents: updateContent(state, action) }
			}
		},
		decrementCartContent: (state, action) => {
			return state = {
				...state,
				data: {
					...state.data,
					contents: decrementContent(state, action)
				}
			}
		},
		removeCartContent: (state, action) => {
			return state = {
				...state,
				data: { ...state.data, contents: removeContent(state, action) }
			}
		},
		setCartDeliveryAddress: (state, action) => {
			return state = {
				...state,
				data: {
					...state.data,
					delivery: {
						...state.data.delivery,
						address: action.payload
					}
				}
			}
		},
		setCartSeller: (state, action) => {
			return state = {
				...state,
				data: { ...state.data, seller: action.payload }
			}
		},
	},
})

export default cartSlice.reducer
export const {
	setCart,
	resetCart,
	createOrIncrementCartContent,
	updateCartContent,
	decrementCartContent,
	removeCartContent,
	setCartDeliveryAddress,
	setCartSeller,
} = cartSlice.actions
