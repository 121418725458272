import  { useRef,  useEffect } from "react"

// Styles
import "./MultipleSingleCharacterInputGroup.css"

function generateBoxes(characters) {
	var boxes = []
	for (var i = 0; i < characters; i++) {
		boxes.push(<input key={i} className="single-character-input center-text" maxLength="1"/>)
	}
	return boxes
}

export default function MultipleSingleCharacterInputGroup({ characters, setCharacters }) {
	const formRef = useRef(null)

	useEffect(() => {
		var form = formRef.current
		const inputs = form.querySelectorAll("input")

		const KEYBOARDS = {
			backspace: 8,
			arrowLeft: 37,
			arrowRight: 39,
		}

		function handleInput(e) {
			const input = e.target
			const nextInput = input.nextElementSibling

			if (nextInput && input.value) {
				nextInput.focus()
				if (nextInput.value) {
					nextInput.select()
				}
			}
		}

		function handlePaste(e) {
			e.preventDefault()
			const paste = e.clipboardData.getData("text")
			inputs.forEach((input, i) => {
				input.value = paste[i] || ""
			})
		}

		function handleBackspace(e) {
			const input = e.target
			if (input.value) {
				input.value = ""
				return
			}

			if (input.previousElementSibling !== null)
				input.previousElementSibling.focus()
		}

		function handleArrowLeft(e) {
			const previousInput = e.target.previousElementSibling
			if (!previousInput) return
			previousInput.focus()
		}

		function handleArrowRight(e) {
			const nextInput = e.target.nextElementSibling
			if (!nextInput) return
			nextInput.focus()
		}

		function handleKeyUp(e) {
			if (e.keyCode === KEYBOARDS.backspace)
				handleBackspace(e)
			else if (e.keyCode === KEYBOARDS.arrowLeft)
				handleArrowLeft(e)
			else if (e.keyCode === KEYBOARDS.arrowRight)
				handleArrowRight(e)

			// Transmits the values to the parent component through the parents useState function passed down
			if (typeof setCharacters === "function")
				setCharacters([...inputs].map(e => e.value))
		}

		// Adding event listeners
		form.addEventListener("input", handleInput)
		inputs[0].addEventListener("paste", handlePaste)
		inputs.forEach((input) => {
			// input.addEventListener("focus", (e) => {
			// 	setTimeout(() => {
			// 		e.target.select();
			// 	}, 0);
			// });

			input.addEventListener("keyup", handleKeyUp)
		})

		// Cleanup function
		return () => {
			form.removeEventListener("input", handleInput)
			inputs[0].removeEventListener("paste", handlePaste)
			inputs.forEach((input) => {
				input.removeEventListener("keyup", handleKeyUp)
			})
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [characters])

	return (
		<div className="grid-columns tiny-gap left-items" ref={formRef}>
			{generateBoxes(characters.length)}
		</div>
	)
}

// Both good examples of how to create similar forms
// https://codepen.io/tatthien/pen/LYZxEmv
// https://codepen.io/RobertAron/pen/gOLLXLo
