import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import RadioButtonInput from "src/shared/components/standard/RadioButtonInput"
import MainButton from "src/shared/components/standard/MainButton"

// Store
import { RootState } from "src/shared/store/reducers"
import { setUser } from "src/shared/store/slices/userSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"



export default function EnvironmentModal() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector((state: RootState) => state.user)
	// States
	const [ambiente, setAmbiente] = useState(user.taxation.nfSettings.ambiente)
	const [renderEnvironmentModal, setRenderEnvironmentModal] = useState(false)



	async function handleUpdateEnvironment() {
		try {
			const token = getToken()
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/account/taxation/ambiente`, {
				method: "PUT",
				body: JSON.stringify({
					ambienteNf: ambiente
				}),
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				}
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			setRenderEnvironmentModal(false)
			dispatch(setUser({
				...user,
				taxation: {
					...user.taxation,
					nfSettings: {
						...user.taxation.nfSettings,
						ambiente: ambiente
					}
				}
			}))
			dispatch(createToast({
				tone: "success",
				miliseconds: 5000,
				message: "Atualizado com sucesso",
			}))
		}
		catch (error) {
			console.error(error)
			dispatch(createToast({
				tone: "failure",
				miliseconds: 5000,
				message: "Houve um erro ao atualizar"
			}))
		}

	}


	return (
	<>
		{renderEnvironmentModal === true && (
			<Modal title="Definição do ambiente de emissão" onClose={() => setRenderEnvironmentModal(false)}>
				<div className="grid medium-gap">
					<RadioButtonInput
						label="Produção"
						name="environment"
						defaultChecked={ambiente === "1"}
						onChange={() => setAmbiente("1")}
					/>
					<RadioButtonInput
						label="Homologação"
						name="environment"
						defaultChecked={ambiente === "2"}
						onChange={() => setAmbiente("2")}
					/>
				</div>

				<MainButton onClick={() => handleUpdateEnvironment()}>
					<span>Definir ambiente</span>
				</MainButton>
			</Modal>
		)}

		<MainButton hierarchy="low" onClick={() => setRenderEnvironmentModal(true)}>
			<span>Definir ambiente</span>
		</MainButton>

	</>

	)
}
