/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { XMLParser } from "fast-xml-parser"
import QRCode from "qrcode"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import {
	REGION_LIST_BR,
	printContent,
	decodeBase64Url,
	toUnsignedInteger
} from "offiziersmesser/lib/utils"
import { RenderNfce, RenderDav } from "offiziersmesser/lib/renderers"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

// Store
import { resetGuest } from "src/shared/store/slices/guestSlice"
import { resetCart, setCart } from "src/shared/store/slices/cartSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"

// Components
import LinkButton from "src/shared/components/standard/LinkButton"
import StepForm from "src/shared/components/custom/StepForm/StepForm"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import AssessmentForm from "src/modules/supplier/assessments/editor/components/AssessmentForm"

import CustomerBlock from "./steps/CustomerBlock"
import CompletePurchase from "./steps/CompletePurchase"

import DeliveryBlock from "./steps/deliveryBlock/DeliveryBlock"
import CheckoutPaymentList from "./steps/paymentBlock/CheckoutPaymentList"

// Styles
import "./Checkout.css"

export default function Checkout() {

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Sore
	const cart = useSelector(state => state.cart)
	const guest = useSelector(state => state.guest)
	const balance = useSelector(state => state.balances)
	const user = useSelector(state => state.user)

	// 0 - default, 1 - newAccount 2 - guest or customer
	const [accountType, setAccountType] = useState(0)

	const [isTaxPayerValid, setIsTaxPayerValid] = useState(false)
	const [, setIsCnpj] = useState(false)
	const [userExists, setUserExists] = useState(false)

	const [nfceEmission, setNfceEmission] = useState(false)

	const [windowOpening] = useState(Date.now())
	const [windowClosing] = useState()

	const [onCredit, setOnCredit] = useState(false)
	const [onCreditTotal, setOnCreditTotal] = useState(false)

	// --- StepForm conditionals
	// Create Account Block

	const userPhoneFilled =
		!!guest.administrative.reference.phone.prefix
		&& !!guest.administrative.reference.phone.destination
		&& guest.administrative.reference.phone.subscriber.length === 9

	const userReferenceFilled =
		!!guest.administrative.reference.name
		&& !!guest.administrative.reference.surname
		&& userPhoneFilled
		&& !!guest.administrative.locality.road
		&& !!guest.administrative.locality.area
		&& !!guest.administrative.locality.number
		&& !!guest.administrative.locality.city
		&& !!guest.administrative.locality.region

	const accountDataFilled = userPhoneFilled && userReferenceFilled

 	// Delivery Block
	const isDelivery =
		cart.data.delivery.shipping.active === true
		&& cart.data.delivery.atSupplier === false

	const receiverFilled =
		!!cart.data.delivery.address.reference.name
		&& !!cart.data.delivery.address.reference.surname
		&& !!cart.data.delivery.address.reference.phone.destination
		&& cart.data.delivery.address.reference.phone.subscriber.length === 9

	const receiverLocalityFilled =
		!!cart.data.delivery.address.locality.area
		&& !!cart.data.delivery.address.locality.city
		&& !!cart.data.delivery.address.locality.road
		&& !!cart.data.delivery.address.locality.number
		&& !!cart.data.delivery.address.locality.postal
		&& !!cart.data.delivery.address.locality.region

	const deliveryDataFilled = receiverFilled && receiverLocalityFilled

	// Payment Block
	const paymentTotalAmout = cart.payment.reduce((acc, item) => acc).total
	const paymentActualAmout = cart.payment.reduce(
		(acc, item) => (acc += item.parts.reduce(
			(acc, item) => (acc += Number(item.value)), 0
		)), 0
	)

	const paymentDefined = (paymentActualAmout === paymentTotalAmout) || (paymentActualAmout >= paymentTotalAmout) || onCreditTotal || onCredit

	// CompletePurchase
	const isScheduledPickupMethod = !!cart.data.delivery.window?.closing
	const isDeliveryMethod = !cart.data.delivery.atSupplier

	const completePurchaseFilled = (isScheduledPickupMethod || isDeliveryMethod) ? !!cart.data.buyer.taxpayer : true
	// --- End of StepForm conditionals

	const options = useMemo(() => ({ ignoreAttributes: false }), [])
	const parser = useMemo(() => new XMLParser(options), [options])

	const [nfceXML, setNfceXML] = useState("") // Xml without parser

	const [nfceXMLObj, setNfceXMLObj] = useState(null) //Parsed object

	// Variables that need to be reformated for NFe emission
	const [accessKey, setAccessKey] = useState("")

	const [printNfce, setPrintNfce] = useState(false)
	const ufCode = REGION_LIST_BR.find(uf => user.administrative.locality?.region === uf?.region)
	const details = {
		model: "65",
		tipo: "1", 			// 	Tipo de operação (0 - entrada, 1 - saida)
		natureza: "venda", 	//	Defined in the description of taxation_br
		impressão: "1", 				// 	Hardcode because we"ll only output portrait format at the moment
		idDest: "1", 					// 	Hardcode

		uf: user.administrative.locality.region,		//	Supplier"s UF
		cuf: ufCode,							    // 	Supplier"s UF code
		municipio: user.administrative.locality.city, 	//	Supplier"s City

		ambiente: user.taxation.nfSettings.ambiente,

		emissão: "1",
		finalidade: "1",
		indFinal: "1",
		indPresenca: "1",
	}

	// Handlers
	async function handleCreateOrder() {
		try {
			const token = getToken()
			const alias = user.alias

			const isClosingWindowValid = (new Date(cart.data.delivery.window.closing)).getTime() > 0;
			const isDelivery = cart.data.delivery.atSupplier === false

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/customer/order/store/${alias}`, {
				method: "POST",
				body: JSON.stringify({
					alias: alias,
					order: {
						...cart.data,
						statuses: (isClosingWindowValid || isDelivery) ? [{
							createdAt: Number(toUnsignedInteger(Date.now()/1000)) ,
							bias: 0,
							note: "Pedido criado"
						}] : [{
							createdAt: Number(toUnsignedInteger(Date.now()/1000)),
							bias: 1,
							note: "Finalizado"
						}],
						customer: guest.taxpayer,
					},
					cashbox: balance ? balance.cashbox : null,
					payments: cart.payment,
					done: cart.done,
					asOf: cart.done ? 0 : Number(toUnsignedInteger((Date.now() / 1000) + (user.installments.timespan.value * 86400)))
				}),
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const orderReturn = await response.json()

			if (nfceEmission) {handleNfceEmission(orderReturn.record) }else{
				printContent(RenderDav(cart, guest, user))
			}

			dispatch(resetCart())
			dispatch(resetGuest())
			navigate("/seller/store")
		} catch (error) {
			console.error(error)
            dispatch(createToast({miliseconds: 5000, message: "Ocorreu algum erro! Tente novamente"}))
		}
	}

	async function handleNfceEmission(order) {

		const token = getToken()

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/invoice`,{
				method: "post",
				body: JSON.stringify( {
					order : order,
					details,
					guest,
				}),
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json"
				},
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const res = await response.json()
			setNfceXML(decodeBase64Url(res.xml))
			setPrintNfce(true)

		}
		catch(error) {
			console.error(error)
			dispatch(createToast({ tone: "failure", miliseconds: 5000, message: "Emissão de NFC-e não foi realizada! Tente novamente" }))
			navigate("/seller/store")
		}

	}

	const retrieveTaxpayerActivity = async () => {
		if (guest.taxpayer.length < 11) return

		try {
			const response = await fetch(process.env.REACT_APP_API_URL + "/user/taxpayer/activity", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ taxpayer: guest.taxpayer })
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()

			setUserExists(data.exists)
		}
		catch(error) {
			console.error(error)
			setUserExists(false)
		}
	}
	// Removes NFce tag from access key
	function formatNfceAccessKey(key) {
		const result = key.replace("NFe", "");
		return result
	}

	// Effects
	useEffect(() => {
		if (isTaxPayerValid) {
			retrieveTaxpayerActivity()
		}
	}, [guest.taxpayer, isTaxPayerValid, retrieveTaxpayerActivity])

	useEffect(() => {
		dispatch(
			setCart({
				...cart,
				data: {
					...cart.data,
					delivery: {
						...cart.data.delivery,
						window: {
							...cart.data.delivery.window,
							opening: windowOpening,
							closing: windowClosing,
						},
					},
				},
			})
		)
	}, [windowClosing])

	useEffect(() => {

		if (!nfceXML) return
		// Parses XML to JSON
		const xml = parser.parse(nfceXML)

		if (xml) {
			setNfceXMLObj(xml)
			setAccessKey(formatNfceAccessKey(xml.nfeProc.NFe.infNFe["@_Id"]))
		}

	}, [nfceXML])

	useEffect(() => {
		(async () => {
			if (printNfce && nfceXMLObj?.nfeProc !== undefined) {

				const qrCode = QRCode.toDataURL(nfceXMLObj.nfeProc.NFe.infNFeSupl.qrCode)
				printContent(RenderNfce(nfceXMLObj, accessKey, qrCode))
				navigate("/seller/store")
			}
		})()
	}, [nfceXMLObj, setPrintNfce])

	useEffect(() => {
		if (guest.taxpayer !== "") {
			dispatch(
				setCart({
					...cart,
					data: {
						...cart.data,
						delivery: {
							...cart.data.delivery,
							address:{
								...cart.data.delivery.address,
								reference: guest.administrative.reference,
								locality: guest.administrative.locality
							}
						},
					},
				})
			)
		}
	}, [guest])

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap">
				{accountType === 0 && (
					<CustomerBlock
						setAccountType={setAccountType}
						setIsCnpj={setIsCnpj}
						isTaxPayerValid={isTaxPayerValid}
						setIsTaxPayerValid={setIsTaxPayerValid}
						userExists={userExists}
						setUserExists={setUserExists}
					/>
				)}

				{accountType === 1 && (
					<>
						<div className="flex">
							<LinkButton
								to="/seller/checkout"
								hierarchy="inferior"
								onClick={() => setAccountType(0) && dispatch(resetGuest())}
							>
								<FontAwesomeIcon icon={faArrowLeft} />
								<span>Alterar Cliente</span>
							</LinkButton>
						</div>

						<StepForm onClickConclude={handleCreateOrder}>
							<AssessmentForm
								stepCondition={accountDataFilled}
								isGuest={true}
							/>
							<DeliveryBlock
								stepCondition={
									isDelivery
									? deliveryDataFilled
									: true
								}
							/>
							<CheckoutPaymentList
								stepCondition={paymentDefined}
								onCredit={onCredit}
								setOnCredit={setOnCredit}
								onCreditTotal={onCreditTotal}
								setOnCreditTotal={setOnCreditTotal}
							/>
							<CompletePurchase
								stepCondition={completePurchaseFilled}
								userAccountData={guest}
								accountType={accountType}
								isScheduledPickupMethod={isScheduledPickupMethod}
								isDeliveryMethod={isDeliveryMethod}
								nfceEmission={nfceEmission}
								setNfceEmission={setNfceEmission}
								onCreditTotal={onCreditTotal}
							/>
						</StepForm>
					</>
				)}

				{accountType === 2 && (
					<>
						<div className="flex">
							<LinkButton
								to="/seller/checkout"
								hierarchy="inferior"
								onClick={() => setAccountType(0) && dispatch(resetGuest())}
							>
								<FontAwesomeIcon icon={faArrowLeft} />
								<span>Alterar Cliente</span>
							</LinkButton>
						</div>

						<StepForm onClickConclude={handleCreateOrder}>
							<DeliveryBlock
								stepCondition={
									isDelivery
									? deliveryDataFilled
									: true
								}
							/>
							<CheckoutPaymentList
								stepCondition={paymentDefined}
								onCredit={onCredit}
								setOnCredit={setOnCredit}
								onCreditTotal={onCreditTotal}
								setOnCreditTotal={setOnCreditTotal}
							/>
							<CompletePurchase
								stepCondition={completePurchaseFilled}
								userAccountData={guest}
								accountType={accountType}
								isScheduledPickupMethod={isScheduledPickupMethod}
								isDeliveryMethod={isDeliveryMethod}
								nfceEmission={nfceEmission}
								setNfceEmission={setNfceEmission}
								onCreditTotal={onCreditTotal}
							/>
						</StepForm>
					</>
				)}
			</div>
		</SupplierRoleNavbar>
	)
}
