const variations = [
	"Ações",
	"Situação",
	"Número",
	"Emissão",
	"CPF ou CNPJ",
	"Representante",
	"Valor",
	"Desconto"
]

export default function PayablesTableHeader() {
	return (
		<thead>
			{variations.map((variation, index) => <th key={index}>{variations[index]}</th>)}
		</thead>
	)
}
