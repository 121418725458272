import { ChangeEvent, /*ReactNode*/ } from "react"

type CheckboxInputProps = {
	id?: string
	name?: string
	label?: string //| ReactNod
	value?: string | number
	onClick?: (event: any) => void
	checked?: boolean
	disabled?: boolean
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	required?: boolean
	className?: string
	defaultChecked?: boolean
}

export default function CheckboxInput({
	id,
	name,
	label,
	value = "",
	checked,
	onClick,
	disabled,
	onChange,
	required,
	className,
	defaultChecked,
}: CheckboxInputProps) {

	return (
		<label htmlFor={id}>
			<input
				id={id}
				name={name}
				type="checkbox"
				value={value}
				checked={checked}
				onClick={onClick}
				disabled={disabled}
				onChange={onChange}
				required={required}
				className={className}
				defaultChecked={defaultChecked}
			/>
			{label}
		</label>
	)
}

		// <label
		// 	htmlFor={id}
		// 	className="checkbox-radio-types"
		// >
		// 	<input
		// 		id={id}
		// 		name={name}
		// 		type="checkbox"
		// 		value={value}
		// 		checked={checked}
		// 		onClick={onClick}
		// 		disabled={disabled}
		// 		onChange={onChange}
		// 		required={required}
		// 		className={className}
		// 		defaultChecked={defaultChecked}
		// 	/>
		// 	<span className="medium semibold-font">
		// 		{label}
		// 	</span>
		// </label>
