// Library imports
import {
	useState,
	forwardRef,
	useImperativeHandle,
} from "react"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Style CSS
import "./HideableBlock.css"

// WARNING! Using ref indiscriminately is an anti-pattern and should be avoided,
// within this component it was used briefly in order to add a powerful functionality for the UX
// but it should be avoided to be used, STILL. References below:
// https://stackoverflow.com/questions/71085811/changing-the-childs-usestate-from-parent-component-react-hooks
// https://stackoverflow.com/questions/66263571/change-children-state-from-parent-component-in-react-js

const HideableBlock = forwardRef((props, ref) => {

	const { children, defaultHidden, onClick, blockTitle, size, fontSize } = props

	const [hidden, setHidden] = useState(
		// If the props does not exist or equals to true the
		(defaultHidden === undefined || defaultHidden === true) ? true : false
	)

	useImperativeHandle(ref, () => ({
		setHidden: (value) => setHidden(value),
	}))

	return (
		<div className="element-border grid small-gap">
			<button
				className={`HideableBlock-button wide cursor-pointer unselectable left-text midblack ${size === "small" ? "tiny-padding-vertical small-padding-horizontal border-radius-small-quarter" :
						"small-padding-vertical medium-padding-horizontal border-radius-small-half"
					} ${fontSize === "big" ? "big" :
						fontSize === "small" ? "small" :
							"medium"
					} ${hidden === true ? "semibolt-font" : "heavy-font"
					}`}
				onClick={() => {
					setHidden(!hidden)
					// If there is any external onClick function triggered by the toggling, it is invoked
					if (typeof onClick === "function") onClick()
				}}
			>
				<FontAwesomeIcon className={(hidden === true) ? "fa-icon rotate" : "fa-icon rotate right"} icon={faChevronDown} />
				<span>{blockTitle}</span>
			</button>

			<div className={(hidden === true) ? "display-none" : "display-block"}>
				{children}
			</div>
		</div>
	)
})

export default HideableBlock
