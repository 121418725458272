import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Utils
import { calculateOrderSumPlusShipping } from "offiziersmesser/lib/helpers"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"
import SupplierNavbar from "src/shared/components/custom/Navbar/SupplierNavbar"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setCart } from "src/shared/store/slices/cartSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"


type Item = {
	item: string
	quantity: number
}

type Quotation = {
	expiresAt: number
	quotation: string
	items: Item[]
}


export default function RetrieveQuotationModalButton() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector((state: any) => state.user)
	const store = useSelector((state: any) => state.store)
	const cart = useSelector((state: any) => state.cart)
	const cartSize = cart.data.contents.length

	// States
	const [renderBudgetHandlerModal, setRenderBudgetHandlerModal] = useState(false)

	const [budgetUrl, setBudgetUrl] = useState("")
	const [userBudget, setUserBudget] = useState<Quotation>({
		quotation: "",
		expiresAt: 0,
		items: []
	})

	const total = calculateOrderSumPlusShipping(cart.data)

	function addAllItemsToCart(itemsToAdd: any) {
		const cartContents = [...cart.data.contents]

		// itemsToAdd.forEach((itemInfo: { element: { item: string; }, amount: number }) => {
		// 	const item = store.data.items.find((element: { item: string }) => element.item === itemInfo.element.item)
		itemsToAdd.forEach((itemInfo: any) => {
			const item = store.data.items.find((element: any) => element.item === itemInfo.element.item)
			if (item) {
				cartContents.push({
					element: item,
					amount: itemInfo.amount,
				})
			}
		})

		dispatch(
			setCart({
				...cart,
				data: {
					...cart.data,
					alias: user.alias,
					address: {
						...cart.data.delivery.address,
						locality: store.data.locality,
					},
					contents: cartContents,
				},
			})
		)
	}

	// Handlers
	async function loadBudget() {
		try {
			const response = await fetch(`${process.env.REACT_APP_PEDDLER_API_URL}/quotation/${budgetUrl}`)

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const userBudget = await response.json()

			addAllItemsToCart(userBudget.items)
		}
		catch {
			dispatch(createToast({
				tone: "failure",
				miliseconds: 5000,
				message: "Não foi possível gerar orçamento."
			} as any))
		}
	}

	return (
		<>
			{renderBudgetHandlerModal &&
				<Modal title="Recuperação de orçamento" onClose={() => setRenderBudgetHandlerModal(false)}>
					<TextInput
						label="Código de orçamento"
						placeholder="1A-2bcD34"
						value={budgetUrl}
						onChange={e => setBudgetUrl(e.target.value)}
					/>
					<MainButton onClick={loadBudget} disabled={budgetUrl===""}>
						<span>Carregar</span>
					</MainButton>
				</Modal>
			}
			<MainButton
				hierarchy="low"
				onClick={() => setRenderBudgetHandlerModal(true)}
			>
				<span>Recuperar orçamento</span>
			</MainButton>
		</>
	)
}