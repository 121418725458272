import { ChangeEvent, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Utils
import { COMMERCIAL_UNITS_BR } from "offiziersmesser/lib/utils"
import { isValidBarcode } from "offiziersmesser/lib/utils"

// Models
import { CategoryModel } from "src/shared/models/item"

// Store
import { RootState } from "src/shared/store/reducers"
import { updateWorkableItem } from "src/shared/store/slices/workableItemsSlice"

// Components
import TextInput from "src/shared/components/standard/TextInput"
import SelectInput from "src/shared/components/standard/SelectInput"
import TextInputBarcode from "src/shared/components/custom/TextInputBarcode/TextInputBarcode"
import { createToast } from "src/shared/store/slices/toastsSlice"

export default function IdentityHandler() {

	const dispatch = useDispatch()

	// Store
	const user = useSelector((state: RootState) => state.user)
	const item = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)
	// States
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isBarcodeValid, setIsBarcodeValid] = useState(false)

	// Handlers
	function onChangeBarcode(value: any) {
		dispatch(
			updateWorkableItem({
				...item,
				control: {
					...item.control,
					barcode: value,
				},
			})
		)
		setIsBarcodeValid(isValidBarcode(value))
	}
	// Effects
	useEffect(() => {
		if(item != undefined)
			setIsBarcodeValid(isValidBarcode(item.control.barcode))
	}, [item])

	return (
		item &&
		<div className="flex flex-column medium-gap">
			<span className="big-x bold-font">Identidade</span>

			<div className="grid medium-gap fill-normal">
				<TextInput
					label="Descrição"
					placeholder="Tinta Branca Balde 20L"
					value={item.control.description}
					onChange={e => dispatch(
						updateWorkableItem({ ...item, control: { ...item.control, description: e.target.value } })
					)}
				/>
				<TextInput
					label="SKU"
					placeholder="12345"
					value={item.control.sku}
					onChange={e => dispatch(
						updateWorkableItem({ ...item, control: { ...item.control, sku: e.target.value } })
					)}
				/>

				{/* <TextInput
					label="GTIN"
					hint={(isBarcodeValid === false) ? "Inválido" : undefined}
					tone={(isBarcodeValid === false) ? "failure" : undefined}
					value={item.control.barcode}
					onChange={e => onChangeBarcode(e)}
				/> */}
				<TextInputBarcode
					label="GTIN"
					placeholder="0123456789128"
					value={item.control.barcode}
					onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeBarcode(event.target.value)}
					onScan={(value: any) => onChangeBarcode(value)}
					onError={() =>
						dispatch(createToast({
							tone: "failure",
							miliseconds: 2000,
							message: "Algo deu errado, tente novamente.",
							dismissable: true,
					} as any))}
				/>

				<SelectInput
					label="Categoria"
					value={item.groups.category}
					onChange={e => dispatch(
						updateWorkableItem({ ...item, groups: { ...item.groups, category: e.target.value } })
					)}
				>
					{user.categories.map((category: CategoryModel, index: number) => (
						<option key={index} value={category.tag}>
							{category.tag}
						</option>
					))}
				</SelectInput>
				<SelectInput
					label="Unidade"
					value={item.control.measure}
					onChange={e => dispatch(
						updateWorkableItem({ ...item, control: { ...item.control, measure: e.target.value } })
					)}
				>
					{COMMERCIAL_UNITS_BR.map((element, index) => (
						<option key={index} value={element.unit}>
							{element.unit + " (" + element.description + ")"}
						</option>
					))}
				</SelectInput>

			</div>
		</div>
	)
}
