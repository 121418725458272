// Libraries
import { Route, Navigate,  Routes } from "react-router-dom"

// Utils
import { EMPLOYEE_ROLES } from "offiziersmesser/lib/utils"

// Components
import PrivateRoute from "./PrivateRoute"


// General
import Authorization from "src/modules/general/authorization/views/Authorization"
import StoreCustomer from "src/modules/general/store/StoreCustomer"
import Actions from "src/modules/general/actions/Actions"


// Supplier - items
import SupplierItems from "src/modules/supplier/items/Items"
import SupplierCategories from "src/modules/supplier/items/Categories"
import XmlCover from "src/modules/supplier/items/editor/views/XmlCover"
import SupplierNewItem from "src/modules/supplier/items/editor/views/NewItem"
import SupplierUpdateItem from "src/modules/supplier/items/editor/views/UpdateItem"

// Supplier - invoices
import SupplierInvoices from "src/modules/supplier/invoices/Invoices"
import SupplierInvoiceInsert from "src/modules/supplier/invoices/editor/InsertInvoice"

// Supplier - orders
import SupplierOrders from "src/modules/supplier/orders/Orders"
import SupplierManageOrder from "src/modules/supplier/orders/editor/subpages/ManageOrder"

// Supplier - account
import SupplierAccount from "src/modules/supplier/account/Account"
import SupplierService from "src/modules/supplier/account/Service"
import SupplierDelivery from "src/modules/supplier/account/Delivery"
import SupplierEcommerce from "src/modules/supplier/account/Ecommerce"

import SupplierAccountInvoices from "src/modules/supplier/account/Invoices"
import InsertTaxRule from "src/modules/supplier/account/editor/subpages/invoices/InsertTaxRule"

// Supplier - transactions
import SupplierTransactions from "src/modules/supplier/transactions/views/Transactions"
import SupplierIssueTransactionReport from "src/modules/supplier/transactions/views/report/IssueReport"
import SupplierInsertTransaction from "src/modules/supplier/transactions/views/insertTransaction/InsertTransaction"
import SupplierRenegotiateTransaction from "src/modules/supplier/transactions/views/renegotiateTransaction/renegotiateTransaction"
import SupplierFinalizedTransactions from "src/modules/supplier/transactions/views/finished/FinalizedTransactions"

// Supplier - balances
import SupplierBalances from "src/modules/supplier/balances/Balances"
import SupplierDetailsOut from "src/modules/supplier/balances/editor/subpages/resume/DetailsOutflow"
import SupplierBalancesReport from "src/modules/supplier/balances/editor/subpages/report/BalancesReport"
import SupplierRegister from "src/modules/supplier/balances/editor/subpages/SupplierRegister/SupplierRegister"

// Supplier - contacts
import SupplierAssessments from "src/modules/supplier/assessments/Assessments"
import SupplierNewAssessment from "src/modules/supplier/assessments/editor/subpages/newAssessment"
import SupplierUpdateAssessment from "src/modules/supplier/assessments/editor/subpages/newAssessment"

// Supplier - employments
import SupplierEmployments from "src/modules/supplier/employments/views/Employments"
import SupplierReturnOrder from "src/modules/supplier/orders/editor/subpages/ReturnOrder"
import SupplierNewEmployments from "src/modules/supplier/employments/views/NewEmployments"


// Seller
import SellerStore from "src/modules/seller/store/Store"
import SellerOrders from "src/modules/seller/orders/Orders"
import SellerBalances from "src/modules/seller/balances/Balances"
import SellerCheckout from "src/modules/seller/checkout/Checkout"
import SellerReturnOrder from "src/modules/supplier/orders/editor/subpages/ReturnOrder"
import SellerOrderManagement from "src/modules/seller/orders/editor/subpages/ManageOrder"
import SellerInsertInvoice from "src/modules/supplier/invoices/editor/SellerInsertInvoice"
import SellerDetailsOutflow from "src/modules/seller/balances/resume/outflow/DetailsOutflow"
import DetailsOutflow from "src/modules/supplier/balances/editor/subpages/resume/DetailsOutflow"


export default function RoutesWrapper() {

	const pathnames = {	home: "/authorization",	reload: "/resource/supplier" }

	return (
		<Routes>
			<Route
				path="/store/:alias"
				element={<StoreCustomer/>}
			/>

			<Route
				path="/authorization"
				element={<Authorization />}
			/>

			<Route
				path="/"
				element={<Navigate to="/authorization" replace />}
			/>

			{/* Supplier routes */}
			<Route path="/" element={<PrivateRoute home={pathnames.home} reload={pathnames.reload} role={EMPLOYEE_ROLES[1].role} />}>
				<Route
					path="/actions"
					element={<Actions />}
				/>

				<Route
					path="/supplier/items"
					element={<SupplierItems/>}
				/>
				<Route
					path="/supplier/categories"
					element={<SupplierCategories/>}
				/>
				<Route
					path="/supplier/item/new"
					element={<SupplierNewItem/>}
				/>
				<Route
					path="/supplier/item/editor"
					element={<SupplierUpdateItem/>}
				/>
				<Route
					path="/supplier/item/import"
					element={<XmlCover/>}
				/>

				{/* Supplier - orders */}
				<Route
					path="/supplier/orders"
					element={<SupplierOrders/>}
				/>
				<Route
					path="/supplier/order/:record"
					element={<SupplierManageOrder/>}
				/>
				<Route
					path="/supplier/return/order/:record"
					element={<SupplierReturnOrder/>}
				/>
				<Route
					path="/supplier/invoice/order/:record"
					element={<SupplierInvoiceInsert/>}
				/>

				{/* Supplier - assessments / contacts */}
				<Route
					path="/supplier/assessments"
					element={<SupplierAssessments/>}
				/>
				<Route
					path="/supplier/assessment/update"
					element={<SupplierUpdateAssessment/>}
				/>
				<Route
					path="/supplier/assessment/new"
					element={<SupplierNewAssessment/>}
				/>

				{/* Supplier - employments */}
				<Route
					path="/supplier/employments"
					element={<SupplierEmployments/>}
				/>
				<Route
					path="/supplier/employments/new"
					element={<SupplierNewEmployments/>}
				/>

				{/* Supplier - transactions */}
				<Route
					path="/supplier/transactions/new"
					element={<SupplierInsertTransaction/>}
				/>
				<Route
					path="/supplier/transactions/report"
					element={<SupplierIssueTransactionReport/>}
				/>
				<Route
					path="/supplier/transaction/:transaction/renegotiation"
					element={<SupplierRenegotiateTransaction/>}
				/>
				<Route
					path="/supplier/transactions"
					element={<SupplierTransactions/>}
				/>
				<Route
					path="/supplier/transactions/payment/:paymentIndex"
					element={<SupplierFinalizedTransactions/>}
				/>

				{/* Supplier - invoices */}
				<Route
					path="/supplier/invoices/new"
					element={<SupplierInvoiceInsert/>}
				/>
				<Route
					path="/supplier/invoices/new/:record"
					element={<SupplierInvoiceInsert/>}
				/>
				<Route
					path="/supplier/invoices"
					element={<SupplierInvoices/>}
				/>

				{/* Supplier - balances */}
				<Route
					path="/supplier/balances/report"
					element={<SupplierBalancesReport/>}
				/>
				<Route
					path="/supplier/balances/details/outflow/:tag"
					element={<SupplierDetailsOut/>}
				/>
				<Route
					path="/supplier/balance/:tag"
					element={<SupplierRegister/>}
				/>
				<Route
					path="/supplier/balances"
					element={<SupplierBalances/>}
				/>
				<Route
					path="/supplier/balances/register"
					element={<SupplierRegister/>}
				/>

				{/* Supplier - account */}
				<Route
					path="/supplier/account"
					element={<SupplierAccount/>}
				/>
				<Route
					path="/supplier/ecommerce"
					element={<SupplierEcommerce/>}
				/>
				<Route
					path="/supplier/service"
					element={<SupplierService/>}
				/>
				<Route
					path="/supplier/delivery"
					element={<SupplierDelivery/>}
				/>

				<Route
					path="/supplier/taxation"
					element={<SupplierAccountInvoices/>}
				/>
				<Route
					path="/supplier/taxation/new"
					element={<InsertTaxRule/>}
				/>
				<Route
					path="/supplier/taxation/edit/:id"
					element={<InsertTaxRule/>}
				/>
			</Route>

			<Route path="/" element={<PrivateRoute home={pathnames.home} reload={pathnames.reload} role={EMPLOYEE_ROLES[2].role} />}>
				{/* Balances */}
				<Route
					path="/seller/balances/details/outflow/:tag"
					element={<DetailsOutflow/>}
				/>
				<Route
					path="/seller/balances/outflow/details"
					element={<SellerDetailsOutflow/>}
				/>
				<Route
					path="/seller/balances"
					element={<SellerBalances/>}
				/>

				{/* Store */}
				<Route
					path="/seller/store"
					element={<SellerStore/>}
				/>

				{/* Checkout */}
				<Route
					path="/seller/checkout"
					element={<SellerCheckout/>}
				/>

				{/* Orders */}
				<Route
					path="/seller/orders"
					element={<SellerOrders/>}
				/>
				<Route
					path="/seller/order/:record"
					element={<SellerOrderManagement/>}
				/>
				<Route
					path="/seller/return/order/:record"
					element={<SellerReturnOrder/>}

				/>

				{/* Invoices */}
				<Route
					path="/seller/invoice/order/:record"
					element={<SellerInsertInvoice/>}
				/>
			</Route>
		</Routes>
	)
}
