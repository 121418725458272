import React, { useEffect, useRef, useState, useCallback } from "react"
import { scanImageData } from "@undecaf/zbar-wasm"

export default function BarcodeScanner(props) {
	const videoRef = useRef(null)
	const canvasRef = useRef(null)
	const streamRef = useRef(null)

	const [scannedBarcodes, setScannedBarcodes] = useState({})
	//const [barcodeSampleCount, setBarcodeSampleCount] = useState(0)
	const [isScanning, setIsScanning] = useState(true)
	// const [displayedBarcode, setDisplayedBarcode] = useState("")
	// const [error, setError] = useState(null)

	const updateScannedBarcodes = useCallback((newBarcode, quality) => {
		setScannedBarcodes(prev => {
			const updated = { ...prev }
			if (!updated[newBarcode]) {
				updated[newBarcode] = { count: 0, quality: 0 }
			}
			updated[newBarcode].count++
			updated[newBarcode].quality = Math.max(updated[newBarcode].quality, quality)
			return updated
		})
	}, [])

	const scanBarcode = useCallback(async () => {
		if (!isScanning || !videoRef.current || videoRef.current.readyState !== videoRef.current.HAVE_ENOUGH_DATA) {
			return
		}

		if (canvasRef.current.width !== videoRef.current.videoWidth || canvasRef.current.height !== videoRef.current.videoHeight) {
			canvasRef.current.width = videoRef.current.videoWidth
			canvasRef.current.height = videoRef.current.videoHeight
		}

		const context = canvasRef.current.getContext("2d")
		context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height)
		const imageData = context.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height)

		try {
			const symbols = await scanImageData(imageData)
			if (symbols.length > 0) {
				const barcode = symbols[0].decode()
				const quality = symbols[0].quality
				updateScannedBarcodes(barcode, quality)
			}
		} catch (err) {
			console.error("Error scanning image data:", err)
		}
	}, [updateScannedBarcodes, isScanning])

	useEffect(() => {
		let scanTimeout

		if (!isScanning) return

		const scan = () => {
			scanBarcode()
			scanTimeout = setTimeout(scan, 50)
		}
		scan()

		return () => {
			if (scanTimeout) clearTimeout(scanTimeout)
		}
	}, [scanBarcode, isScanning])

	useEffect(() => {
		const setupCamera = async () => {
			if (!isScanning) return

			try {
				// const devices = await navigator.mediaDevices.enumerateDevices()
				// const videoDevices = devices.filter(device => device.kind === "videoinput")
				// const lastDevice = videoDevices[videoDevices.length - 1]

				const stream = await navigator.mediaDevices.getUserMedia({
					video: {
						// deviceId: lastDevice ? { exact: lastDevice.deviceId } : undefined,
						// width: { ideal: 1280 },
						// height: { ideal: 720 },
						facingMode: "environment"
					}
				})

				if (videoRef.current) {
					videoRef.current.srcObject = stream
					streamRef.current = stream
				}
			} catch (err) {
				console.error("Error accessing camera stream:", err)
				setIsScanning(false)
				// setError(err)
				props.onError(err)
			}
		}

		setupCamera()

		return () => {
			// Use the stream stored in the ref for cleanup
			streamRef.current?.getTracks().forEach(track => track.stop())
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isScanning])

	useEffect(() => {
		const findHighTrustBarcode = () => {
			let maxCount = 0
			let chosenBarcode = ""

			for (const [barcode, data] of Object.entries(scannedBarcodes)) {
				if (data.count > maxCount && data.quality >= 10) {
					maxCount = data.count
					chosenBarcode = barcode
				}
			}

			if (maxCount >= 5) {
				//setBarcodeSampleCount(maxCount)
				setIsScanning(false)
				// setDisplayedBarcode(chosenBarcode)
				props.onScan(chosenBarcode)
			}
		}

		findHighTrustBarcode()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scannedBarcodes])

	return (
		<>
			{isScanning &&
				<>
					<video
						ref={videoRef}
						style={{ display: "block", width: "100%" }}
						autoPlay
						playsInline
						muted
					></video>
					<canvas ref={canvasRef} style={{ display: "none" }}></canvas>
				</>
			}
			{
				// error && <div>Error accessing camera: {error.message}</div>
			}
			{
				// displayedBarcode && <div className="barcode-label">Scanned Barcode: {displayedBarcode} (Confirmed after {barcodeSampleCount} samples)</div>
			}
		</>
	)
}
