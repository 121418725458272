import { createSlice } from "@reduxjs/toolkit"

// Models

const employmentsSlice = createSlice({
	name: "employments",
	initialState: [] as any[],
	reducers: {
		setEmployments: (state, action) => {
			return state = action.payload
		},
		addEmployment: (state, action) => {
			return state = [...state, action.payload]
		},
        updateEmployment: (state, action) => {
			return state = action.payload
		},
		resetEmployments: state => {
			return state = []
		},
	}
})

export default employmentsSlice.reducer
export const { setEmployments, resetEmployments, updateEmployment } = employmentsSlice.actions
