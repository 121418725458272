import { useEffect, useRef } from "react"
// import QrCreator from "qr-creator" // https://www.npmjs.com/package/qr-creator
import QRCode from "qrcode" // https://www.npmjs.com/package/qrcode

const QuickResponseCode = ({ style, className, value = "test", options, callback }) => {

	const containerRef = useRef(null)

	useEffect(() => {
		QRCode.toCanvas(containerRef.current, value, options, callback)
	}, [value, options, callback])

	return <canvas ref={containerRef} style={style} className={className} />
}

export default QuickResponseCode