export default function ManageOrderTableRow({
	purchase,
}) {
	const sumReturns = (returns) =>{
		if(returns === null) return 0

		let sum = 0
		returns.map(r=> sum += r.amount)

		return sum
	}
	return (
		<tr>
			<td className="big-x"><b>{(purchase.amount - (sumReturns(purchase.returns)))}x</b></td>

			<td>{purchase.element.control.code}</td>

			<td>{purchase.element.control.description}</td>

			<td>{purchase.element.origin.manufacturer}</td>

			<td>{purchase.element.groups.category}</td>

			{(sumReturns(purchase.returns) === 0)
				? <td className="disabled"></td>
				: <td className="big-x"><b>{sumReturns(purchase.returns)}x</b></td>
			}
		</tr>
	)
}
