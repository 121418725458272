const variations = [
	"Ações", // List of contents, change order status
    "Status",
    "Cliente",
	"Criado em",
	"Atualizado em",
	"Entrega",
	"Entregador",
]

export default function OrdersTableHeader() {
	return (
		<tr>
			{variations.map((header, index) => <th key={index}>{header}</th>)}
		</tr>
	)
}
