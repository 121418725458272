const insertItem = (state, payload) => [...state, payload]

const updateItem = (state, payload) => {
	return state.map(item => {
		if (item.item === payload.item)
			item = payload
		return item
	})
}

const removeItem = (state, payload) => state.filter(item => item.item !== payload.item)

export {
	insertItem,
	updateItem,
	removeItem,
}
