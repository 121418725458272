import { Dispatch, SetStateAction } from "react"

// Utils
import { toFixedImproved } from "offiziersmesser/lib/utils"
import { calculateItemPrice } from "offiziersmesser/lib/helpers"
import { ContentModel, OrderModel, ReturnModel } from "offiziersmesser/lib/models/orders"

// Icons
import { faInfo } from "@fortawesome/free-solid-svg-icons"
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import ReturnedItemCard from "../../ReturnedItemCard/ReturnedItemCard"
import Notice from "../../../../../../../shared/components/custom/Notice/Notice"
import CheckboxInput from "../../../../../../../shared/components/standard/CheckboxInput"

type ItemsToReturnProps = {
	order: any
	returnedInfoOrder: any
	setReturnedInfoOrder: Dispatch<SetStateAction<any>>
	setOrder: Dispatch<SetStateAction<any>>
}

export default function ItemsToReturn({
	order,
	returnedInfoOrder,
	setOrder,
	setReturnedInfoOrder
}: ItemsToReturnProps) {

	//if content haves one return with createdAt is null so is new return.
	const verifyReturned = (content:ContentModel) => {

		let returned = false
		let index = 0
		if(content.returns != null && content.returns?.length > 0 ){
			content.returns?.forEach((element:ReturnModel, i) => {
				if(element.createdAt === null){
					returned = true
					index = i
				}
			})
		}

		return {returned,index}
	}

	const verifyNotReturned = (content:ContentModel) => {
		let returned = true

		if(content.returns != null && content.returns?.length > 0){
			content?.returns?.forEach((element:ReturnModel, i) => {
				if(element.createdAt === null){
					returned = false
				}
			})
		}
		return returned
	}

	const qtdReturned = (content:ContentModel) => {

		const totalReturns = content?.returns?.map((returns: ReturnModel) => returns.createdAt != null? returns.amount: 0)
		.reduce((prev, curr) => prev + curr, 0) || 0

		return totalReturns
	}

	const selectionItem = (content:ContentModel,index:number) => {


		setReturnedInfoOrder({...returnedInfoOrder,
			total: Number((returnedInfoOrder.total + (calculateItemPrice(content.element)*(content.amount-qtdReturned(content)))).toFixed(2)),
			totalItemsSelected: returnedInfoOrder.totalItemsSelected+1
		})

		const newReturns = content.returns ? content.returns : []
		const newContents = order.contents

		newContents[index].returns = [...newReturns,
				{
					amount: content.amount-qtdReturned(content),
					createdAt: null,
					createdBy: null,
					reason: 0,
					method: ""
				}
			]
		setOrder({...order, contents: newContents})
	}

	const setAmount = (order: OrderModel, content: ContentModel, index : number ,quantity: number) => {


			const updatedItems = order.contents.map((returnedContent: ContentModel) => {

				if (returnedContent.element.item === content.element.item) {
					let newReturns = content.returns

					if(newReturns[index].amount< quantity){
						setReturnedInfoOrder({...returnedInfoOrder,
							total: Number((returnedInfoOrder.total + (calculateItemPrice(content.element)*((isNaN(quantity) ? 0 : quantity) - newReturns[index].amount))).toFixed(2))
						})
					}else{

						setReturnedInfoOrder({...returnedInfoOrder,
							total: Number((returnedInfoOrder.total - (calculateItemPrice(content.element)*(newReturns[index].amount - (isNaN(quantity) ? 0 : quantity)))).toFixed(2))
						})
					}
					newReturns[index].amount = isNaN(quantity) ? 0 : quantity
					return {
						...content,
						returns: newReturns
					}
				}
				return returnedContent
			})

			setOrder( {
				...order,
				contents: updatedItems
			})

	}

	return (
		<div className="grid large-gap">
			<span className="big-x bold-font">Selecione os itens para devolução</span>

			<div className="grid small-gap">
				<span className="big bold-font">Itens para devolução</span>

				<div className="grid">
					{(order.contents?.length > 0) &&
						order.contents.map((content: ContentModel,i: number) => {
							let {returned, index} = verifyReturned(content)

							return(returned &&
								<ReturnedItemCard
								key={content.element.item}
								itemName={content.element.control.description}
								value={calculateItemPrice(content.element)}
								maxAmount={content.amount - qtdReturned(content)}
								quantity={content.amount}
								amountSelected={content.returns[index].amount}
								checked={true}
								setAmount={(quantity)=>setAmount(order,content,index, quantity)}
								onSelectItem={() => {

									setReturnedInfoOrder({...returnedInfoOrder,
										total: Number((returnedInfoOrder.total - (calculateItemPrice(content.element)*(content.returns[index].amount))).toFixed(2)),
										totalItemsSelected: returnedInfoOrder.totalItemsSelected-1
									})

									const newReturn = content.returns.filter((e,indexF)=> indexF != index)
									const newContents = order.contents
									newContents[i].returns = newReturn
									setOrder((order: any) => {
										return {
											...order,
											contents: newContents
										}
									})

								}}
							/>

							)
						})
					}
				</div>
			</div>

			<div className="grid small-gap">
				<span className="big bold-font">Itens do pedido</span>

				<div className="grid">
					{(order?.contents?.length > 0) ? (
						order.contents.map((content: ContentModel, index: number) => (

							verifyNotReturned(content)&&<ReturnedItemCard
								key={content.element.item}
								itemName={content.element.control.description}
								value={calculateItemPrice(content.element)}
								maxAmount={content.amount - qtdReturned(content)}
								quantity={content.amount}
								amountSelected={0}
								checked={false}
								content={content}
								index={index}
								onSelectItem={selectionItem}
								setAmount={() => ""}
							/>
						))
					) : (
						<Notice tone="info">
							<div className="flex medium-gap align-items-center">
								<FontAwesomeIcon icon={faInfo as Icon} />
								<p>Nenhum item restante</p>
							</div>
						</Notice>
					)}
				</div>
			</div>

			<div className="flex justify-content-space-between big">
				<div className="grid-columns small-gap">
					<span>Itens selecionados:</span>
					<b>{returnedInfoOrder.totalItemsSelected}</b>
				</div>
				<div className="grid-columns small-gap">
					<span>Total:</span>
					<b>R$ {toFixedImproved(returnedInfoOrder.total, 2)}</b>
				</div>
			</div>

			<div>
				<CheckboxInput
					label="Retornar itens ao estoque"
					checked={returnedInfoOrder.returnToStock}
					onChange={e => setReturnedInfoOrder(({
						...returnedInfoOrder,
						returnToStock: e.target.checked
					}))}
				/>
			</div>
		</div>
	)
}
