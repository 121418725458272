// Components
import AccountInvoicesHeader from "./AccountInvoicesHeader"
import AccountInvoicesRow from "./AccountInvoicesRow"

export default function InvoicesTable({ taxRules, setTaxRules }) {
	const removeRule = async (entry) =>{
		const rules = taxRules.filter((rule) => rule.entry != entry)
		setTaxRules(rules)
	}
	return (
		<table className="Table-container">
			<tbody>
				<AccountInvoicesHeader />

				{taxRules.map((rule,index) => (
					<AccountInvoicesRow key={index} rule={rule} removeRule={removeRule} />
				))}
			</tbody>
		</table>
	)
}
