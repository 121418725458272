import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	data:{
		items:[]
	}
}

const storeSlice = createSlice({
	name: "store",
	initialState,
	reducers: {
		updateStore: (state, action) => {
			return state = { ...state, ...action.payload }
		},
	}
})

export default storeSlice.reducer
export const { updateStore } = storeSlice.actions

