import { createSlice, current } from "@reduxjs/toolkit"

// Store
import ItemModel from "../../models/item"

export type WorkableItem = {
	item: ItemModel
	images: File[]
}

const initialState = {
	items: [] as WorkableItem[],
	index: 0
}

const workableItems = createSlice({
	name: "workableItems",
	initialState,
	reducers: {
		resetWorkableItems: state => {
			return state = initialState
		},
		// This function works by pointing to the first item of the array added, working for both manually editing a single item/*  */
		// or when a file with multiple items to be edited is consumed
		insertIntoWorkableItems: (state, action) => {

			const currentState = current(state)

			// Does not matter if a single or multiple items are added, the updated index starts at the first of the new series
			const updatedIndex = currentState.items.length + 1
			// Merges the current item list and the payload array with a faster function (over spread operator)
			state.items = currentState.items.concat(action.payload)
			state.index = updatedIndex

			return state
		},

		// MUST BE CALLED whenever the "current workable item" is consumed somehow, being deleted from the workable list or inserted/updated
		removeWorkableItem: state => {

			const currentState = current(state)

			let updatedItems = []

			for (let i = 0; i < currentState.items.length; i++)
				if (i !== currentState.index) updatedItems.push(currentState.items[i])

			state.items = updatedItems

			return state
		},
		// This function is the usual dispatched function whenever an item is updated FROM a handler's onChange events
		updateWorkableItem: (state, action) => {

			const currentState = current(state)

			let updatedItems = []

			for (let i = 0; i < currentState.items.length; i++) {
				// Pushes the updated item to the array whenever
				if (i === currentState.index) {
					updatedItems.push(
						{
							item: action.payload,
							images: currentState.items[i].images
						}
					)
				}
				else updatedItems.push(currentState.items[i])
			}

			state.items = updatedItems

			return state
		},
		// This function is the usual dispatched function whenever an item image is updated FROM a handler
		updateWorkableImages: (state, action) => {

			const currentState = current(state)

			let updatedItems = []

			for (let i = 0; i < currentState.items.length; i++) {
				// Pushes the updated item to the array whenever
				if (i === currentState.index) {
					updatedItems.push(
						{
							item: currentState.items[i].item,
							images: action.payload
						}
					)
				}
				else updatedItems.push(currentState.items[i])
			}

			state.items = updatedItems

			return state
		},
		// This function is called whenever a card with a item is clicked (and therefore the intention to update the given item)
		updateWorkableItemIndex: (state, action) => {

			const currentState = current(state)

			// If the given index is out of bounds, it is updated as the nearest valid array bound
			state.index = (action.payload > currentState.items.length)
				? currentState.items.length
				: (action.payload < 0)
					? 0/*  */
					: action.payload

			return state
		}
	}
})

export default workableItems.reducer
export const {
	resetWorkableItems,
	insertIntoWorkableItems,
	removeWorkableItem,
	updateWorkableItem,
	updateWorkableImages,
	updateWorkableItemIndex
} = workableItems.actions
