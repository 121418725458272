// Components
import { ContentModel } from "offiziersmesser/lib/models/orders"
import CheckboxInput from "../../../../../../shared/components/standard/CheckboxInput"

// Styles
import "./ReturnedItemCard.css"

type ReturnedItemCardProps = {
	itemName: string
	value: number
	maxAmount: number
	quantity: number
	amountSelected: number
	checked: boolean
	setAmount: (quantity: number) => void
	onSelectItem?: any
	content?:ContentModel
	index?: number
}

export default function ReturnedItemCard({
	itemName,
	value,
	maxAmount,
	quantity = 0,
	amountSelected,
	checked,
	setAmount,
	onSelectItem,
	content,
	index
}: ReturnedItemCardProps) {

	return (
		<div className="returned-item flex justify-content-space-between">
			<CheckboxInput
				name="item"
				checked={checked}
				label={`${checked ? itemName : `${maxAmount}x ${itemName}`} `}
				onChange={ () => {onSelectItem(content,index)}}
				disabled={maxAmount === 0 && !checked}
			/>

			<div className="returned-item-options flex medium-gap">
				{checked ? (
					<input
						type="number"
						value={amountSelected}
						max={maxAmount}
						onBlur={e => setAmount(isNaN(e.target.valueAsNumber) ? 1 : e.target.valueAsNumber)}
						onChange={e => setAmount((e.target.valueAsNumber > maxAmount ? maxAmount : e.target.valueAsNumber))}
						min={1}
						disabled={false}
					/>
				) : ""}

				<div className="grid fill-tiny-xx align-items-flex-end">
					<span>{checked && "x"} <b>{ !isNaN(amountSelected)? checked ? (value * amountSelected).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}): (value * maxAmount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}):(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
					</span>
					</div>
			</div>
		</div>
	)
}
