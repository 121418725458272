// Library imports
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setOrders } from "src/shared/store/slices/ordersSlice"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle, faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons"

// Components
import OrdersTable from "../../supplier/orders/editor/components/tables/OrderTable/OrdersTable"
import Notice from "src/shared/components/custom/Notice/Notice"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

export default function Orders() {

	// Store
	const orders = useSelector(state => state.orders)
	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()
	// States
	const [supplierCarriers, setSupplierCarriers] = useState([])
	const [loaded, setLoaded] = useState(false)

	const getOrders = async () => {
		try {
			const token = getToken()

			const response = await fetch(
				process.env.REACT_APP_API_URL + `/resource/supplier/orders`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
				},
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const ordersData = await response.json()

			const responseCarriers = await fetch(
				process.env.REACT_APP_API_URL + "/resource/seller/supplier/carriers",
				{
					method: "GET",
					headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const supplierCarriersData = await responseCarriers.json()

			dispatch(setOrders(ordersData))
			setSupplierCarriers(supplierCarriersData)
			setLoaded(true)


		}
		catch(error){
			console.error(error)
		}

	}
	// Effects
	useEffect(() => {
		getOrders()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap">

				<PageTitle title="Pedidos" />

				{(loaded === false) ? (
					<ComponentLoader />
				) : (orders?.length === 0) ? (
					<Notice tone="info" to="/seller/store">
						<div className="grid-columns medium-gap left-items">
							<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle} />
							<div className="grid small-gap">
								<span className="small">
									Nenhum pedido encontrado. Vá até o catálogo e realize uma venda para visualizar um pedido.
								</span>
								<div className="grid-columns small-gap left-items bold-font tiny">
									<span>Clique aqui para ir até o catálogo</span>
									<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt} />
								</div>
							</div>
						</div>
					</Notice>
				) : (
					<OrdersTable
						orders={orders}
						supplierCarriers={supplierCarriers}
						userType="seller"
					/>
				)}
			</div>
		</SupplierRoleNavbar>
	)
}