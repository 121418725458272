// Components
import FormTabs from "src/shared/components/custom/FormTabs/FormTabs"

import MediaHandler from "./MediaForm"
import BatchesHandler from "./BatchesForm"
import ControlHandler from "./ControlForm"
import IdentityHandler from "./IdentityForm"
import DiscountsHandler from "./DiscountsForm"
import LogisticsHandler from "./LogisticsForm"
import ManufacturerHandler from "./ManufacturerForm"
import AccountingHandler from "./AccountingForm"

export default function ItemsForm() {

	return (
		<div className="grid medium-gap">
			<FormTabs tabs={["Essencial", "Auxiliar"]}>

				<div className="grid large-gap">
					<IdentityHandler />
					<ControlHandler />
					<BatchesHandler />
					<AccountingHandler />
				</div>

				<div className="grid large-gap">
					<DiscountsHandler />
					<ManufacturerHandler />
					<LogisticsHandler />
					<MediaHandler />
				</div>

			</FormTabs>
		</div>
	)
}
