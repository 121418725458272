import { ChangeEvent } from "react"
import { useSelector, useDispatch } from "react-redux"

// Store
import { RootState } from "src/shared/store/reducers"
import { updateWorkableItem } from "src/shared/store/slices/workableItemsSlice"

// Components
import TextInput from "src/shared/components/standard/TextInput"

export default function ManufacturerHandler() {

	const dispatch = useDispatch()

	// Store
	const workableItem = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)

	return (
		workableItem &&
		<div className="flex flex-column medium-gap">
			<span className="big-x bold-font">Fabricante</span>

			<div className="grid fill-normal medium-gap">
				<TextInput
					label="Fabricante"
					value={workableItem.origin.manufacturer}
					onChange={(e: ChangeEvent<HTMLInputElement>) => dispatch(
						updateWorkableItem({ ...workableItem, origin: { ...workableItem.origin, manufacturer: e.target.value } })
					)}
				/>

				<TextInput
					label="Código do fabricante"
					value={workableItem.origin.model}
					onChange={(e: ChangeEvent<HTMLInputElement>) => dispatch(
						updateWorkableItem({ ...workableItem, origin: { ...workableItem.origin, model: e.target.value } })
					)}
				/>
			</div>
		</div>
	)
}
