// Libraries
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

// Utils
import { EMPLOYEE_ROLES } from "offiziersmesser/lib/utils"

type NavigationEntryWithRoutesModel = {
	name: string
	routes: Array<{ path: string; name: string }>
	toggled: boolean
}

type NavigationEntryWithPathModel = {
	name: string
	path: string
}

type NavigationEntryModel = NavigationEntryWithRoutesModel | NavigationEntryWithPathModel

function doesNavigationEntryHasRoutes(entry: NavigationEntryModel): entry is NavigationEntryWithRoutesModel {
	return "routes" in entry && !("path" in entry)
}

// Can be improved adding some 'default' property to the desired navigation entry/route entry
// in the reducer initial state, but manually picking the first entry reduces code complexity
function getDefaultPathInNavigation(navigation: NavigationEntryModel[]) {
	return doesNavigationEntryHasRoutes(navigation[0]) ? navigation[0].routes[0].path : navigation[0].path
}

type RoleNavigationModel = {
	role: string
	navigation: NavigationEntryModel[]
	active: boolean
}

const managerNavigationInitialState: NavigationEntryModel[] = [
	{
		name: "inventory",
		routes: [
			{ path: "/supplier/items", name: "items" },
			{ path: "/supplier/categories", name: "categories" }
		],
		toggled: true
	},
	{
		name: "operations",
		routes: [
			{ path: "/supplier/orders", name: "orders" },
			{ path: "/supplier/employments", name: "employments" }
		],
		toggled: false
	},
	{
		name: "financials",
		routes: [
			{ path: "/supplier/assessments", name: "assessments" },
			{ path: "/supplier/transactions", name: "transactions" },
			{ path: "/supplier/balances", name: "balances" },
			{ path: "/supplier/invoices", name: "invoices" },
			{ path: "/supplier/taxation", name: "taxation" }
		],
		toggled: false
	},
	{
		name: "definitions",
		routes: [
			{ path: "/supplier/account", name: "account" },
			{ path: "/supplier/delivery", name: "delivery" },
			{ path: "/supplier/service", name: "service" },
			{ path: "/supplier/ecommerce", name: "ecommerce" }
		],
		toggled: false
	}
]

const sellerNavigationInitialState: NavigationEntryModel[] = [
	{
		name: "orders",
		path: "/seller/orders",
	},
	{
		name: "balances",
		path: "/seller/balances",
	},
	{
		name: "store",
		path: "/seller/store",
	},
]

const userRolesInitialStates: RoleNavigationModel[] = [
	{
		active: true,
		navigation: managerNavigationInitialState,
		...EMPLOYEE_ROLES[1]
	},
	{
		active: false,
		navigation: sellerNavigationInitialState,
		...EMPLOYEE_ROLES[2]
	},
]


type RoleModel = {
	createdAt: number
	updatedAt: number
	role: string
	active: boolean
}

function setAuthorizedUserRoles(authorizedRoles?: string[]): RoleNavigationModel[] {
	if (!authorizedRoles || authorizedRoles.length === 0)
	// if (authorizedRoles.length === 0 || authorizedRoles.includes("supplier"))
		return userRolesInitialStates
	else
		return userRolesInitialStates.filter(entry => authorizedRoles.includes(entry.role))
}

type StakeholderModel = "supplier" | "employee" | "customer" // | "investor" | "partner"

interface SessionStateModel {
	authenticated: boolean
	navbar: boolean
	stakeholder: StakeholderModel
	roles: RoleNavigationModel[]
}

const initialState: SessionStateModel = {
	authenticated: false, // Manual flag to guarantee that the user is authenticated
	// initialized: false, // Manual flag to guarantee that the session or post-authentication processes are done
	navbar: true,
	stakeholder: "supplier",
	roles: [],
}



// Those definitions merely safeguard the session initialization parameters when the stakeholder is supplier
interface BaseInitializationPayload {
	stakeholder: string
}
interface SupplierInitializationActionPayload extends BaseInitializationPayload {
	stakeholder: "supplier"
	authorizedRoles?: never // Ensures it is not used when stakeholder is supplier
}
interface NonSupplierInitializationActionPayload extends BaseInitializationPayload {
	stakeholder: Exclude<string, "supplier">
	authorizedRoles: RoleModel[]
}
type InitializationActionPayload = SupplierInitializationActionPayload | NonSupplierInitializationActionPayload
function isSupplierPayload(payload: InitializationActionPayload): payload is SupplierInitializationActionPayload {
	return payload.stakeholder === "supplier"
}


const sessionSlice = createSlice({
	name: "session",
	initialState,
	reducers: {

		setAuthenticated(state, action: PayloadAction<boolean>) {
			state.authenticated = action.payload
		},

		setNavbar(state, action: PayloadAction<boolean>) {
			state.navbar = action.payload
		},

		// Initializes session roles and sets the specified role as active
		initializeSession(state, action: PayloadAction<InitializationActionPayload>) {
			state.stakeholder = action.payload.stakeholder as StakeholderModel

			if (isSupplierPayload(action.payload))
				state.roles = setAuthorizedUserRoles(undefined)
			else {
				const activeAuthorizedRoles : string[] = []

				for (let index = 0; index < action.payload.authorizedRoles.length; index++) {
					if(action.payload.authorizedRoles[index].active === true){
						activeAuthorizedRoles.push(action.payload.authorizedRoles[index].role)
					}
				}

				// Filter and set roles based on the provided list (by the back-end), ensuring only authorized roles are included
				state.roles = setAuthorizedUserRoles(activeAuthorizedRoles)

				// IMPORTANT! Among authorized roles, automatically initializes state after the the first active one found
				// Loop through each role in the state
				for (let index = 0; index < state.roles.length; index++) {
					// Activate the role that matches the 'activeRole' from the payload; deactivate others
					state.roles[index].active = state.roles[index].role === activeAuthorizedRoles[0];
				}
			}
		},

		setActiveRole(state, action: PayloadAction<string>) { // getter -> state.roles.find(role => role.active)?.role
			state.roles.forEach(role => {
				role.active = role.role === action.payload
			})
		},

		// Updates the active state of sections within the active role based on the current path
		updateToggledSectionByPath(state, action: PayloadAction<string>) {
			const path = action.payload // The current navigation path to match against

			// Find the currently active role. Assumes only one role can be active at any time
			const activeRole = state.roles.find((role: RoleNavigationModel) => role.active)

			// If there's no active role, exit the function as there's nothing to update
			if (!activeRole) return

			// Iterate over the navigation entries of the active role
			activeRole.navigation.forEach((entry: NavigationEntryModel) => {
				// Only proceed if the entry has a 'routes' property
				if (doesNavigationEntryHasRoutes(entry))
					// Set the section's active state based on whether any of its routes match the current path
					// True if a match is found (making this the active section), false otherwise
					entry.toggled = entry.routes.some((route: { path: string; name: string }) => {
						if (route.path === path){
							// Toggle the active state
							return true
						}
						else{
							return false // Ensure other entries are not active
						}
					})
				// Entries without 'routes' are not considered here
			})
		},

		updateToggledSectionByName(state, action: PayloadAction<string>) {
			const name = action.payload
			const activeRole = state.roles.find((role: RoleNavigationModel) => role.active)
			if (!activeRole) return

			activeRole.navigation.forEach((entry: NavigationEntryModel) => {
				if (doesNavigationEntryHasRoutes(entry)) {
					if (entry.name === name)
						entry.toggled = !entry.toggled // Toggle the active state
					else
						entry.toggled = false // Ensure other entries are not active
				}
			})
		},

	},
})

export {
	doesNavigationEntryHasRoutes,
	getDefaultPathInNavigation
}

export const {
	setAuthenticated,
	setNavbar,
	initializeSession,
	setActiveRole,
	updateToggledSectionByPath,
	updateToggledSectionByName
} = sessionSlice.actions


export type {
	NavigationEntryWithRoutesModel,
	NavigationEntryWithPathModel,
	NavigationEntryModel,
	RoleNavigationModel,
	RoleModel
}

export default sessionSlice.reducer




















// import { createSlice } from "@reduxjs/toolkit"


// const managerNavigationInitialState = [
// 	{
// 		title: "Inventory",
// 		routes: [
// 			{ path: "/supplier/entrys", title: "Entrys" },
// 			{ path: "/supplier/categories", title: "Categories" }
// 		],
// 		active: false
// 	},
// 	{
// 		title: "Operations",
// 		routes: [
// 			{ path: "/supplier/orders", title: "Orders" },
// 			{ path: "/supplier/employments", title: "Employments" }
// 		],
// 		active: false
// 	},
// 	{
// 		title: "Financials",
// 		routes: [
// 			{ path: "/supplier/assessments", title: "Assessments" },
// 			{ path: "/supplier/transactions", title: "Transactions" },
// 			{ path: "/supplier/balances", title: "Balances" },
// 			{ path: "/supplier/invoices", title: "Invoices" },
// 			{ path: "/supplier/taxation", title: "Invoices rules" }
// 		],
// 		active: false
// 	},
// 	{
// 		title: "Definitions",
// 		routes: [
// 			{ path: "/supplier/account", title: "Account" },
// 			{ path: "/supplier/fiscal", title: "Fiscal" },
// 			{ path: "/supplier/delivery", title: "Delivery" },
// 			{ path: "/supplier/service", title: "Service" },
// 			{ path: "/supplier/ecommerce", title: "Ecommerce" }
// 		],
// 		active: true
// 	},
// 	{
// 		title: "Misc",
// 		path: "/supplier/misc"
// 	}
// ]

// const sellerNavigationInitialState = [
// 	{
// 		title: "Orders",
// 		path: "/seller/orders",
// 	},
// 	{
// 		title: "Balances",
// 		path: "/seller/balances",
// 	},
// 	{
// 		title: "Store",
// 		path: "/seller/store",
// 	},
// ]

// // ["unknown", "manager", "seller", "carrier", "accountant"]
// // Based on the roles given to the user at the moment of authentication we filter out the roles unavailable to the user
// const userRolesInitialStates = [
// 	{
// 		stakeholder: "supplier",
// 		navigation: managerNavigationInitialState
// 	},
// 	{
// 		stakeholder: "seller",
// 		navigation: sellerNavigationInitialState
// 	}
// ]

// function setAuthorizedUserRoles(authorizedRoles: any) {
// 	// Check if authorized roles array is empty or contains "supplier"
// 	if (authorizedRoles.length === 0 || authorizedRoles.includes("supplier")) {
// 		// Return the original array if authorized roles array is empty or contains "supplier"
// 		return userRolesInitialStates
// 	} else {
// 		// Filter the roles array to include only those roles present in authorized ones
// 		return userRolesInitialStates.filter(role => authorizedRoles.includes(role))
// 	}
// }

// // function setUserRoleActive(currentRolesState: Role[], roleName: string): Role[] {
// // 	return currentRolesState.map(role => {
// // 		if (role.name === roleName)
// // 			role.active = true
// // 		else
// // 			role.active = false
// // 		return role
// // 	})
// // }

// interface SessionState {
// 	authenticated: boolean
// 	navbar: boolean
// 	roles: Role[]
// }

// const initialState: SessionState = {
// 	authenticated: false,
// 	navbar: true,
// 	roles: []
// }

// const sessionSlice = createSlice({
// 	name: "session",
// 	initialState: initialState,
// 	reducers: {
// 		setAuthenticated: (state, action) => {
// 			return state = { ...state, authenticated: action.payload }
// 		},
// 		setNavbar: (state, action) => {
// 			return state = { ...state, navbar: action.payload }
// 		},
// 		// setSessionRoles: (state, action) => {
// 		// 	return state = { ...state, roles: action.payload }
// 		// },
// 		initializeSessionRoles: (state, action) => {
// 			return state = { ...state, roles: setAuthorizedUserRoles(action.payload) }
// 		},
// 		// setSessionUserRoleActive: (state, action) => {
// 		// 	return state = { ...state, roles: setUserRoleActive(state.roles, action.payload) }
// 		// }
// 		// setSessionUserRoleActive: (state, action) => {
// 		// 	state.roles = state.roles.map(role => {
// 		// 		return { ...role, active: role.name === action.payload }
// 		// 	})
// 		// },
// 		// setSessionNavigationRoute: (state, action) => {
// 		// 	state.roles = state.roles.map(role => {
// 		// 		if (role.active) {
// 		// 			role.navigation.map(entry => ({
// 		// 				...entry,
// 		// 				// Set active to true if the route names match and it is not already active, false otherwise
// 		// 				active: action.payload === entry.name && entry.active === false,
// 		// 			}))
// 		// 		}
// 		// 		return role
// 		// 	})
// 		// }

// 	}
// })

// export default sessionSlice.reducer
// export const {
// 	setAuthenticated,
// 	setNavbar,
// 	// setSessionRoles,
// 	initializeSessionRoles,
// 	// setSessionUserRoleActive,
// 	// setSessionNavigationRoute,
// } = sessionSlice.actions
















// const navigationInitialStates = {
// 	supplier: [
// 		{
// 			active: false,
// 			series: "Estoque",
// 			routes: [
// 				{ title: "Itens", path: "/supplier/entrys" },
// 				{ title: "Categorias", path: "/supplier/categories" }
// 			]
// 		},
// 		{
// 			active: false,
// 			series: "Operação",
// 			routes: [
// 				{ title: "Pedidos", path: "/supplier/orders" },
// 				{ title: "Colaboradores", path: "/supplier/employments" },
// 			]
// 		},
// 		{
// 			active: false,
// 			series: "Financeiro",
// 			routes: [
// 				{ title: "Contatos", path: "/supplier/assessments" },
// 				{ title: "Transações", path: "/supplier/transactions" },
// 				{ title: "Registradores", path: "/supplier/balances" },
// 				{ title: "Notas fiscais", path: "/supplier/invoices" },
// 				{ title: "Regras fiscais", path: "/supplier/taxation" }

// 			]
// 		},
// 		{
// 			active: true,
// 			series: "Definições",
// 			routes: [
// 				{ path: "/supplier/account", title: "Conta" },
// 				{ path: "/supplier/fiscal", title: "Fiscal" },
// 				{ path: "/supplier/delivery", title: "Entregas" },
// 				{ path: "/supplier/service", title: "Atendimento" },
// 				{ path: "/supplier/ecommerce", title: "Catálogo virtual" }
// 			]
// 		},
// 	]
// }

// const initialState = {
// 	authenticated: false,
// 	navbar: true,
// 	stakeholder: "supplier",
// 	roles: {
// 		available: [],
// 		current: ""
// 	},
// 	navigation: navigationInitialStates["supplier"] // Used that notation just to improve skimming
// }

// const sessionSlice = createSlice({
// 	name: "session",
// 	initialState: initialState,
// 	reducers: {
// 		authenticate: (state, action) => {
// 			return state = { ...state, authenticated: action.payload }
// 		},
// 		setNavbar: (state, action) => {
// 			return state = { ...state, navbar: action.payload }
// 		},
// 		setNavigationRoutes: (state, action) => {
// 			return state = { ...state, navigation: action.payload }
// 		},
// 	}
// })

// export default sessionSlice.reducer
// export const { authenticate, setNavbar, setNavigationRoutes } = sessionSlice.actions
