import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Components
import MainButton from "src/shared/components/standard/MainButton"
import SelectInput from "src/shared/components/standard/SelectInput"

// Store
import { setUser } from "src/shared/store/slices/userSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"
import { RootState } from "src/shared/store/reducers"


export default function RegimeTributarioModal() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector((state:RootState) => state.user)

	// States
	const [regimeTributario, setRegimeTributario] = useState(user.taxation.regimeTributario || "1")
	const [aliquotaSn, setAliquotaSn] = useState(user.taxation.aliquotaSn || Number(0))

	// Handlers
	async function handlePutNewTaxSettings() {
		try {
			const token = getToken()
			const updatedData = {
				aliquotaSn: aliquotaSn,
				regimeTributario: regimeTributario,
			}

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/sefaz`, {
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(updatedData),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({
				...user,
				taxation:
				{
					...user.taxation,
					sefaz: updatedData
				}
			}))
			dispatch(createToast({
				tone: "success",
				miliseconds: 5000,
				message: "Atualizado com sucesso",
				dismissable: true
			}))
		}
		catch (error) {
			console.error(error)
			dispatch(createToast({
				tone: "failure",
				miliseconds: 5000,
				message: "Houve um erro ao atualizar",
				dismissable:true
			}))
		}
	}

	useEffect(() => {
		setRegimeTributario(user.taxation.sefaz.regimeTributario || "1")
		setAliquotaSn(user.taxation.sefaz.aliquotaSn)
	}, [user])

	useEffect(() => {
		if (regimeTributario === "2" || regimeTributario === "3") {
			setAliquotaSn(0)
		}
	}, [regimeTributario])

	return (
		<div className="grid medium-gap">
			<div className="flex medium-gap">
				<SelectInput
					label="Regime tributário"
					value={regimeTributario}
					onChange={e => setRegimeTributario(e.target.value)}
				>
					<option value="1">Simples nacional</option>
					<option value="2">Simples nacional em excesso de sublimite de receita bruta</option>
					<option value="3">Regime normal (Lucro real ou lucro presumido)</option>
				</SelectInput>
			</div>

			<div>
				<MainButton
					onClick={handlePutNewTaxSettings}
				>
					<span>Definir regime tributário</span>
				</MainButton>
			</div>
		</div>
	)
}
