import { ChangeEvent, useState } from "react"

import { validateTaxpayer } from "offiziersmesser/lib/utils"

type TextInputTaxpayerProps = {
	errorMessage?: string | null
	name?: string
	className?: string
	onChange?: (event: ChangeEvent<HTMLInputElement>, valid: boolean) => void
	defaultValue?: string
	value?: string
	placeholder?: string
	required?: boolean
	hidden?: boolean
	disabled?: boolean
}

export default function TextInputTaxpayer({
	errorMessage = null,
	name,
	className,
	onChange,
	defaultValue,
	value = "",
	placeholder,
	required,
	hidden,
	disabled,
}: TextInputTaxpayerProps) {

	const [error, setError] = useState({
		active: !!errorMessage ? true : false,
		message: errorMessage,
	})

	if (hidden === true) return null

	return (
		<div className="flex flex-column justify-content-flex-end small-gap">
			<div className="flex small-gap align-items-flex-end">
				<span className="medium semibold-font">CPF ou CNPJ</span>
				{error.active === true && <span className="tiny red bold-font wide">{error.message}</span>}
			</div>
			<input
				type="text"
				name={name}
				className={`${className ? className : ''} ${error.active === true ? 'error' : ''}`}
				onChange={(e => {
					!!onChange && onChange(e, !error.active)

					if (e.target.value.length > 0 && !validateTaxpayer(e.target.value)) {
						setError({
							active: true,
							message: "inválido",
						})
					} else {
						setError({
							active: false,
							message: null,
						})
					}
				})}
				defaultValue={defaultValue}
				value={value}
				placeholder={placeholder}
				disabled={disabled}
				required={required}
			/>
		</div>
	)
}
