import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Icons
import {
	faArrowLeft,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import LinkButton from "src/shared/components/standard/LinkButton"
import MainButton from "src/shared/components/standard/MainButton"
import SelectInput from "src/shared/components/standard/SelectInput"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import HideableBlock from "src/shared/components/custom/HideableBlock/HideableBlock"
import BiasedTimeline from "src/shared/components/custom/BiasedTimeline/BiasesTimeline"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import OrderListTable from "../components/tables/OrderList/OrderListTable"

export default function ManageOrder() {

	const responseCodeHandler = useResponseCodeHandler()

	let { record } = useParams();

	// Variables
	const statusList = [
		{ note: "Solicitando entregador", bias: 0 },
		{ note: "Entrega em andamento", bias: 0 },
		{ note: "Pronto para retirada", bias: 0 },
		{ note: "Descarregando pedido", bias: 0 },
		{ note: "Problema com entrega", bias: 2 },
		{ note: "Cancelado", bias: 2 },
		{ note: "Finalizado", bias: 1 },
	]

	// States
	const [supplierOrder, setSupplierOrder] = useState(null)
	const [loading, setLoading] = useState(true)

	const [statuses, setStatuses] = useState([])
	const [selectedStatus, setSelectedStatus] = useState("")

	const orderTitle = record?.slice(0, 13)

	// Handlers
	const getOrder = async () => {

		setLoading(true)

		if (!record) return

		try{
			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/order/${record}`,
				{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const reponseData = await response.json()

			setSupplierOrder(reponseData)
			setStatuses(reponseData.statuses)

		}catch(error){
			console.error(error)
		}finally{
			setLoading(false)
		}
	}

	const  returnOrderDataItemQuantity = async (entry, quantity) => {

		const updatedContent = supplierOrder.content.map(
			(purchase, index) => {
				if (purchase.item.entry === entry)
					purchase.returned = purchase.returned + quantity
				// purchase.item.control.quantity = purchase.item.control.quantity + quantity
				return purchase
			}
		)
		const updatedOrderData = { ...supplierOrder, content: updatedContent }

		try{
			const token = getToken()
			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/supplier/order/data",
				{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ record: supplierOrder.record, data: updatedOrderData }),
			})
			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)
		}catch(error){
			console.error(error)
		}


	}
	const handleStatusUpdate = async () => {

		const status = statusList.find(status => status.note === selectedStatus)

		const updatedData = {
			...supplierOrder,
			statuses: [
				...statuses,
				{
					createdAt: parseInt(Date.now()/1000),
					bias: status.bias,
					note: status.note
				}
			]
		}

		setStatuses(prevStatuses => (
			[
				...prevStatuses,
				{
					createdAt: parseInt(Date.now()/1000),
					bias: status.bias,
					note: status.note
				}
			]
		))
		try{
			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/order/data`,
				{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(updatedData)
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			await getOrder()

		}catch(error){
			console.error(error)
		}

	}

	// Effects
	useEffect(() => {
		!!record && getOrder()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [record])
	useEffect(() => {

		if (!!supplierOrder && supplierOrder?.statuses?.length > 0) {
			setStatuses(supplierOrder?.statuses || [])
		}
	}, [supplierOrder])

	return (
		(loading === true) ? (
			<SupplierRoleNavbar>
				<div className="grid medium-gap">
					<PageTitle title={`Pedido <b>${record}</b>`} />

					<ComponentLoader />
				</div>
			</SupplierRoleNavbar>
		) : (
			<SupplierRoleNavbar>
				<div className="flex flex-column medium-gap">
					<div className="flex flex-column tiny-gap">
						<div className="flex">
							<LinkButton to="/supplier/orders" hierarchy="inferior" >
								<FontAwesomeIcon icon={faArrowLeft} />
								<span>Pedidos</span>
							</LinkButton>
						</div>
						<PageTitle title={`Pedido <b>${orderTitle}</b>`} />
						<span className="bold-font">Id do pedido: <b>{supplierOrder?.record}</b></span>
					</div>

					<div>
						<HideableBlock blockTitle="Editar informações do pedido">
							<div className="grid medium-gap medium-margin-vertical">
								<div className="grid">
									<span className="big bold-font">Atualizar status</span>
									<span>
										O status selecionado para esse pedido <b>também</b> será visto  pelo cliente
									</span>
								</div>

								<div className="flex small-gap align-items-flex-end justify-content-flex-start">
									<SelectInput
										label="Selecione um status"
										value={selectedStatus}
										onChange={event => setSelectedStatus(event.target.value)}
									>
										<option value="—" disabled={true}>Selecione um status</option>
										{statusList.map((status, index) => (
											<option key={index} value={status.note}>{status.note}</option>
										))}
									</SelectInput>
									<MainButton onClick={() => handleStatusUpdate()} >
										<span>Alterar status</span>
									</MainButton>
								</div>
							</div>
						</HideableBlock>
					</div>

					<div>
						<span className="big-x bold-font">Histórico</span>

						<BiasedTimeline items={statuses} informative={true} />
					</div>

					<div className="grid medium-gap">
						<span className="big-x bold-font">Itens</span>

						{supplierOrder.contents ? (
							<OrderListTable
								content={supplierOrder.contents}
								returnOrderDataItemQuantity={returnOrderDataItemQuantity}
							/>
						) : (
							<span className="red bold-font medium">Nenhum item encontrado!</span>
						)}
					</div>
				</div>
			</SupplierRoleNavbar>
		)
	)
}
