import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setOrders } from "../../../shared/store/slices/ordersSlice"

// Icons
import {
	faExclamationCircle,
	faExternalLinkSquareAlt,
	faBookOpen,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import OrdersTable from "./editor/components/tables/OrderTable/OrdersTable"
import { RootState } from "src/shared/store/reducers"
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

export default function Orders() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()
	// Store
	const user = useSelector((state:RootState) => state.user)
	const items = useSelector((state:RootState) => state.items)
	const orders = useSelector((state:RootState) => state.orders)

	// States
	const [loaded, setLoaded] = useState(false)

	const getOrders = async () => {

		try{
			const token = getToken()

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/resource/supplier/orders`,
				{
					headers: {
						"Authorization": `Bearer ${token}`
				}
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()
			dispatch(setOrders(data))
			setLoaded(true)

		}catch(error){
			console.error(error)
		}
	}
	// Effects
	useEffect(() => {
		getOrders()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	return (
		<SupplierRoleNavbar>
			<div className="flex flex-column medium-gap">
				{(loaded === false) ? (
					<>
						<PageTitle title="Pedidos" />

						<ComponentLoader />
					</>
				) : ((orders?.length === 0) ? (
					(user.categories === null || user.categories.length === 0) ? (
						<>
							<PageTitle title="Pedidos" />

							<Notice tone="warning" to="/supplier/categories">
								<div className="grid-columns medium-gap left-items">
									<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle} />
									<div className="grid small-gap">
										<span className="small">Antes de sequer ter um item para vender, você precisa criar uma categoria.</span>
										<div className="grid-columns small-gap left-items bold-font tiny">
											<span>Clique aqui para ir ao editor de categorias</span>
											<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt} />
										</div>
									</div>
								</div>
							</Notice>
						</>
					) : (items.length === 0) ? (
						<>
							<PageTitle title="Pedidos" />

							<Notice tone="info" to="/supplier/items">
								<div className="grid-columns medium-gap left-items">
									<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle} />
									<div className="grid small-gap">
										<span className="small">
											Para conseguir vender é preciso ao menos possuir ao menos um item cadastrado no estoque.
										</span>
										<div className="grid-columns small-gap left-items bold-font tiny">
											<span>Clique aqui para criar um novo item</span>
											<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt} />
										</div>
									</div>
								</div>
							</Notice>
						</>
					) : (
						<>
							<PageTitle title="Pedidos" />

							<Notice>
								<div className="grid-columns medium-gap left-items">
									<FontAwesomeIcon className="fa-icon" icon={faBookOpen} />
									<span className="small small-margin-vertical">
										Assim que realizar sua primeira venda através do ponto de venda, seus pedidos serão gerenciáveis a partir daqui.
									</span>
								</div>
							</Notice>
						</>
					)
				) : (
					<>
						<PageTitle title="Pedidos" />

						<OrdersTable orders={orders} userType={undefined} />
					</>
				))}
			</div>
		</SupplierRoleNavbar>
	)
}
