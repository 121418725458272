import { ReactNode, useState } from "react"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Utils
// import { RenderCart } from "offiziersmesser/lib/renderers"
import { printContent, toFixedImproved } from "offiziersmesser/lib/utils"
import { calculateItemPrice, calculateOrderSumPlusShipping } from "offiziersmesser/lib/helpers"

// Icons
import {
	faExclamationCircle,
	faExternalLinkSquareAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import Notice from "src/shared/components/custom/Notice/Notice"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"
import LinkButton from "src/shared/components/standard/LinkButton"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import SupplierNavbar from "src/shared/components/custom/Navbar/SupplierNavbar"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setCart } from "src/shared/store/slices/cartSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"


type Item = {
	item: string
	quantity: number
}

type Quotation = {
	expiresAt: number
	quotation: string
	items: Item[]
}

export default function CreateQuotationModalButton() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	const { pathname } = useLocation()

	// Store
	const user = useSelector((state: any) => state.user)
	const store = useSelector((state: any) => state.store)
	const cart = useSelector((state: any) => state.cart)
	const cartSize = cart.data.contents.length


	// const supplier = useSelector(state => state.supplier) // need to remove this

	// States
	const [renderBudgetHandlerModal, setRenderBudgetHandlerModal] = useState(false)

	const [discount,] = useState(0)

	const [budgetUrl, setBudgetUrl] = useState("")
	const [userBudget, setUserBudget] = useState<Quotation>({
		quotation: "",
		expiresAt: 0,
		items: []
	})

	const total = calculateOrderSumPlusShipping(cart.data)

	// Handlers
	async function getBudgetCode() {
		try {
			const response = await fetch(`${process.env.REACT_APP_PEDDLER_API_URL}/quotation/generate/code`)

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const userBudget = await response.json()

			setUserBudget(userBudget)
			setRenderBudgetHandlerModal(true)
		}
		catch {
			dispatch(createToast({
				tone: "failure",
				miliseconds: 5000,
				message: "Erro ao gerar orçamento"
			} as any))
		}

	}

	async function createBudget(/*print: boolean*/) {
		const token = getToken()

		try {
			const response = await fetch(`${process.env.REACT_APP_PEDDLER_API_URL}/quotation`, {
				method: "POST",
				headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
				body: JSON.stringify({ ...userBudget, items: cart.data.contents })
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			// if (print) {
				// printContent(RenderCart(cart, total, discount, userBudget.quotation, seller, user))
			// 	setRenderBudgetHandlerModal(false)
			// } else {
				const textToCopy = userBudget.quotation

				try {
					await navigator.clipboard.writeText(textToCopy)
					dispatch(createToast({
						tone: "success",
						miliseconds: 5000,
						message: "Código gerado e copiado",
					} as any))
					setRenderBudgetHandlerModal(false)
				} catch (error) {
					console.error(error)
				}
			// }

		} catch (error) {
			dispatch(
				createToast({
					tone: "failure",
					miliseconds: 5000,
					message: "Não foi possível imprimir orçamento",
				} as any)
			)
		}
	}


	return (
		<>
			{renderBudgetHandlerModal &&
				<Modal
					title={userBudget ? `Orçamento ${userBudget.quotation}` : "Orçamento"}
					onClose={() => setRenderBudgetHandlerModal(false)}
				>
					<>
						<div className="flex justify-content-space-between">
							<span className="bold-font">Total</span>
							<span className="bold-font">R$ {toFixedImproved(total - 0, 2)}</span>
						</div>
						<div className="grid small-gap">
						{cart.data.contents.map((item: any) =>
							<div className="flex justify-content-space-between">
								<div className="flex medium-gap">
									<span className="bold-font">{item.amount}</span>
									<span className="bold-font">{item.element.control.measure}</span>
									<span>{item.element.control.description}</span>
								</div>

								<span className="bold-font">R$ {toFixedImproved(calculateItemPrice(item.element) * item.amount, 2)}</span>
							</div>
						)}
						</div>
						<MainButton
							onClick={() => createBudget()}
						>
							<span>Gerar orçamento</span>
						</MainButton>
					</>
				</Modal>
			}
			<MainButton
				hierarchy="low"
				onClick={() => setRenderBudgetHandlerModal(true)}
			>
				<span>Criar orçamento</span>
			</MainButton>
		</>
	)
}