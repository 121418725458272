import { Dispatch, SetStateAction, useEffect, useState } from "react"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import { cleanTaxpayer, validateCnpj, validateCpf, validateTaxpayer } from "offiziersmesser/lib/utils"

// Components
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"
import SelectInput from "src/shared/components/standard/SelectInput"
import TextInputTaxpayer from "src/shared/components/custom/TextInputTaxpayer/TextInputTaxpayer"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import { AdministrativeModel } from "src/shared/models/user"
import { EMPLOYEE_ROLES } from "offiziersmesser/lib/utils"

type ValidationBlockProps = {
	blockTitle: string
	employee: any
	setEmployee: Dispatch<SetStateAction<any>>
	employeeEmployments: any[]
	handleAddNewEmployment: () => void
	setIndividualRegistration: Dispatch<SetStateAction<any>>
	setBusinessRegistration: Dispatch<SetStateAction<any>>
}

export const ValidationBlock = ({
	blockTitle,
	employee, setEmployee,
	employeeEmployments,
	handleAddNewEmployment,
	setIndividualRegistration,
	setBusinessRegistration
}: ValidationBlockProps) => {

	const responseCodeHandler = useResponseCodeHandler()

	// States
	const [, setIsGeneric] = useState(false)
	const [isManager, setIsManager] = useState(false)
	const [isSeller, setIsSeller] = useState(false)
	const [isCarrier, setIsCarrier] = useState(false)

    const [userExists, setUserExists] = useState(false)
    const [user, setUser] = useState<AdministrativeModel>()

    const employmentValidation = async () => {
		try{
			const token = getToken()
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/resource/supplier/employee/identifier`,
				{
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body:JSON.stringify({taxpayer:cleanTaxpayer(employee.taxpayer)})
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()

			setUserExists(true)
			setUser(data)

		}catch(error){
            console.error(error)
            setUserExists(false)
        }
	}

	// Effects
	useEffect(() => {

		if (employeeEmployments.length > 0) {
			employeeEmployments.map((employment: any) => {
				switch (employment.employment?.role) {
					case EMPLOYEE_ROLES[0].role: setIsGeneric(true); break
					case EMPLOYEE_ROLES[1].role: setIsManager(true); break
					case EMPLOYEE_ROLES[2].role: setIsSeller(true); break
					case EMPLOYEE_ROLES[3].role: setIsCarrier(true); break
					default: break
				}
				return null
			})
		} else {
			setIsGeneric(false)
			setIsManager(false)
			setIsSeller(false)
			setIsCarrier(false)
		}

	}, [employeeEmployments])

    useEffect(() => {
		if(employee.taxpayer.length === 11){
			employmentValidation()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employee.taxpayer])

	return (
		<>
			<PageTitle
				title={`${employeeEmployments.length > 0 ? "Editar colaborador" : "Criar colaborador"}`}
			/>

			<span className="bold-font big">{blockTitle}</span>

			<div className="grid small-gap fill-normal wide wide-children">
				<TextInputTaxpayer
					value={employee.taxpayer}
                    disabled={employeeEmployments.length > 0}
					onChange={e => setEmployee((employee: any) => ({
						...employee,
						taxpayer: e.target.value.replace(/\D/g, "")
					}))}
				/>

				{(validateCpf(employee.taxpayer) === true
					&& (employeeEmployments.length > 0)) && (
						<>
							<TextInput
								label="Nome"
								value={user?.byname}
								disabled={true}
							/>
							<TextInput
								label="Sobrenome"
								value={user?.company}
								disabled={true}
							/>
						</>
					)}

                {(validateCpf(employee.taxpayer) === true && employeeEmployments.length === 0 && userExists) && (
						<>
							<TextInput
								label="Nome"
								value={user?.reference.name ||''}
								disabled={true}
							/>
							<TextInput
								label="Sobrenome"
								value={user?.reference.surname||''}
								disabled={true}
							/>
						</>
					)}

				{(validateCnpj(employee.taxpayer) === true
					&& employeeEmployments.length > 0) && (
						<>
							<TextInput
								label="Razão social"
								value={employee.administrative.reference.company ? employee.administrative.reference.company : user?.company||""}
								disabled={true}
							/>
							<TextInput
								label="Nome fantasia"
								value={employee.administrative.reference.byname ? employee.administrative.reference.byname : user?.byname||""}
								disabled={true}
							/>
						</>
					)}

                {(validateCnpj(employee.taxpayer) === true && employeeEmployments.length === 0 && userExists) && (
						<>
							<TextInput
								label="Razão social"
								value={user?.company||""}
								disabled={true}
							/>
							<TextInput
								label="Nome fantasia"
								value={user?.byname||""}
								disabled={true}
							/>
						</>
					)}
			</div>

			<div className="grid fill-normal small-gap wide wide-children">
				<SelectInput
					label="Cargo"
					value={employee.role}
					onChange={e => setEmployee((employee: any) => ({
						...employee,
						role: e.target.value
					}))}
				>
					<option value={EMPLOYEE_ROLES[0].role} disabled={isManager}>{isManager ? "Ja é outros" : "Outros"}</option>
					<option value={EMPLOYEE_ROLES[1].role} disabled={isManager}>{isManager ? "Ja é gerente" : "Gerente"}</option>
					<option value={EMPLOYEE_ROLES[2].role} disabled={isSeller}>{isSeller ? "Ja é vendedor" : "Vendedor"}</option>
					<option value={EMPLOYEE_ROLES[3].role} disabled={isCarrier}>{isCarrier ? "Ja é entregador" : "Entregador"}</option>
				</SelectInput>
			</div>

			{((employeeEmployments.length > 0 && employee.taxpayer !== "") || userExists) ? (
				<div className="grid fill-normal">

					<MainButton onClick={() => { handleAddNewEmployment() }} >
						<span>Concluir</span>
					</MainButton>
				</div>
			) : (
				<div className="grid fill-normal">
					<MainButton
						onClick={() =>
							(validateCpf(employee.taxpayer) === true)
								? setIndividualRegistration(true)
								: setBusinessRegistration(true)
						}
						disabled={!validateTaxpayer(employee.taxpayer)}
					>
						<span>Avançar</span>
					</MainButton>
				</div>
			)}
		</>
	)

}
