import { useState, useEffect } from "react"

// Icons
import { faQrcode } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import MainButton from "src/shared/components/standard/MainButton"
import BarcodeScanner from "src/shared/components/custom/BarcodeScanner/BarcodeScanner"
import ExtendableTextInput from "../ExtendableTextInput/ExtendableTextInput"


async function checkForCameras() {
	const devices = await navigator.mediaDevices.enumerateDevices()
	return devices.filter(device => device.kind === "videoinput").length
}

// // WARNING!
// // This does not work (yet, at least) on Firefox, so better not rely on this function. Further reference on
// // https://stackoverflow.com/questions/53147944/firefox-permission-name-member-of-permissiondescriptor-camera-is-not-a-vali
// async function checkCameraAccess() {
// 	const permission = await navigator.permissions.query({ name: "camera" })
// 	if (permission.state === "granted") {
// 		return true
// 	} else if (permission.state === "prompt") {
// 		return requestCameraAccess() // This will wait for the requestCameraAccess to resolve
// 	} else {
// 		return false
// 	}
// }

// async function requestCameraAccess() {
// 	try {
// 		const stream = await navigator.mediaDevices.getUserMedia({ video: true })
// 		// It is important to stop the stream if you are not going to use it immediately
// 		stream.getTracks().forEach(track => track.stop())
// 		// Access was granted
// 		return true
// 	} catch (error) {
// 		console.error("Camera access denied:", error)
// 		// Access was denied
// 		return false
// 	}
// }


export default function TextInputBarcode({
    onScan,
    onError,
    ...props
}) {

	// const browser = getBrowser()

	const [modal, setModal] = useState(false)

	const [availableCameras, setAvailableCameras] = useState(0)
	const ensureCameraAvailability = async () => setAvailableCameras(await checkForCameras())
	useEffect(() => {
		ensureCameraAvailability()
	}, [])

	// Ugly workaround for a unique solution across Firefox and Chromium. Further reference on
	// https://stackoverflow.com/questions/53147944/firefox-permission-name-member-of-permissiondescriptor-camera-is-not-a-vali
	const [cameraPermissionStatus, setCameraPermissionStatus] = useState(localStorage.getItem("cameraPermission"))
	const storeCameraPermission = permission => {
		sessionStorage.setItem("cameraPermission", permission)
		setCameraPermissionStatus(permission)
	}

	const OpenCameraScannerButton = () =>
		<button
			type="button"
			className="element-highlight border-radius-small-half unselectable medium"
			onClick={() => setModal(true)}
		>
			<FontAwesomeIcon icon={faQrcode} />
		</button>

	return (
		<>
			{(modal === true) && (
				(cameraPermissionStatus !== "granted") ? (
					<Modal title="Permissão para câmera" onClose={() => setModal(false)}>
						<div className="grid medium-gap left-items">
							{(availableCameras === 0) ? (
								<span>Nenhuma câmera disponível pra uso nesse dispositivo.</span>
							) : (
								<>
									<span>O próximo passo é permitir a leitura de códigos com sua câmera.</span>
									<MainButton onClick={() => storeCameraPermission("granted")}>
										<span>Autorizar câmera</span>
									</MainButton>
								</>
							)}
						</div>
					</Modal>
				) : (
					<Modal noPadding={true}>
						<BarcodeScanner
							onScan={code => { onScan(code); setModal(false) }}
							onError={err => { onError(err); setModal(false) }}
						/>
					</Modal>
				)
			)}

			<ExtendableTextInput
				{...props}
				after={<OpenCameraScannerButton/>}
			/>
		</>
	)
}
