import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { XMLParser as xmlParser } from "fast-xml-parser"

// Utils
import insertItem from "../services/insertItem"

// Models
import ItemModel from "src/shared/models/item"

// Store
import { RootState } from "src/shared/store/reducers"
import { insertIntoWorkableItems, resetWorkableItems, WorkableItem } from "src/shared/store/slices/workableItemsSlice"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Icon } from "@fortawesome/fontawesome-svg-core"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import MainButton from "src/shared/components/standard/MainButton"
import LinkButton from "src/shared/components/standard/LinkButton"
import NumberInput from "src/shared/components/standard/NumberInput"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import RadioButtonInput from "src/shared/components/standard/RadioButtonInput"
import PaginationForm from "src/shared/components/custom/PaginationForm/PaginationForm"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import ItemsForm from "../components/forms/ItemsForm"
import XmlItemCard from "../components/xmlItemCard/XmlItemCard"
import itemJsonTemplate from "../templates/xmlItemJsonTemplate"

export default function XmlCover() {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	// Store
	const user = useSelector((state: RootState) => state.user)
	const files = useSelector((state: RootState) => state.files)
	const workableItems = useSelector((state: RootState) => state.workableItems.items)

	// States
	const [loading, setLoading] = useState(true) // feedback for loading
	const [loadingSendAll, setLoadingSendAll] = useState(false) // feedback for loading
	const [addedItems, setAddedItems] = useState<boolean[]>([])

	const [xmls, setXmls] = useState<ItemModel[]>([]) // used when there are multiple files
	const [xmlSettings, setXmlSettings] = useState({
		priceCost: true,
		markup: 0,
	})
	const [renderModal, setRenderModal] = useState(true) // modal

	// Array Xml
	const [xmlItems, setXmlItems] = useState<ItemModel[]>([])

	// Parser options
	const options = useMemo(() => ({ ignoreAttributes: false }), [])
	const parser = useMemo(() => new xmlParser(options), [options])

	// Handlers
	async function handleSendAll() {
		try {
			await handleToggleLoadingSendAll(true)

			const allUnsended = [] as WorkableItem[]
			addedItems.map((item, index) => item === false && allUnsended.push(workableItems[index]))

			await Promise.all(allUnsended.map(async (workable, index) => {

				const workableItem = workable.item
				const workableImages = workable.images

				return await insertItem(
					workableItem,
					workableImages,
					workableItem.accounting.taxation,
					{
						ncm: workableItem.accounting.details.ncm,
						cest: workableItem.accounting.details.cest,
						origem: workableItem.accounting.details.origin
					},
					user.alias
				)
			}))

			await handleToggleLoadingSendAll(false)
			navigate("/supplier/items")
		} catch (error) {
			console.error("SEND ALL ERROR", error)
		}
	}
	async function handleToggleLoadingSendAll(status: boolean) {

		if (status === true) {
			setLoadingSendAll(true)
		} else {
			return new Promise((resolve) => { setTimeout(() => { setLoadingSendAll(status); resolve("") }, 1250) })
		}
	}

	// Effect
	useEffect(() => {

		setLoading(true)

		const localFiles = files.files.map(file => {
			return parser.parse(file.content)
		})

		setXmls(localFiles)
	}, [files, parser])
	useEffect(() => {
		if (renderModal === false) {

			if (xmls.length <= 0) return

			try {
				xmls.forEach((xml: any) => {
					if (
						Array.isArray(
							typeof xml?.nfeProc === "undefined"
								? xml.NFe.infNFe.det
								: xml.nfeProc.NFe.infNFe.det
						)
					) {
						typeof xml?.nfeProc === "undefined" ?
							(
								setXmlItems(prevState => {
									return [...prevState, ...xml.NFe.infNFe.det.map((item: any, index: number): ItemModel => {
										return itemJsonTemplate(item, index, xmlSettings.markup, xmlSettings.priceCost, user.categories[0].tag)
									})]
								})
							)
							: (
								setXmlItems(prevState => {
									return [...prevState, ...xml.nfeProc.NFe.infNFe.det.map((item: any, index: number): ItemModel => {
										return itemJsonTemplate(item, index, xmlSettings.markup, xmlSettings.priceCost, user.categories[0].tag)
									})]
								})
							)
					} else {
						if (typeof xml?.nfeProc === "undefined") {
							setXmlItems(prevState => [...prevState, itemJsonTemplate(xml.NFe.infNFe.det, 0, xmlSettings.markup, xmlSettings.priceCost, user.categories[0].tag)])
						} else {
							setXmlItems(prevState => [...prevState, itemJsonTemplate(xml.nfeProc.NFe.infNFe.det, 0, xmlSettings.markup, xmlSettings.priceCost, user.categories[0].tag)])
						}
					}
				})
			} catch (err) {
				console.error(err)
			} finally {
				setLoading(false)
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [xmls, renderModal])
	useEffect(() => {
		setAddedItems(xmlItems.map(() => false))
		dispatch(resetWorkableItems())
		dispatch(insertIntoWorkableItems(xmlItems.map(item => ({ item: item, images: [] }))))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [xmlItems])

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap">
				{renderModal === true && (
					<Modal title="Configuração de preços" /*onClose={() => setRenderModal(false)}*/>
						<div className="grid medium-gap">
							<span>Escolha como os preços extraídos devem ser interpretados{/*Para definir se os valores que irão vir do arquivo são de:*/}</span>
							<RadioButtonInput
								name="priceCost"
								label="Preço de custo"
								onChange={() => setXmlSettings(prevState => ({ ...prevState, priceCost: true }))}
								defaultChecked={xmlSettings.priceCost === true}
								checked={xmlSettings.priceCost === true}
							/>
							<RadioButtonInput
								name="priceCost"
								label="Preço de venda"
								onChange={() => setXmlSettings(prevState => ({ ...prevState, priceCost: false }))}
								defaultChecked={xmlSettings.priceCost === false}
								checked={xmlSettings.priceCost === false}
							/>
						</div>
						<NumberInput
							label="Percentual geral de lucro"
							value={xmlSettings.markup}
							onChange={(e: any) => setXmlSettings((prevState: any) => ({ ...prevState, markup: e.target.valueAsNumber }))}
							onBlur={(e: any) => setXmlSettings((prevState: any) => ({ ...prevState, markup: xmlSettings.priceCost === false && e.target.valueAsNumber === 0 ? 1 : e.target.valueAsNumber }))}
						/>
						<MainButton
							onClick={() => setRenderModal(false)}
						>
							<span>Configurar preços</span>
						</MainButton>
					</Modal>
				)}
				{loading === true ? (
					<>
						<div>
							<LinkButton to="/supplier/items" hierarchy="inferior">
								<FontAwesomeIcon icon={faArrowLeft as Icon} />
								<span>Itens</span>
							</LinkButton>
						</div>
						<ComponentLoader />
					</>
				) : (xmlItems.length > 0) ? (
					<>
						<div>
							<LinkButton to="/supplier/items" hierarchy="inferior">
								<FontAwesomeIcon icon={faArrowLeft as Icon} />
								<span>Itens</span>
							</LinkButton>
						</div>

						<div className="flex justify-content-space-between">
							<div className="wide">
								<PageTitle title="Importar XML" />
							</div>
							<div className="flex wide justify-content-flex-end width-fit-content small-gap">
								{addedItems.filter(item => item === true).length < xmlItems.length && (
									<MainButton
										hierarchy="low"
										onClick={() => navigate("/supplier/items")}
									>
										<span>Finalizar</span>
									</MainButton>
								)}

								<MainButton
									onClick={() => {
										if (addedItems.filter(item => item === true).length <= xmlItems.length) {
											handleSendAll()
										} else {
											navigate("/supplier/items")
										}
									}}
									// loading={loadingSendAll}
								>
									<span>
										{`${addedItems.filter(item => item === false).length === xmlItems.length
											? "Enviar todos"
											: (addedItems.filter(item => item === true).length === xmlItems.length)
												? "Concluir"
												: `Enviar ${addedItems.filter(item => item === false).length} restantes`}`}
									</span>
								</MainButton>


							</div>
						</div>

						<PaginationForm buttonsUp={true}>
							{xmlItems.map((item, index) =>
								<XmlItemCard
									key={index}
									index={index}
									disabled={addedItems[index] === true}
									setAddedItems={setAddedItems}
								>
									<ItemsForm />
								</XmlItemCard>
							)}
						</PaginationForm>
					</>
				) : null}
			</div>
		</SupplierRoleNavbar>
	)
}
