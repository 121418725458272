// Utils
import { AREA_CODES_BR, REGION_LIST_BR } from "offiziersmesser/lib/utils"
import { formatCapitalizeOnlyFirstLetter, validateCnpj } from "offiziersmesser/lib/utils"

// Components
import TextInput from "../../../../../../shared/components/standard/TextInput"
import LinkButton from "../../../../../../shared/components/standard/LinkButton"
import SelectInput from "../../../../../../shared/components/standard/SelectInput"

// Styles
import "../../../Invoices.css"
import TextInputTaxpayer from "../../../../../../shared/components/custom/TextInputTaxpayer/TextInputTaxpayer"


export default function ClientBlock({
	customerName, setCustomerName,
	customerSurname, setCustomerSurname,
	customerEmail, setCustomerEmail,
	customerPhone, setCustomerPhone,
	customerPostal, setCustomerPostal,
	customerArea, setCustomerArea,
	customerCity, setCustomerCity,
	customerRoad, setCustomerRoad,
	customerExtra, setCustomerExtra,
	customerNumber, setCustomerNumber,
	customerRegion, setCustomerRegion,
	customerTaxpayer, setCustomerTaxpayer,
	customerIe, setCustomerIe,
	customerIeSt, setCustomerIeSt,
	customerCompany, setCustomerCompany,
	customerByname, setCustomerByname,
	availableCep

}) {
	return (
		<div className="flex flex-column medium-gap">

			<span className="bold-font big">Cliente</span>

			<div className="flex flex-column small-gap">
				<div className="grid fill-normal small-gap">
					<TextInputTaxpayer
						name="taxpayer"
						value={customerTaxpayer}
						placeholder="123.456.789-10"
						onChange={(e) => setCustomerTaxpayer(e.target.value)}
					/>

					{customerTaxpayer !== "" && (
						(validateCnpj(customerTaxpayer) === true ? (
							<>
								<TextInput
									label="Nome fantasia"
									name="byname"
									value={customerByname}
									placeholder=""
									onChange={(e) => setCustomerByname(e.target.value)}
								/>
								<TextInput
									label="Razão social"
									name="company"
									value={customerCompany}
									placeholder=""
									onChange={(e) => setCustomerCompany(e.target.value)}
								/>
								<TextInput
									label="Inscrição Estadual"
									name="ie"
									value={customerIe}
									placeholder=""
									onChange={(e) => setCustomerIe(e.target.value)}
								/>
								<TextInput
									label="Inscrição Estadual ST"
									name="ieSt"
									value={customerIeSt}
									placeholder=""
									onChange={(e) => setCustomerIeSt(e.target.value)}
								/>

							</>
						) : (
							<>
								<TextInput
									label="Nome"
									name="name"
									value={customerName}
									placeholder="João"
									onChange={(e) => setCustomerName(formatCapitalizeOnlyFirstLetter(e.target.value))}
								/>
								<TextInput
									label="Sobrenome"
									name="surname"
									value={customerSurname}
									placeholder="Almeida"
									onChange={(e) => setCustomerSurname(formatCapitalizeOnlyFirstLetter(e.target.value))}
								/>

							</>
						))
					)}
				</div>



				<div className="grid fill-normal medium-gap">
					<div className="flex small-gap wide">
						<div className="grid fill-tiny-xx">
							<SelectInput
								label="DDD"
								name="destination"
								defaultValue={customerPhone.destination || "—"}
								onChange={e => setCustomerPhone({ ...customerPhone, destination: e.target.value })}>
								{AREA_CODES_BR.map((code,index)=>
									<option key={index} value={code}>{code}</option>
								)}
							</SelectInput>
						</div>
						<div className="wide wide-children">
							<TextInput
								label="Celular"
								name="subscriber"
								value={customerPhone.subscriber}
								placeholder="901239876"
								onChange={e => setCustomerPhone({ ...customerPhone, subscriber: e.target.value })}
							/>
						</div>
					</div>
					<TextInput
						label="E-mail"
						value={customerEmail}
						placeholder="joao@exemplo.com"
						onChange={e => setCustomerEmail(e.target.value)}
					/>
				</div>
			</div>

			<span className="bold-font big">Endereço</span>

			<div className="grid fill-normal">
				<TextInput
					type="number"
					value={customerPostal}
					onChange={e => setCustomerPostal(e.target.value.replaceAll(/[^0-9]/g, ""))}
					label={
						<div className="flex justify-content-space-between wide">
							<span>CEP</span>
							<LinkButton
								hierarchy="minimal"
								target="_blank"
								to="/correios"
							>
								<span className="small">Não sabe seu CEP?</span>
							</LinkButton>
						</div>
					}
				/>
			</div>

			{availableCep && (
				<>
					<div className="grid fill-huge-x small-gap wide">
						<div className="flex small-gap">
							<div className="wide wide-children">
								<TextInput
									label="Rua"
									name="road"
									value={customerRoad}
									onChange={e => setCustomerRoad(formatCapitalizeOnlyFirstLetter(e.target.value))}
								/>
							</div>
							<div className="flex">
								<TextInput
									label="Número"
									value={customerNumber}
									onChange={e => setCustomerNumber(formatCapitalizeOnlyFirstLetter(e.target.value))}
								/>
							</div>
						</div>

						<TextInput
							label="Bairro"
							value={customerArea}
							onChange={e => setCustomerArea(formatCapitalizeOnlyFirstLetter(e.target.value))}
						/>
					</div>

					<div className="grid medium-gap medium-gap fit-normal">

						<TextInput
							label="Complemento"
							value={customerExtra}
							onChange={e => setCustomerExtra(formatCapitalizeOnlyFirstLetter(e.target.value))}
						/>
						<TextInput
							label="Cidade"
							value={customerCity}
							onChange={e => setCustomerCity(formatCapitalizeOnlyFirstLetter(e.target.value))}
						/>
						<SelectInput
							label="Estado"
							name="region"
							value={customerRegion}
							onChange={e => setCustomerRegion(formatCapitalizeOnlyFirstLetter(e.target.value))}>
							<option key={0} disabled={true} value="—"></option>,
							{REGION_LIST_BR.map((entry, index) => (
								<option key={index + 1} value={entry.region}>
									{entry.region}
								</option>
							))}
						</SelectInput>
					</div>
				</>
			)}
		</div>
	)
}
