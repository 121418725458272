import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import { getPostalData, validateTaxpayer } from "offiziersmesser/lib/utils"

// Store
import { RootState } from "src/shared/store/reducers"

// Models
import { AdministrativeModel, LocalityModel, ReferenceModel } from "src/shared/models/user"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import MainButton from "src/shared/components/standard/MainButton"
import LocalityForm from "src/shared/components/custom/Forms/LocalityForm/LocalityForm"
import ReferenceForm from "src/shared/components/custom/Forms/ReferenceForm/ReferenceForm"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import { ValidationBlock } from "../components/steps/ValidationBlock"
import TextInput from "src/shared/components/standard/TextInput"
import TextInputTaxpayer from "src/shared/components/custom/TextInputTaxpayer/TextInputTaxpayer"

export type EmployeeModel = {
	taxpayer: string
	role: string,
	administrative: AdministrativeModel
}

type NewEmploymentsProps = {
	location: any
}

export default function NewEmployments({
	location
}: NewEmploymentsProps) {

	const navigate = useNavigate()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const employments = useSelector((state: RootState) => state.employments)

	// States
	const [employee, setEmployee] = useState<EmployeeModel>({
		taxpayer: "",
		role: "0",
		administrative: {
			company: "",
			byname: "",
			reference: {
				name: "",
				surname: "",
				email: "",
				phone: {
					prefix: "55",
					subscriber: "",
					destination: "",
				}
			},
			locality: {
				area: "",
				city: "",
				road: "",
				extra: "",
				number: "",
				postal: "",
				region: "",
				country: "BR",
			},
		}
	})
	const [reference, setReference] = useState<ReferenceModel>(
		{
			name: "",
			surname: "",
			email: "",
			phone: {
				prefix: "55",
				subscriber: "",
				destination: "",
			}
		}
	)
	const [locality, setLocality] = useState<LocalityModel>(
		{
			area: "",
			city: "",
			road: "",
			extra: "",
			number: "",
			postal: "",
			region: "",
			country: "BR",
		}
	)
	const [byname, setByname] = useState<string>("")
	const [company, setCompany] = useState<string>("")
	const [taxpayer, setTaxpayer] = useState<string>("")
	const [employeeEmployments, setEmployeeEmployments] = useState<any[]>([])


	// Validation Variables
	const [individualRegistration, setIndividualRegistration] = useState<boolean>(false)
	const [businessRegistration, setBusinessRegistration] = useState<boolean>(false)

	// Handlers
	async function handleAddNewEmployment() {

		try {
			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/employee`, {
				method: "post",
				body: JSON.stringify({
					taxpayer: employee.taxpayer,
					role: employee.role,
					administrative: {
						...employee.administrative,
						byname,
						company
					},
				}),
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)
		}
		catch (error) { console.error(error) }
		finally { navigate(-1) }
	}

	// Effects
	useEffect(() => {
		setEmployee(employee => ({
				...employee,
				administrative:{
					...employee.administrative,
					reference: reference,
				}
			}))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reference])

	// Effects
	useEffect(() => {
		setEmployee(employee => ({
				...employee,
				administrative:{
					...employee.administrative,
					locality: locality,
				}
			}))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locality])

	// Effects
	useEffect(() => {
		if (validateTaxpayer(taxpayer)) {

			setEmployee(employee => ({
				...employee,
				taxpayer: taxpayer
			}))
			setEmployeeEmployments(employments.filter(employment => employment.taxpayer === taxpayer))

		} else {
			setEmployeeEmployments([])
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxpayer])

	useEffect(() => {
		if (employeeEmployments.length > 0) {

			const employment = employeeEmployments[0]

			setEmployee(employee => ({
				...employee,
				taxpayer: employment.taxpayer,
				role: employment.employment.role,
				administrative: {
					...employee.administrative,
					reference: reference,
					locality: locality,
					company: employment.employee?.company,
					byname: employment.employee?.byname,
				}
			}))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employeeEmployments])

	useEffect(() => {

		const postal = locality.postal

		if (postal.replaceAll(/-|_/g, "").length !== 8) {
			return
		}

		(async () => {

			const postalData = await getPostalData(postal.replaceAll(/-|_/g, ""))

			setLocality(l => ({
				...l,
				area: postalData.bairro,
				city: postalData.localidade,
				road: postalData.logradouro,
				postal: postal,
				region: postalData.uf,
			}))

		})()
	}, [locality.postal])

	useEffect(()=>{
		setTaxpayer(location?.region?.id as string || "")
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap large-margin-bottom">
				<div className="flex">
					<MainButton onClick={() => navigate(-1)} hierarchy="inferior">
						<FontAwesomeIcon icon={faArrowLeft as Icon} />
						<span>Colaboradores</span>
					</MainButton>
				</div>

				{businessRegistration === false && (
					individualRegistration === false && (
						<ValidationBlock
							blockTitle="Dados do colaborador"
							employee={employee} setEmployee={setEmployee}
							employeeEmployments={employeeEmployments}
							setIndividualRegistration={setIndividualRegistration}
							setBusinessRegistration={setBusinessRegistration}
							handleAddNewEmployment={handleAddNewEmployment}
						/>
					)
				)}

				{(businessRegistration === true
				|| individualRegistration === true) && (
						<>
						{!!businessRegistration
						&&
						<>
							<TextInput
								label="Nome Fantasia"
								name="nomeFantasia"
								value={byname}
								onChange={e => setByname(e.target.value)}
							/>
							<TextInput
								label="Razão Social"
								name="razaoSocial"
								value={company}
								onChange={e => setCompany(e.target.value)}
							/>
						</>}
							<TextInputTaxpayer
								value={employee.taxpayer}
								disabled={employeeEmployments.length > 0}
								onChange={e => setEmployee((employee: any) => ({
									...employee,
									taxpayer: e.target.value.replace(/\D/g, "")
								}))}
							/>
							<ReferenceForm
								formTitle="Seus dados"
								reference={reference}
								setReference={setReference}
							/>
							<LocalityForm
								formTitle="O endereço do seu negócio"
								locality={locality}
								setLocality={setLocality}
							/>

							<div className="grid fill-normal">
								<MainButton onClick={() => handleAddNewEmployment()}>
									<span> Adicionar colaborador </span>
								</MainButton>
							</div>
						</>
					)}
			</div>
		</SupplierRoleNavbar>
	)
}
