
// Components
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"
import AssessmentForm from "../components/AssessmentForm"


export default function NewAssessment() {
	return (
		<SupplierRoleNavbar>
			<AssessmentForm/>
		</SupplierRoleNavbar>
	)
}
