import CscModal from "../components/modals/CscModal"
import NfeModal from "../components/modals/NfeModal"
import EnvironmentModal from "../components/modals/EnvironmentModal"
import CertificateModal from "../components/modals/CertificateModal"
import TaxDefinitionModal from "../components/modals/TaxDefinitionModal"
import RegimeTributarioHandler from "../handlers/RegimeTributarioHandler"
import InscricaoModal from "../components/modals/InscricoesModal"

export default function FiscalHandler() {
	return (
		<div className="grid medium-gap">

			<span className="big-x bold-font">Informações fiscais</span>

			<div className="grid fill-huge small-gap">
				<CertificateModal/>
				<EnvironmentModal/>
				<NfeModal/>
				<CscModal/>
				<TaxDefinitionModal/>
				<InscricaoModal/>
			</div>

			<RegimeTributarioHandler/>
		</div>
	)
}
