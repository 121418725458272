// Library imports
import { useSelector } from "react-redux"

// Icons
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Utils
import { formatTaxpayer } from "offiziersmesser/lib/utils"

// Components
import FormTabs from "src/shared/components/custom/FormTabs/FormTabs"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import FiscalHandler from "./editor/handlers/FiscalHandler"
import BankingHandler from "./editor/handlers/BankingHandler"
import AdministrativeCompanyHandler from "./editor/handlers/AdministrativeCompanyHandler"
import AdministrativeReferenceHandler from "./editor/handlers/AdministrativeReferenceHandler"

// Styles
import "./Account.css"

// Store
import { RootState } from "src/shared/store/reducers"

export default function Account() {

	// Store
	const user = useSelector((state:RootState) => state.user)

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap">
				<div className="grid tiny-gap">
					<PageTitle title="Conta" />
					<div className="grid-columns small-gap left-items">
						<span className="uppercase semibold-font">{user.administrative.company}</span>
						<span className="heavy-font">{formatTaxpayer(user.taxpayer)}</span>
					</div>
				</div>

				<div className="grid tiny-gap fill-huge-x center-items border-radius-medium element-border">
					<div className="grid tiny-gap center-items">
						<span className="semibold-font">Seu ID</span>
						<span className="big-xxx heavy-font">{user.alias}</span>
						<span className="small">é único e serve para identificar seu negócio.</span>
					</div>
					<div className="grid-columns medium-gap small left-items">
						<FontAwesomeIcon className="fa-icon fa-lg" icon={faExclamationCircle} />
						<span>Colaboradores usam esse ID para acessar as contas de trabalho. Clientes usam esse ID para localizar seu catálogo virtual de itens.</span>
					</div>
				</div>

				<FormTabs tabs={["Responsável", "Empresa","Fiscal", "Bancário"]}>
					<AdministrativeReferenceHandler />

					<AdministrativeCompanyHandler />

					<FiscalHandler />

					<BankingHandler />
				</FormTabs>
			</div>
		</SupplierRoleNavbar>
	)
}
