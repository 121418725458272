// Components
import OrderListTableRow from "./OrderListTableRow"
import OrderListTableHeader from "./OrderListTableHeader"

export default function OrderListTable({
	content,
	returnOrderDataItemQuantity
}) {

	return (
		<div className="table-wrapper">
			<table className="orders-table-container">
			<tbody>
				<OrderListTableHeader />
				{content.map((purchase, index) => (
					<OrderListTableRow
						key={index}
						purchase={purchase}
						returnOrderDataItemQuantity={returnOrderDataItemQuantity}
					/>
				))}
			</tbody>
		</table>
		</div>
	)
}
