//Libary import
import { useEffect } from "react"
import { useState } from "react"

// Components
import SelectInput from "src/shared/components/standard/SelectInput"
import Textarea from "src/shared/components/standard/Textarea"

import UserBlockSearch from "../../../components/UserBlockSearch"

export const InsertTransactionStepOne = ({
	transactionsType,
	setTransactionsType,
	taxpayer,
	note,
	setTaxpayer,
	setDocumentType,
	documentType
}) => {

	// States
	const [userSearchValue, setUserSearchValue] = useState(taxpayer)
	const [userFound, setUserFound] = useState(false)

	// Effects
	useEffect(() => {
		userFound && setTaxpayer(userSearchValue)
	}, [userFound, userSearchValue, setTaxpayer])

	return (
		<div className="flex medium-margin-top">
			<div className="grid medium-gap">
				<div className="grid-columns medium-gap">
					<SelectInput
						label="Tipo de conta"
						name="transactionsType"
						value={transactionsType}
						onChange={event => setTransactionsType(Number(event.target.value))}
						disabled={true}
					>
						<option value={0}>A pagar</option>
						<option value={1}>A receber</option>
					</SelectInput>
					<SelectInput
						label="Tipo de documento"
						name="documentType"
						value={documentType}
						onChange={event => setDocumentType(Number(event.target.value))}
					>
						<option value={0}>Boleto</option>
						<option value={1}>Duplicata</option>
					</SelectInput>
				</div>
				<div>
					<span className="bold-font small flex small-margin-bottom">
						{transactionsType === 0 && "Fornecedor"}
						{transactionsType === 1 && "Cliente"}
					</span>
					<UserBlockSearch
						searchValue={userSearchValue}
						setSearchValue={setUserSearchValue}
						userFound={userFound}
						setUserFound={setUserFound}
						placeholder={`Busque pelo ${transactionsType === 0 ? "fornecedor" : "cliente"}`}
						disabled={true}
					/>
				</div>
				<Textarea
					value={note}
					placeholder="Ex: Conta de luz"
					label="Observação"
					disabled={true}
				/>
			</div>
		</div>
	)
}
