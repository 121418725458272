import { ChangeEvent } from "react"

type RadioButtonInputProps = {
	id?: string
	name: string
	label?: string
	value?: string
	checked?: boolean
	onClick?: () => void
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	disabled?: boolean
	className?: string
	defaultChecked?: boolean
}

export default function RadioButtonInput({
	id,
	name,
	label,
	value,
	checked,
	onClick,
	onChange,
	disabled,
	className,
	defaultChecked,
}: RadioButtonInputProps) {

	return (
		<label htmlFor={id}>
			<input
				id={id}
				name={name}
				type="radio"
				value={value}
				checked={checked}
				onClick={onClick}
				onChange={onChange}
				disabled={disabled}
				className={className}
				defaultChecked={defaultChecked}
			/>
			{label}
		</label>
	)
}

		// <label
		// 	htmlFor={id}
		// 	className="checkbox-radio-types"
		// >
		// 	<input
		// 		type="radio"
		// 		id={id}
		// 		name={name}
		// 		className={className}
		// 		onChange={onChange}
		// 		onClick={onClick}
		// 		value={value}
		// 		disabled={disabled}
		// 		checked={checked}
		// 		defaultChecked={defaultChecked}
		// 	/>
		// 	<span className="medium semibold-font">
		// 		{label}
		// 	</span>
		// </label>
