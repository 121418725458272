import { useState } from "react"

import { useDispatch } from "react-redux"
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setBalances } from "../../../../../shared/store/slices/balancesSlice"

// Utils
import { PAYMENT_METHODS } from "offiziersmesser/lib/utils"

// Components
import Form from "../../../../../shared/components/standard/Form"
import Modal from "../../../../../shared/components/custom/Modal/Modal"
import TextInput from "../../../../../shared/components/standard/TextInput"
import MainButton from "../../../../../shared/components/standard/MainButton"
import SelectInput from "../../../../../shared/components/standard/SelectInput"
import NumberInput from "../../../../../shared/components/standard/NumberInput"
import DatePickerSelect from "../../../../../shared/components/custom/DatePickerSelect/DatePickerSelect"

// Styles
import "./SellerBalancesInsertEntryOutflowModal.css"

export default function SellerBalancesInsertEntryOutflowModal({
	onRenderModal,
	isSupplier,
	balances
}) {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	// const balances = useSelector(state => state.balances)

	// States
	const [note, setNote] = useState("")
	const [value, setValue] = useState(0)
	const [issueDate, setIssueDate] = useState(null)
	const [methodType, setMethodType] = useState(null)
	const [transactionsType, setTransactionsType] = useState(null)

	// Variables
	const cannotSubmit = (
		note === ""
		|| value === 0
		|| methodType === null
		|| transactionsType === null
	)

	// Handles
	async function handleAddNewCashOutflow(event) {

		event.preventDefault()

		try {
			const token = getToken()
			const cashbox = balances === undefined ? "" : balances.cashbox
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/cashInAndOut`, {
				method: "post",
				body: JSON.stringify({
					cashbox,
					createdAt: issueDate,
					type: transactionsType,
					done: true,
					note,
					payments: [
						{
							total: value,
							installment: {
								custom: true, // created in transactions
								period: 1,
								timespan: {
									unit: "day",
									value: 0, // In days
								},
								Interest: {
									compound: false,
									percentage: 0.0,
								},
							},
							heir: null,
							parts: [
								{
									createdAt: 0, // Manual set if transaction is being custom-created, auto if its a heir
									value, // Partial (of full) value paid
									method: methodType, // Lookup table storing how the payment was done (Cash, credit card, etc)
								},
							],
							other:[0,0]
						},
					],
					order: null,
				}),
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				}
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			if (cashbox != ""){
				let response =await fetch(`${process.env.REACT_APP_API_URL}/resource/seller/balance/active`, {
					headers: { Authorization: `Bearer ${token}` },
				})

				responseCodeHandler(response)
				if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				const balanceData = response.json()

				response = await fetch(`${process.env.REACT_APP_API_URL}/resource/seller/cashbox/${balanceData?.cashbox}`, {
					headers: { Authorization: `Bearer ${token}` },
				})

				responseCodeHandler(response)
				if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				const transactionsData = response.json()

				if (typeof transactionsData === "object") {
					dispatch(
						setBalances({
							...balanceData,
							transactions: {
								entry: transactionsData.entry,
								outflow: transactionsData.outflow,
							},
						})
					)
				}
			}

		} catch (error) {
			console.error("INSERT ERROR -", error)
		} finally {
			onRenderModal()
		}
	}

	return (
		<Modal onClose={() => onRenderModal()} >
			<div className="modal-container">
				<div className="header">
					<span className="bold-font big-x">
						Adicionar entrada ou saída
					</span>
				</div>

				<Form
					className="flex flex-column medium-gap"
					onSubmit={e => handleAddNewCashOutflow(e)}
				>
					<div className="grid small-gap fit-normal">

						<SelectInput
							label="Tipo*"
							name="transactionsType"
							value={transactionsType}
							onChange={e => setTransactionsType(Number(e.target.value))}
							defaultValue
						>
							<option value="—" disabled={true} selected={true}>
								Selecione o tipo
							</option>
							<option value={0}>Entrada</option>
							<option value={1}>Saida</option>
						</SelectInput>

						<NumberInput
							name={value}
							onChange={e => setValue(Number(e.target.value))}
							label="Valor*"
						/>

						<SelectInput
							label="Método*"
							name="documentType"
							value={methodType}
							onChange={e =>
								setMethodType(Number(e.target.value))
							}
							defaultValue
						>
							<option value="—" disabled={true} selected={true}>
								Selecione o método
							</option>

							{PAYMENT_METHODS.map((entry, index) => (
								<option key={index} value={index}>
									{entry.name}
								</option>
							))}
						</SelectInput>

						<TextInput
							label="Observação*"
							placeholder="Ex: Conta de Luz"
							value={note}
							onChange={e => setNote(e.target.value)}
						/>
					</div>

					<div className="wide">
						<div className="grid medium-gap element-border">

							<span className="bold-font medium">Data*</span>
							<DatePickerSelect
								value={issueDate}
								timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
								setDate={(date) =>
									setIssueDate(
										new Date(
											date.year,
											date.month - 1,
											date.day
										).getTime() / 1000
									)
								}
							/>
						</div>
					</div>

					<div className="wide wide-children medium-margin-top">
						<MainButton
							disabled={cannotSubmit}
							type="submit"
						>
							<span>Concluir</span>
						</MainButton>
					</div>
				</Form>
			</div>
		</Modal>
	)
}
