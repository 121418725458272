// Library imports
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"

// Models
import { ItemModel } from "offiziersmesser/lib/models"

// Store
import { RootState } from "src/shared/store/reducers"

// Components
import TextInputBarcode from "../TextInputBarcode/TextInputBarcode"
import { createToast } from "src/shared/store/slices/toastsSlice"

type SearchItemsProps = {
	setSelectedItems: React.Dispatch<React.SetStateAction<ItemModel[]>>
	isStore: boolean
}

export default function SearchItems({
	setSelectedItems,
	isStore
}: SearchItemsProps) {

	const dispatch = useDispatch()

	// States
	const [searchValue, setSearchValue] = useState("")

	// Store
	const items = useSelector((state: RootState) => state.items)
	const store = useSelector((state: RootState) => state.store)

	// Handlers
	function handleSelection(term: string) {
		handleSearch(term.toLowerCase())
	}

	function handleSearch(term: string) {
		if ( isStore === true ) {
			const itemsDescriptionFiltered = store.data.items.filter((item: any) => item.control.description.toLowerCase().includes(term)
			) as unknown as ItemModel[]

			const itemsBarcodeFiltered : ItemModel[] = store.data.items.filter((item: any) =>
				item.control.barcode.toLowerCase().includes(term)
			) as unknown as ItemModel[]

			setSelectedItems([...itemsDescriptionFiltered, ...itemsBarcodeFiltered])
		} else {

			const itemsDescriptionFiltered = items.filter(item => item.control.description.toLowerCase().includes(term)
			) as unknown as ItemModel[]

			const itemsBarcodeFiltered : ItemModel[] = items.filter(item =>
				item.control.barcode.toLowerCase().includes(term)
			) as unknown as ItemModel[]

			setSelectedItems([...itemsDescriptionFiltered, ...itemsBarcodeFiltered])
		}
	}

	// Effects
	useEffect(() => {
		if (searchValue != "") {
			handleSelection(searchValue)
		} else {
			setSelectedItems([])
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue])

	return (
		<TextInputBarcode
			// label="Descrição ou GTIN do item"
			placeholder="'TINTA ABC' ou '00012345678905'"
			// before={<FontAwesomeIcon icon={faMagnifyingGlass} />}
			value={searchValue}
			// placeholder="Busque por um produto ou escaneie um código de barras"
			onChange={(e: any) => setSearchValue(e.target.value)}
			onScan={(e: any) => setSearchValue(e.target.value)}
			onError={() =>
				dispatch(createToast({
					tone: "failure",
					miliseconds: 2000,
					message: "Algo deu errado, tente novamente.",
					dismissable: true,
			} as any))}
		/>
	)
}
