import { calculateOrderContentSum } from "offiziersmesser/lib/helpers";
import { formatHumanDate, toFixedImproved } from "offiziersmesser/lib/utils";
import Notice from "src/shared/components/custom/Notice/Notice";
import CheckboxInput from "src/shared/components/standard/CheckboxInput";


interface RowsProps {
    order: {
      record: string;
      createdAt: number;
      statuses: Array<{ bias: number; note: string }>;
	  contents: []
    };
    selectedOrders: string[];
    setSelectedOrders: React.Dispatch<React.SetStateAction<string[]>>;
  }

export function Rows({order, setSelectedOrders, selectedOrders}: RowsProps) {

    const isSelected = selectedOrders.includes(order.record);

	const total = calculateOrderContentSum(order.contents)

    const toggleSelection = () => {
      setSelectedOrders((prevSelectedOrders) => {
        if (isSelected) {
          return prevSelectedOrders.filter((selectedOrder) => selectedOrder !== order.record);
        } else {
          return [...prevSelectedOrders, order.record];
        }
      });
    };


    return(
        <tr>
            <td className="checkbox-table">
                <CheckboxInput checked={isSelected} onChange={toggleSelection} />
            </td>
			<td>R$ {toFixedImproved(total - 0, 2)}</td>
            <td>
                {formatHumanDate(new Date(order.createdAt * 1000), "pt-BR")}
            </td>
            <td>
                {(() => {

                    let status: "info" | "success" | "failure" | "warning" | undefined

                    switch (order.statuses?.length > 0 ? order?.statuses[order.statuses?.length - 1].bias : "") {
                        case 0:
                            status = "info"; break
                        case 1:
                            status = "success"; break
                        case 2:
                            status = "failure"; break
                        default: break;
                    }

                    return (
                        <Notice tone={status} size="tiny">
                            <span className="small">
                                {order.statuses?.length > 0 ? order?.statuses[order.statuses?.length - 1].note : ""}
                            </span>
                        </Notice>
                    )
                })()}
            </td>
        </tr>
    )
}
