// Icons
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getPostalData } from "offiziersmesser/lib/utils"

// Components
import ExtendableTextInput from "../ExtendableTextInput/ExtendableTextInput"

export default function TextInputPostal(props) {

	const onChangeAutofill = async e => {
		const data = { postal: e.target.value.replace(/\D/g, "") }

		try {
			if (e.target.value.length === 8) {
				const { logradouro, bairro, localidade, uf } = await getPostalData(data.postal)
				data.road = logradouro
				data.area = bairro
				data.city = localidade
				data.region = uf
			}
		}
		catch (error) {
			console.error(error)
		}
		finally {
			if (e.target.value.length < 9)
				return data
		}
	}

	const handleChange = async e => {
		if (props.autofill) {
			const autofillData = await onChangeAutofill(e)
			props.onChange(autofillData)
		} else
			props.onChange(e)
	}

	return (
		<ExtendableTextInput
			{...props}
			onChange={handleChange}
			after={
				<a
					target="_blank"
            		rel="noopener noreferrer"
            		href="https://buscacepinter.correios.com.br/app/endereco/index.php"
					className="element-highlight border-radius-small-half unselectable medium"
            	>
            		<FontAwesomeIcon icon={faQuestionCircle} />
            	</a>
			}
		/>
	)
}
