import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setUser } from "src/shared/store/slices/userSlice"
import { setEmployments } from "src/shared/store/slices/employmentsSlice"

// Components
import Form from "src/shared/components/standard/Form"
import Modal from "src/shared/components/custom/Modal/Modal"
import TextInput from "src/shared/components/standard/TextInput"
import SelectInput from "src/shared/components/standard/SelectInput"
import MainButton from "src/shared/components/standard/MainButton"
import SelectedTag from "src/shared/components/custom/SelectedTag/SelectedTag"

// Styles
import "./BalanceNewRegisterModal.css"
import { useNavigate } from "react-router-dom"

export default function BalanceNewRegisterModal({
	onRenderModal,
	tag = "",
	editorMode = false
}) {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const employments = useSelector(state => state.employments)
	const user = useSelector(state => state.user)

	// States
	const [currentCashboxTag, setCurrentCashboxTag] = useState(tag || "")

	const [sellers, setSellers] = useState([])
	const [selectedAssignee, setSelectedAssignee] = useState("")
	const [assigneesTaxpayers, setAssigneesTaxpayers] = useState([])
	const [assignees, setAssignees] = useState([])

	const [registerEdited, setRegisterEdited] = useState(false)
	const [registerDelete, setRegisterDelete] = useState(false)

	// Variables
	const tagNotInUse = (user.cashboxes?.length > 0)
		? user.cashboxes.filter(c => c.tag === currentCashboxTag).length === 0
		: true

	const reservedTag = (currentCashboxTag === "general")

	const allowCreation = (typeof currentCashboxTag === "string" && tagNotInUse === true) || editorMode

	const cashbox = (user.cashboxes?.length > 0 && user.cashboxes.find(cashbox => cashbox.tag === tag)) || []

	// Handlers
	/**
	 * It takes a seller object as an argument, and if the seller object is not
	 * already in the array of selected sellers, it adds it to the array of selected
	 * sellers, and removes it from the array of sellers
	 * @param seller - The seller that was selected from the list of sellers.
	*/
	function handleAssign(seller) {

		try {

			setRegisterEdited(true)

			// add seller from assignees array
			setAssignees(prevState => {

				let alreadySelected = false
				const selectedAssignees = prevState

				selectedAssignees.map(selectedAssignee => {

					if (selectedAssignee.user === seller.user)
						alreadySelected = true

					return selectedAssignee
				})

				if (!alreadySelected)
					selectedAssignees.push(seller)

				setSelectedAssignee("")
				setAssigneesTaxpayers(selectedAssignees.map(seller => seller.user))

				return selectedAssignees
			})

			//  remove seller from available sellers array
			setSellers(sellers => sellers.filter(prevSeller => prevSeller.user !== seller.user))
		} catch (error) {
			console.error("HANDLER SELLER ASSOCIATION ERROR -", error)
		}
	}
	/**
	 * It takes a seller object as an argument, and then filters the assignees
	 * array to remove the seller object that matches the argument
	 * @param seller - The seller to be disassociated
	*/
	function handleUnassign(seller) {

		try {
			// Set that the register was edited
			setRegisterEdited(true)

			// Remove seller from assignees array
			setAssignees(assignees => {

				const allSellers = assignees.filter(assignee => assignee.user !== seller.user)

				setAssigneesTaxpayers(allSellers.map(seller => seller.user))

				return allSellers
			})

			//  add seller to available sellers array
			setSellers(sellers => [...sellers, seller])
		} catch (error) {
			console.error("HANDLER SELLER DISASSOCIATION ERROR -", error)
		}
	}
	/**
	 * It handles the creation of a new register
	 * @param event - The event that triggered the function.
	*/
	async function handleCreateRegister(event) {

		event && event.preventDefault()

		const token = getToken()

		if (registerDelete) {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/cashbox/${tag}`, {
					method: "DELETE",
					headers: { "Authorization": `Bearer ${token}` }
				})

				responseCodeHandler(response)

				if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				dispatch(setUser({
					...user,
					cashboxes: [
						...user.cashboxes?.filter(c => c.tag !== tag)
					]
				}))
			} catch (error) { console.error(error) }
			finally{
				navigate("/supplier/balances")
			}
		} else {
			if(editorMode){
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/cashboxes`, {
						method: "PATCH",
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${token}`
						},
						body:JSON.stringify({ tag: tag, assignedTo: assigneesTaxpayers })
					})

					responseCodeHandler(response)

					if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

					dispatch(setUser({
						...user,
						cashboxes: [
							...user.cashboxes?.filter(c => c.tag !== tag),
							{ tag: tag, assignedTo: assigneesTaxpayers }
						]
					}))
				} catch (error) { console.error(error) }
			}else{
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/cashboxes`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${token}`
						},
						body: JSON.stringify({
							tag: currentCashboxTag,
							assignedTo: assigneesTaxpayers
						})
					})

					responseCodeHandler(response)

					if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

					if (user.cashboxes === null){
						dispatch(setUser({
							...user,
							cashboxes: [
								{
									tag: currentCashboxTag,
									assignedTo: assigneesTaxpayers
								}
							]
						}))
					}else{
						dispatch(setUser({
							...user,
							cashboxes: [
								...user.cashboxes,
								{
									tag: currentCashboxTag,
									assignedTo: assigneesTaxpayers
								}
							]
						}))
					}
				} catch (error) { console.error(error) }
			}
		}

		setSelectedAssignee("")
		setCurrentCashboxTag("")
		setAssignees([])
		setSellers(employments.filter(employment => employment.employment.role === 2))
		onRenderModal()
	}
	/**
	 * It takes a string, converts it to lowercase, replaces spaces with dashes,
	 * limits the length to 200 characters, removes diacritics, and removes
	 * apostrophes
	 * @param value - The string to be slugified
	 * @returns A string that has been converted to lowercase, has all spaces replaced
	 * with dashes, has been sliced to 200 characters, has been normalized, and has
	 * all diacritics removed.
	*/
	function slugify(value) {

		return value
			.toLowerCase()
			.replace(/\s+/g, "—")
			.slice(0, 200)
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "")
			.replace(/[""]/g, "")
	}
	const getEmployees = async () => {
		try {
			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/employees`, {
				headers: { Authorization: `Bearer ${token}` }
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const employmentsData = await response.json()
			dispatch(setEmployments(employmentsData))

		} catch (error) { console.error("NEW REGISTER MODAL EMPLOYMENTS REQ ERROR -", error) }
	}
	// Effects
	useEffect(() => {
		getEmployees()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {

		const sellers = employments.filter(employment => employment.employment.role === 2)

		if (editorMode === true) {

			setSellers(() => sellers.filter(seller => !cashbox.assignedTo.find(user => seller.user === user)))
			setAssignees(() => cashbox.assignedTo.map(user => sellers.find(seller => seller.user === user)))
		} else {
			setSellers(sellers)
		}
	}, [employments, cashbox.assignedTo, editorMode])

	return (
		<Modal onClose={() => onRenderModal()} >
			<div className="new-register-modal">
				<div className="header">
					<span className="bold-font big-x small-margin-bottom">
						{editorMode ? "Editar registrador" : "Criar registrador"}
					</span>
				</div>

				<Form onSubmit={event => handleCreateRegister(event)}>

					{editorMode === false && (
						<div className="flex flex-column small-gap">
							<TextInput
								label="Tag"
								onChange={event => {
									setCurrentCashboxTag(slugify(event.target.value))
									setRegisterEdited(true)
								}}
								value={currentCashboxTag}
							/>

							<span className="small bold-font red">
								{(tagNotInUse === false) && "Tag já utilizada."}
								{(reservedTag === true) && "Tag reservada."}
							</span>
						</div>
					)}

					<div className="input-row">
						<SelectInput
							label="Associar vendedor"
							value={!!selectedAssignee ? selectedAssignee.reference?.name : "default"}
							onChange={event => {
								setSelectedAssignee(() => sellers.filter(seller => (seller?.user === event.target.value)))
								setRegisterEdited(true)
							}}
						>
							{sellers.length > 0 ? (
								<>
									<option value="default" disabled={true} selected={true}>Selecione um vendedor</option>

									{sellers.map(seller => (
										<option
											value={seller.user}
											key={seller.user}
										>
											{seller.employee?.reference.name} {seller.employee.reference.surname}
										</option>
									))}
								</>
							) : (
								<option disabled={true} selected={true}>Nenhum vendedor disponível</option>
							)}
						</SelectInput>

						<MainButton
							disabled={selectedAssignee === ""}
							onClick={() => handleAssign(selectedAssignee[0])}
						>
							Associar
						</MainButton>
					</div>

					<div className="input-colunm">
						<span className="input-title bold-font big small-margin-bottom flex">
							Vendedores
						</span>

						{(assignees.length > 0) ? (
							<ul className="tag-list">
							{assignees.map(assignee =>
								<SelectedTag
									key={assignee?.user}
									tagName={assignee?.employee.reference.name}
									onTagRemove={() => handleUnassign(assignee)}
									activeTagRemove={true}
								/>
							)}
							</ul>
						) : (
							<p>Nenhum vendedor associado</p>
						)}
					</div>

					<MainButton
						type="submit"
						disabled={allowCreation === false || reservedTag === true || registerEdited === false}
					>
						<span>{editorMode ? "Salvar alterações" : "Criar registrador"}</span>
					</MainButton>

					{editorMode&&<MainButton
						type="submit"
						onClick={()=>setRegisterDelete(true)}
					>
						<span>excluir registrador</span>
					</MainButton>}
				</Form>
			</div>
		</Modal>
	)
}
