// Services
import { getToken } from "src/shared/services/general"

export default async function updateItem(
	item: any,
	images: Blob[],
	taxation: string,
	details: { ncm: string, cest: string, origem: string },
	alias: string
) {
	try {
		const token = getToken()

		// Variables
		const multipart = new FormData()
		// @workround: redo
		multipart.append("alias", alias)
		multipart.append("item", JSON.stringify({
			...item,
			updatedAt: Date.now(),
			createdAt: item.createdAt
		}))
		multipart.append("taxation", JSON.stringify(taxation))
		multipart.append("details", JSON.stringify(details))
		images.forEach(image => multipart.append("images", image))

		const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/item/data`, {
			method: "PUT",
			headers: { Authorization: `Bearer ${token}` },
			body: multipart,
		})

		if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

	} catch(error){
		console.error()
	}
}
