import { ChangeEvent, ReactNode } from "react"

type FileInputProps = {
	form?: string
	name?: string
	accept?: string
	children?: ReactNode
	disabled?: boolean
	multiple?: boolean
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	className?: string

	fontSize?: string
	hierarchy?: string
	overrideClass?: string
}

export default function FileInput({
	form,
	name,
	accept = "image/jpeg,image/gif,image/png",
	children,
	disabled,
	multiple = true,
	onChange,
	className,

	fontSize,
	hierarchy,
	overrideClass,
}: FileInputProps) {

	return (
		<label
			className={
				overrideClass ||
				(
					`unselectable center-text element-highlight ${disabled === true ? "disabled file" : "file"
					} ${hierarchy === "low" ? "action-low" :
						hierarchy === "inferior" ? "action-inferior" :
						hierarchy === "minimal" ? "action-minimal" :
						"action-normal"
					} ${fontSize === "big" ? "big" :
						fontSize === "small" ? "small" :
						"medium"
					}`
				)
			}>

			{children}
			<input
				form={form}
				type="file"
				name={name}
				accept={accept}
				disabled={disabled}
				onChange={onChange}
				multiple={multiple}
				className={className}
			/>
		</label>
	)
}
