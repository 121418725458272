// Models
import { ReferenceModel } from "../../../../models/user"

// Components
import TextInput from "../../../standard/TextInput"
import PhoneInputGroup from "../../../custom/PhoneInputGroup/PhoneInputGroup"


type DisableableFields = Array<"name" | "surname" | "email" | "phone">

type ReferenceFormProps = {
	formTitle?: string,
	disabledFields?: DisableableFields,
	reference: ReferenceModel,
	setReference: (value: ReferenceModel) => void,
}

export default function ReferenceForm({
	formTitle = "Dados referenciais",
	disabledFields,
	reference,
	setReference,
}: ReferenceFormProps) {

	// Handlers
	/**
	 * This function updates the user's reference information and triggers a form
	 * change event.
	 * @param {string} target - a string representing the property of the user object
	 * that needs to be updated.
	 * @param {any} value - The value that will be assigned to the specified target
	 * property in the user object.
	 */
	function handleSetReference(
		target: string,
		value: any,
	) {
		setReference({
			...reference,
			[target]: value
		})
	}

	return (
		<div className="grid medium-gap">
			<span className="bold-font big-x">
				{formTitle}
			</span>

			<div className="grid small-gap fill-normal">
				<TextInput
					name="name"
					label="Nome"
					placeholder="Alex"
					value={reference?.name}
					onChange={e => handleSetReference(e.target.name, e.target.value)}
					disabled={disabledFields?.includes("name")}
				/>
				<TextInput
					name="surname"
					label="Sobrenome"
					placeholder="Silva"
					value={reference?.surname}
					onChange={e => handleSetReference(e.target.name, e.target.value)}
					disabled={disabledFields?.includes("surname")}
				/>
				<PhoneInputGroup
					phone={reference?.phone}
					setPhone={value => handleSetReference("phone", value)}
				/>
				<TextInput
					label="E-mail"
					name="email"
					placeholder="alex@ferragensjp.com"
					value={reference?.email}
					onChange={e => handleSetReference(e.target.name, e.target.value)}
					disabled={disabledFields?.includes("email")}
				/>
			</div>
		</div>
	)
}
