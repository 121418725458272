const variations = [
	"Ações",
	"SKU",
	"Descrição",
	"Custo",
	"Preço",
	"Quantidade",
	"Categoria",
]

export default function TableHeader() {
	return (
		<thead>
			<tr>
				{variations.map((variation, index) => <th key={index}>{variation}</th>)}
			</tr>
		</thead>
	)
}
