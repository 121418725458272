import ItemModel from "../../../../../shared/models/item"

export default function validateEssentialsFields(item: ItemModel) {
	if(!item)
		return false

	return !!item.control.description
		&& !!item.control.measure
		&& !!(item.control.quantity>=0)
		&& !!(item.costs.production>=0)
		&& !!item.groups.category
		&& !!item.accounting.details.ncm
		&& !!(item.accounting.details.origin != "")
		&& !!(item.accounting.taxation != "")
}
