// Icons
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Styles
import "./SelectedTag.css"

export default function SelectedTag({
	tagName,
	onTagRemove,
	activeTagRemove
}) {

	return (
		<li className="selected-tag">
			<span>{tagName}</span>
			{activeTagRemove === true && (
				<button type="button" onClick={(event) => onTagRemove(event)}>
					<FontAwesomeIcon icon={faTimes} />
				</button>
			)}
		</li>
	)
}
