import { createSlice } from "@reduxjs/toolkit";

const balancesSlice = createSlice({
	name: "balances",
	initialState: [] as any[],
	reducers: {
		setBalances: (state, action) => {
			return state = action.payload
		},
		resetBalances: state => {
			return state = []
		},
	}
})

export default balancesSlice.reducer
export const { setBalances, resetBalances } = balancesSlice.actions
