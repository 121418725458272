import { useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Models
import { PhoneModel } from "src/shared/models/user"

// Utils
import { formatTaxpayer } from "offiziersmesser/lib/utils"

//
import { removeAssessment } from "src/shared/store/slices/assessmentsSlice"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAddressBook, faPen, faTrash } from "@fortawesome/free-solid-svg-icons"

// Components
// import Tooltip from "src/shared/components/custom/Tooltip/Tooltip"
import MainButton from "src/shared/components/standard/MainButton"

// Styles
import "./AssessmentCard.css"
import { setAssessment } from "src/shared/store/slices/assessmentSlice"
import { useNavigate } from "react-router-dom"

type ReferenceCardProps = {
	name: string
	surname: string
	taxpayer: string
	email: string
	phone: PhoneModel,
	assessment: any
}

export default function AssessmentCard({
	name,
	surname,
	taxpayer,
	email,
	phone,
	assessment,
}: ReferenceCardProps) {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const responseCodeHandler = useResponseCodeHandler()

	async function handleDeleteAssessment (taxpayer: string) {
		try{

			const token = sessionStorage.getItem('token')

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/assessment`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({taxpayer}),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(removeAssessment(taxpayer))
		}catch(error){
			console.error(error)
		}
	}

	return (
		<div className="contact-card-container">

			<div className="contact-card-info flex small-gap">
				<span className="bold-font">
					{name} {surname}
				</span>
				<span>
					{formatTaxpayer(taxpayer)}
				</span>
			</div>

			<div className="contact-card-actions small-gap">
				<MainButton
					hierarchy="low"
					onClick={() =>{
						dispatch(setAssessment(assessment))
						navigate("/supplier/assessment/update")
					}}
				>
					<FontAwesomeIcon className="fa-icon" icon={faPen as Icon} />
				</MainButton>
				<MainButton
					hierarchy="inferior"
					onClick={() => handleDeleteAssessment(taxpayer)}
				>
					<FontAwesomeIcon className="fa-icon" icon={faTrash as Icon} />
				</MainButton>

				{/* <Tooltip
					tooltipContent={
						<div>
							<span>{email}</span>
							<span>+55 {phone.destination} {phone.subscriber}</span>
						</div>
					}
					tooltipAlign="top-left"
				> */}
					<MainButton hierarchy="inferior">
						<FontAwesomeIcon className="fa-icon" icon={faAddressBook as Icon} />
					</MainButton>
				{/* </Tooltip> */}
			</div>
		</div>
	)
}
