import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { nanoid } from "nanoid"

// Models
import ToastModel from "../../components/custom/Toasts/toast"

const initialState: ToastModel[] = []

const prepareToast = (toast: any): ToastModel => ({
	...toast,
	key: nanoid(6),
	dismissable: toast.dismissable ?? false
})

const toastsSlice = createSlice({
	name: "toasts",
	initialState,
	reducers: {
		// Use PayloadAction to correctly type the action payload
		createToast: (state, action: PayloadAction<ToastModel>) => {
			// Directly returning the new state array
			return [...state, prepareToast(action.payload)]
		},
		deleteToast: (state, action: PayloadAction<string>) => {
			// Filtering out the toast to delete
			return state.filter(entry => entry.key !== action.payload)
		},
		setToasts: (state, action: PayloadAction<ToastModel[]>) => {
			// Replacing the state with new array of toasts
			return action.payload
		},
	},
})

export const { createToast, deleteToast, setToasts } = toastsSlice.actions
export default toastsSlice.reducer
