import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import { createArrayParts, createTotalParts } from "offiziersmesser/lib/helpers"
import { toFixedImproved, toUnsignedInteger } from "offiziersmesser/lib/utils"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCashRegister, faExclamationCircle, faExternalLinkSquareAlt, faFile, faPlus, } from "@fortawesome/free-solid-svg-icons"

// Store
import { setBalances } from "src/shared/store/slices/balancesSlice"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import LinkButton from "src/shared/components/standard/LinkButton"
import MainButton from "src/shared/components/standard/MainButton"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import RadioButtonInput from "src/shared/components/standard/RadioButtonInput"
// import GlobalSearchBar from "src/shared/components/custom/GlobalSearchBar/GlobalSearchBar"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import BalancesCard from "./editor/components/BalancesCard/BalancesCard"
import BalanceNewRegisterModal from "./editor/modals/BalanceNewRegisterModal/BalanceNewRegisterModal"

// Styles
import "./Balances.css"
import { RootState } from "src/shared/store/reducers"
import { PaymentModel, PartModel, TransactionModel } from "offiziersmesser/lib/models"
import Pagination from "src/shared/components/custom/Pagination/Pagination"

type balanceGeneralModel = {
	paymentsEntry: TransactionModel[]
	paymentsOutflow: TransactionModel[]
}
type equipmentModel ={
	tag: string
	assignedTo : string[]
}
type balanceModel= {
	active: boolean
	base: number
	transactions: TransactionModel[]
	cashbox: string
	note: string
  }


export default function Balances() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const balances = useSelector((state:RootState) => state.balances)
	const user = useSelector((state:RootState) => state.user)

	// States
	const [renderModal, setRenderModal] = useState(false)
	const [inactiveCashboxes, setInactiveCashboxes] = useState([])
	const [cashboxesView, setCashboxesView] = useState("opened") // <"opened" | "closed">
	const [loading, setLoading] = useState(false)
	const [generalBalance, setGeneralBalance] = useState<balanceGeneralModel|null>(null)
	const [currentBalances,setCurrentBalances] = useState(balances)

	// Getters
	function getTotalEntryBalance(payments: PaymentModel[]) {
		const parts = []
		const totalParts = []
		const balanceEntry = []
		let balancesTotal = 0

		if (payments != null) {

			parts.push(...createArrayParts(payments))
			totalParts.push(...createTotalParts(parts))
			balanceEntry.push(...totalParts)

			balancesTotal = balanceEntry
				.map((balances) => balances.value)
				.reduce((prev, curr) => prev + curr, 0)
		}

		return !isNaN(balancesTotal) ? balancesTotal : 0
	}
	function getTotalOutflowBalance(payments: TransactionModel[]) {

		const parts : PartModel[] = []
		const totalParts = []
		const balanceEntry = []
		let balancesTotal = 0

		if (payments != null) {

			payments.map((transaction: TransactionModel ) => {
				return parts.push(...[...parts, ...transaction.payments[0].parts])
			})

			totalParts.push(...createTotalParts(parts))
			balanceEntry.push(...totalParts)

			balancesTotal = balanceEntry
				.map((balances) => balances.value)
				.reduce((prev, curr) => prev + curr, 0)
		}

		return !isNaN(balancesTotal) ? balancesTotal : 0
	}
	function getGeneralTotalEntryBalance() {

		if(generalBalance === null) return 0

		const parts : PartModel[] = []
		const totalParts = []
		const balanceEntry = []

		let balancesTotal = 0

		if (generalBalance.paymentsEntry != null) {

			generalBalance.paymentsEntry.map((entry:TransactionModel) => {

				entry.payments.map((entry) => {
					parts.push(...entry.parts)
					return null
				})
				return null
			})

			totalParts.push(...createTotalParts(parts))
			balanceEntry.push(...totalParts)

			balancesTotal = balanceEntry
				.map((balances) => balances.value)
				.reduce((prev, curr) => prev + curr, 0)
		}

		return balancesTotal
	}
	function getGeneralTotalOutflowBalance() {

		if(generalBalance === null) return 0

		const parts : PartModel[] = []
		const totalParts = []
		const balanceEntry = []

		let balancesTotal = 0

		if (generalBalance.paymentsOutflow != null) {

			generalBalance.paymentsOutflow.map((entry) => {

				entry.payments.map((entry) => {
					parts.push(...entry.parts)
					return null
				})
				return null
			})

			totalParts.push(...createTotalParts(parts))
			balanceEntry.push(...totalParts)

			balancesTotal = balanceEntry
				.map((balances) => balances.value)
				.reduce((prev, curr) => prev + curr, 0)
		}

		return balancesTotal
	}

	const getBalacesActives = async () => {

		handleLoadingState(true)

		try{
			const token = getToken()

			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/supplier/balance",{
				headers: { Authorization: `Bearer ${token}` },
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()

			dispatch(setBalances(data))

			setInactiveCashboxes(() =>
				user.cashboxes
					? (user.cashboxes.filter(
						(cashbox: equipmentModel) => data.filter(
							(balance: balanceModel) => balance.cashbox === cashbox.tag
						).length === 0
					)) : []
			)

			handleLoadingState(false)

		}catch(error){ console.error(error) }
	}

	const getBalanceGeneral = async () => {


		try{
			const token = getToken()
			const date = new Date()

			date.setHours(0,0,0)

			const initialTimestamp = toUnsignedInteger((new Date(Number(date)).getTime() / 1000).toString());
			const finalTimestamp = toUnsignedInteger(((new Date(Number(date)).getTime() / 1000) + 86399).toString());

			const url = `${process.env.REACT_APP_API_URL}/resource/supplier/balance/general?initialDate=${initialTimestamp}&finalDate=${finalTimestamp}`

			const response = await fetch(url,{
				headers: { Authorization: `Bearer ${token}` },
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()

			setGeneralBalance(data)

		}catch(error){ console.error(error) }
	}

	// Handlers
	function onRenderModal() {
		setRenderModal(prevState => !prevState)
	}
	function handleLoadingState(loadingState:boolean) {

		if (loadingState === true) {

			setLoading(loadingState)
		} else {

			setTimeout(() => {
				setLoading(loadingState)
			}, 200);
		}
	}

	// Effects
	useEffect(() => {
		getBalacesActives()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.cashboxes])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => { getBalanceGeneral() }, [])

	return (
		<SupplierRoleNavbar>

			{renderModal === true && (
				<BalanceNewRegisterModal
					onRenderModal={onRenderModal}
				/>
			)}

			<div className="grid medium-gap">
				{loading === true ? (
					<ComponentLoader />
				) : (
					((inactiveCashboxes?.length > 0) || (balances?.length > 0)) ? (
						<>
							<PageTitle
								title="Registradores"
								description={`Possui um total de ${(balances && balances?.length) || "0"} registradores abertos no momento`}
							>
								{/* <GlobalSearchBar
									value=""
									onChange={() => ""}
									placeholder="Busque um registrador"
								/> */}
							</PageTitle>

							<div className="grid fill-normal small-gap">

								<MainButton onClick={() => onRenderModal()} >
									<FontAwesomeIcon icon={faPlus} />
									<span>Adicionar registrador</span>
								</MainButton>

								{((inactiveCashboxes?.length > 0) || (balances?.length > 0)) && (
									<LinkButton to="/supplier/balances/report" hierarchy="low">
										<FontAwesomeIcon icon={faFile} />
										<span>Emitir Relatório</span>
									</LinkButton>
								)}
							</div>

							<BalancesCard
								status="Aberto"
								cashInflow={`R$ ${toFixedImproved(getGeneralTotalEntryBalance(), 2)}`}
								cashOutflow={`R$ ${toFixedImproved(getGeneralTotalOutflowBalance(), 2)}`}
								cashTotal={`R$ ${toFixedImproved((getGeneralTotalEntryBalance() - getGeneralTotalOutflowBalance()), 2)}`}
								registerTag="Visão geral"
								mainRegister ={true}
							/>

							<div className="flex">
								<div className="element-border flex flex-column small-gap">
									<span className="bold-font medium"> Registradores </span>

									<div className="flex medium-gap">
										<RadioButtonInput
											name="transactions"
											label="Abertos"
											value="0"
											defaultChecked={cashboxesView === "opened"}
											onChange={() => setCashboxesView("opened")}
										/>
										<RadioButtonInput
											name="transactions"
											label="Fechados"
											defaultChecked={cashboxesView === "closed"}
											value="1"
											onChange={() => setCashboxesView("closed")}
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-column small-gap">
								{(cashboxesView === "opened") ? (
									(balances && balances.length > 0) ? (
										<>
											<Pagination elements={balances} setCurrentElements={setCurrentBalances}/>
											{currentBalances.map(balance => (
												<BalancesCard
													key={balance.cashbox}
													status="Aberto"
													cashInflow={
														`R$ ${toFixedImproved(getTotalEntryBalance(balance.transactions?.entry), 2)}`
													}
													cashOutflow={
														`R$ ${toFixedImproved(getTotalOutflowBalance(balance.transactions?.outflow), 2)}`
													}
													cashTotal={
														`R$ ${toFixedImproved((getTotalEntryBalance(balance.transactions?.entry)
															- getTotalOutflowBalance(balance.transactions.outflow)), 2)}`
													}
													registerTag={balance.cashbox}
													mainRegister ={false}
												/>
											))}
										</>
									) : (
										<Notice>
											<FontAwesomeIcon icon={faCashRegister} />
											<span>Nenhum registrador aberto encontrado</span>
										</Notice>
									)
								) : (
									(inactiveCashboxes && inactiveCashboxes.length > 0) ? (
										inactiveCashboxes.map((cashbox:equipmentModel) => (
											<BalancesCard
												key={cashbox.tag}
												status="Fechado"
												cashInflow="R$ 0.00"
												cashOutflow="R$ 0.00"
												cashTotal="R$ 0.00"
												registerTag={cashbox.tag}
												mainRegister ={false}
											/>
										))
									) : (
										<Notice>
											<FontAwesomeIcon icon={faCashRegister} />
											<span>Nenhum registrador fechado encontrado</span>
										</Notice>
									)
								)}
							</div>
						</>
					) : (
						<>
							<PageTitle title="Registradores" />

							<Notice onClick={() => onRenderModal()} tone="info">
								<div className="grid-columns medium-gap left-items">
									<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle} />
									<div className="grid small-gap">
										<span className="small">
											Acompanhe aqui todas as movimentações de caixas registradoras, malotes de banco, cofres ou qualquer dispositivo que armazene valores físicos.
										</span>
										<div className="grid-columns small-gap left-items bold-font tiny">
											<span>Clique aqui para criar seu primeiro registrador</span>
											<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt} />
										</div>
									</div>
								</div>
							</Notice>
						</>
					)
				)}
			</div>
		</SupplierRoleNavbar>
	)
}
