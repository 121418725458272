import { ChangeEvent, FocusEvent } from "react"

type NumberInputProps = {
	min?: number
	max?: number
	name?: string
	step?: string
	label?: string
	value?: number | string
	onBlur?: (event: FocusEvent) => void
	disabled?: boolean
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	className?: string
	placeholder?: string
	defaultValue?: number | string
}

export default function NumberInput({
	min,
	max,
	name,
	step,
	label = "",
	value,
	onBlur,
	disabled,
	onChange,
	className,
	placeholder,
	defaultValue,
}: NumberInputProps) {

	return (
		<label>
			{label}
			<input
				min={min} // No negative
				max={max}
				name={name}
				step={step || "any"}
				type="number"
				value={value}
				onBlur={onBlur}
				onChange={onChange}
				disabled={disabled}
				className={className}
				placeholder={placeholder}
				// defaultValue={defaultValue}
			/>
		</label>
	)
}

		// <label className="flex flex-column justify-content-flex-end small-gap">
		// 	<span className="medium semibold-font">
		// 		{label}
		// 	</span>
		// 	<input
		// 		min={min} // No negative
		// 		max={max}
		// 		name={name}
		// 		step={step || "any"}
		// 		type="number"
		// 		value={value && value.toString()}
		// 		onBlur={onBlur}
		// 		onChange={onChange}
		// 		disabled={disabled}
		// 		className={className}
		// 		placeholder={placeholder}
		// 		defaultValue={defaultValue}
		// 	/>
		// </label>
