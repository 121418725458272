import { memo } from "react"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Icons
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import LinkButton from "../../../../../shared/components/standard/LinkButton"
import SelectedTag from "../../../../../shared/components/custom/SelectedTag/SelectedTag"
import MainButton from "../../../../../shared/components/standard/MainButton"
import { createToast } from "src/shared/store/slices/toastsSlice"
import { useDispatch } from "react-redux"

function AccountInvoicesRow({ rule, removeRule }) {
	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	const handleDelete = async () => {

		try {
			const token = getToken()
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				body: JSON.stringify({ entry : rule.entry }),
			})

			responseCodeHandler(response)

			if (!response.ok){
				if(response.status === 400){
					const data = await response.json()
					dispatch(createToast({ tone: "failure", miliseconds: 5000, message: data.msg }))
				}else{
					throw new Error(`HTTP error status ${response.status}`)
				}
			}else{
				removeRule(rule.entry)
				dispatch(createToast({ tone: "info", miliseconds: 5000, message: "Regra excluida" }))
			}
		}
		catch (error) { console.error(error) }
	}

	return (
		<tr>
			<td className="flex medium-gap">
			{!rule.default &&
				<LinkButton  to={`/supplier/taxation/edit/${rule.entry}`}>
					<FontAwesomeIcon className="fa-icon" icon={faEdit} />
				</LinkButton>
			}

				<MainButton hierarchy="inferior" disabled={rule.default} onClick={() => handleDelete()}>
					Excluir
				</MainButton>
			</td>
			<td>{rule.active?"Ativa":""}</td>
			<td>{rule.descricao}</td>
			<td>
				{rule.ncm !== null && rule.ncm.length > 0 ? (
					rule.ncm.length > 2 ? (
						<div className="flex medium-gap">
							{rule.ncm.map((ncms) => (
								<SelectedTag tagName={ncms} />
							)).slice(2)}
							<SelectedTag tagName="..." />
						</div>
					) : (
						<div className="flex medium-gap">
							{rule.ncm.map((ncms) => (
								<SelectedTag tagName={ncms} />
							))}
						</div>
					)
				) : (
					<SelectedTag tagName="Não especificado" />
				)}
			</td>
			<td>{rule.regra.interna.cstCsosn}</td>
			<td>{rule.regra.interna.aliquota}</td>
			<td>{rule.regra.interna.cfop}</td>
		</tr>
	)
}

export default memo(AccountInvoicesRow)
