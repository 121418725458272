import { FormEvent, ReactNode } from "react"

type FormProps = {
	children?: ReactNode
	onSubmit?: (event: FormEvent) => void
	className?: string
}

export default function Form({
	children,
	onSubmit,
	className,
}: FormProps) {
	return (
		<form
			onSubmit={onSubmit || (event => event.preventDefault())}
			className={className}
		>
			{children}
		</form>
	)
}
