import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"
// Utils
import { sumTotal } from "offiziersmesser/lib/helpers"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setTransactions } from "src/shared/store/slices/transactionsSlice"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

import StepForm from "src/shared/components/custom/StepForm/StepForm"
import LinkButton from "src/shared/components/standard/LinkButton"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"
import SupplierFinalizedTransactionsStepOne from "./FinalizedTransactionsStepOne"
import SupplierFinalizedTransactionsStepTwo from "./FinalizedTransactionsStepTwo"

export default function SupplierFinalizedTransactions() {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const transaction = useSelector(state => state.transactions)

	// States
	const [parts, setParts] = useState([])
	const [method, setMethod] = useState(0)
	const [partial, setPartial] = useState(false)
	const [extra, setExtra] = useState(0)
	const [deduction, setDeduction] = useState(0)

	let {paymentIndex} = useParams()
	const totalPayment = transaction?.payments === undefined? 0: transaction.payments[paymentIndex].total + (transaction.payments[paymentIndex].other.length !== 0?transaction.payments[paymentIndex].other.reduce((acc,item)=> acc+= item):0)

	// Handlers
	function totalTransaction(payments) {
		return payments.reduce((partialSum, actual) => partialSum + actual.total + (actual.other.length !== 0?actual.other.reduce((acc,item)=> acc+= item):0), 0)
	}
	function transactionIsDone(payments) {
		if (sumTotal(totalTransaction(payments), getArrayParts(payments)) === 0) return true
		return false
	}
	const getArrayParts = (arrayPayments) => {
		const a = []
		arrayPayments.forEach((p) => {
			a.push(...p.parts)
		})
		return a
	}
	const handleFinalizedTransactions = async () => {

		// when the transaction has one part go to first conditional.
		let part = {}

		if (parts.length === 0) {

			part = {
				createdAt: Date.now(), // Manual set if transaction is being custom-created, auto if its a heir
				value: sumTotal(transaction.payments[paymentIndex].total, transaction.payments[paymentIndex].parts), // Partial (of full) value paid
				method,
				extra,
				deduction // Lookup table storing how the payment was done (Cash, credit card, etc)
			}

			dispatch(
				setTransactions({
					...transaction,
					updatedAt: Date.now(),
					payments: transaction.payments.map((payment, index) => {
						if (index === paymentIndex) {
							payment = {
								...payment,
								parts: [...payment.parts, part]
							}
							return payment
						}
						return payment
					})
				})
			)
			//when the transaction has multiple parts go to second conditional.
		} else {
			dispatch(
				setTransactions({
					...transaction,
					updatedAt: Date.now(),
					payment: transaction.payments.map((payment, index) => {
						if (index === paymentIndex) {
							payment = {
								...payment,
								parts: [...payment.parts, ...parts]
							}
							return payment
						}
						return payment
					})
				})
			)
		}

		if (deduction !== 0 || extra !== 0) {

			dispatch(
				setTransactions({
					...transaction,
					updatedAt: Date.now(),
					payment: transaction.payments.map((payment, index) => {
						if (index === paymentIndex) {
							payment.parts[payment.parts.length - 1] = {
								...payment.parts[payment.parts.length - 1],
								deduction,
								extra
							}

							return payment
						}
						return payment
					})
				})
			)
		}
		try {

			const token = getToken()

			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/supplier/transactions/parts",
				{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					transaction: transaction.transaction,
					parts: (parts.length === 0) ? [part] : parts,
					period: transaction.payments[paymentIndex].installments.period,
					addition: Number(extra+transaction.payments[paymentIndex].other[0]??0),
					discount: Number(deduction+transaction.payments[paymentIndex].other[1]??0),
				})
			})
			responseCodeHandler(response)
			if(response.ok){
				const data = await response.json()
				if (transactionIsDone(data)) {
					const response = await fetch(
						process.env.REACT_APP_API_URL + "/resource/supplier/transactions/done",
						{
						method: "PUT",
						headers: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json"
						},
						body: JSON.stringify({
							transaction: transaction.transaction,
							"done": true,
							"note": transaction.transaction?.note || ""
						})
					})

					responseCodeHandler(response)

					if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

					navigate("/supplier/transactions")
					dispatch(setTransactions({})) // clear transaction

				} else {
					navigate("/supplier/transactions")
					dispatch(setTransactions({})) // clear transaction
				}
			}



		} catch (error) {
			console.error(error)
		}
	}

	const sumParts = useCallback((transaction) => {
		let sum = 0

		if ((transaction?.payments === undefined)) return sum

		transaction.payments[paymentIndex].parts.map((part) => {
			return sum += part.value
		})

		return sum
	}, [paymentIndex])

	const [covered, setCovered] = useState(sumParts(transaction))

	// Effects

	//here the initial state of the variables is set, when a transaction is updated
	useEffect(() => {
		setParts([])
		setPartial(false)
		setMethod(0)
		setExtra(0)
		setDeduction(0)
	}, [transaction])

	// Update covered
	useEffect(() => {

		let sum = 0
		parts.map((part) => {
			return sum += part.value
		})
		setCovered(sum + sumParts(transaction))
	}, [parts, sumParts, transaction])

	return (
		<SupplierRoleNavbar>
			<div className="flex flex-column small-gap">
				<div>
					<LinkButton to="/supplier/transactions" hierarchy="inferior">
						<FontAwesomeIcon icon={faArrowLeft} />
						<span>Transações</span>
					</LinkButton>
				</div>

				<StepForm
					componentTitle="Finalizar transação"
					onClickConclude={handleFinalizedTransactions}
				>
					<SupplierFinalizedTransactionsStepOne
						stepCondition={((covered <= ((totalPayment + extra) - deduction)) || covered <= 0)&&(covered>= (extra - deduction)) && ((covered >0)||(parts.length === 0))}
						extra={extra} setExtra={setExtra}
						deduction={deduction} setDeduction={setDeduction}
						paymentIndex={paymentIndex}
						method={method}
						setMethod={setMethod}
						partial={partial} setPartial={setPartial}
						parts={parts} setParts={setParts}
					/>
					<SupplierFinalizedTransactionsStepTwo
						paymentIndex={paymentIndex}
						method={method}
						deduction={deduction}
						extra={extra}
						partial={partial}
						parts={parts}
						setParts={setParts}
						sumTotal={sumTotal}
					/>
				</StepForm>
			</div>
		</SupplierRoleNavbar>
	)
}
