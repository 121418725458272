import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import { formatTaxpayer } from "offiziersmesser/lib/utils"

// Store
import { RootState } from "src/shared/store/reducers"
import { updateEmployment } from "src/shared/store/slices/employmentsSlice"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExchangeAlt, faAddressBook, faPen } from "@fortawesome/free-solid-svg-icons"

// Components
import LinkButton from "src/shared/components/standard/LinkButton"
// import Tooltip from "src/shared/components/custom/Tooltip/Tooltip"
import MainButton from "src/shared/components/standard/MainButton"

// Styles
import "./EmployeeCard.css"

type EmployeeCardRowProps = {
	employment: any
}

export default function EmployeeCard ({
	employment
}: EmployeeCardRowProps) {
    const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

    const employments = useSelector((state: RootState) => state.employments)

	async function UpdateSupplierStatus(employment: any) {
		try {
            const token = getToken()
           const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/employee`, {
				method: "put",
				body: JSON.stringify({
					employee: employment.user,
					role: Number(employment.employment.role),
					active: !employment.employment.active,

				}),
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

		} catch (error) {
			console.error(error)
		}
        finally {
            dispatch(updateEmployment(
                employments.map((employee) => {
                    if (employee.taxpayer === employment.taxpayer && employee.employment.role === employment.employment.role) {
                        return {
                            ...employee,
                            employment: {
                                ...employee.employment,
                                active: !employee.employment.active,
                            },
                        };
                    }
                    return employee;
                })
            ));
        }
    }

	return (
		<div className="employee-card-container">
			<div className="employee-card-status">
				<div
					className={employment.employment.active === true ? "relation-active tiny" : "relation-inactive tiny"}>
					{employment.employment.active === true ? "Ativo" : "Inativo"}
				</div>
			</div>

			<div className="employee-card-info">
				<span >
					<b>{employment.employee.company === "" ? employment.employee.reference.name : employment.employee.company}</b> {employment.employee.byname === "" ? employment.employee.reference.surname : employment.employee.byname}
				</span>
				<span className="bold-font">
					{formatTaxpayer(employment.taxpayer)}
				</span>
			</div>

			<div className="employee-card-actions">
				<LinkButton
					hierarchy="low"
					to={{
						pathname: "/supplier/employments/new",
						region: {id: employment.taxpayer}
					}}
				>
					<FontAwesomeIcon icon={faPen as Icon} />
				</LinkButton>

				<MainButton
					hierarchy="inferior"
					onClick={() => UpdateSupplierStatus(employment)}
				>
					<FontAwesomeIcon className="fa-icon" icon={faExchangeAlt as Icon} />
					<span>{employment.employment.active === true ? "Desativar" : "Ativar"}</span>
				</MainButton>
{/*
				<Tooltip
					tooltipContent={
						<div>
							<span>{employment.employee.reference.email}</span>
							<span>+55 {employment.employee.reference.phone.destination} {employment.employee.reference.phone.subscriber}</span>
						</div>
					}
					tooltipAlign="top-left"
				> */}
					<MainButton hierarchy="inferior">
						<FontAwesomeIcon className="fa-icon" icon={faAddressBook as Icon} />
					</MainButton>
				{/* </Tooltip> */}
			</div>
		</div>
	)
}
