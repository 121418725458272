// Components
import ItemsTableRow from "./ItemsTableRow"
import ItemsTableHeader from "./ItemsTableHeader"


export default function ItemsTable({ items }) {



	return (
		<>
			<div className="table-wrapper">
				<table>
					<ItemsTableHeader />
					<tbody>
						{items.map((item) => (
							<ItemsTableRow
								key={item.item} // Good practice using a real immutable information
								item={item}
							/>
						))}
					</tbody>
				</table>
			</div>
		</>
	)
}
