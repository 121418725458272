// Components
import TextInput from "../../../standard/TextInput"

type BankingModel = {
	institution: string
	branch: string
	account: string
}

type DisableableFields = Array<"institution" | "branch" | "account">

type BankingFormProps = {
	institutionLabel?: string,
	branchLabel?: string,
	accountLabel?: string,
	disabledFields?: DisableableFields,
	banking: BankingModel,
	setBanking: (value: BankingModel) => void,
}

export default function BankingForm({
	institutionLabel = "Instituição",
	branchLabel = "Agência",
	accountLabel = "Conta",
	disabledFields,
	banking,
	setBanking,
}: BankingFormProps) {

	// Handlers
	/**
	 * This function updates the user's banking information and triggers a form
	 * change event.
	 * @param {string} target - a string representing the property of the user object
	 * that needs to be updated.
	 * @param {string} value - The value that will be assigned to the specified target
	 * property in the user object.
	 */
	function handleSetBanking(
		target: string,
		value: string,
	) {
		setBanking({
			...banking,
			[target]: value
		})
	}

	return (
		<div className="grid fill-normal small-gap">
			<div className="grid-columns small-gap fill-tiny-x">
				<TextInput
					name="institution"
					label={institutionLabel}
					value={banking?.institution}
					disabled={disabledFields?.includes("institution")}
					onChange={(e) => handleSetBanking(e.target.name, e.target.value)}
				/>
				<TextInput
					name="branch"
					label={branchLabel}
					value={banking?.branch}
					disabled={disabledFields?.includes("branch")}
					onChange={(e) => handleSetBanking(e.target.name, e.target.value)}
				/>
			</div>
			<TextInput
				name="account"
				label={accountLabel}
				value={banking?.account}
				disabled={disabledFields?.includes("account")}
				onChange={(e) => handleSetBanking(e.target.name, e.target.value)}
			/>
		</div>
	)
}
