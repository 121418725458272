// Library imports
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

// Store
import { RootState } from "src/shared/store/reducers"
import { setUser } from "src/shared/store/slices/userSlice"
import {
	setAuthenticated,
	initializeSession,
	getDefaultPathInNavigation,
	// setSessionUserRoleActive
} from "src/shared/store/slices/sessionSlice"


// Models
import { ReferenceModel, LocalityModel} from "offiziersmesser/lib/models"

// Components
import Authentication from "./Authentication"
import PasswordReset from "./PasswordReset"
import SupplierRegistration from "./SupplierRegistration"

// Validators
// import fetchEmployeeRoles from "../validators/fetchEmployeeRoles"
import retrieveTaxpayerActivity, { TaxpayerActivityProps } from "../validators/retrieveTaxpayerActivity"

// Handlers
import EmployeeRegistration from "./EmployeeRegistration"

// Services
import authorizeSupplier from "../services/authorizeSupplier"
import authorizeEmployee from "../services/authorizeEmployee"

import "../styles/Authorization.css"
import { ReactComponent as Logo } from "src/assets/materiapp-logo.svg"


export default function Authorization() {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	// Store
	const session = useSelector((state: RootState) => state.session)

	// States
	const [alias, setAlias] = useState("")
	const [taxpayer, setTaxpayer] = useState("")
	const [passphrase, setPassphrase] = useState("")
	const [isPassphraseStrong, setIsPassphraseStrong] = useState(false)

	// The following variable is null instead of undefined because the server returns null whenever
	// the customer is not found having any employee roles within the supplier
	const [employeeRoles, ] = useState([])

	const [asEmployee, setAsEmployee] = useState(false)
	const [isTaxpayerValid, setIsTaxpayerValid] = useState(false)

	const [userExists, setUserExists] = useState(false)
	const [isUserActive, setIsUserActive] = useState(false)
	const [isUserNovice, setIsUserNovice] = useState(false)

	const [passphraseReset, setPassphraseReset] = useState(false)

	const [isSupplier, setIsSupplier] = useState(false)

	// Administrative data
	const [company, setCompany] = useState("")
	const [byname, setByname] = useState("")

	const [reference, setReference] = useState<ReferenceModel>({
		name: "",
		surname: "",
		email: "",
		phone: {
			prefix: "55", // default placeholder
			destination: "11", // default placeholder
			subscriber: ""
		}
	})

	const [locality, setLocality] = useState<LocalityModel>({
		area: "",
		city: "",
		road: "",
		extra: "",
		number: "",
		postal: "",
		region: "SP", // default placeholder
		country: "BR" // default placeholder
	})

	// const [contact, setContact] = useState(reference.phone)

	// Registration type
	const [supplierRegistration, setSupplierRegistration] = useState(false)
	const [employeeRegistration, setEmployeeRegistration] = useState(false)

	// Phone code characters
	const [characters, setCharacters] = useState(new Array(4))
	const [isCodeSent, setIsCodeSent] = useState(false)
	const [isCodeSentTwice, setIsCodeSentTwice] = useState(false)

	const user = {
		taxpayer,
		isSupplier: false,
		alias,
		cashboxes: [],
		// taxation: {
		// 	sefaz: sefaz,
		// 	inscricoes: inscricoes,
		// 	definicoesNf: definicoesNf
		// },
		administrative: {
			byname,
			company,
			reference: reference,
			locality: locality,
		},
		// contacts: {
		// 	phones: [],
		// },
		// categories: []
	} as any


	// Effects
	useEffect(() => {
		if (isTaxpayerValid) {
			retrieveTaxpayerActivity({
				taxpayer,
				setIsUserActive,
				setIsUserNovice,
				setUserExists,
				// contact, setContact,
				setByname,
				setCompany,
				setReference,
				setLocality,
			} as TaxpayerActivityProps)
		}
	// Requires both as dependencies to cover the edge case where the taxpayer changes but it is still
	// a valid one, like a copy and paste action, triggering the data retrieval
	}, [taxpayer, isTaxpayerValid])


	function completeAuthorization(token: string, payload: any) {
		// Set user-related data
		sessionStorage.setItem("token", token) // Auto-authentication during refresh
		dispatch(setUser(payload))
		dispatch(setAuthenticated(true))
	}
	async function completeEmployeeAuthorization() {
		const response = await authorizeEmployee(alias, taxpayer, passphrase)
		// Keeps track of all available roles for this given employee user
		dispatch(initializeSession({ stakeholder: "employee", authorizedRoles: response.roles }))
		completeAuthorization(response.token, response.payload)
	}
	async function completeSupplierAuthorization() {
		const response = await authorizeSupplier(taxpayer, passphrase)
		dispatch(initializeSession({ stakeholder: "supplier" }))
		completeAuthorization(response.token, response.payload)
	}

	useEffect(() => {
		if (session.authenticated === true){
			navigate(getDefaultPathInNavigation(session.roles[0].navigation))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session])

	return (
		<div className="grid huge-gap">
			<div className="medium-margin grid left-items">
				<Logo />
			</div>

			<div className="Authorization-flow grid huge-gap">
				{(supplierRegistration === false && employeeRegistration === false && passphraseReset === false)
				&& (
					<Authentication
						alias={alias} setAlias={setAlias}
						taxpayer={taxpayer} setTaxpayer={setTaxpayer}
						passphrase={passphrase}	setPassphrase={setPassphrase} setIsPassphraseStrong={setIsPassphraseStrong}
						asEmployee={asEmployee}	setAsEmployee={setAsEmployee}
						userExists={userExists}
						isUserActive={isUserActive}
						employeeRoles={employeeRoles}
						supplierRegistration={supplierRegistration}
						isUserNovice={isUserNovice}
						setSupplierRegistration={setSupplierRegistration}
						setEmployeeRegistration={setEmployeeRegistration}
						setPassphraseReset={setPassphraseReset}
						isTaxpayerValid={isTaxpayerValid} setIsTaxpayerValid={setIsTaxpayerValid}

						completeSupplierAuthorization={completeSupplierAuthorization}
						completeEmployeeAuthorization={completeEmployeeAuthorization}
					/>
				)}

				{(supplierRegistration === true && employeeRegistration === false && passphraseReset === false)
				&& (
					<SupplierRegistration
						user={user}
						taxpayer={taxpayer}
						passphrase={passphrase} setPassphrase={setPassphrase}
						isPassphraseStrong={isPassphraseStrong} setIsPassphraseStrong={setIsPassphraseStrong}
						characters={characters} setCharacters={setCharacters}
						reference={reference} setReference={setReference}
						byname={byname} setByname={setByname}
						company={company} setCompany={setCompany}
						// sefaz={sefaz} setSefaz={setSefaz}
						// contact={contact} setContact={setContact}
						isSupplier={isSupplier} setIsSupplier={setIsSupplier}
						locality={locality} setLocality={setLocality}
						setSupplierRegistration={setSupplierRegistration}
						isCodeSent={isCodeSent} setIsCodeSent={setIsCodeSent}
						isCodeSentTwice={isCodeSentTwice} setIsCodeSentTwice={setIsCodeSentTwice}

						completeSupplierAuthorization={completeSupplierAuthorization}
					/>
				)}

				{(supplierRegistration === false && employeeRegistration === true && passphraseReset === false)
				&& (
					<EmployeeRegistration
						user={user}
						alias={alias}
						taxpayer={taxpayer}
						passphrase={passphrase} setPassphrase={setPassphrase}
						isPassphraseStrong={isPassphraseStrong} setIsPassphraseStrong={setIsPassphraseStrong}
						characters={characters} setCharacters={setCharacters}
						reference={reference} setReference={setReference}
						locality={locality} setLocality={setLocality}
						isCodeSent={isCodeSent} setIsCodeSent={setIsCodeSent}
						isCodeSentTwice={isCodeSentTwice} setIsCodeSentTwice={setIsCodeSentTwice}
						setEmployeeRegistration={setEmployeeRegistration}

						completeEmployeeAuthorization={completeEmployeeAuthorization}
					/>
				)}

				{(supplierRegistration === false && employeeRegistration === false && passphraseReset === true)
				&& (
					<PasswordReset
						phone={reference.phone}
						isCodeSent={isCodeSent} setIsCodeSent={setIsCodeSent}
						setPassphraseReset={setPassphraseReset}
						passphrase={passphrase} setPassphrase={setPassphrase} setIsPassphraseStrong={setIsPassphraseStrong}
						characters={characters} setCharacters={setCharacters}
						isCodeSentTwice={isCodeSentTwice} setIsCodeSentTwice={setIsCodeSentTwice}
					/>
				)}
			</div>
		</div>
	)
}
