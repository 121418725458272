import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import SearchBar from "src/shared/components/custom/SearchBar/SearchBar"

// Components


export default function ProductsBlockSearch({
	itemFound,
	setItemFound,
	searchValue,
	setSearchValue,
}) {
	// States
	const [searchResults, setSearchResults] = useState([])
	const [searchResultsValues, setSearchResultsValues] = useState([])

	const items = useSelector(state => state.items)

	// Handlers
	function handleSelection(value) {
		setSearchValue(value)
		setSearchResultsValues([])
		setItemFound(true)
	}

	function handleSearch(e) {
		setSearchValue(() => {
			setItemFound(false)

			items.foreach((item) => {
				if (item.control.sku === e.target.value.replace(/\D/g, "")) {
					setItemFound(true)
					handleSelection(e.target.value)
					setSearchResultsValues([])
				}
			})

			return e.target.value.replace(/\D/g, "")
		})

		if (e.target.value === "") {
			setSearchResults([])
			setSearchResultsValues([])
			return
		}

		setSearchResults(
			items.filter(item => {
				if (
					!!e.target.value &&
					item.control.sku.includes(e.target.value)
				) {
					return item.control.sku
				}
				return ""
			})
		)
	}

	// Effects
	useEffect(() => {
		setSearchResultsValues(previousState => {
			if (itemFound)
				return previousState

			return searchResults.map(result => ({
				valueIndentifier: result.data.control.sku,
				value: `${result.data.control.sku} - ${result.data.control.description}`,
				returnedValue: `${result.data.control.sku} - ${result.data.control.description}`
			}))
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchResults])

	return (
		<SearchBar
			value={searchValue}
			onChange={e => handleSearch(e)}
			results={searchResultsValues}
			handleSelection={handleSelection}
			placeholder="Busque por SKU"
		/>
	)
}
