import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// State
import { setCart } from "../../../../../shared/store/slices/cartSlice"

// Components
import SelectInput from "../../../../../shared/components/standard/SelectInput"
import CheckboxInput from "../../../../../shared/components/standard/CheckboxInput"
import PageTitle from "../../../../../shared/components/custom/PageTitle/PageTitle"
import DatePickerSelect from "../../../../../shared/components/custom/DatePickerSelect/DatePickerSelect"

export default function PickupMethod() {

	const dispatch = useDispatch()

	// Store
	const cart = useSelector(state => state.cart)

	// States
	const [schedulePickup, setSchedulePickup] = useState(false)
	const [schedulePickupWindow, setSchedulePickupWindow] = useState(false)

	const [openingHours, setOpeningHours] = useState(0)
	const [openingMinutes, setOpeningMinutes] = useState(0)

	const [closingHours, setClosingHours] = useState(0)
	const [closingMinutes, setClosingMinutes] = useState(0)

	const [selectedOpeningHourTimestamp, setSelectedOpeningHourTimestamp] = useState(0)
	const [selectedClosingHourTimestamp, setSelectedClosingHourTimestamp] = useState(0)

	let hoursOptions = []
	let minutesOptions = []

	for (let i = 0; i < 24; i++)
		hoursOptions.push(
			<option key={i} value={i}>
				{i < 10 ? "0" + i : i}
			</option>
		)
	for (let i = 0; i < 60; i++)
		minutesOptions.push(
			<option key={i} value={i}>
				{i < 10 ? "0" + i : i}
			</option>
		)

	// Handlers
	function handleSetWindowDate(date, dateSelector) {
		if (!!schedulePickup && !schedulePickupWindow) {
			dispatch(
				setCart({
					...cart,
					data: {
						...cart.data,
						delivery: {
							...cart.data.delivery,
							window: {
								...cart.data.delivery.window,
								opening: Date.now(),
								closing:
									new Date(
										date.year,
										date.month - 1,
										date.day
									).getTime() / 1000 + selectedOpeningHourTimestamp,
							},
						},
					},
				})
			)
		}
		if (!!schedulePickupWindow && dateSelector === "closing") {
			dispatch(
				setCart({
					...cart,
					data: {
						...cart.data,
						delivery: {
							...cart.data.delivery,
							window: {
								...cart.data.delivery.window,
								closing:
									new Date(
										date.year,
										date.month - 1,
										date.day
									).getTime() /
									1000 + selectedClosingHourTimestamp,
							},
						},
					},
				})
			)
		}
		if (!!schedulePickupWindow && dateSelector === "opening") {
			dispatch(
				setCart({
					...cart,
					data: {
						...cart.data,
						delivery: {
							...cart.data.delivery,
							window: {
								...cart.data.delivery.window,
								opening:
									new Date(
										date.year,
										date.month - 1,
										date.day
									).getTime() /
									1000 +
									selectedOpeningHourTimestamp,
							},
						},
					},
				})
			)
		}
	}
	function handleSchedulePickup() {
		setSchedulePickup((prevState) => {
			const newState = !prevState
			if (newState === false) {
				setSelectedClosingHourTimestamp(undefined)
			}
			return newState
		})
	}
	function handleScheduleWindowPickup() {
		setSchedulePickupWindow((prevState) => !prevState)
	}

	// Effects
	useEffect(() => {
		setSelectedOpeningHourTimestamp(() => openingHours * 60 * 60 + openingMinutes * 60)
		setSelectedClosingHourTimestamp(() => closingHours * 60 * 60 + closingMinutes * 60)
	}, [openingHours, openingMinutes, closingHours, closingMinutes])

	return (
		<div className="flex flex-column medium-gap large-margin-bottom large-margin-top">

			<PageTitle title="Retirada" />

			<div className="grid small-gap">
				<CheckboxInput
					label="Agendar retirada"
					onChange={() => handleSchedulePickup()}
				/>

				{schedulePickup && (
					<CheckboxInput
						label="Definir um período de retirada"
						onChange={() => handleScheduleWindowPickup()}
					/>
				)}
			</div>

			{schedulePickup && (
				<>
					<span className="big-x bold-font">
						Agendar Retirada
					</span>

					<div className="flex flex-column">
						{schedulePickupWindow && (
							<span className="bold-font medium small-margin-bottom">
								Inicio do periodo
							</span>
						)}

						<div className="grid small-gap fill-large center-items wide-children">
							<div className="element-border">
								<DatePickerSelect
									value={cart.data.delivery.window.closing}
									timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
									setDate={(date) => handleSetWindowDate(date, "opening")}
								/>
							</div>

							<div className="element-border grid-columns small-gap">
								<SelectInput
									label="Horas"
									value={openingHours}
									onChange={(e) => setOpeningHours(Number(e.target.value))}
								>
									{hoursOptions}
								</SelectInput>
								<SelectInput
									label="Minutos"
									value={openingMinutes}
									onChange={(e) => setOpeningMinutes(Number(e.target.value))}
								>
									{minutesOptions}
								</SelectInput>
							</div>
						</div>
					</div>

					{schedulePickupWindow && (
						<div className="flex flex-column">
							{schedulePickupWindow && (
								<span className="bold-font medium small-margin-bottom">
									Fim do periodo
								</span>
							)}
							<div className="grid small-gap fill-large center-items wide-children">
								<div className="element-border">
									<DatePickerSelect
										value={cart.data.delivery.window.closing}
										timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
										setDate={(date) => handleSetWindowDate(date, "closing")}
									/>
								</div>
								<div className="element-border grid-columns small-gap">
									<SelectInput
										label="Horas"
										value={closingHours}
										onChange={(e) =>
											setClosingHours(Number(e.target.value))
										}
									>
										{hoursOptions}
									</SelectInput>
									<SelectInput
										label="Minutos"
										value={closingMinutes}
										onChange={(e) =>
											setClosingMinutes(Number(e.target.value))
										}
									>
										{minutesOptions}
									</SelectInput>
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	)
}
