import { ReactNode, useState } from "react"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Utils
import { toFixedImproved } from "offiziersmesser/lib/utils"
import { calculateItemPrice, calculateOrderSumPlusShipping } from "offiziersmesser/lib/helpers"

// Icons
import {
	faExclamationCircle,
	faExternalLinkSquareAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import Notice from "src/shared/components/custom/Notice/Notice"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"
import LinkButton from "src/shared/components/standard/LinkButton"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import SupplierNavbar from "src/shared/components/custom/Navbar/SupplierNavbar"

import CartItem from "./CheckoutItemCard/CartItem"
import CreateQuotationModalButton from "./modals/CreateQuotationModalButton"
import RetrieveQuotationModalButton from "./modals/RetrieveQuotationModalButton"

// Styles
import "./StoreCartbar.css"

// Services
import { getToken } from "src/shared/services/general"

// Store
// import {
// 	// Utilitary functions
// 	getDefaultPathInNavigation,
// 	// Types
// 	NavigationEntryModel,
// } from "../../../store/slices/sessionSlice"
import { setCart } from "src/shared/store/slices/cartSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"


type Item = {
	item: string
	quantity: number
}

type Quotation = {
	expiresAt: number
	quotation: string
	items: Item[]
}

type StoreCartNavbarProps =  {
	topLeft?: ReactNode
	topRight?: ReactNode
	children: ReactNode
}

export default function StoreCartNavbar({
	topLeft,
	topRight,
	children,
}: StoreCartNavbarProps) {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	const { pathname } = useLocation()

	// Store
	const user = useSelector((state: any) => state.user)
	const store = useSelector((state: any) => state.store)
	const cart = useSelector((state: any) => state.cart)
	const cartSize = cart.data.contents.length


	// const supplier = useSelector(state => state.supplier) // need to remove this

	// States
	const [renderBudgetHandlerModal, setRenderBudgetHandlerModal] = useState(false)

	const [discount,] = useState(0)

	const [budgetUrl, setBudgetUrl] = useState("")
	const [userBudget, setUserBudget] = useState<Quotation>({
		quotation: "",
		expiresAt: 0,
		items: []
	})

	const total = calculateOrderSumPlusShipping(cart.data)

	function addAllItemsToCart(itemsToAdd: any) {
		const cartContents = [...cart.data.contents]

		// itemsToAdd.forEach((itemInfo: { element: { item: string; }, amount: number }) => {
		// 	const item = store.data.items.find((element: { item: string }) => element.item === itemInfo.element.item)
		itemsToAdd.forEach((itemInfo: any) => {
			const item = store.data.items.find((element: any) => element.item === itemInfo.element.item)
			if (item) {
				cartContents.push({
					element: item,
					amount: itemInfo.amount,
				})
			}
		})

		dispatch(
			setCart({
				...cart,
				data: {
					...cart.data,
					alias: user.alias,
					address: {
						...cart.data.delivery.address,
						locality: store.data.locality,
					},
					contents: cartContents,
				},
			})
		)
	}

	// Handlers
	async function loadBudget() {
		try {
			const response = await fetch(`${process.env.REACT_APP_PEDDLER_API_URL}/quotation/${budgetUrl}`)

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const userBudget = await response.json()

			addAllItemsToCart(userBudget.items)
		}
		catch {
			dispatch(createToast({
				tone: "failure",
				miliseconds: 5000,
				message: "Não foi possível gerar orçamento."
			} as any))
		}
	}

	async function getBudgetCode() {
		try {
			const response = await fetch(`${process.env.REACT_APP_PEDDLER_API_URL}/quotation/generate/code`)

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const userBudget = await response.json()

			setUserBudget(userBudget)
			setRenderBudgetHandlerModal(true)
		}
		catch {
			dispatch(createToast({
				tone: "failure",
				miliseconds: 5000,
				message: "Erro ao gerar orçamento"
			} as any))
		}

	}

	async function createBudget(print: boolean) {
		const token = getToken()

		try {
			const response = await fetch(`${process.env.REACT_APP_PEDDLER_API_URL}/quotation`, {
				method: "POST",
				headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
				body: JSON.stringify({ ...userBudget, items: cart.data.contents })
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			if (print) {
				// printContent(RenderCart(cart, total, discount, userBudget.quotation, user, supplier))
				setRenderBudgetHandlerModal(false)
			} else {
				const textToCopy = userBudget.quotation

				try {
					await navigator.clipboard.writeText(textToCopy)
					dispatch(createToast({
						tone: "success",
						miliseconds: 5000,
						message: "Código copiado",
					} as any))
					setRenderBudgetHandlerModal(false)
				} catch (error) {
					console.error(error)
				}
			}

		} catch (error) {
			dispatch(
				createToast({
					tone: "failure",
					miliseconds: 5000,
					message: "Não foi possível imprimir orçamento",
				} as any)
			)
		}
	}

// display: flex;
//   align-items: center;

	return (
		<div>
			<div className="grid tiny-gap">
				<span className="heavy-font gigantic-xxx">{`${toFixedImproved(total - discount, 2)}`}</span>
			</div>

			{pathname === "/seller/checkout" ? (
				<LinkButton
					to="/seller/store"
					hierarchy="inferior"
				>
					<div className="wide center-text">Retornar à loja</div>
				</LinkButton>
			) : (
				<div className="grid fill-normal small-gap">
					<RetrieveQuotationModalButton />
					<CreateQuotationModalButton />
					<LinkButton
						to="/seller/checkout"
					>
						<span>Fechar pedido</span>
					</LinkButton>
				</div>
			)}

			<div className="grid small-gap">
				{cart.data.contents.map((element: any, index: number) =>
					<CartItem key={index} item={element} />
				)}
			</div>
		</div>
	)
}



		// <>
		// 	{cartSize > 0 ? (
		// 		<div className="grid small-gap high">
		// 			{cart.data.contents.map((element: any, index: number) =>
		// 				<CartItem key={index} item={element} />
		// 			)}
		// 		</div>
		// 	) : (
		// 		<Notice tone="info" to="/seller/store">
		// 			<div className="grid-columns medium-gap left-items">
		// 				<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle} />
		// 				<div className="grid small-gap">
		// 					<span className="small">
		// 						Carrinho vazio. Para começar a vender, carregue um orçamento ou acesse o catálogo.
		// 					</span>
		// 					<div className="grid-columns small-gap left-items bold-font tiny">
		// 						<span>Clique aqui para ir até o catálogo</span>
		// 						<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt} />
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</Notice>
		// 	)}

		// 	{cartSize > 0 ? (
		// 		<div className="flex flex-column small-gap wide small-padding-horizontal small-padding-top">
		// 			<div className="cart-items-sidebar grid-columns space-between-items element-border wide wide-children" >
		// 				<p className="bold-font big">Total:</p>
		// 				<p className="bold-font big">{`R$ ${toFixedImproved(total - discount, 2)}`}</p>
		// 			</div>

		// 			{pathname !== "/seller/checkout" && (
		// 				<div className="wide wide-children flex tiny-gap">
		// 					<MainButton
		// 						hierarchy="minimal"
		// 						onClick={() => getBudgetCode()}
		// 					>
		// 						<span>Orçamento</span>
		// 					</MainButton>
		// 					<LinkButton
		// 						to="/seller/checkout"
		// 					>
		// 						<span>Fechar pedido</span>
		// 					</LinkButton>

		// 				</div>
		// 			)}

		// 			{pathname === "/seller/checkout" && (
		// 				<LinkButton
		// 					to="/seller/store"
		// 					hierarchy="inferior"
		// 				>
		// 					<div className="wide center-text">Cancelar</div>
		// 				</LinkButton>
		// 			)}
		// 		</div>
		// 	)}
		// </>
