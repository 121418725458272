import { sumTotal } from "offiziersmesser/lib/helpers"
import { PAYMENT_METHODS, toFixedImproved } from "offiziersmesser/lib/utils"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"
import NumberInput from "src/shared/components/standard/NumberInput"
import SelectInput from "src/shared/components/standard/SelectInput"
import TextInput from "src/shared/components/standard/TextInput"
import PaymentListTransaction from "./PaymentListTransaction"



export default function SupplierFinalizedTransactionsStepOne({
	deduction,
	setDeduction,
	extra,
	setExtra,
	indexPayment,
	method,
	setMethod,
	partial,
	setPartial,
	parts,
	setParts,
}) {

	// Store
	const transaction = useSelector(state => state.transactions)

	// States
	const [interest, setInterest] = useState(false)
	const [discount, setDiscount] = useState(false)
	const [total, setTotal] = useState(
		(Number(sumTotal(
			transaction.payments[indexPayment].total,
			transaction.payments[indexPayment].parts
		)) + Number(extra)) - Number(deduction)
	)


	// Effects
	useEffect(() => {
		setTotal(
			(Number(sumTotal(
				transaction.payments[indexPayment].total,
				transaction.payments[indexPayment].parts
			)))+ (transaction.payments[indexPayment].other.length !== 0?transaction.payments[indexPayment].other.reduce((acc,item)=> acc+= item):0)
		)
	}, [indexPayment, transaction, extra, deduction])

	// if payment isn"t partial then array parts is reset
	useEffect(() => {
		if (!partial) setParts([])
	}, [partial, method, setParts])

	useEffect(()=>{
		if(!interest){
			setExtra(0)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[interest])

	useEffect(()=>{
		if(!discount){
			setDeduction(0)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[discount])

	return (
		<div className="grid medium-gap">
			<div className="grid fill-normal medium-gap medium-margin-top">
				<TextInput
					label="Descrição"
					value={transaction.note}
					disabled
				/>
				{partial === false && (
					<TextInput
						label="Valor"
						value={toFixedImproved(total, 2)}
						disabled
					/>
				)}
				<SelectInput
					label="Método de pagamento"
					name="method"
					value={method}
					onChange={(e) => setMethod(e.target.value)}
					disabled={partial}
				>
					{PAYMENT_METHODS.map((entry, index) => (
						<option key={index} value={entry.name}>
							{entry.name}
						</option>
					))}
				</SelectInput>


			</div>

			<div className="grid small-gap">
				<CheckboxInput
					label="Criar acréscimos"
					checked={interest}
					onChange={() => {
						interest === false
							? setInterest(true)
							: setInterest(false)
					}}
				/>
				<CheckboxInput
					label="Criar descontos"
					checked={discount}
					onChange={() => {
						discount === false
							? setDiscount(true)
							: setDiscount(false)
					}}
				/>

				<CheckboxInput
					label="Definir valor parcial"
					checked={partial}
					onChange={() => { partial === false ? setPartial(true) : setPartial(false) }}
				/>
			</div>

			{((interest === true) || (discount === true)) && (
				<div className="flex small-gap">
					{interest === true && (
						<NumberInput
							label="Acréscimos"
							value={extra}
							onChange={(event) => setExtra(isNaN(event.target.valueAsNumber)?0:event.target.valueAsNumber)}
						/>
					)}
					{discount === true && (
						<NumberInput
							value={deduction}
							label="Desconto"
							onChange={(event) => setDeduction(isNaN(event.target.valueAsNumber)?0:event.target.valueAsNumber)}
						/>
					)}
				</div>
			)}

			<div className="grid small-gap">
			{partial === true && (
				/* partial payments */
				<PaymentListTransaction
					indexPayment={indexPayment}
					parts={parts}
					setParts={setParts}
					total={total+(extra-deduction)}
				/>
			)}
			</div>
		</div>
	)
}
