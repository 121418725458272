import { ReactNode } from "react"

import SupplierNavbar from "src/shared/components/custom/Navbar/SupplierNavbar"
import RoleNavigationSection from "src/shared/components/custom/Navbar/RoleNavigationSection"

type SupplierRoleNavbarProps =  {
	topLeft?: ReactNode
	// topRight?: ReactNode
	context?: ReactNode
	children: ReactNode
}

export default function SupplierRoleNavbar({
	topLeft,
	// topRight,
	context,
	children,
}: SupplierRoleNavbarProps) {

	return (
		<SupplierNavbar
			topLeft={topLeft}
			// topRight={topRight}
			leftCenter={<RoleNavigationSection/>}
			context={context}
		>
			{children}
		</SupplierNavbar>
	)
}
