import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	taxpayer: "",
	administrative: {
		reference: {
			name: "",
			surname: "",
			email: "",
			phone: {
				prefix: "55",
				subscriber: "",
				destination: ""
			},
		},
		locality: {
			area: "",
			city: "",
			road: "",
			extra: "",
			number: "",
			postal: "",
			region: "",
			country: "",
		},
		company: "",
		byname: "",
	},
	assessments:{
		taxpayer: "",
		clearence: false,
		general: {
			administrative: {
				reference: {
					name: "",
					surname: "",
					email: "",
					phone: {
						prefix: "55",
						subscriber: "",
						destination: ""
					},
				},
				locality: {
					area: "",
					city: "",
					road: "",
					extra: "",
					number: "",
					postal: "",
					region: "",
					country: "",
				},
				company: "",
				byname: "",
			}
		},
		billing: {
			address: {
				city: "",
				road: "",
				number: "",
				area: "",
				postal: "",
				region: "",
				country: "BR",
				extra: "",
			},
			restrictions: [],
			closure: 0,
		},
		credit: {
			limit: 0,
			timespan: {
				value: 0,
				unit: "dd"
			},
		},
		debt: {
			limit: 0,
			timespan: {
				value: 0,
				unit: "dd"
			},
		},
		endorsements: [],
		checks: [],
		biases: []
	}
}

const guestSlice = createSlice({
	name: "guest",
	initialState,
	reducers: {
		setGuest: (state, action) => {
			return state = action.payload
		},
		resetGuest: state => {
			return state = initialState
		}
	}
})

export default guestSlice.reducer
export const { setGuest, resetGuest } = guestSlice.actions
