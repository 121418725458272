import { createSlice } from "@reduxjs/toolkit"

interface FileModel {
	content: string | Buffer
	type: string
}

const initialState = {
	files: [] as FileModel[],
	index: 0
}

const files = createSlice({
	name: "files",
	initialState,
	reducers: {
		resetFiles: state => {
			return state = initialState
		},
		// // This function works by pointing to the first item of the array added, working for both manually editing a single item
		// // or when a file with multiple items to be edited is consumed
		insertIntoFiles: (state, action) => {
			// Does not matter if a single or multiple items are added, the updated index starts at the first of the new series
			const updatedIndex = state.files.length + 1
			// Merges the current item list and the payload array with a faster function (over spread operator)

			state.files = state.files.concat(action.payload)
			state.index = updatedIndex

			return state
		},
	}
})

export default files.reducer
export const {
	resetFiles,
	insertIntoFiles
} = files.actions
