import { useState } from "react"
// Icons
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Notice from "../../../../../shared/components/custom/Notice/Notice"

import InvoicesTableHeader from "./InvoicesTableHeader"
import InvoicesTableBody from "./InvoicesTableBody"
import Pagination from "src/shared/components/custom/Pagination/Pagination"

export default function InvoicesTable({
	invoices
}) {
	const [currentInvoices, setCurrentInvoices] =  useState(invoices)

	return (
		(invoices.length === 0) ? (
			<Notice>
				<FontAwesomeIcon icon={faFileInvoice} />
				<span>Você não possui nenhuma nota emitida</span>
			</Notice>
		) : (
			<div className="table-wrapper">
				<Pagination children={invoices} setCurrentArray={setCurrentInvoices}/>
				<table>
					<InvoicesTableHeader />
					<tbody>
						<InvoicesTableBody invoices={currentInvoices} />
					</tbody>
				</table>
			</div>
		)
	)
}
