import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Utils
import insertItem from "../../services/insertItem"

// Store
import { RootState } from "../../../../../../shared/store/reducers"
import { updateWorkableItemIndex } from "../../../../../../shared/store/slices/workableItemsSlice"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import MainButton from "../../../../../../shared/components/standard/MainButton"
import AssociateItemModal from "../modals/AssociateItemModal/AssociateItemModal"

// Styles
import "./XmlItemCard.css"

type CardItemsProps = {
	index: number
	children: ReactNode
	disabled: boolean
	setAddedItems: Dispatch<SetStateAction<boolean[]>>
}

export default function XmlItemCard({
	index,
	children,
	disabled,
	setAddedItems
}: CardItemsProps) {

	const dispatch = useDispatch()

	// Store
	const workableItem = useSelector((state: RootState) => state.workableItems.items[index]?.item)
	const workableItems = useSelector((state: RootState) => state.workableItems)
	const workableImages = useSelector((state: RootState) => state.workableItems.items[index]?.images)
	const items = useSelector((state: RootState) => state.items)
	const user = useSelector((state: RootState) => state.user)

	// States
	const [hide, setHide] = useState(true)
	const [renderAssociateModal, seRenderAssociateModal] = useState(false)
	const [itemVinculated, setItemVinculated] = useState(false)

	// Handlers
	function handleEdit() {
		dispatch(updateWorkableItemIndex(index))
		setHide(hide => !hide)
	}
	async function handleInsert() {
		try {
			dispatch(updateWorkableItemIndex(index))
			await await insertItem(
				workableItem,
				workableImages,
				workableItem.accounting.taxation,
				{
					ncm: workableItem.accounting.details.ncm,
					cest: workableItem.accounting.details.cest,
					origem: workableItem.accounting.details.origin
				},
				user.alias
			)
			setHide(true)
			seRenderAssociateModal(false)
			setAddedItems(addedItems => addedItems.map((item, itemIndex) => (index === itemIndex) ? true : item))
		} catch (error) {
			console.error("INSERT XML ITEM ERROR", error)
		}
	}
	function onRenderModal() {
		dispatch(updateWorkableItemIndex(index))
		seRenderAssociateModal(renderModal => !renderModal)
	}


	// Effects
	useEffect(()=>{

		setHide(!(index === workableItems.index))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[workableItems.index])

	return (
		<div className={`card-items-border ${disabled === true ? "card-disabled" : ""}`}>
			{(hide === false) && (
				<>
					{children}
					<div className="flex medium-gap fill-normal small-margin-bottom">
						<MainButton onClick={() => handleInsert()}>
							<span>Criar item</span>
						</MainButton>
					</div>
				</>
			)}
			{renderAssociateModal === true && (
				<AssociateItemModal
					onRenderModal={onRenderModal}
					onVinculate={() => setItemVinculated(true)}
				/>
			)}

			<div className="flex justify-content-space-between">
				<div className="grid-columns medium-gap">
					<span className={hide ? "heavy-font big" : "heavy-font big"}>{workableItem?.control.description}</span>
				</div>

				<div className="flex medium-gap fill-normal">
					<MainButton hierarchy="inferior" onClick={() => handleEdit()}>
						<FontAwesomeIcon icon={faEdit as Icon} />
						<span>{hide ? "Expandir" : "Recolher"}</span>
					</MainButton>
					{items.length > 0 && (
						<MainButton hierarchy="low" onClick={() => onRenderModal()} disabled={itemVinculated}>
							<span>Vincular à item</span>
						</MainButton>
					)}
				</div>
			</div>

		</div>
	)
}
