import { useEffect, useState } from "react"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"
import SelectedTag from "src/shared/components/custom/SelectedTag/SelectedTag"

export default function IncludeNcmModal({
	setIncludeNcm,
	includeNcm,
	onRenderModal
 }) {

	// States
	const [ncm, setNcm] = useState("")
	const [selectedIncludesNcm, setSelectedIncludesNcm] = useState([])

	const [ncmAlreadyIncluded, setNcmAlreadyIncluded] = useState(false)

	// Handlers
	const handleAddNcm = (newNcm) => {
		setSelectedIncludesNcm(prevState => ([...prevState, newNcm]))
		setNcm("")
	}
	const handleRemoveNcm = (removedNcm) => {
		setSelectedIncludesNcm(prevState => ([...prevState.filter(ncm => ncm !== removedNcm)]))
	}

	// Effects
	useEffect(() => {
		if (selectedIncludesNcm.includes(ncm) || includeNcm.includes(ncm)) {
			setNcmAlreadyIncluded(true)
		} else {
			setNcmAlreadyIncluded(false)
		}
	}, [ncm, selectedIncludesNcm, includeNcm])

	return (
		<Modal onClose={() => onRenderModal()}>
			<div className="modal-container flex flex-column medium-gap">

				<div className="modals-header">
					<span className="big-x bold-font">Incluir NCM</span>
				</div>

				<div className="flex align-items-flex-end medium-gap wide">
					<div className="wide">
						<TextInput
							label="ncm"
							value={ncm}
							name="ncm"
							onChange={e => setNcm(e.target.value)}
						/>
					</div>
					<MainButton
						onClick={() => handleAddNcm(ncm)}
						disabled={ncmAlreadyIncluded}
					>
						<span>Adicionar</span>
					</MainButton>
				</div>

				{ncmAlreadyIncluded && (
					<span className="red bold-font small">NCM já incluso</span>
				)}

				<div className="flex small-gap flex-wrap">
					{selectedIncludesNcm.map((ncm, index) => (
						<SelectedTag
							key={index}
							tagName={ncm}
							activeTagRemove={true}
							onTagRemove={() => handleRemoveNcm(ncm)}
						/>
					))}
				</div>

				<div className="wide wide-children medium-margin-top">
					<MainButton
						onClick={() => {
							setIncludeNcm([...selectedIncludesNcm, ...includeNcm])
							setSelectedIncludesNcm([])
							onRenderModal()
						}}
					>
						<span>Concluir</span>
					</MainButton>
				</div>
			</div>
		</Modal>
	)
}
