import { createSlice } from "@reduxjs/toolkit"

// Models
import UserModel from "../../models/user"

const initialState = {} as UserModel

const supplierSlice = createSlice({
	name: "supplier",
	initialState,
	reducers: {
		setSupplier: (state, action) => {
			return state = action.payload
		},
		resetSupplier: state => {
			return state = initialState
		},
	}
})

export default supplierSlice.reducer
export const { setSupplier, resetSupplier } = supplierSlice.actions
