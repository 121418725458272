import { useState } from "react"
import { useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"
import FileInput from "src/shared/components/standard/FileInput"
import { File } from "buffer"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { createToast } from "src/shared/store/slices/toastsSlice"



export default function CertificateModal() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// States
	const [renderCertificateModal, setRenderCertificateModal] = useState(false)
	const [certificate, setCertificate] = useState<File>()
	const [certificatePassword, setCertificatePassword] = useState("")


	function setFile(e :React.ChangeEvent<HTMLInputElement>) {
		const files = e.target.files
		if (files) {
			const file = files[0]
			setCertificate(file as unknown as File)
		}
	}

	async function handleUploadDigitalCertificate() {
		try {
			const token = getToken()
			const body = new FormData()
			body.append("a1", certificate as Blob)
			body.append("password", certificatePassword)

			const url = `${process.env.REACT_APP_API_URL}/resource/supplier/certificates/a1`

			const response = await fetch(url,
			{
				method: "PUT",
				body,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			responseCodeHandler(response)

			if (response.ok){
				dispatch(createToast({
					tone: "success",
					miliseconds: 5000,
					message: "Certificado atualizado com sucesso",
					dismissable:true
				}))
			} else {
				throw new Error(`HTTP error status ${response.status}`)
			}
		} catch(error){
			console.error(error)
		}
	}

	function truncateString(str: string, len: number) {
		return str.length > len ? (str.slice(0, len) + "...") : str
	}

	return (
	<>
		{renderCertificateModal === true && (
			<Modal title="Atualização do certificado digital A1" onClose={() => setRenderCertificateModal(false)}>
				<div className="grid medium-gap">
					<FileInput
						hierarchy="low"
						accept=".p12,.pfx"
						onChange={e => setFile(e)}
					>
						<span>
						{certificate === null || certificate === undefined
							? "Carregar arquivo PFX"
							: `${truncateString(certificate.name, 27)}`}
						</span>
					</FileInput>

					<TextInput
						label="Senha do certificado"
						value={certificatePassword}
						onChange={e => setCertificatePassword(e.target.value)}
					/>
				</div>

				<MainButton onClick={() => {
					setRenderCertificateModal(false)
					handleUploadDigitalCertificate()
				}}>
					<span>Configurar certificado</span>
				</MainButton>
			</Modal>)
		}

		<MainButton hierarchy="low" onClick={() => setRenderCertificateModal(true)}>
			<span>Configurar certificado digital A1</span>
		</MainButton>
	</>
	)
}
