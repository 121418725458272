
const itemTemplate = {
	item: "",
	createdAt: 0,
	updatedAt: 0,
	active: true,
	sellable: false,
	variant: "",
	media: { images: [] },
	groups: { category: "" },
	origin: { model: "", manufacturer: "" },
	costs: {
		fixed: 0,
		variable: 0,
		production: 0,
		sudden: { custom: true, value: 0 },
	},
	batch: { series: "", shelfLife: { producedAt: 0, expiresAt: 0 } },
	control: {
		markup: 0,
		sku: "",
		barcode: "",
		measure: "UNID",
		returnable: false,
		quantity: 0,
		description: "",
		available: true,
		autoDeactivatable: false,
		fractionable: false,
	},
	logistics: {
		stackable: true,
		dimensions: [{ type: "weight", value: 0, unit: "kg" }],
		location: [],
	},
	discount: { quota: 0, custom: true, percentage: 0 },
	accounting: {
		active: false,
		details: {
			cest:"",
			ncm:"",
			origin:"0"
		},
		rules: {
			cfop: "",
			icms: {
				cst: "",
				origin: 0,
				rate: "",
				st: {
					mva: "",
					rate: "",
					calculationBasis: "",
					calculationBasisModality: "",
				},
				calculationBasis: "",
				calculationBasisModality: "",
				icmssn1number: { csosn: "", orig: "" },
			},
			pis: { in: { cst: "", rate: "" }, out: { cst: "", rate: "" } },
			cofins: {
				in: { cst: "", rate: "" },
				out: { cst: "", rate: "" },
			},
			ipi: {
				framingCode: "",
				framingClass: "",
				rate: "",
				controlCode: "",
				producerCnpj: "",
			},
		},
	},
}

export default itemTemplate
