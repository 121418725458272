import { useDispatch } from "react-redux";
import { createToast } from "../store/slices/toastsSlice";
import { useNavigate } from "react-router-dom";
import { setAuthenticated } from "../store/slices/sessionSlice";

const useResponseCodeHandler = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleResponseCode = (responseCodeHandler:Response)=> {
		if (responseCodeHandler){
			if (!responseCodeHandler.ok){
				if (responseCodeHandler.status === 401){
					dispatch(createToast({
						tone: "failure",
						miliseconds: 8000,
						message: "Sua expirou sessão de fazer o login novamente",
						dismissable: true,
					}))
					dispatch(setAuthenticated(false))
					navigate("/authorization")

				} else {
					dispatch(createToast({
							tone: "failure",
							miliseconds: 2000,
							message: "Algo deu errado, tente novamente.",
							dismissable: true,
					}))
				}
			}

		}
	}

	return handleResponseCode;
};

export default useResponseCodeHandler;
