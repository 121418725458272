// Services
import { getToken } from "src/shared/services/general"

// Models
import ItemModel from "../../../../../shared/models/item"

export default async function insertItem(
	item: ItemModel,
	images: File[],
	taxation: string,
	details: {ncm: string, cest: string, origem: string},
	alias: string
) {
	try {
		const token = getToken()
		const multipart = new FormData()

		if (taxation === "" || taxation === undefined){
			taxation = await handleSelectActiveRegra()
			if (taxation === ""){
				taxation = await getTaxations()
			}
		}

		multipart.append("alias", alias )
		multipart.append("item", JSON.stringify(item))
		multipart.append("taxation", JSON.stringify(taxation))
		multipart.append("details", JSON.stringify(details))
		images.forEach(image => multipart.append("images", image))

		const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/item`, {
			method: "POST",
			headers: { Authorization: `Bearer ${token}` },
			body: multipart,
		})

		if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

	} catch (error) {
		console.error("INSERT ITEM ERROR", error)
	}
}


const getTaxations = async () => {
	try {
		const token = getToken()

		const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br`, {
			headers: { Authorization: `Bearer ${token}` },
		})

		if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

		const taXRules = await response.json()

		return taXRules[0] && taXRules[0].entry
	} catch (error) {
		console.error("GET TAX RULES ERROR", error)
	}
}

const handleSelectActiveRegra = async () =>{

	try {
		const token = getToken()
		const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br/active`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			}
		})

		if (!response.ok)
			throw new Error(`HTTP error status ${response.status}`)
		const data = await response.json()


		return data

	}
	catch (error) { console.error(error) }
}
