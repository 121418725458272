// Library imports
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

// Store
import { RootState } from "src/shared/store/reducers"

// Components
import CscModal from "src/modules/supplier/account/editor/components/modals/CscModal"
import NfeModal from "src/modules/supplier/account/editor/components/modals/NfeModal"
import EnvironmentModal from "src/modules/supplier/account/editor/components/modals/EnvironmentModal"
import CertificateModal from "src/modules/supplier/account/editor/components/modals/CertificateModal"
import TaxDefinitionModal from "src/modules/supplier/account/editor/components/modals/TaxDefinitionModal"
import RegimeTributarioHandler from "src/modules/supplier/account/editor/handlers/RegimeTributarioHandler"
import InscricaoModal from "src/modules/supplier/account/editor/components/modals/InscricoesModal"

import MainButton from "src/shared/components/standard/MainButton"

// Hooks
import { actionComponentList } from "src/modules/general/actions/useEvaluateActions"

export default function TaxationSetup() {

	const navigate = useNavigate()

	// Store
	const user = useSelector((state: RootState) => state.user)
	const session = useSelector((state: RootState) => state.session)

	const [isCompleted, setIsCompleted] = useState(false)

	useEffect(() => { // Whenever the evaluation function is satisfied, it shows a button that goes back to where the user was
		if (
			actionComponentList
			.find(component => component.name === "TAXATION_SETUP")
			?.evaluationFunction(user, session)
		)
			setIsCompleted(true)
	}, [user])

	return isCompleted === false ? (
			<div className="grid huge-gap">

				<span className="big-xxx bold-font">Nossa jornada <b>juntos</b> está prestes a começar.</span>
				<div>
					<span className="big-x bold-font">Precisamos saber mais algumas informações do seu negócio para você poder usar seu Materiapp à vontade.</span>
					<span className="big-x bold-font">É uma etapa importantíssima, preencha com a ajuda do seu contador.</span>
				</div>

				<RegimeTributarioHandler/>

				<div className="grid fill-huge small-gap">
					<CertificateModal/>
					<EnvironmentModal/>
					<NfeModal/>
					<CscModal/>
					<TaxDefinitionModal/>
					<InscricaoModal/>
				</div>

			</div>

		) : (

			<div className="grid medium-gap">
				<span className="big-xxx bold-font">Tudo pronto por aqui.</span>
				<div>
					<MainButton onClick={() => navigate(-1)}>
						<span>Avançar</span>
					</MainButton>
				</div>
			</div>

		)
}
