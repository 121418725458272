import { memo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import { toFixedImproved } from "offiziersmesser/lib/utils"
import { calculateItemCost, calculateItemPrice } from "offiziersmesser/lib/helpers"

// Store
import { setItems } from "src/shared/store/slices/itemsSlice"
import { updateItem } from "src/shared/store/helpers/items"
import { insertIntoWorkableItems, resetWorkableItems } from "src/shared/store/slices/workableItemsSlice"

// Icons
import {
	faTag,
	faTrashAlt,
	faTrashRestoreAlt,
	faPen
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import LinkButton from "src/shared/components/standard/LinkButton"
import MainButton from "src/shared/components/standard/MainButton"

import GenerateItemBarcodeModal from "../../modals/GenerateItemBarcodeModal/GenerateItemBarcodeModal"

function ItemsTableRow({
	item
}) {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const items = useSelector(state => state.items)

	// States
	const [renderBarcodeModal, setRenderBarcodeModal] = useState(false)

	// Functions
	async function toggleItemActivity() {
		try {
			const multipart = new FormData()
			multipart.append("active", !item.active)
			multipart.append("item", item.item)
			const token = getToken()

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/resource/supplier/item/activity`,
				{
				method: "PUT",
				headers: { "Authorization": `Bearer ${token}` },
				body: multipart,
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const updatedItem = { ...item, active: !item.active }
			dispatch(setItems(updateItem(items, updatedItem)))

		} catch (error) {
			console.error("TOGGLE ITEM ACTIVITY ERROR -", error)
		}
	}
	function handleEditItem() {
		dispatch(resetWorkableItems())
		dispatch(insertIntoWorkableItems({ item: item, images: [] }))
	}

	return (
		<>
			{(renderBarcodeModal === true) && (
				<GenerateItemBarcodeModal
					onRenderModal={() => setRenderBarcodeModal(!renderBarcodeModal)}
					sku={item.control.sku}
					barcode={item.control.barcode}
				/>
			)}

			<tr>
				<td>
					<div className="flex small-gap">

						<LinkButton
							hierarchy="low"
							to={`/supplier/item/editor`}
							title="Editar"
							onClick={() => handleEditItem()}
						>
							<FontAwesomeIcon className="fa-icon" icon={faPen} />
						</LinkButton>

						<MainButton
							hierarchy="inferior"
							title={(item.active) ? "Desativar" : "Ativar"}
							onClick={() => toggleItemActivity()}
						>
							<FontAwesomeIcon className="fa-icon" icon={(item.active) ? faTrashAlt : faTrashRestoreAlt} />
						</MainButton>

						<MainButton
							hierarchy="inferior"
							title="Código de barras"
							onClick={() => setRenderBarcodeModal(renderBarcodeModal => !renderBarcodeModal)}
						>
							<FontAwesomeIcon className="fa-icon" icon={faTag} />
						</MainButton>
					</div>
				</td>

				<td>{item.control.sku}</td>

				<td>{item.control.description}</td>

				<td>{toFixedImproved(calculateItemCost(item), 2)}</td>

				<td>{toFixedImproved(calculateItemPrice(item), 2)}</td>

				{(item.control.quantity === null)
					? <td className="disabled"></td>
					: <td>{item.control.quantity}</td>
				}

				<td>{item.groups.category}</td>
			</tr>
		</>
	)
}


export default memo(ItemsTableRow)
