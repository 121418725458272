// Library imports
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setBalances } from "src/shared/store/slices/balancesSlice"

// Components
import OpenBalanceBlock from "./OpenBalanceBlock"
import BalancesResume from "./resume/BalancesResume"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Styles
import "./Balances.css"

export default function SellerBalances() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const balances = useSelector(state => state.balances)

	// States
	const [balancesOpen, setBalancesOpen] = useState(false)
	const [cashbox, setCashbox] = useState(null)
	const [initialValue, setInitialValue] = useState(0)

	// Effects
	useEffect(() => {

		const getActiveBalance = async () => {
			try {
				const token = getToken()

				// verify if exists one balances active
				const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/seller/balance/active`, {
					headers: { Authorization: `Bearer ${token}` }
				})
				responseCodeHandler(response)
				if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				if (response.status === 200){
					const balances = await response.json()
					const responseBalanceData = await fetch(`${process.env.REACT_APP_API_URL}/resource/seller/cashbox/${balances.cashbox}`, {
						headers: { Authorization: `Bearer ${token}` }
					})

					responseCodeHandler(response)
					if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

					const balanceData = await responseBalanceData.json()

					if (!!balanceData){
						dispatch(
							setBalances({
								...balances,
								transactions: {
									entry: balanceData?.entry,
									outflow: balanceData?.outflow,
								},
							})
						)
					}

				}
			}
			catch (error) {
				console.error(error)
			}
		}

		getActiveBalance()
		// getCashboxesSeller()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const openBalance = async () => {
		try {
			const token = getToken()
			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/seller/balance",{
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					createdAt: Date.now(),
					updatedAt: Date.now(),
					base: initialValue,
					transactions: [],
					cashbox,
					note: "",
				}),
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const balanceData = await response.json()

			dispatch(
				setBalances({
					...balanceData,
					transactions: {
						entry: [],
						outflow: [],
					},
				})
			)

		}
		catch(error){
			console.error(error)
		}
	}
	useEffect(() => {
		if (balancesOpen) {
			openBalance()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [balancesOpen])

	return (
		<SupplierRoleNavbar>
			<div className="balances-container">
				<div className="balances-content">
					{!!balances.cashbox ? (
						<BalancesResume
							transactions={balances.transactions}
							cashbox={balances.cashbox}
						/>
					) : (
						<OpenBalanceBlock
							selectedCashbox={cashbox}
							initialValue={initialValue}
							setCashbox={value => setCashbox(value)}
							setInitialValue={value => setInitialValue(value)}
							setBalancesOpen={value => setBalancesOpen(value)}
						/>
					)}
				</div>
			</div>
		</SupplierRoleNavbar>
	)
}
