// Library imports
import { toFixedImproved } from "offiziersmesser/lib/utils"
import { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import PaymentCardTransaction from "./PaymentCardTansaction"
import MainButton from "src/shared/components/standard/MainButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"



export default function PaymentListTransaction({
	indexPayment,
	parts,
	setParts,
	total,
}) {

	// Store
	const transaction = useSelector(state => state.transactions)
	// const totalPayment = transaction.payments[indexPayment].total

	// sum parts of payment
	const sumParts = useCallback((transaction) => {
		let sum = 0

		if (transaction.payments[indexPayment].parts.length === 0) return sum

		transaction.payments[indexPayment].parts.map((part) => {
			return sum += part.value
		})

		return sum
	}, [indexPayment])

	// States
	const [covered, setCovered] = useState(sumParts(transaction))

	// inital state part
	const [part, setPart] = useState({
		createdAt: Date.now(), // Manual set if transaction is being custom-created, auto if its a heir
		value: total - covered, // Partial (of full) value paid
		method: 0, // Lookup table storing how the payment was done (Cash, credit card, etc)
		deduction: 0,
		extra: 0,
	})

	// Effects
	useEffect(() => {
		setParts([part])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		setPart({
			createdAt: Date.now(), // Manual set if transaction is being custom-created, auto if its a heir
			value: total - covered, // Partial (of full) value paid
			method: 0, // Lookup table storing how the payment was done (Cash, credit card, etc)
			deduction: 0,
			extra: 0,
		})
	}, [total, covered])
	// Update covered
	useEffect(() => {
		let sum = 0
		parts.map((part) => {
			return sum += part.value
		})
		setCovered(sum + sumParts(transaction))
	}, [parts, sumParts, transaction])

	return (
		<div className="grid small-gap">
			<div>
				{covered < total && covered !== 0 ? (
					<div className="grid-columns">
						<p>
							Especifique como pagar R$ <b>{toFixedImproved(total, 2)}</b>
							{" "}
							<span className="medium bold-font">
								(Valor restante R$ <b>{toFixedImproved(total - covered, 2)}</b>)
							</span>
						</p>
					</div>
				) : (
					<span> Especifique como pagar <b>R$ {toFixedImproved(total, 2)}</b></span>
				)}
			</div>

			<div className="CheckoutPaymentList-container">

				{parts.length > 0 && parts.map((part, index) => (
					<PaymentCardTransaction
						key={index}
						element={part}
						index={index}
						setParts={setParts}
						parts={parts}
						deleteable={parts.length > 1}
					/>
				))}

				<div className="flex align-items-center">
					{(covered < total) ? (
						<MainButton
							hierarchy="low"
							onClick={() => setParts([...parts, part])}
							// Do not let a new instance to be created if any existing one has a value of zero
							disabled={(parts?.some((element) => element.value === 0) === true)}
						>
							Criar pagamento
						</MainButton>
					) : (covered === total) ? (
						<span className="flex align-items-center small-gap big bold-font green">
							<FontAwesomeIcon
								className="fa-icon"
								icon={faCheckCircle}
							/>
							Pagamento especificado
						</span>
					) : (covered > total) ? (
						<span className="flex align-items-center small-gap center-items  big bold-font red">
							<FontAwesomeIcon
								className="fa-icon"
								icon={faTimesCircle}
							/>
							Pagamento excedendo fatura
						</span>
					) : ""}
				</div>
			</div>
		</div>
	)
}
