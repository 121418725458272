import { ChangeEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import { encodeFile } from "offiziersmesser/lib/utils"

// Store
import { RootState } from "src/shared/store/reducers"
import { setItems } from "src/shared/store/slices/itemsSlice"
import { insertIntoFiles, resetFiles } from "src/shared/store/slices/filesSlice"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faExclamationCircle, faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import FileInput from "src/shared/components/standard/FileInput"
import LinkButton from "src/shared/components/standard/LinkButton"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import Pagination from "src/shared/components/custom/Pagination/Pagination"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import ItemsTable from "./editor/components/tables/itemsTable/ItemsTable"

// Styles
import "./Items.css"
import { ItemModel } from "offiziersmesser/lib/models"
import SearchItems from "src/shared/components/custom/SearchItems/SearchItems"
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"


export default function Items() {

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// States
	const [searchResults, setSearchResults] = useState<ItemModel[]>([])

	// Store
	const user = useSelector((state: RootState) => state.user)
	const items = useSelector((state: RootState) => state.items)

	// States
	const [loading, setLoading] = useState(true)

	// Variables
	const categories = user.categories || []

	async function handleImportFile(event: ChangeEvent<HTMLInputElement>) {

		dispatch(resetFiles())

		try {
			const files = event.target.files as FileList
			const file = files[0]

			dispatch(insertIntoFiles(await encodeFile(file)))

			navigate("/supplier/item/import")
		} catch (err) {
			console.error(err)
		}
	}

	const getItems = async () => {
		try {
			setLoading(true)

			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/items`, {
				headers: { Authorization: `Bearer ${token}` }
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const items = await response.json()
			dispatch(setItems(items))
		} catch (error) {
			console.error("GET ITEMS ERROR", error)
		} finally {
			setLoading(false)
		}
	}

	// Effects
	useEffect(() => {
		getItems()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [currentItems, setCurrentItems] = useState(items)

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap">

				<PageTitle title="Itens" />

				{loading === true ? (
					<ComponentLoader />
				) : (
					(categories.length === 0) ? (
						<Notice tone="warning" to="/supplier/categories">
							<div className="grid-columns medium-gap left-items">
								<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle as Icon} />
								<div className="grid small-gap">
									<span className="small">Antes de criar um novo item para o seu estoque, você precisa criar uma categoria.</span>
									<div className="grid-columns small-gap left-items bold-font tiny">
										<span>Clique aqui para ir ao editor de categorias</span>
										<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt as Icon} />
									</div>
								</div>
							</div>
						</Notice>
					) : (
						(items.length === 0) ? (
							<>
								<Notice tone="info">
									<div className="grid-columns medium-gap left-items">
										<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle as Icon} />
										<div className="small">
											{/*<span>O mais poderoso gerenciamento de estoque te espera.</span>*/}
											<span>Comece adicionando um por um manualmente, ou vários itens de uma vez só através de um arquivo XML de nota fiscal.</span>
										</div>
									</div>
								</Notice>

								<div className="grid small-gap fill-large left-items">
									<LinkButton to="/supplier/item/new">
										<FontAwesomeIcon className="fa-icon" icon={faPlus as Icon} />
										<span>Criar item</span>
									</LinkButton>

									<FileInput
										accept=".zip,.xml"
										onChange={e => handleImportFile(e)}
										multiple={false}
									>
										<FontAwesomeIcon className="fa-icon" icon={faPlus as Icon} />
										<span>Importar via XML ou ZIP</span>
									</FileInput>
								</div>
							</>
						) : (
							<>
								<div className="grid medium-gap fill-huge left-items">
									<SearchItems setSelectedItems={setSearchResults} isStore={false}/>
									<Pagination elements={(searchResults.length > 0) ? searchResults : items} setCurrentElements={setCurrentItems}/>

									<div className="flex small-gap">

									<LinkButton to="/supplier/item/new">
										<FontAwesomeIcon className="fa-icon" icon={faPlus as Icon} />
										<span>Criar item</span>
									</LinkButton>

									<FileInput
										accept=".xml,.zip"
										onChange={e => handleImportFile(e)}
										multiple={false}
									>
										<FontAwesomeIcon className="fa-icon" icon={faPlus as Icon} />
										<span>Importar XML ou ZIP</span>
									</FileInput>
									</div>

								</div>

								<ItemsTable items={currentItems}/>
							</>
						)
					)
				)}
			</div>
		</SupplierRoleNavbar>
	)
}
