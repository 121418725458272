import { createSlice } from "@reduxjs/toolkit";

const ordersSlice = createSlice({
	name: "orders",
	initialState: [],
	reducers: {
		setOrders: (state: any, action: any) => {
			return state = action.payload
		},
		updateOrder: (state: any, action: any) => {
			return state = state.map((order: any) => {
				if (order.record === action.payload.record) return action.payload
				return order
			})
		}
	}
})

export default ordersSlice.reducer
export const { setOrders, updateOrder } = ordersSlice.actions
