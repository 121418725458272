import { useMemo } from "react"
import { useSelector } from "react-redux"


// Components
import TaxationSetup from "src/modules/general/actions/views/TaxationSetup"


const taxationSetup = {
	name: "TAXATION_SETUP",
	critical: false, // Critical conditions will override navigation to the action screen
	functionComponent: TaxationSetup,
	evaluationFunction: (user, session) => {
		return (
			!!user && !!session
			&&
				(session.stakeholder === "supplier" || session.stakeholder === "employee")
			&&
				user.hasOwnProperty("taxation")
			&&
			( // Only supplier users have the taxation property
				user.taxation.inscricoes.municipal != "" &&
				user.taxation.inscricoes.estadual != "" &&
				user.taxation.inscricoes.estadualSt != ""
			)
			&&
			(
				user.taxation.nfSettings.NnfH.serie == "" ||
				user.taxation.nfSettings.NnfH.lote == "" ||
				user.taxation.nfSettings.NnfH.nnf == ""
			)
			&&
			(
				user.taxation.nfSettings.NnfP.serie == "" ||
				user.taxation.nfSettings.NnfP.lote == "" ||
				user.taxation.nfSettings.NnfP.nnf == ""
			)
			&&
				user.sefaz.regimeTributario == ""
		)
	}
}

const actionComponentList = [
	taxationSetup,
]

// Custom hook that executes evaluation functions with current state
const useEvaluateActions = () => {
	const user = useSelector(state => state.user)
	const session = useSelector(state => state.session)

	const someActionComponentEvaluatesTrue = useMemo(() =>
		() => actionComponentList.some(action => action.critical && action.evaluationFunction(user, session)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[user]
	)

	const filterForActionableComponents = useMemo(() =>
		() => actionComponentList.filter(action => action.evaluationFunction(user, session)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[user]
	)

	return { someActionComponentEvaluatesTrue, filterForActionableComponents }
}

export default useEvaluateActions
export {
	actionComponentList
}
