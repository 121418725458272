import { ReactNode } from "react"
import { Link } from "react-router-dom"

// Style CSS
import "./Notice.css"

type NoticeProps = {
	to?: string
	tone?: "success" | "info" | "warning" | "failure"
	size?: string
	onClick?: () => void
	children?: ReactNode
}
export default function Notice({
	to,
	tone,
	size,
	onClick,
	children
}: NoticeProps) {
	const className = `${tone === "warning" ? "bg-gilded" :
		tone === "success" ? "bg-meadow" :
			tone === "failure" ? "bg-crimson" :
				tone === "info" ? "bg-royal" :
					(to || onClick) ? "bg-midblack" : "bg-black" // Black is only used in non-clickable text throughout the platform
		} ${size === "tiny" ? "small-gap tiny-padding-vertical small-padding-horizontal border-radius-small-quarter" :
			size === "small" ? "small-gap small-padding border-radius-small-half" :
				size === "large" ? "large-gap large-padding border-radius-big" :
					"medium-gap medium-padding border-radius-small-half"
		}`

	return (
		(to || onClick) ?
			to ?
				<Link
					className={`Notice grid unselectable cursor-pointer ${className}`}
					to={to}
				>
					{children}
				</Link> :
				<div
					className={`Notice grid unselectable cursor-pointer ${className}`}
					onClick={onClick}
				>
					{children}
				</div> :
			<div className={`Notice grid ${className}`}>
				{children}
			</div>
	)
}
