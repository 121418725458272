import { createSlice } from "@reduxjs/toolkit";

const invoicesSlice = createSlice({
	name: "invoices",
	initialState: [],
	reducers: {
		setInvoices: (state, action) => {
			return state = action.payload
		},
		createInvoice: (state, action) => {
			return state = [...state, action.payload]
		}
	}
})

export default invoicesSlice.reducer
export const { setinvoices, createInvoice } = invoicesSlice.actions
