// Model
import { UserModel, ReferenceModel, LocalityModel } from "offiziersmesser/lib/models"

// Components
import StepForm from "src/shared/components/custom/StepForm/StepForm"
import MainButton from "src/shared/components/standard/MainButton"
import LocalityForm from "src/shared/components/custom/Forms/LocalityForm/LocalityForm"
import StepContainer from "src/shared/components/custom/StepForm/StepContainer"
import ReferenceForm from "src/shared/components/custom/Forms/ReferenceForm/ReferenceForm"
import PassphraseInputValidationStatusesGroup from "src/shared/components/custom/PassphraseInputValidationStatusesGroup/PassphraseInputValidationStatusesGroup"

import PhoneValidationForm from "../components/PhoneValidationForm"

// Handlers
import finishRegistration from "../services/finishAuthorization"
import TextInput from "src/shared/components/standard/TextInput"
// import SefazForm from "src/shared/components/custom/Forms/SefazForm/SefazForm"

import beginPhoneVerification from "../services/beginPhoneVerification"


type SupplierRegistrationProps = {
	user: UserModel
	taxpayer: string
	passphrase: string
	setPassphrase: (value: string) => void
	isPassphraseStrong: boolean
	setIsPassphraseStrong: (value: boolean) => void
	characters: string[]
	setCharacters: (value: string[]) => void
	byname: string
	setByname: (value: string) => void
	company: string
	setCompany: (value: string) => void
	// sefaz: SefazModel
	// setSefaz: (value: SefazModel) => void
	// contact: PhoneModel
	// setContact: (value: PhoneModel) => void
	isSupplier: boolean
	setIsSupplier: (value: boolean) => void
	reference: ReferenceModel
	setReference: (value: ReferenceModel) => void
	locality: LocalityModel
	setLocality: (value: LocalityModel) => void
	setSupplierRegistration: (value: boolean) => void
	isCodeSent: boolean
	setIsCodeSent: (value: boolean) => void
	isCodeSentTwice: boolean
	setIsCodeSentTwice: (value: boolean) => void
	completeSupplierAuthorization: () => void
}

export default function SupplierRegistration({
	user,
	taxpayer,
	passphrase, setPassphrase,
	isPassphraseStrong, setIsPassphraseStrong,
	characters, setCharacters,
	// sefaz, setSefaz,
	// contact, setContact,
	isSupplier, setIsSupplier,
	byname, setByname,
	company, setCompany,
	reference, setReference,
	locality, setLocality,
	setSupplierRegistration,
	isCodeSent, setIsCodeSent,
	isCodeSentTwice, setIsCodeSentTwice,

	completeSupplierAuthorization
}: SupplierRegistrationProps) {

	const { phone } = reference

	return (
		<div className="grid medium-gap">
			<div className="grid left-items">
				<MainButton
					hierarchy="inferior"
					onClick={() => setSupplierRegistration(false)}>
					<span>Voltar ao início</span>
				</MainButton>
			</div>

			<StepForm
				componentTitle="Sua empresa de conta nova, rapidinho"
				onClickConclude={async () => {
					await finishRegistration(user, passphrase, characters) //, sefaz)
					await completeSupplierAuthorization()
					// Reset variable states
					setSupplierRegistration(false)
					setPassphrase("")


					// const { payload, token } = await authorizeSupplier(taxpayer, passphrase)

					// // Set user-related data
					// sessionStorage.setItem("alias", payload.alias)
					// sessionStorage.setItem("token", token) // Auto-authentication during refresh

					// dispatch(setUser(payload))
					// dispatch(setAuthenticated(true))

					// // Reset variable states
					// setSupplierRegistration(false)
					// setPassphrase("")

					// // Redirect page
				    // navigate("/supplier/account")
				}}
			>
				<StepContainer
					stepCondition={
						!!byname
						&& !!company
						// && !!sefaz.inscricaoMunicipal
						// && !!sefaz.inscricaoEstadual
						// && !!sefaz.inscricaoEstadualST
					}
				>
					<div className="grid medium-gap">
						<div className="grid medium-gap fill-normal">
							<TextInput
								name="company"
								value={company}
								label="Razão social"
								placeholder="Mota Lima Tintas LTDA"
								onChange={e => setCompany(e.target.value)}
							/>
							<TextInput
								name="byname"
								value={byname}
								label="Nome fantasia"
								placeholder="Tintas ML"
								onChange={e => setByname(e.target.value)}
							/>
						</div>

{/*						<SefazForm
							formTitle="Dados fiscais"
							sefaz={sefaz}
							setSefaz={setSefaz}
						/>*/}

					</div>
				</StepContainer>

				<StepContainer
					stepCondition={
						(locality.road !== "")
						&& (locality.number !== "")
						&& (locality.area !== "")
						&& (locality.postal !== "")
						&& (locality.city !== "")
						&& (locality.region !== "")
					}
				>
					<LocalityForm
						formTitle="O endereço do seu negócio"
						locality={locality}
						setLocality={setLocality}
					/>
				</StepContainer>

				<StepContainer
					stepCondition={
						(reference.name !== "")
						&& (reference.surname !== "")
						&& (phone.destination !== "")
						&& (phone.subscriber.length === 9)
					}
				>
					<ReferenceForm
						formTitle="Sua empresa"
						reference={reference}
						setReference={setReference}
					/>
				</StepContainer>

				<StepContainer stepCondition={isPassphraseStrong === true}>
					<PassphraseInputValidationStatusesGroup
						passphrase={passphrase}
						setPassphrase={setPassphrase}
						setIsPassphraseStrong={setIsPassphraseStrong}
					/>
				</StepContainer>

				<StepContainer
					fireOnMount={
						() => {
							beginPhoneVerification({
								phone,
								isCodeSent,
								setIsCodeSent,
								setIsCodeSentTwice
							})
						}
					}
				>
					<PhoneValidationForm
						characters={characters}
						phone={phone}
						isCodeSent={isCodeSent}
						isCodeSentTwice={isCodeSentTwice}
						setCharacters={setCharacters}
						setIsCodeSent={setIsCodeSent}
						setIsCodeSentTwice={setIsCodeSentTwice}
					/>
				</StepContainer>

			</StepForm>
		</div>
	)
}
