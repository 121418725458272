// Components
import Pagination from "src/shared/components/custom/Pagination/Pagination"
import OrdersTableHeader from "./OrdersTableHeader"
import OrdersTableRow from "./OrdersTableRow"
import { useState } from "react"

export default function OrdersTable({
	orders,
	userType
}) {
	const [currentOrders, setCurrentOrders] = useState(orders)

	return (
		<div className="table-wrapper">
			<Pagination elements={orders} setCurrentElements={setCurrentOrders}/>
			<table>
				<thead>
					<OrdersTableHeader />
				</thead>
				<tbody>
					{currentOrders?.map((order, index) => (
						<OrdersTableRow key={index} order={order} userType={userType} />
					))}
				</tbody>
			</table>
		</div>
	)
}
