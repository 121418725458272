
// Models
import { UserModel, LocalityModel, ReferenceModel } from "offiziersmesser/lib/models"

// Components
import StepForm from "src/shared/components/custom/StepForm/StepForm"
import MainButton from "src/shared/components/standard/MainButton"
import StepContainer from "src/shared/components/custom/StepForm/StepContainer"
import PassphraseInputValidationStatusesGroup from "src/shared/components/custom/PassphraseInputValidationStatusesGroup/PassphraseInputValidationStatusesGroup"
import ReferenceForm from "src/shared/components/custom/Forms/ReferenceForm/ReferenceForm"
import LocalityForm from "src/shared/components/custom/Forms/LocalityForm/LocalityForm"

import PhoneValidationForm from "../components/PhoneValidationForm"

// Handlers
import finishRegistration from "../services/finishAuthorization"

type EmployeeRegistrationProps = {
	user: UserModel
	alias: string
	taxpayer: string
	passphrase: string
	setPassphrase: (value: string) => void
	isPassphraseStrong: boolean
	setIsPassphraseStrong: (value: boolean) => void
	characters: string[]
	setCharacters: (value: string[]) => void
	reference: ReferenceModel
	setReference: (value: ReferenceModel) => void
	locality: LocalityModel
	setLocality: (value: LocalityModel) => void
	isCodeSent: boolean
	setIsCodeSent: (value: boolean) => void
	isCodeSentTwice: boolean
	setIsCodeSentTwice: (value: boolean) => void
	setEmployeeRegistration: (value: boolean) => void
	completeEmployeeAuthorization: () => void
}

export default function EmployeeRegistration({
	user,
	passphrase, setPassphrase,
	isPassphraseStrong, setIsPassphraseStrong,
	characters, setCharacters,
	reference, setReference,
	locality, setLocality,
	isCodeSent, setIsCodeSent,
	isCodeSentTwice, setIsCodeSentTwice,
	setEmployeeRegistration,
	completeEmployeeAuthorization
}: EmployeeRegistrationProps) {

	return (
		<div className="grid tiny-gap">
			<div className="grid left-items">
				<MainButton
					hierarchy="inferior"
					onClick={() => setEmployeeRegistration(false)}
				>
					<span>Voltar ao início</span>
				</MainButton>
			</div>

			<StepForm
				componentTitle="Comece a vender em até 2 minutos"
				onClickConclude={async () => {
					await finishRegistration(user, passphrase, characters)
					await completeEmployeeAuthorization()
					// Reset variable states
					setEmployeeRegistration(false)
					setPassphrase("")

					// const response = await authorizeEmployee(alias, taxpayer, passphrase)

					// // Set user-related data
					// // sessionStorage.setItem("alias", response.alias)
					// sessionStorage.setItem("token", response.token) // Auto-authentication during refresh
					// // if (response.payload.supplier)
					// // 	sessionStorage.setItem("supplier", response.payload.supplier)
					// dispatch(setAuthenticated(true))
					// dispatch(setUser(response.payload))
					// // dispatch(setSupplier(response.payload.account.supplier))

					// // Reset variable states
					// setEmployeeRegistration(false)
					// setPassphrase("")

					// // Redirect page
					// // if (employee === "seller")
					// 	navigate("/seller/balances")
					// // else
					// // 	navigate("/manager/relationships")
				}}
			>
				<StepContainer
					stepCondition={
						reference.name !== ""
						&& reference.surname !== ""
						&& reference.phone.subscriber.length === 9
					}
				>
					<ReferenceForm
						formTitle="Sua empresa"
						reference={reference}
						setReference={setReference}
					/>
				</StepContainer>

				<StepContainer
					stepCondition={
						(locality.road !== "")
						&& (locality.number !== "")
						&& (locality.area !== "")
						&& (locality.postal !== "")
						&& (locality.city !== "")
						&& (locality.region !== "")
					}
				>
					<LocalityForm
						locality={locality}
						setLocality={setLocality}
					/>
				</StepContainer>

				<StepContainer stepCondition={isPassphraseStrong === true} >
					<PassphraseInputValidationStatusesGroup
						passphrase={passphrase}
						setPassphrase={setPassphrase}
						setIsPassphraseStrong={setIsPassphraseStrong}
					/>
				</StepContainer>

				<PhoneValidationForm
					characters={characters}
					phone={reference.phone}
					isCodeSent={isCodeSent}
					isCodeSentTwice={isCodeSentTwice}
					setCharacters={setCharacters}
					setIsCodeSent={setIsCodeSent}
					setIsCodeSentTwice={setIsCodeSentTwice}
				/>
			</StepForm>
		</div>
	)
}
