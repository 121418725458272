// Icons
import { faCashRegister } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Utils
import { createTotalParts } from "offiziersmesser/lib/helpers"
import { PAYMENT_METHODS } from "offiziersmesser/lib/utils"

// Components
import Notice from "../../../../../shared/components/custom/Notice/Notice"

export default function BalanceOutflow({ payments }) {

	let totalParts = []
	let balanceEntry = []
	let balancesTotal = 0

	if (payments != null) {
		let parts = []

		payments.map((transaction) => {
			return parts = [...parts, ...transaction.payments[0].parts]
		})

		totalParts = createTotalParts(parts)

		balanceEntry = totalParts

		balancesTotal = balanceEntry
			.map((balances) => balances.value)
			.reduce((prev, curr) => prev + curr, 0)
	}

	return (
		<div className="register-wrapper flex flex-column wide" >

			{/* DISABLED BY NOW NEED TO BE FIXED */}
			{/* <div className="grid small-gap fill-normal small-margin-bottom">
				<LinkButton
					disabled={!payments}
					to={`/supplier/balances/details/outflow/`}
				>
					Detalhes das Saídas
				</LinkButton>
			</div> */}

			<div className="register-history seller-entry-history element-border wide small-margin-top">
				{(balanceEntry.length > 0) ? (
					<ul>
						{balanceEntry.map((balance, index) => {
							return (
								<li key={index} className="register-history-item">
									<span key={index} className="bold-font">
										{PAYMENT_METHODS.map((method, index) => {
											if (balance.method === index) return <>{method.name}</>
											return null
										})}
									</span>
									<span className="bold-font">
										- {balance.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
									</span>
								</li>
							)
						})}
					</ul>
				) : (
					<Notice>
						<FontAwesomeIcon icon={faCashRegister} />
						<span>Registrador não possui saída.</span>
					</Notice>
				)}
			</div>

			<div className="element-border wide small-margin-top">
				<ul>
					<li className="register-history-item">
						<span className="bold-font">Total</span>
						<span className="bold-font orange big">
							- {balancesTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
						</span>
					</li>
				</ul>
			</div>
		</div>
	)
}
