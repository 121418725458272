const variations = [
	"Ação",
	"Padrão (Ativa)",
	"Descrição",
	"Produtos",
	"CST",
	"Alíquota",
	"CFOP",
]

export default function AccountInvoicesHeader(props) {
	return (
		<tr>
			{variations.map((variation, index) => <th key={index}>{variations[index]}</th>)}
		</tr>
	)
}
