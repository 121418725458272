// Services
import { getToken } from "src/shared/services/general"

export default async function updateItemImage(
	item: string,
	images: string[]
){

    try {
		const token = getToken()

		// Updates user's category with the updated items referece
		const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/item/media/images/${item}`, {
			method: "put",
			body: JSON.stringify(images),
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json"
			},
		})

		if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

	} catch (error) {
		console.error("UPDATE MEDIA IMAGE ERROR -", error)
	}

}
