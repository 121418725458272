import TransactionModel from "src/shared/models/transaction";

export default function handleTransactions(transactions: TransactionModel[], addressedTo: "addressee" | "issuer") {

	return transactions.map(transaction => {


		if(transaction[addressedTo]){
			return ({
				...transaction,

				name: `${transaction[addressedTo].company === "" ? transaction[addressedTo].reference.name : transaction[addressedTo].company} ${transaction[addressedTo].reference.name !== "" ? transaction[addressedTo].reference.surname : ""}`,

				taxpayer: transaction[addressedTo].taxpayer,

				address: `${transaction[addressedTo].locality.road} ${transaction[addressedTo].locality.number}, ${transaction[addressedTo].locality.area}, ${transaction[addressedTo].locality.city}, ${transaction[addressedTo].locality.region}, ${transaction[addressedTo].locality.postal}`,

				phone: `+55 (${transaction[addressedTo].reference.phone.destination}) ${transaction[addressedTo].reference.phone.subscriber}`,
			})
		}else{
			return({
				...transaction,

				name: "Não informado",

				taxpayer: "",

				address: "",

				phone: ""

			})
		}


})
}
