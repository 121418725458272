import { RefObject, useEffect } from "react"

export default function useOutsideClick(
	ref: RefObject<any>,
	callback: ()=> void
) {
	useEffect(() => {
		const handleClick = (event: Event) => {
			if (ref.current
				&& !ref.current.contains(event.target)) {
				callback()
			}

		}

		document.addEventListener("click", handleClick, true)

		return () => {
			document.removeEventListener("click", handleClick, true)
		}
	}, [ref, callback])
}

// https://medium.com/@kevinfelisilda/click-outside-element-event-using-react-hooks-2c540814b661
// https://www.robinwieruch.de/react-hook-detect-click-outside-component/
