// Components
import { Dispatch, SetStateAction } from "react"
import NumberInput from "../../../../../../../shared/components/standard/NumberInput"
import SelectInput from "../../../../../../../shared/components/standard/SelectInput"
import { PAYMENT_METHODS, RETURN_REASONS_SHIPPING, RETURN_REASONS_ITEM_ISSUES, RETURN_REASONS_OTHER } from "offiziersmesser/lib/utils"

type ReturnreturnsProps = {
	order: any
	returnedInfoOrder: any
	setReturnedInfoOrder: Dispatch<SetStateAction<any>>
}

export default function Returnreturns({
	order,
	returnedInfoOrder,
	setReturnedInfoOrder
}: ReturnreturnsProps) {

    return (
		<div className="grid medium-gap">
			<span className="big-x bold-font">Descreva a devolução</span>

			<div className="grid small-gap">
				<div className="grid fill-large">
					<SelectInput
						label="Razão"
						value={returnedInfoOrder.reason}
						onChange={e => setReturnedInfoOrder({
								...returnedInfoOrder,
								reason: Number(e.target.value)
						})}
					>
						<option key={0} value="—" disabled={true}>Selecione uma razão</option>

						<option key={1} value="—1" disabled={true}>- Sobre entrega</option>
						{RETURN_REASONS_SHIPPING.map((element, index) => (
							<option key={index} value={element.index}>
								{element.reason}
							</option>
						))}
						<option key={2} value="—2" disabled={true}>- Sobre problemas</option>
						{RETURN_REASONS_ITEM_ISSUES.map((element, index) => (
							<option key={index} value={element.index}>
								{element.reason}
							</option>
						))}
						<option key={3} value="—3" disabled={true}>- Outras razões</option>
						{RETURN_REASONS_OTHER.map((element, index) => (
							<option key={index} value={element.index}>
								{element.reason}
							</option>
						))}
					</SelectInput>
				</div>
				<div className="grid fill-large">
					<SelectInput
						label="Método de devolução"
						value={returnedInfoOrder.method === "" ? "1" : returnedInfoOrder.method}
						onChange={e => setReturnedInfoOrder({
								...returnedInfoOrder,
								method: Number(e.target.value)
							})
						}
					>
						<option value="—" disabled={true}>Selecione um método</option>

						{PAYMENT_METHODS.map(method => (
							<option value={method.sefaz}>{method.name}</option>
						))}
					</SelectInput>
				</div>
				<div className="grid fill-large">
					<NumberInput
						label="Valor da devolução"
						placeholder="R$ 0,00"
						value={parseFloat(returnedInfoOrder.total.toFixed(2))}
						disabled={true}
						onChange={e => setReturnedInfoOrder({
							...order,
							total: e.target.value
						})
					}
					/>
				</div>
			</div>
		</div>
	)
}
