import { Dispatch, SetStateAction, useState } from "react"

// Models
import { PhoneModel } from "src/shared/models/user"

// Components
import MainButton from "src/shared/components/standard/MainButton"

import NewEndorsementModal from "./NewEndorsementModal"

type EndorsementCardProps = {
	endorsementIndex: number
	endorsement: {
		reference: {
			name?: string
			surname?: string
			email?: string
			phone?: PhoneModel
		}
		note?: string
	}
	setEndorsements: Dispatch<SetStateAction<any>>
	removeEndorsements: (index:number)=> void
	updateEndorsements: (endorsement:any)=>void
}

export default function EndorsementCard({
	endorsementIndex,
	endorsement,
	setEndorsements,
	removeEndorsements,
	updateEndorsements
}: EndorsementCardProps) {

	// States
	const [renderEditReferenceModal, setRenderEditReferenceModal] = useState(false)

	// Handlers
	function handleRenderEditReferenceModal() {

		setRenderEditReferenceModal(prevState => !prevState)
	}

	return (
		<div className="element-border bottom grid medium-gap">

			{renderEditReferenceModal && (
				<NewEndorsementModal
					endorsementData={endorsement}
					editorMode={true}
					renderModal={() => handleRenderEditReferenceModal()}
					setEndorsements={setEndorsements}
					updateEndorsements={updateEndorsements}
					isUpdate={true}
				/>
			)}

			<div>
				<div className="flex justify-content-space-between">
					<span className="bold-font big">{endorsement.reference.name} {endorsement.reference.surname}</span>
				</div>
				<span className="small">
					{/* FIXME needs to receibe updated at */}
					{/* <span className="bold-font">Atualizado em</span> {endorsement.updateAt} */}
				</span>

				{/* <div className="grid tiny-gap-xx">
					<span>{phone.destination} {phone.subscriber}</span>
					<span>{email}</span>
				</div> */}
			</div>

			{/* <div className="grid tiny-gap-xx">
				<span className="">{locality.road}, {locality.number} - {locality.area}, {locality.city}, {locality.region} - {locality.postal}</span>
			</div> */}

			{/* <span className="bold-font small midblack">Ele e muito compromissado com o trabalho</span> */}

			<div className="grid-columns small-gap wide-children">
				<MainButton
					hierarchy="low"
					onClick={() => handleRenderEditReferenceModal()}
				>
					<span>Visualizar</span>
				</MainButton>
				<MainButton
					hierarchy="inferior"
					onClick={() => removeEndorsements(endorsementIndex)}
				>
					<div className="flex wide justify-content-center">
						<span>Excluir</span>
					</div>
				</MainButton>
			</div>
		</div>
	)
}

