import { ChangeEvent, FocusEvent } from "react"

// Common properties
type CommonProps = {
	id?: string
	name?: string
	type?: string
	label?: string
	value?: string
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void
	onFocus?: (event: FocusEvent<HTMLInputElement>) => void
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	required?: boolean
	disabled?: boolean
	className?: string
	maxLength?: number
	placeholder?: string
	defaultValue?: string
}

// Props when tone is set
type ToneHintProps = CommonProps & {
	tone?: "success" | "failure"
	hint?: string // hint is required when tone is set
}

// Props when tone is not set
type HintProps = CommonProps & {
	tone?: never
	hint?: string // hint is optional when tone is not set
}

type TextInputProps = ToneHintProps | HintProps


export default function TextInput({
	id,
	name,
	type,
	label,
	value = "",
	onBlur,
	onFocus,
	onChange,
	required,
	disabled,
	className,
	maxLength,
	placeholder,
	defaultValue,

	hint,
	tone,
}: TextInputProps) {

	const backgroundColorClass =
		(tone === "success") ? "bg-lightgreen" :
		(tone === "failure") ? "bg-lightorange" :
		"bg-midwhite"

	const hintColorClass =
		(tone === "success") ? "green" :
		(tone === "failure") ? "red" :
		"grey"

	return (
		<label>
			{label}
			<input
				id={id}
				name={name}
				type={/*type || */"text"}
				value={value}
				onBlur={onBlur}
				onFocus={onFocus}
				onChange={onChange}
				required={required}
				disabled={disabled}
				className={
					(!!hint && value.length > 0)
					? (className
						? `${backgroundColorClass} ${className}`
						: backgroundColorClass)
					: className
				}
				maxLength={maxLength}
				placeholder={placeholder}
				defaultValue={defaultValue}
			/>

			{!!hint && value.length > 0 && (
				<span className={`hint small semibold-font ${hintColorClass}`}>{hint}</span>
			)}
		</label>
	)
}