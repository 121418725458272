// Utils
import { PAYMENT_METHODS } from "offiziersmesser/lib/utils"
import {
	createTotalParts,
} from "offiziersmesser/lib/helpers"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCashRegister } from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"

export default function BalanceEntryGeneral({
	entries,
	paymentsOnCredit
}) {

	const parts = []
	const totalParts = []
	const balanceEntry = []

	let balanceOnCreditTotal = 0
	let balancesTotal = 0

	if (entries != null) {

		entries.map((entry) => {

			entry.payments.map((entry) => {
				parts.push(...entry.parts)
				return null
			})
			return null
		})

		totalParts.push(...createTotalParts(parts))
		balanceEntry.push(...totalParts)

		balancesTotal = balanceEntry
			.map((balances) => balances.value)
			.reduce((prev, curr) => prev + curr, 0)
	}

	if (paymentsOnCredit != null) {

		balanceOnCreditTotal = paymentsOnCredit
			.map((payment) => payment.total)
			.reduce((prev, curr) => prev + curr, 0)
	}

	return (
		<div className="register-wrapper flex flex-column wide high small-gap">
			<div className="register-history outflow-history element-border wide high">
				<div>
					{balanceEntry.length > 0 ? (
						balanceEntry.map((balance, index) => {
							return (
								<li key={index} className="register-history-item">
									<span key={index} className="bold-font">
										{PAYMENT_METHODS.map((method, index) => {
											if (balance.method === index) return <span key={index}>{method.name}</span>
											return null
										})}
									</span>
									<span className="bold-font">
										{balance.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
									</span>
								</li>
							)
						})
					) : (
						<Notice>
							<FontAwesomeIcon icon={faCashRegister} />
							<span>Registrador não possui entrada.</span>
						</Notice>
					)}
				</div>
			</div>

			<div className="element-border wide small-gap">
				<ul>
					<li className="register-history-item">

						<span className="bold-font">Vendas à prazo</span>
						<span className="bold-font orange big">
							{balanceOnCreditTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
						</span>
					</li>

					<li className="register-history-item">

						<span className="bold-font">Total</span>
						<span className="bold-font orange big">
							{balancesTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
						</span>
					</li>
				</ul>
			</div>
		</div >
	)
}
