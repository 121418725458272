// Library imports
import { useSelector, useDispatch } from "react-redux"

// Store
import { setCartDeliveryAddress } from "../../../../../shared/store/slices/cartSlice"

// Utils
import { AREA_CODES_BR } from "offiziersmesser/lib/utils"

// Components
import TextInput from "../../../../../shared/components/standard/TextInput"
import SelectInput from "../../../../../shared/components/standard/SelectInput"

export default function CheckoutReceiverForm() {

	const dispatch = useDispatch()

	// Store
	const cart = useSelector(state => state.cart)

	return (
		<div className="large-margin-top">
			<div className="medium-margin-top medium-margin-bottom">
				<span className="big-x bold-font">Caracterize quem recebe</span>
			</div>

			<div className="grid small-gap fill-normal center-items wide-children">
				<TextInput
					label="Nome"
					name="name"
					placeholder="Exemplo: Jonas"
					value={cart.data.delivery.address.reference.name}
					onChange={(event) =>
						dispatch(
							setCartDeliveryAddress({
								...cart.data.delivery.address,
								reference: {
									...cart.data.delivery.address.reference,
									name: event.target.value,
								},
							})
						)
					}
				/>
				<TextInput
					label="Sobrenome"
					name="surname"
					placeholder="Exemplo: Souza"
					value={cart.data.delivery.address.reference.surname}
					onChange={(event) =>
						dispatch(
							setCartDeliveryAddress({
								...cart.data.delivery.address,
								reference: {
									...cart.data.delivery.address.reference,
									surname: event.target.value,
								},
							})
						)
					}
				/>
				<div className="grid-columns small-gap">
					<SelectInput
						label="DDD"
						name="destination"
						defaultValue={cart.data.delivery.address.reference.phone.destination || "—"}
						onChange={(event) =>
							dispatch(
								setCartDeliveryAddress({
									...cart.data.delivery.address,
									reference: {
										...cart.data.delivery.address.reference,
										phone: {
											...cart.data.delivery.address.reference.phone,
											destination: event.target.value,
										},
									},
								})
							)
						}
					>
						{AREA_CODES_BR.map((code,index)=>
							<option key={index} value={code}>{code}</option>
						)}
					</SelectInput>
					<TextInput
						label="Celular"
						name="subscriber"
						placeholder="Exemplo: 911110000"
						value={
							cart.data.delivery.address.reference.phone.subscriber
						}
						onChange={(event) =>
							dispatch(
								setCartDeliveryAddress({
									...cart.data.delivery.address,
									reference: {
										...cart.data.delivery.address.reference,
										phone: {
											...cart.data.delivery.address.reference.phone,
											subscriber: event.target.value,
										},
									},
								})
							)
						}
					/>
				</div>
			</div>
		</div>
	)
}
