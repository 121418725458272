import { createSlice } from "@reduxjs/toolkit"

// Models
const initialStateAssessment = {
	"reference": "",
	"taxpayer": "",
	"clearence": false,
	"credit": {
		"updateAt": 0,
		"updatedBy": null,
		"limit": 0,
		"timespan": {
			"unit": "dd",
			"value": 0
		}
	},
	"debt": {
		"updateAt": 0,
		"updatedBy": null,
		"limit": 0,
		"timespan": {
			"unit": "dd",
			"value": 0
		}
	},
	"general": {
		"createdBy": 0,
		"updatedBy": null,
		"administrative": {
			"company": "",
			"byname": "",
			"locality": {
				"city": "",
				"road": "",
				"number": "",
				"area": "",
				"postal": "",
				"region": "",
				"country": "",
				"extra": ""
			},
			"reference": {
				"name": "",
				"email": "",
				"phone": {
					"prefix": "55",
					"subscriber": "",
					"destination": ""
				},
				"surname": ""
			}
		}
	},
	"billing": {
		"createdBy": "",
		"updatedBy": "",
		"address": {
			"receiver": {
				"name": "",
				"email": "",
				"phone": {
					"prefix": "",
					"subscriber": "",
					"destination": ""
				},
				"surname": ""
			},
			"locality": {
				"city": "",
				"road": "",
				"number": "",
				"area": "",
				"postal": "",
				"region": "",
				"country": "",
				"extra": ""
			}
		},
		"restrictions": "",
		"closure": 0
	},
	"endorsements": [],
	"checks": [],
	"biases": []

}


const contactsSlice = createSlice({
	name: "assessment",
	initialState: initialStateAssessment, // FIXME model peding
	reducers: {
		setAssessment: (state, action) => {
			return state = action.payload
		},

		clearAssessment: (state) => {
			return state = initialStateAssessment
		},
	}
})

export default contactsSlice.reducer
export const { setAssessment, clearAssessment,  } = contactsSlice.actions
