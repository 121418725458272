// Utils
import { PAYMENT_METHODS } from "offiziersmesser/lib/utils"
import { createTotalParts } from "offiziersmesser/lib/helpers"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCashRegister } from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "../../../../../shared/components/custom/Notice/Notice"

export default function BalanceEntry({ payments }) {
	let totalParts = []

	let balanceEntry = []

	let balancesTotal = 0

	if (payments != null) {
		totalParts = createTotalParts(payments)

		balanceEntry = totalParts
		//sum total
		balancesTotal = balanceEntry
			.map((balances) => balances.value)
			.reduce((prev, curr) => prev + curr, 0)
	}

	return (
		<div className="register-history-container flex flex-column wide high">

			<div className="register-history seller-entry-history element-border wide small-margin-top">
				{(balanceEntry.length > 0) ? (
					balanceEntry.map((balance, index) => (
						<div key={index} className="register-history-item">
							<span key={index} className="bold-font">
								{PAYMENT_METHODS.map((method, index) => {
									if (balance.method === index) return <>{method.name}</>
									return null
								})}
							</span>
							<span className="bold-font">
								{balance.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
							</span>
						</div>
					))
				) : (
					<Notice>
						<FontAwesomeIcon icon={faCashRegister} />
						<span>Registrador não possui entrada.</span>
					</Notice>
				)}
			</div>

			<div className="element-border wide small-margin-top">
				<ul>
					<li className="register-history-item">

						<span className="bold-font">Total</span>
						<span className="bold-font orange big">
							{balancesTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
						</span>
					</li>
				</ul>
			</div>
		</div >
	)
}
