import { ChangeEvent, ReactNode } from "react"

type SelectInputProps = {
	name?: string
	label?: string //| ReactNode
	value?: string
	children?: ReactNode
	disabled?: boolean
	multiple?: boolean
	onChange: (event: ChangeEvent<HTMLSelectElement>) => void
	className?: string
	defaultValue?: string
}

export default function SelectInput({
	name,
	label,
	value = "",
	children,
	multiple,
	disabled,
	onChange,
	className,
	defaultValue
}: SelectInputProps) {

	return (
		<label>
			{label}
			<select
				name={name}
				value={value}
				disabled={disabled}
				multiple={multiple}
				onChange={onChange}
				className={className}
			>
				{children}
			</select>
		</label>
	)
}








// import { ChangeEvent, ReactNode } from "react"

// type SelectInputProps = {
// 	label?: string | ReactNode
// 	name?: string
// 	className?: string
// 	value?: string
// 	defaultValue?: string
// 	children?: ReactNode
// 	multiple?: boolean
// 	disabled?: boolean
// 	onChange: (event: ChangeEvent<HTMLSelectElement>) => void
// }

// export default function SelectInput({
// 	label,
// 	name,
// 	className,
// 	value,
// 	defaultValue,
// 	children,
// 	multiple,
// 	disabled,
// 	onChange,
// }: SelectInputProps) {

// 	return (
// 		<label className="flex flex-column small-gap">
// 			<span className="medium semibold-font">
// 				{label}
// 			</span>
// 			<select
// 				name={name}
// 				className={className}
// 				onChange={event => onChange(event)}
// 				defaultValue={!!defaultValue ? defaultValue : "—"}
// 				value={value}
// 				disabled={disabled}
// 				multiple={multiple}
// 			>
// 				{children}
// 			</select>
// 		</label>
// 	)
// }
