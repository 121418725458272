// Models
import { UserModel } from "offiziersmesser/lib/models"
import { RoleModel } from "src/shared/store/slices/sessionSlice"

type EmployeeAuthorizationResponseDataModel = {
	// alias: string
	// payload: {
	// 	account: {
	// 		user: UserModel
	// 		supplier: UserModel
	// 	}
	// 	supplier?: string
	// }
	// token: string
	token: string
	payload: UserModel
	roles: RoleModel[]
}

export default async function authorizeEmployee(
	alias: string,
	taxpayer: string,
	passphrase: string
) {
	try {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/employee/authorization`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				alias,
				taxpayer,
				passphrase
			}),
		})

		if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

		const data = await response.json() as EmployeeAuthorizationResponseDataModel
		return data

	} catch (error) {
		console.error(error)
		throw error
	}
}
