const variations = [
    "",
	"valor",
	"Data do pedido",
    "Status"
]

export default function Header() {
	return (
		<tr>
			{variations.map((variation, index) => <th key={index}>{variation}</th>)}
		</tr>
	)
}
