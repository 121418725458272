// Icons
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Compoenents
import MainButton from "../../../../../shared/components/standard/MainButton"
import { toFixedImproved } from "offiziersmesser/lib/utils";
import { localDate } from "offiziersmesser/lib/utils";
import { INVOICE_STATUS } from "offiziersmesser/lib/utils";

export default function InvoicesTableBody({
	invoices
}) {
    function getTotalPayment(payments) {

        let transactionsTotal = 0
        payments.forEach((transaction) => transactionsTotal += transaction.total + (transaction.other.length !== 0 ? transaction.other[0] - transaction.other[1] : 0))
        return transactionsTotal
    }

    function getTotalDiscount(transaction) {
		return transaction.reduce((acc, item) => acc += (item.other[1]??0), 0)*(-1)
	}
	return (
		<>
			{invoices.map((invoice, index) => {
				return (
					<tr key={index}>
						<td>
							<div className="grid-columns tiny-gap">

								<MainButton hierarchy="low" title="Visualizar nota">
									<FontAwesomeIcon icon={faExpandAlt} />
								</MainButton>

								{/* {invoice.tipo === "0" ? (
									<MainButton hierarchy="inferior" title="Devolver nota">
										<FontAwesomeIcon icon={faRedoAlt} />
									</MainButton>
								) : (
									<MainButton hierarchy="inferior" title="Cancelar nota">
										<FontAwesomeIcon icon={faTimes} />
									</MainButton>
								)} */}
							</div>
						</td>
						<td>
                            {INVOICE_STATUS.filter((i, index) => invoice.status === index)}
                        </td>
						<td>{invoice.details.nnf}</td>
						<td>{localDate(invoice.createdAt * 1000)}</td>
						<td>{invoice.taxpayer}</td>
						<td>{invoice.supplier}</td>
						<td>R$ {toFixedImproved(getTotalPayment(invoice.payment), 2)}</td>
						<td>R$ {toFixedImproved(getTotalDiscount(invoice.payment),2)}</td>
					</tr>
				)
			})}
		</>
	)
}
