// Icons
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"

// Store
import {
	insertIntoWorkableItems,
	resetWorkableItems,
	updateWorkableItemIndex,
} from "src/shared/store/slices/workableItemsSlice"
import { RootState } from "src/shared/store/reducers"

// Components
import LinkButton from "src/shared/components/standard/LinkButton"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"
import InsertUpdateItemHandler from "../components/forms/InsertUpdateItemForm"
import itemTemplate from "../templates/itemTemplate"

export default function NewItem() {

	const dispatch = useDispatch()

	// Store
	const workableItem = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)
	const user = useSelector((state: RootState) => state.user)

	// States
	const [loading, setLoading] = useState(true)

	// Effect
	useEffect(() => {
		dispatch(resetWorkableItems())
		dispatch(insertIntoWorkableItems({
			item: {
				...itemTemplate, groups: {
					...itemTemplate.groups,
					category: user?.categories[0].tag
				}
			}, images: []
		}))
		dispatch(updateWorkableItemIndex(0))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		(async () => {
			typeof workableItem === "object" && setLoading(false)
		})()
	}, [workableItem])

	return (
		<SupplierRoleNavbar>
			{loading === true ? (
				<ComponentLoader />
			) : (
				<InsertUpdateItemHandler update={false} />
			)}
		</SupplierRoleNavbar>
	)
}
