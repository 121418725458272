// Library imports
import { ReactNode, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useIntl } from "react-intl"

// Utils
import { EMPLOYEE_ROLES } from "offiziersmesser/lib/utils"

// Store
import { RootState } from "src/shared/store/reducers"
import {
	// Utilitary functions
	getDefaultPathInNavigation,
	setAuthenticated
} from "src/shared/store/slices/sessionSlice"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import {
	faSignOutAlt,
	faSyncAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import Navbar from "src/shared/components/custom/Navbar/Navbar"
import LinkButton from "src/shared/components/standard/LinkButton"
import MainButton from "src/shared/components/standard/MainButton"

type SupplierNavbarProps =  {
	topLeft?: ReactNode
	// topRight?: ReactNode
	leftCenter?: ReactNode
	context?: ReactNode
	children: ReactNode
}

function SupplierNavbar({
	topLeft,
	// topRight,
	leftCenter,
	context,
	children,
}: SupplierNavbarProps) {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const intl = useIntl()

	// Store
	const user = useSelector((state: RootState) => state.user)
	const session = useSelector((state: RootState) => state.session)

	// States
	const [renderRoleSwitcherModal, setRenderRoleSwitcherModal] = useState(false)

	const activeRole = session.roles.find(role => role.active)?.role

	function getRoleName/*Data*/(role?: string) { // Replace for a reducer whenever custom roles are implemented?
		const name = EMPLOYEE_ROLES.find(entry => role === entry.role)?.name // With custom roles, this may be undefined
		if (typeof name === "string")
			return intl.formatMessage({ id: name })
		// else // It is a custom role, need proper handling, either read the reducer or a database call
	}

	return (
		<Navbar
			topLeft={topLeft}
			// topRight={topRight}
			leftCenter={leftCenter}
			leftBottom={
				<div className="tiny-padding-horizontal small-padding-vertical grid tiny-gap left-items">
					<span className="small-padding-horizontal medium bold-font">{`${user.administrative.reference.name} ${user.administrative.reference.surname}`}</span>
					<MainButton hierarchy="inferior" onClick={() => setRenderRoleSwitcherModal(true)}>
						<FontAwesomeIcon icon={faSyncAlt as Icon}/>
						<span>{getRoleName(activeRole)}</span>
					</MainButton>
				</div>
			}
			context={context}
		>
			{renderRoleSwitcherModal &&
				<Modal title={intl.formatMessage({ id: "go_to" })} onClose={() => setRenderRoleSwitcherModal(false)}>
					<div className="grid-columns small-gap">
						{session.roles.map(entry =>	(entry.active === false) &&
							<MainButton
								key={entry.role}
								hierarchy="inferior"
								onClick={() => navigate(getDefaultPathInNavigation(entry.navigation))}
							>
								<FontAwesomeIcon className="fa-icon" icon={faSyncAlt}/>
								<span>{getRoleName(entry.role)}</span>
							</MainButton>
						)}

						<LinkButton
							hierarchy="inferior"
							to="/authorization"
							onClick={() => {
								dispatch(setAuthenticated(false))
								sessionStorage.removeItem("token")
							}}
						>
							<FontAwesomeIcon className="fa-icon" icon={faSignOutAlt}/>
							<span>{intl.formatMessage({ id: "finalize_session" })}</span>
						</LinkButton>
					</div>
				</Modal>
			}
			{children}
		</Navbar>
	)
}


export default SupplierNavbar
export {

}