import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { RootState } from "../../../shared/store/reducers"
import { setAssessments } from "../../../shared/store/slices/assessmentsSlice"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { faExclamationCircle, faExternalLinkSquareAlt, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import LinkButton from "src/shared/components/standard/LinkButton"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import ContactCard from "./editor/components/ContactCard/AssessmentCard"
import Pagination from "src/shared/components/custom/Pagination/Pagination"

export default function Assessments() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const assessments = useSelector((state: RootState) => state.assessments)

	// States
	const [loading, setLoading] = useState(true)

	// Handlers
	const getAssessments = async () => {
		try {
			setLoading(true)

			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/assessments`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				}
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const assessments = await response.json()

			dispatch(setAssessments(assessments))
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	// Effects
	useEffect(() => {
		getAssessments()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [currentAssessments, setCurrentAssessments] = useState(assessments)

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap large-margin-bottom">
				{loading === true ? (
					<>
						<PageTitle title="Contatos" />
						<ComponentLoader />
					</>
				) : (assessments.length <= 0 ? (
					<>
						<PageTitle title="Contatos" />

						<Notice tone="info" to="/supplier/assessment/new">
							<div className="grid-columns medium-gap left-items">
								<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle as Icon} />
								<div className="grid small-gap">
									<span className="small">Você não possue nenhum contato.</span>
									<div className="grid-columns small-gap left-items bold-font tiny">
										<span>Clique aqui para criar um contato</span>
										<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt as Icon} />
									</div>
								</div>
							</div>
						</Notice>
					</>
				) : (
					<>
						<PageTitle title="Contatos" />

						<div className="grid fill-normal">
							<LinkButton to="/supplier/assessment/new">
								<FontAwesomeIcon icon={faPlus as Icon} className="fa-icon" />
								<span>Adicionar contato</span>
							</LinkButton>
						</div>

						<div>
							{assessments?.length > 0 &&
							<>
								<Pagination elements={assessments} setCurrentElements={setCurrentAssessments}/>
								{currentAssessments.map((contact) => (
										<ContactCard
											name={contact.general.administrative.reference.name}
											surname={contact.general.administrative.reference.surname}
											email={contact.general.administrative.reference.email}
											taxpayer={contact.taxpayer}
											key={contact.taxpayer}
											phone={{
												destination: contact.general.administrative.reference.phone.destination,
												subscriber: contact.general.administrative.reference.phone.subscriber,
												prefix: "55",
											}}
											assessment={contact}
										/>
									))
								}
							</>
							}
						</div>
					</>
				))}
			</div>
		</SupplierRoleNavbar>
	)
}
