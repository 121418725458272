import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

// Components
import LinkButton from "src/shared/components/standard/LinkButton"
import StepForm from "src/shared/components/custom/StepForm/StepForm"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import { InsertTransactionStepOne } from "./steps/renegotiateTransactionStepOne"
import { InsertTransactionStepTwo } from "./steps/renegotiateTransactionStepTwo"

export default function RenegotiateTransaction() {

	const navigate = useNavigate()
	let { transaction } = useParams()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const transactions = useSelector(state => state.transactions)
	const currentTransaction = transactions.find(currentTransaction => currentTransaction.transaction === transaction)
	const user = useSelector(state => state.user)

	// States
	const [transactionsType, setTransactionsType] = useState(
		currentTransaction && user.taxpayer === currentTransaction.issuer.taxpayer ? 0 : 1
	)



	const [note, setNote] = useState("")
	const [value, setValue] = useState(0)

	const [taxpayer, setTaxpayer] = useState(currentTransaction && currentTransaction.issuer.taxpayer)
	const [activeInstallments, setActiveInstallments] = useState(false)

	const [expirationDate, setExpirationDate] = useState(Date.now())
	const [issueDate, setIssueDate] = useState(Date.now())
	const [payments, setPayments] = useState([])

	// Handlers
	function sumTotal(total, parts) {
		let sumParts = 0
		parts.map((parts) => (sumParts += parts.value))
		const sum = total - sumParts
		return sum
	}
	async function handleRenegotiate () {
		try{
			const token = getToken()

			const response = await fetch(
				process.env.REACT_APP_API_URL + "/resource/supplier/transactions/renegociation",
				{
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body:JSON.stringify({
					transaction: "",
					asOf: issueDate,
					issuer: currentTransaction.issuer.taxpayer,
					addressee: currentTransaction.addressee.taxpayer,
					done: false,
					note: note,
					payments: payments,
					Renegotiation: transaction,
					order: null,
				})
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			navigate("/supplier/transactions")

		}catch(error){
			console.error(error)
		}
	}

	// Effects
	useEffect(() => {

		setTransactionsType(user.taxpayer === currentTransaction?.issuer.taxpayer ? 0 : 1)
		setValue(
			currentTransaction?.payments.reduce((acc, payment)=> {
				return acc += sumTotal(payment.total, payment.parts)
			}, 0)
		)
		setNote(`Renegociação de ${currentTransaction?.note || ""}`)
	}, [transactions, currentTransaction, user])

	if (!transaction) { navigate(-1) }

	return (
		<SupplierRoleNavbar>
			<div className="small-margin-bottom">
				<LinkButton to="/supplier/transactions" hierarchy="inferior">
					<FontAwesomeIcon icon={faArrowLeft} />
					<span>Contas</span>
				</LinkButton>
			</div>

			<StepForm
				componentTitle="Renegociar conta"
				onClickConclude={() => { handleRenegotiate() }}
			>
				<InsertTransactionStepOne
					setTaxpayer={setTaxpayer}
					taxpayer={taxpayer}
					transactionsType={transactionsType}
					setTransactionsType={setTransactionsType}
					note={note}
					stepCondition={note !== "" && (taxpayer?.length === 11 || taxpayer?.length === 14)}
				/>
				<InsertTransactionStepTwo
					expirationDate={expirationDate}
					setExpirationDate={setExpirationDate}
					issueDate={issueDate}
					setIssueDate={setIssueDate}
					value={value}
					payments={payments}
					setPayments={setPayments}
					activeInstallments={activeInstallments}
					setActiveInstallments={setActiveInstallments}
					stepCondition={(value !== 0) && (expirationDate !== 0) && (issueDate !== 0)}
				/>
			</StepForm>
		</SupplierRoleNavbar>
	)
}
