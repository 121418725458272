type SupplierAuthorizationResponseDataModel = {
	alias: string
    payload: any
    token: string
}

export default async function authorizeSupplier(
	taxpayer: string,
	passphrase: string
) {
	try {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/supplier/authorization`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ taxpayer, passphrase })
		})

		if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

		const data = await response.json() as SupplierAuthorizationResponseDataModel
		return data

	} catch (error) {
		console.error(error)
		throw error
	}
}
