import { useState } from "react"
import { useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { createToast } from "src/shared/store/slices/toastsSlice"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import MainButton from "src/shared/components/standard/MainButton"
import PassphraseHandler from "src/shared/components/custom/PassphraseInputValidationStatusesGroup/PassphraseInputValidationStatusesGroup"

export default function PassphraseModal() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	const [passphrase, setPassphrase] = useState("")
	const [isPassphraseStrong, setIsPassphraseStrong] = useState(false)
	const [renderPassphraseModal, setRenderPassphraseModal] = useState(false)

	const handlePassphraseUpdate = async () => {
		const token = getToken()

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/authorization`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({ passphrase: passphrase }),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			setPassphrase("")
			dispatch(createToast({ tone: "success", miliseconds: 5000,	message: "Atualizado com sucesso" }))
			setRenderPassphraseModal(false)
		} catch (error) {
			console.error(error)
			dispatch(createToast({ tone: "failure", miliseconds: 5000, message: "Erro ao atualizar senha" }))
		}
	}

	return (
	<>
		{renderPassphraseModal === true && (
			<Modal title="Redefinição de senha" onClose={() => setRenderPassphraseModal(false)}>
				<PassphraseHandler
					passphrase={passphrase} setPassphrase={setPassphrase}
					isPassphraseStrong={isPassphraseStrong} setIsPassphraseStrong={setIsPassphraseStrong}
				/>

				<MainButton
					disabled={isPassphraseStrong === false}
					onClick={handlePassphraseUpdate}
				>
					<span>Atualizar senha</span>
				</MainButton>
			</Modal>
		)}

		<MainButton hierarchy="low" onClick={() => setRenderPassphraseModal(true)}>
			<span>Redefinir senha</span>
		</MainButton>
	</>
	)
}
