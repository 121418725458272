// Library imports
import { useState, useEffect } from "react"
// Page components
import SelectInput from "../../standard/SelectInput"
// Auxiliary files

// Style CSS
import "./DatePickerSelect.css"

const isLeapYear = (year) =>
	(year & 3) == 0 && ((year % 25) != 0 || (year & 15) == 0)

const enforceDayLimit = (month, day, year) =>
	(day > 28) ? (
		(month === 2) ? (isLeapYear(year) ? 29 : 28) :
			((day === 31) && (month === 4 || month === 6 || month === 9 || month === 11)) ? 30 :
				day
	) : day

export default function DatePickerSelect(props) {
	// Default state of the calendar data for generating React select's array of options
	const [calendar, setCalendar] = useState({
		days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
		months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		years: [1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058, 2059, 2060, 2061, 2062, 2063, 2064, 2065, 2066, 2067, 2068, 2069]
	})
	const timestamp = new Date(Number(props.timestamp))
	const [date, setDate] = useState({
		day: props.day || timestamp.getDate(),
		month: props.month || timestamp.getMonth() + 1,
		year: props.year || timestamp.getFullYear()
	})

	useEffect(() => {
		// Sends the updated date object back to the parent component
		props.setDate(date)
		// Updates the calendar state
		let numberOfDays = [31, (isLeapYear(date.year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][date.month - 1]
		let days = []
		for (var i = 1; i <= numberOfDays; i++)
			days.push(i)
		setCalendar({ ...calendar, days: days })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date])

	return (
		<div className={props.datePickerSelectClass || "flex small-gap wide-children"}>
			<SelectInput
				label="Dia"
				value={date.day}
				disabled={props.dayInputDisabled}
				onChange={event => setDate({ ...date, day: Number(event.target.value) })}
			>
				{calendar.days.map((day, index) => <option key={index} value={day}>{day}</option>)}
			</SelectInput>
			<SelectInput
				label="Mês"
				value={date.month}
				disabled={props.monthInputDisabled}
				onChange={event => {
					const month = Number(event.target.value)
					setDate({ ...date, day: enforceDayLimit(month, date.day, date.year), month: month })
				}}
			>
				{calendar.months.map((month, index) => <option key={index} value={month}>{month}</option>)}
			</SelectInput>
			<SelectInput
				label="Ano"
				value={date.year}
				disabled={props.yearInputDisabled}
				onChange={event => {
					const year = Number(event.target.value)
					setDate({ ...date, day: enforceDayLimit(date.month, date.day, year), year: year })
				}}
			>
				{calendar.years.map((year, index) => <option key={index} value={year}>{year}</option>)}
			</SelectInput>
		</div>
	)
}
