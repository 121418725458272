// Utils
import { SEFAZ_TIPOS_DE_NOTA, SEFAZ_INDICADORES_PRESENCA, SEFAZ_FINALIDADES, SEFAZ_NATUREZAS_DE_OPERACAO, SEFAZ_TIPOS_DE_OPERACAO } from "offiziersmesser/lib/utils"


//
import SelectInput from "../../../../../shared/components/standard/SelectInput"

//
import "../../Invoices.css"
import { Table } from "./ClientBlock/OrdersTable/Table"
import OrderBlockSearch from "./ClientBlock/OrderBlockSearch"

export default function InvoiceBlock({
	tipoNota,
	setTipoNota,
	tipoOperacao,
	setTipoOperacao,
	naturezaOperacao,
	setNaturezaOperacao,
	finalidade,
	setFinalidade,
	// transportSpecified,
	// setTransportSpecified,
    setSelectedOrders, selectedOrders,
	indPresenca,
	setIndPresenca,
	orders,
	setOrders
}) {




	// const rulesEntryFilter = taxationBr !== undefined && taxationBr !== null ? taxationBr.filter(rule => rule.type === 0) : null
	// const rulesOutFilter = taxationBr !== undefined && taxationBr !== null ? taxationBr.filter(rule => rule.type === 1)  : null

	return (
		<div className="normal-padding item-full-height normal-margin-top">
			{/* <div className="grid-columns small-gap fit-normal center-items large-margin-bottom">
				<CheckboxInput
					label={<span className="bold-font small">Transporte especificado</span>}
					inputChecked={transportSpecified}
					onChange={() => setTransportSpecified(!transportSpecified)}
				/>
			</div> */}
			<div className="normal-margin-top medium-margin-bottom grid medium-gap fit-large">
					<SelectInput
						label={<span className="bold-font small">Tipo de operação</span>}
						value={tipoOperacao}
						onChange={(event) =>setTipoOperacao(event.target.value)}
					>
						{SEFAZ_TIPOS_DE_OPERACAO.map((tipo, index)=>{
							return(<option value={tipo.sefaz} key={index}>{tipo.name}</option>)
						})}
					</SelectInput>
					<SelectInput
						label={<span className="bold-font small">Tipo</span>}
						value={tipoNota}
						onChange={(event) => {setTipoNota(event.target.value)}}
					>
						{SEFAZ_TIPOS_DE_NOTA.map((tipo, index)=>{
							return(<option value={tipo.sefaz} key={index}>{tipo.name}</option>)
						})}
					</SelectInput>

                    <SelectInput
                        label="Natureza da operação"
                        value={naturezaOperacao}
                        onChange={e =>setNaturezaOperacao(e.target.value)}
                    >
						{SEFAZ_NATUREZAS_DE_OPERACAO.map((tipo, index)=>{
							return(<option value={tipo.name} key={index}>{tipo.name}</option>)
						})}
                    </SelectInput>

			<SelectInput
				label="Finalidade"
				value={finalidade}
				onChange={e => setFinalidade(e.target.value)}
			>
				{SEFAZ_FINALIDADES.map((tipo, index)=>{
					return(<option value={tipo.name} key={index}>{tipo.name}</option>)
				})}
			</SelectInput>
			<SelectInput
				label="Indicador de presença"
				value={indPresenca}
				onChange={e => setIndPresenca(e.target.value)}
			>
				{SEFAZ_INDICADORES_PRESENCA.map((tipo, index)=>{
							return(<option value={tipo.sefaz} key={index}>{tipo.name}</option>)
				})}
			</SelectInput>


		</div>

            <OrderBlockSearch
                setOrders={setOrders}
            />

            { orders.length > 0 &&
				<>
					<span className="big-x bold-font medium-margin-top">Pedidos</span>

					<Table
						orders={orders}
						setSelectedOrders={setSelectedOrders}
						selectedOrders={selectedOrders}
					/>

				</>

            }

        </div>
	)
}
