type ImageProps = {
	alt?: string
	src?: string | Blob
	width?: string
	height?: string
	className?: string
}

export default function Image({
	alt,
	src,
	width,
	height,
	className,
}: ImageProps) {
	return (
		<img
			alt={alt}
			src={src as string}
			width={width}
			height={height}
			loading="lazy"
			className={className}
		/>
	)
}
