import { memo } from "react"

// Icons
import { faArrowDown, faArrowUp, faSlidersH } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import LinkButton from "../../../../../../shared/components/standard/LinkButton"

// Styles
import "./BalancesCard.css"

function BalancesCard({
	status,
	cashInflow,
	cashOutflow,
	cashTotal,
	registerTag,
	mainRegister
}) {
	return (
		<div className={mainRegister ? "balances-card-container main-register" : "balances-card-container"}>

			<div className="balances-card-info flex medium-gap">
				<div className="balances-card-status">
					<div className={`${status.toLowerCase() === "aberto" ? "balance-active" : "balance-inactive"} balance-status`}>
						{status}
					</div>
				</div>

				<span className="balances-card-tag bold-font big">{registerTag}</span>
			</div>

			<div className="balances-card-cash-flow flex align-items-center space-between-items">
				<div className="cash-flow-item">
					<FontAwesomeIcon className="green" icon={faArrowDown} />
					<span className="cash-span">{cashInflow}</span>
				</div>

				<div className="cash-flow-item">
					<FontAwesomeIcon className="red" icon={faArrowUp} />
					<span className="cash-span">{cashOutflow}</span>
				</div>

				<div className="cash-flow-item">
					<span className="cash-span bold-font big"> {cashTotal} </span>
				</div>
			</div>

			<div className="balances-card-actions grid small-gap fill-tiny-xx">
				<LinkButton
					hierarchy="low"
					to={
						mainRegister
							? "/supplier/balance/general"
							: `/supplier/balance/${registerTag}`
					}
				>
					<FontAwesomeIcon icon={faSlidersH} />
				</LinkButton>
			</div>
		</div>
	)
}

export default memo(BalancesCard)
