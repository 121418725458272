import { useEffect, useState } from "react"
import { Navigate, Outlet, useNavigate, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

// Services
import { getToken } from "src/shared/services/general"
// import actionComponentList, { someActionComponentEvaluatesTrue } from "src/modules/general/actions/actionComponentList"
import useEvaluateActions from "src/modules/general/actions/useEvaluateActions"


// Store
import { RootState } from "src/shared/store/reducers"
import { setUser } from "src/shared/store/slices/userSlice"
import {
	initializeSession,
	setAuthenticated,
	setActiveRole
} from "src/shared/store/slices/sessionSlice"

// Models
import UserModel from "src/shared/models/user"
import { RoleModel } from "src/shared/store/slices/sessionSlice"

// Components
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

type PrivateRouteProps = {
	role: string
	home: string
	reload: string
}

type RefreshAuthorizationResponseModel = {
	token: string
	payload: UserModel
	roles: RoleModel[] | null
}



type UpdateEmployeeTokenResponseModel = {
	token: string
}





export default function PrivateRoute({ role, home, reload }: PrivateRouteProps) {
	const location = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const responseCodeHandler = useResponseCodeHandler()
	const { someActionComponentEvaluatesTrue } = useEvaluateActions()

	// Store
	const session = useSelector((state: RootState) => state.session)
	const synchronized = session.roles.find(entry => entry.active)?.role === role // Current role of routes block must match active session role or JWT will have to be updated

	// States
	const [loading, setLoading] = useState(true)

	// Common
	const currentToken = getToken()
	const actionsPathname = "/actions"

	async function updateEmployeeToken(token: string, role: string): Promise<UpdateEmployeeTokenResponseModel> {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/employee/token/update`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${token}`
			},
			body: JSON.stringify({ role }),
		})

		responseCodeHandler(response)

		if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

		return response.json()
	}

	async function refreshAuthorization(token: string, path: string): Promise<RefreshAuthorizationResponseModel> {
		const response = await fetch(process.env.REACT_APP_API_URL + path, {
			method: "GET",
			headers: { "Authorization": `Bearer ${token}` },
		})

		responseCodeHandler(response)

		if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

		return response.json()
	}

	const assertAuthentication = async () => {
		try {
			const { token, payload, roles } = await refreshAuthorization(currentToken, reload) as RefreshAuthorizationResponseModel
			sessionStorage.setItem("token", token)

			dispatch(setUser(payload))
			dispatch(initializeSession(
				(roles ===  null)
				? ({ stakeholder: "supplier" })
				: ({ stakeholder: "employee", authorizedRoles: roles })
			))
			dispatch(setAuthenticated(true))
		}
		catch (error) {
			console.error(error)
			sessionStorage.clear()
			navigate("/")
		}
		finally {
			setLoading(false)
		}
	}

	const synchronizeSession = async () => {
		try {
			dispatch(setActiveRole(role))

			switch (session.stakeholder) {
				// case "supplier":
				// break
				case "employee":
					const { token } = await updateEmployeeToken(currentToken, role) as UpdateEmployeeTokenResponseModel
					sessionStorage.setItem("token", token)
					break
				// default:
			}
		}
		catch (error) {
			console.error(error)
			sessionStorage.clear()
			navigate("/")
		}
		finally {
			setLoading(false)
		}
	}

	// Checks JWT and reducer data states
	const checkIdentityIntegrity = async () => {
		if (currentToken) {
			if (!session.authenticated) { // Attempts to recover reducer state using current JWT
				await assertAuthentication()
			}
			else if (!synchronized) { // JWT and reducer states must be synchronized when attempting to access features of a different role
				await synchronizeSession()
			}
		}
		setLoading(false)
	}

	useEffect(() => {
		checkIdentityIntegrity()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname])

	if (loading) {
		return (
			<ComponentLoader />
		)
	}

	return session.authenticated ? (

		// Checks if an action component must be rendered whenever the user is not already on the actions path
		((location.pathname !== actionsPathname) && someActionComponentEvaluatesTrue())
		? <Navigate to={actionsPathname} state={{ from: location }} />
		: <Outlet />

	) : (
		<Navigate to={home} state={{ from: location }} />
	)
}
