// Library imports
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "src/shared/store/reducers"

// Services
import { getToken } from "src/shared/services/general"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Compnents
import LinkButton from "src/shared/components/standard/LinkButton"
import ReturnReason from "../components/steps/returnOrder/returnReason"
import StepForm from "src/shared/components/custom/StepForm/StepForm"
import ItemsToReturn from "../components/steps/returnOrder/itemsToReturn"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import StepContainer from "src/shared/components/custom/StepForm/StepContainer"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

export default function ReturnOrder() {

	let { record } = useParams()
	const responseCodeHandler = useResponseCodeHandler()

	const navigate = useNavigate()
	const session = useSelector((state: RootState) => state.session)

	// States
	const [loading, setLoading] = useState(true)

	const [order, setOrder] = useState<any>()
	const [returnedInfoOrder, setReturnedInfoOrder] = useState({
		total: 0,
		totalItemsSelected: 0,
		returnToStock: false,
		cashbox:null,
		reason: 0,
		method: 0
	})

	const orderTitle = record && record.slice(0, 13)

	// Handlers
	const handleReturnOrder = async () => {
		const newOrder = order
		newOrder.transaction.payments[0].parts.push({value: -returnedInfoOrder.total, method: returnedInfoOrder.method})
		setOrder(newOrder)
		try {
			const token = getToken()
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/order/return`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					cashbox: returnedInfoOrder.cashbox,
					returnStock: returnedInfoOrder.returnToStock,
					order
				})
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

		}
		catch (error) { console.error(error) }
		finally { navigate(`/${session.stakeholder}/orders`) }
	}




	// Effects
	useEffect(() => {
		const getRecord = async () => {

			setLoading(true)
			try {
				if (!record) return

				const token = getToken()

				const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/${session.stakeholder}/order/${record}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})

				responseCodeHandler(response)

				if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				const orderData = await response.json()

				setOrder(orderData)

			} catch (error) {
				console.error(error)
			} finally {
				setLoading(false)
			}


		}

		const getActiveCashbox = async () => {



			try {
				const token = getToken()

				const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/seller/balance/active`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})

				responseCodeHandler(response)

				if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				if(response.status === 200){
					const balanceData = await response.json()
					setReturnedInfoOrder({...returnedInfoOrder, cashbox: balanceData.cashbox})
				}

			} catch (error) {
				console.error(error)
			}


		}

		getRecord()
		getActiveCashbox()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [record])


	const pageContent = loading ? (
		<div className="grid medium-gap">
			<PageTitle title={`Devolução pedido <b>${orderTitle}</b>`} />

			<ComponentLoader />
		</div>
	) : (
		<div className="grid medium-gap">
			<div className="flex">
				<LinkButton to={`/${session.stakeholder}/orders`} hierarchy="inferior" >
					<FontAwesomeIcon icon={faArrowLeft as Icon} />
					<span>Pedidos</span>
				</LinkButton>
			</div>

			<PageTitle title={`Devolução pedido ${orderTitle}`} />

			<span className="bold-font">Id do pedido: <b>{order?.record}</b></span>

			<StepForm onClickConclude={handleReturnOrder}>

				<StepContainer stepCondition={returnedInfoOrder.totalItemsSelected > 0}>
					<ItemsToReturn
						order={order}
						returnedInfoOrder={returnedInfoOrder}
						setReturnedInfoOrder={setReturnedInfoOrder}
						setOrder={setOrder}
					/>
				</StepContainer>

				<StepContainer
					stepCondition={
						returnedInfoOrder.total > 0
					}
				>
					<ReturnReason
						order={order}
						returnedInfoOrder={returnedInfoOrder}
						setReturnedInfoOrder={setReturnedInfoOrder}
					/>
				</StepContainer>
			</StepForm>
		</div>
	)
	return (
		<SupplierRoleNavbar>
			{pageContent}
		</SupplierRoleNavbar>
	)
}
