import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Icons
import { faPlus, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


// Components
import Form from "src/shared/components/standard/Form"
import TextInput from "src/shared/components/standard/TextInput"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import MainButton from "src/shared/components/standard/MainButton"
import SelectedTag from "src/shared/components/custom/SelectedTag/SelectedTag"
import SelectInput from "src/shared/components/standard/SelectInput"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import NumberInput from "src/shared/components/standard/NumberInput"

// Utils
import {
	REGION_LIST_BR,
	SEFAZ_CSOSN,
	SEFAZ_CST_PIS_COFINS,
	SEFAZ_MODBC,
	SEFAZ_NATUREZAS_DE_OPERACAO,
	SEFAZ_TIPOS_DE_OPERACAO
} from "offiziersmesser/lib/utils"
import IncludeNcmModal from "../../components/modals/IncludeNcmModal"


export default function InsertTaxRule() {

	const responseCodeHandler = useResponseCodeHandler()

	let { id } = useParams()
	const navigate = useNavigate()

	// states
	const [includeNcm, setIncludeNcm] = useState([])
	const [rule, setRule] = useState({
		descricao: "",
		tipo: "0",// 0-entrada 1-saída
		regra: {
			interna: {
				modalidade: "0",
				aliquota: 0,
				reducao: 0,
				aliquotaSt: 0,
				reducaoSt:0,
				cfop: "0",
				cstCsosn: "0",
			},
			externa: {
				modalidade: "0",
				aliquota: 0,
				reducao: 0,
				aliquotaSt: 0,
				reducaoSt:0,
				cfop: "0",
				cstCsosn: "0",
			},
			ipi: {
				enquadramento: "",
				aliquota: 0,
				cstIpi: "",
			},
			pisCofins: {
				aliquotaCofins: 0,
				aliquotaPis: 0,
				isencao: "",
				cst: "",
			},
		},
		regime: "1",// 1-Simples Nacional 2-Simples Nacional com excesso 3-Regime normal
		estados: {
			AC: 0,
			AL: 0,
			AP: 0,
			AM: 0,
			BA: 0,
			CE: 0,
			DF: 0,
			ES: 0,
			GO: 0,
			MA: 0,
			MT: 0,
			MS: 0,
			MG: 0,
			PA: 0,
			PB: 0,
			PR: 0,
			PE: 0,
			PI: 0,
			RJ: 0,
			RN: 0,
			RS: 0,
			RO: 0,
			RR: 0,
			SC: 0,
			SP: 0,
			SE: 0,
			TO: 0,
		},
		active: false,
		consumidor: "1",
		natureza: "0",
		ncm: [],
	})

	const [renderIncludeNcmModal, setRenderIncludeNcmModal] = useState(false)

	const [isEditMode, setIsEditMode] = useState(false)
	const [edited, setEdited] = useState(false)

	const [finalConsumer, setFinalConsumer] = useState(false)

	useEffect(() => {
		handleInputChange("consumidor",finalConsumer?"1":"0","consumidor")// 0=Normal; 1=Consumidor final;
	}, [finalConsumer])

	// Handlers
	const handleInsert = async event => {
		event.preventDefault()
		try {
			const token = getToken()
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				body: JSON.stringify({ ...rule }),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				navigate("/supplier/taxation")
		}
		catch (error) { console.error(error) }
	}

	const handleUpdate = async event => {
		event.preventDefault()
		try {
			const token = getToken()
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				body: JSON.stringify({ ...rule }),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				navigate("/supplier/taxation")
		}
		catch (error) { console.error(error) }
		finally {
			setEdited(false)
		}
	}

	const handleInputChange = (name, value, prop) => {
		/**
		 * It takes the input name, the value and the data type as parameters and then it
		 * sets the state of the rule object
		 * @param name - The name of the input that will be changed
		 * @param value - The value of the input
		 * @param prop - The property that will be changed
		*/

		setEdited(true) // if is in edit mode say that 1 or more values was manipulated

		switch (prop) {
			case "estados":
				setRule(prevState => ({
					...prevState,
					estados: { ...prevState.estados, [name]: value }
				}))
				break
			case "interna":
				setRule(prevState => ({
					...prevState,
					regra: {
						...prevState.regra,
						interna: { ...prevState.regra.interna, [name]: value }
					}
				}))
				break
			case "externa":
				setRule(prevState => ({
					...prevState,
					regra: {
						...prevState.regra,
						externa: { ...prevState.regra.externa, [name]: value }
					}
				}))
				break
			case "ipi":
				setRule(prevState => ({
					...prevState,
					regra: {
						...prevState.regra,
						ipi: { ...prevState.regra.ipi, [name]: value }
					}
				}))
				break
			case "pisCofins":
				setRule(prevState => ({
					...prevState,
					regra: {
						...prevState.regra,
						pisCofins: { ...prevState.regra.pisCofins, [name]: value }
					}
				}))
				break
			default:
				setRule(prevState => ({ ...prevState, [name]: value }))
				break
		}
	}

	const handleIncludeNcm = (ncms) => {

		setEdited(true) // if is in edit mode say that 1 or more values was manipulated

		setIncludeNcm([...ncms])
		setRule(prevState => ({ ...prevState, ncm: [...ncms] }))
	}

	const handleRemoveNcm = (removedncm) => {
		const ncms = includeNcm.filter(ncm => ncm !== removedncm)
		handleIncludeNcm(ncms)
	}

	const onRenderIncludeNcmModal = () => {
		setRenderIncludeNcmModal(prevState => !prevState)
	}

	// Effects
	useEffect(() => {
		setIsEditMode(((id !== undefined)) ? true : false)
	}, [id])

	useEffect(() => {

		if (isEditMode) {
			((async () => {

				const token = getToken()

				const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br/${id}`, {
					headers: { Authorization: `Bearer ${token}` },
				})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				const rule = await response.json()
				if(rule.default){
					navigate("/supplier/invoices/rules")
				}

				setRule(rule)
				setIncludeNcm(rule.ncm)

			})())
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, isEditMode])

	return (
		<SupplierRoleNavbar>

			{renderIncludeNcmModal === true && (
				<IncludeNcmModal
					onRenderModal={onRenderIncludeNcmModal}
					includeNcm={includeNcm}
					setIncludeNcm={handleIncludeNcm}
				/>
			)}

			<MainButton hierarchy="inferior" onClick={() => navigate(-1)} >
				<FontAwesomeIcon icon={faArrowLeft} />
				<span>Regras fiscais</span>
			</MainButton>

			<PageTitle title={isEditMode === true ? "Editar regra" : "Adicionar nova regra"} />

			<Form
				className="flex flex-column medium-gap medium-margin-vertical"
				onSubmit={event => { isEditMode === true ? handleUpdate(event) : handleInsert(event) }}
			>

				<div className="form-group">
					<SelectInput
						name="natureza"
						label="Natureza de operação"
						value={rule.natureza}
						onChange={({ target }) => handleInputChange(target.name, target.value)}
					>
						{SEFAZ_NATUREZAS_DE_OPERACAO.map((element, index) => (
							<option key={index} value={element.name}>
								{element.name}
							</option>
						))}
					</SelectInput>
					<TextInput
						name="descricao"
						label="Descrição"
						value={rule.descricao}
						onChange={({ target }) => handleInputChange(target.name, target.value)}
					/>
					<div className="small">
						<SelectInput
							name="tipo"
							label="Tipo"
							value={rule.tipo}
							onChange={({ target }) => handleInputChange(target.name, target.value)}
						>
							{SEFAZ_TIPOS_DE_OPERACAO.map((element, index) => (
								<option key={index} value={element.sefaz}>
									{element.name}
								</option>
							))}
						</SelectInput>
					</div>
					{/* <SelectInput
						name="indicadorPresenca"
						label="Indicador de presença"
						value={rule.indicadorPresenca}
						onChange={({ target }) => handleInputChange(target.name, target.value)}
					>
						{indPresencaOptions}
					</SelectInput> */}
				</div>

				<div>
					<CheckboxInput
						value={finalConsumer}
						label="Consumidor final"
						onChange={() => setFinalConsumer(finalConsumer => !finalConsumer)}
					/>
				</div>

				<div className="flex flex-column medium-gap element-border">

					<span className="big-x bold-font">ICMS - Venda local</span>

					<div className="flex">
						<SelectInput
							name="cstCsosn"
							label="CSOSN"
							value={rule.regra.interna.cstCsosn}
							onChange={({ target }) => handleInputChange(target.name, target.value, "interna")}
						>
						{SEFAZ_CSOSN.map((element, index) => (
							<option key={index} value={element.sefaz}>
								{element.name}
							</option>
						))}
						</SelectInput>
					</div>
					<div className="form-group">
						<SelectInput
							name="modalidade"
							label="modalidade Base de Cálculo"
							value={rule.regra.interna.modalidade}
							onChange={({ target }) => handleInputChange(target.name, target.value, "interna")}
						>
						{SEFAZ_MODBC.map((element, index) => (
							<option key={index} value={index}>
								{element}
							</option>
						))}
						</SelectInput>
						<NumberInput
							name="aliquota"
							label="Alíquota"
							value={rule.regra.interna.aliquota}
							onChange={({ target }) => handleInputChange(target.name, target.valueAsNumber, "interna")}
						/>
						<NumberInput
							name="reducao"
							label="Redução"
							value={rule.regra.interna.reducaoSt}
							onChange={({ target }) => handleInputChange(target.name, target.valueAsNumber, "externa")}
						/>
						<NumberInput
							name="aliquotaSt"
							label="Alíquota ICMS ST"
							value={rule.regra.interna.aliquotaSt}
							onChange={({ target }) => handleInputChange(target.name, target.valueAsNumber, "interna")}
						/>
						<NumberInput
							name="reducaoSt"
							label="Redução ST"
							value={rule.regra.interna.reducaoSt}
							onChange={({ target }) => handleInputChange(target.name, target.valueAsNumber, "externa")}
						/>
						<TextInput
							name="cfop"
							label="CFOP"
							value={rule.regra.interna.cfop}
							onChange={({ target }) => handleInputChange(target.name, target.value, "interna")}
						/>
					</div>
				</div>

				<div className="flex flex-column medium-gap element-border">

					<span className="big-x bold-font">ICMS - Venda externa</span>

					<div className="flex">
						<SelectInput
							name="cstCsosn"
							label="CSOSN"
							value={rule.regra.externa.cstCsosn}
							onChange={({ target }) => handleInputChange(target.name, target.value, "externa")}
						>
						{SEFAZ_CSOSN.map((element, index) => (
							<option key={index} value={element.sefaz}>
								{element.name}
							</option>
						))}
						</SelectInput>
					</div>
					<div className="form-group">
						<SelectInput
							name="modalidade"
							label="modalidade Base de Cálculo"
							value={rule.regra.externa.modalidade}
							onChange={({ target }) => handleInputChange(target.name, target.value, "externa")}
						>
						{SEFAZ_MODBC.map((element, index) => (
							<option key={index} value={index}>
								{element}
							</option>
						))}
						</SelectInput>
						<NumberInput
							name="aliquota"
							label="Alíquota"
							value={rule.regra.externa.aliquota}
							onChange={({ target }) => handleInputChange(target.name, target.valueAsNumber, "externa")}
						/>
						<NumberInput
							name="reducao"
							label="Redução"
							value={rule.regra.externa.reducao}
							onChange={({ target }) => handleInputChange(target.name, target.valueAsNumber, "externa")}
						/>
						<NumberInput
							name="aliquotaSt"
							label="Alíquota ICMS ST"
							value={rule.regra.externa.aliquotaSt}
							onChange={({ target }) => handleInputChange(target.name, target.valueAsNumber, "externa")}
						/>
						<NumberInput
							name="reducaoSt"
							label="Redução ST"
							value={rule.regra.externa.reducaoSt}
							onChange={({ target }) => handleInputChange(target.name, target.valueAsNumber, "externa")}
						/>
						<TextInput
							name="cfop"
							label="CFOP"
							value={rule.regra.externa.cfop}
							onChange={({ target }) => handleInputChange(target.name, target.value, "externa")}
						/>
					</div>
				</div>
				<div className="flex flex-column medium-gap element-border">

					<span className="big-x bold-font">Contribuições sociais (PIS/COFINS)</span>

					<div className="flex">
						<SelectInput
							name="cst"
							label="CST"
							value={rule.regra.pisCofins.cst}
							onChange={({ target }) => handleInputChange(target.name, target.value, "pisCofins")}
						>
							{SEFAZ_CST_PIS_COFINS.map((element, index) => (
								<option key={index} value={element.codigo}>
									{element.descricao}
								</option>
							))}
						</SelectInput>
					</div>
					<div className="form-group">

						<NumberInput
							name="aliquotaPis"
							label="Alíquota PIS"
							value={rule.regra.pisCofins.aliquotaPis}
							onChange={({ target }) => handleInputChange(target.name, target.valueAsNumber, "pisCofins")}
						/>

						<NumberInput
							name="aliquotaCofins"
							label="Alíquota Cofins"
							value={rule.regra.pisCofins.aliquotaCofins}
							onChange={({ target }) => handleInputChange(target.name, target.valueAsNumber, "pisCofins")}
						/>

					</div>
				</div>

				<div className="flex flex-column medium-gap element-border">
					<div className="medium-gap large-gap align-items-center">
						<span className="big-x bold-font">NCM(s) inclusos:</span>

						<MainButton hierarchy="minimal" onClick={() => onRenderIncludeNcmModal(true)} >
							<FontAwesomeIcon className="fa-icon" icon={faPlus} />
							<span>Adicionar</span>
						</MainButton>
					</div>

					<div className="flex small-gap flex-wrap">
						{includeNcm?.length == undefined || includeNcm.length === 0 ? (
							<SelectedTag tagName="Não especificado" />
						) : (
							includeNcm.map((ncm) => (
								<SelectedTag
									tagName={ncm}
									activeTagRemove={true}
									onTagRemove={() => handleRemoveNcm(ncm)}
								/>
							))
						)}
					</div>
				</div>
				<div className="flex flex-column medium-gap element-border">
					<div className="flex small-gap flex-wrap ">
					{REGION_LIST_BR.map((region, index)=>{
						return(
							<NumberInput
							key={index}
							name={region.region}
							label={region.region}
							value={rule.estados[region.region]}
							onChange={({ target }) => handleInputChange(target.name, target.valueAsNumber, "estados")}
						/>
						)
					})}
					</div>
				</div>

				<div className="flex">
					<MainButton disabled={edited === false && isEditMode === true} type="submit">
						<span>{isEditMode === true ? "Salvar alterações" : "Adicionar"}</span>
					</MainButton>
				</div>
			</Form>
		</SupplierRoleNavbar>
	)
}
