import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

// Icons
import {
	faBars
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Utils
import { RenderCart } from "offiziersmesser/lib/renderers"
import { printContent, toFixedImproved } from "offiziersmesser/lib/utils"
import { calculateItemPrice, calculateOrderSumPlusShipping } from "offiziersmesser/lib/helpers"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"

import CartItem from "src/modules/seller/store/cart/CheckoutItemCard/CartItem"

// Styles
import "./StoreCartNavbarCustomer.css"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setCart, resetCart } from "src/shared/store/slices/cartSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

export default function StoreCartNavbarCustomer({ children }) {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()
	// Store
	const items = useSelector(state => state.store)?.data.items
	const cart = useSelector(state => state.cart)
	const cartSize = cart.data.contents.length
	const supplier = useSelector(state => state.supplier)

	let {alias} = useParams()
	const store = useSelector(state => state.store)

	// States
	const [isSidebarOpen, setIsSidebarOpen] = useState(true)
	const [renderBudgetHandlerModal, setRenderBudgetHandlerModal] = useState(false)

	const [discount,] = useState(0)

	const [budgetURL, setBudgetURL] = useState("")
	const [userBudget, setUserBudget] = useState({})

	const total = calculateOrderSumPlusShipping(cart.data)

	function addAllItemsToCart(itemsToAdd) {
		const cartContents = [...cart.data.contents]

		itemsToAdd.forEach((itemInfo) => {
			const item = items.find(element => element.item === itemInfo.element.item)
			if (item) {
				cartContents.push({
					element: item,
					amount: itemInfo.amount,
				})
			}
		})

		dispatch(
			setCart({
				...cart,
				data: {
					...cart.data,
					alias: alias,
					address: {
						...cart.data.delivery.address,
						locality: store.data.locality,
					},
					contents: cartContents,
				},
			})
		)
	}

	// Handlers
	async function loadBudget() {
		try {
			const response = await fetch(`http://localhost:3030/quotation/${budgetURL}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json"
				}
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const userBudget = await response.json()
			addAllItemsToCart(userBudget.items)
		}
		catch{
			dispatch(createToast({tone: "failure", miliseconds: 5000, message: "Não foi possível gerar orçamento."}))
		}

	}

	async function getBudgetCode() {
		try {
			const response = await fetch("http://localhost:3030/quotation/generate/code", {
				method: "GET",
				headers: {
					"Content-Type": "application/json"
				}
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const userBudget = await response.json()

			setUserBudget(userBudget)
			setRenderBudgetHandlerModal(true)
		}
		catch{
			dispatch(createToast({tone: "failure", miliseconds: 5000, message: "Não foi possível gerar orçamento."}))
		}

	}
	async function createBudget(print) {
		const token = getToken();

		try {
			const response = await fetch("http://localhost:3030/quotation", {
				method: "POST",
				body: JSON.stringify({ items: cart.data.contents, ...userBudget }),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			if (print) {
				const response = await fetch("http://localhost:3030/resource/user", {
					headers: {Authorization: `Bearer ${token}`}
				})

				responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				const seller = await response.json()

				printContent(RenderCart(cart, total, discount, userBudget.quotation, seller, supplier))
				setRenderBudgetHandlerModal(false)
			} else {
				const textToCopy = userBudget.quotation

				try {
					await navigator.clipboard.writeText(textToCopy);
					dispatch(
						createToast({
							tone: "success",
							miliseconds: 5000,
							message: "Código copiado",
						})
					)
					setRenderBudgetHandlerModal(false)
				} catch (error) {
					console.error("Erro ao copiar para a área de transferência:", error);
				}
			}


		} catch (error) {
			dispatch(
				createToast({
					tone: "failure",
					miliseconds: 5000,
					message: "Não foi possível imprimir orçamento.",
				})
			);
		}
	}

	return (
		<>

		{renderBudgetHandlerModal &&
			<Modal onClose={() => setRenderBudgetHandlerModal(false)}>
				<div className="modal-container flex flex-column">
					<div className="header">
						<span className="big-x bold-font">Orçamento</span>
					</div>

					<div>
						<div className="header">
							<span className="big bold-font">Código do orçamento: {userBudget ? userBudget.quotation : ""}</span>
						</div>
					</div>

					<div className="cart-modal-budget">
						{cart.data.contents.map(item =>
							<div className="flex justify-content-space-between">
								<div className="flex medium-gap">
									<span className="bold-font">x{item.amount}</span>
									<span>{item.element.control.description}</span>
								</div>

								<span className="bold-font">R$  {toFixedImproved(
									calculateItemPrice(item.element)
									* item.amount, 2
								)}</span>
							</div>
						)}
					</div>
					<div className="flex justify-content-space-between cart-modal-budget-total">
						<span className="bold-font">Total:</span>
						<span className="bold-font">R$ {toFixedImproved(total - 0, 2)}</span>
					</div>
					<div className="flex justify-content-space-between">
						<MainButton
							hierarchy="minimal"
							onClick={() => setRenderBudgetHandlerModal(false)}
						>
							<span>Cancelar</span>
						</MainButton>
						<div className="flex small-gap">
							<MainButton
								onClick={() => createBudget(false)}
							>
								<span>Gerar orçamento</span>
							</MainButton>
							<MainButton
								onClick={() => createBudget(true)}
							>
								<span>Imprimir</span>
							</MainButton>
						</div>
					</div>
				</div>
			</Modal>
		}

		<div className={isSidebarOpen ? "store-cart-page-wrapper toggled" : "store-cart-page-wrapper"}>

			<div className="sidebar-button">
				<MainButton hierarchy="minimal" id={`${isSidebarOpen ? "close-sidebar" : "show-sidebar"}`} onClick={() => setIsSidebarOpen(true)}>
					<FontAwesomeIcon className="fa-icon" icon={faBars} />
				</MainButton>
			</div>

			<div id="sidebar" className="store-cart-sidebar-wrapper">

					<div className="navbar-brand small-margin-vertical grid-columns space-between-items wide-children center-items">
						<div className="flex medium-gap align-items-center">
							<span className="big-x bold-font">Carrinho</span>
							{cartSize > 0 && (
								<div className="flex cart-items-notification bold-font white">
									{cartSize}
								</div>
							)}
						</div>
						<MainButton
							hierarchy="minimal"
							id="close-navbar"
							onClick={() => setIsSidebarOpen(false)}
						>
							<FontAwesomeIcon className="fa-icon" icon={faBars} />
						</MainButton>
					</div>

				<div className="card-items-sidebar-content">
					<div className="cart-items-sidebar-menu high">
						{
							cart.data.contents.map((element, index) => (
								<CartItem key={index} item={element} />
							))
						}
					</div>
				</div>

				<div className={cartSize > 0 ? "sidebar-footer bold-font cart-has-content" : "sidebar-footer bold-font"}>
					{cartSize > 0 ? (
						<div className="flex flex-column small-gap wide small-padding-horizontal small-padding-top">
							<div className="cart-items-sidebar grid-columns space-between-items element-border wide wide-children" >
								<p className="bold-font big">Total:</p>
								<p className="bold-font big">{`R$ ${toFixedImproved(total - discount, 2)}`}</p>
							</div>


							<div className="wide wide-children flex tiny-gap">
								<MainButton
									hierarchy="minimal"
									onClick={() => getBudgetCode()}
								>
									<span>Orçamento</span>
								</MainButton>
							</div>


							{cartSize > 0  && (
								<div className="wide wide-children flex tiny-gap">
									<MainButton
										hierarchy="minimal"
										onClick={() => dispatch(resetCart())}
									>
										<span>Limpar carrinho</span>
									</MainButton>
								</div>
							)}
						</div>
					) : (
						<div className="wide flex flex-column small-gap">
							<TextInput
								label="Orçamento"
								placeholder="Insira o código da lista de orçamento"
								value={budgetURL}
								onChange={e => setBudgetURL(e.target.value)}
							/>
							<MainButton onClick={loadBudget} disabled={budgetURL===""}>
								<span>Carregar</span>
							</MainButton>
						</div>
					)}
				</div>
			</div>

			<div className="page-content store-cart-page-content">
				<div className="store-cart-container-fluid">
					{children}
				</div>
			</div>
		</div>
		</>
	)
}
