import { BrowserRouter as Router } from "react-router-dom"

// Components
import RoutesWrapper from "src/routes/RoutesWrapper"
import Toasts from "src/shared/components/custom/Toasts/Toasts"

// Global sections styles
import "src/shared/styles/App.css"

export default function App() {
	return (
		<Router>
			<RoutesWrapper />
			<Toasts />
		</Router>
	)
}
