import { useState, useEffect, ChangeEvent } from "react"
import { useSelector, useDispatch } from "react-redux"

// Utils
import {
	toUnsignedFloat,
	toFixedImproved,
} from "offiziersmesser/lib/utils"
import { calculateItemCost, calculateItemPrice } from "offiziersmesser/lib/helpers"


// Store
import { RootState } from "src/shared/store/reducers"
import { updateWorkableItem } from "src/shared/store/slices/workableItemsSlice"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import MainButton from "src/shared/components/standard/MainButton"
import NumberInput from "src/shared/components/standard/NumberInput"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"
import ExtendableTextInput from "src/shared/components/custom/ExtendableTextInput/ExtendableTextInput"

export default function GroupsControlHandler() {

	const dispatch = useDispatch()

	// Store
	const item = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)

	// States
	const [suddenCostsInformationModal, setSuddenCostsInformationModal] = useState(false)
	const [precificationInformationModal, setPrecificationInformationModal] = useState(false)

	const [cost, setCost] = useState<number>(calculateItemCost(item))
	const [price, setPrice] = useState<number>(calculateItemPrice(item))

	const [currencyMarkup, setCurrencyMarkup] = useState<number>(0)

	function setMarkupInPercentage(value: number) {
		dispatch(updateWorkableItem({ ...item, control: { ...item.control, markup: Number(toFixedImproved(value, 2)) } }))
		setCurrencyMarkup(Number(toFixedImproved((value / 100) * cost, 2)))
	}
	function setMarkupInCurrency(value: number) {
		setMarkupInPercentage((value / cost) * 100)
	}

	function setPriceMarkup(value: number) {
		setMarkupInPercentage(((value - cost) / cost) * 100)
	}

	useEffect(() => {
		setCost(calculateItemCost(item))
		setPrice(Number(toFixedImproved(calculateItemPrice(item), 2)))
	}, [item])

	return (
		item &&
		<div className="grid medium-gap">
			{(suddenCostsInformationModal === true) && (
				<Modal title="O que são custos eventuais" onClose={() => setSuddenCostsInformationModal(false)}>
					<div className="grid small-gap">
						<span>Custos eventuais são quaisquer despesas relacionadas ao produto que não sejam custo de produção, como custo de instalação por exemplo.</span>
						<span className="small heavy-font">Atenção! Não faz parte do cálculo de custo do produto e é apresentado separadamente do preço.</span>
					</div>
				</Modal>
			)}
			{(precificationInformationModal === true) && (
				<Modal onClose={() => setPrecificationInformationModal(false)}>
					<span className="medium regular-font">Preço apenas como "soma dos custos + lucro", sem impostos.</span>
				</Modal>
			)}

			<span className="big-x bold-font">Precificação</span>

			<div className="grid fill-tiny medium-gap">
				<NumberInput
					label="Custo de produção"
					value={item.costs.production}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						const value = e.target.valueAsNumber
						dispatch(
							updateWorkableItem({ ...item, costs: { ...item.costs, production: value } })
						)
					}}
				/>

				<ExtendableTextInput
					type="number"
					label="Custos eventuais"
					value={item.costs.sudden.value}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						dispatch(
							updateWorkableItem({ ...item,  costs: { ...item.costs, sudden: { ...item.costs.sudden, value: toUnsignedFloat(e.target.value, 3) } }  })
						)
					}
					after={
						<MainButton hierarchy="inferior" onClick={() => setSuddenCostsInformationModal(true)}>
							<FontAwesomeIcon className="fa-icon" icon={faInfoCircle as Icon} />
						</MainButton>
					}
				/>
			</div>

			<CheckboxInput
				label="Custos eventuais personalizáveis pelo vendedor"
				checked={item.costs.sudden.custom === true}
				onChange={
					(e: ChangeEvent<HTMLInputElement>) => dispatch(updateWorkableItem({ ...item,  costs: { ...item.costs, sudden: { ...item.costs.sudden, custom: !item.costs.sudden.custom }}  }))
				}
			/>

			{
				// automaticPrice === true && (
				// <div className="grid small-gap">
				// 	<NumberInput
				// 		label="Percentual de lucro"
				// 		value={item.control.markup}
				// 		onChange={
				// 			(e: ChangeEvent<HTMLInputElement>) => dispatch(updateWorkableItem({ ...item, control: { ...item.control, markup: toUnsignedFloat(e.target.valueAsNumber, 3) } }))
				// 		}

				// 	/>

				// 	<NumberInput
				// 		label="Quantidade na nota fiscal"
				// 		value={item.control.quantity}
				// 		disabled={(item.control.available === false)}
				// 		onChange={
				// 			(event: ChangeEvent<HTMLInputElement>) => dispatch(updateWorkableItem({ ...item, control: { ...item.control, quantity: toUnsignedFloat(event.target.valueAsNumber, 3) } }))
				// 		}
				// 	/>

				// 	<NumberInput
				// 		label="ICMS ST"
				// 		value={item.accounting.rule.icms.st.rate}
				// 		onChange={
				// 			(event: ChangeEvent<HTMLInputElement>) => setIcms({
				// 				...item.accounting.rule.icms,
				// 				st: {
				// 					...item.accounting.rule.icms.st,
				// 					rate: event.target.value
				// 				}
				// 			})
				// 		}
				// 	/>

				// 	<NumberInput
				// 		label="IPI"
				// 		value={item.accounting.rule.pis.in.rate}
				// 		onChange={
				// 			(event: ChangeEvent<HTMLInputElement>) => setIpi({ ...item.accounting.rule.ipi, rate: event.target.value })
				// 		}
				// 	/>

				// 	<MainButton onClick={() => murrayHandle()}>
				// 		Calcular
				// 	</MainButton>
				// </div>
				// )
			}

			<div className="grid fill-normal medium-gap">
				<NumberInput
					label="Lucro em porcentagem"
					value={item.control.markup}
					disabled={item.costs.production === 0}
					onChange={(e: ChangeEvent<HTMLInputElement>) => setMarkupInPercentage(e.target.valueAsNumber)}
				/>
				<NumberInput
					label="Lucro em valor moeda"
					value={currencyMarkup}
					disabled={item.costs.production === 0}
					onChange={(e: ChangeEvent<HTMLInputElement>) => setMarkupInCurrency(e.target.valueAsNumber)}
				/>
				<NumberInput
					label="Valor de venda"
					value={price}
					disabled={item.costs.production === 0}
					onChange={(e: ChangeEvent<HTMLInputElement>) => setPriceMarkup(e.target.valueAsNumber)}
				/>
			</div>

{/*			<div className="grid medium-gap left-items">
				<div className="grid">
					<span className="big-x bold-font"> {"R$ " + tax} </span>
					<span className="bold-font"> {"Custo de R$ " + toFixedImproved(cost, 2)} </span>
					<span className="bold-font"> {"Lucro de R$ " + toFixedImproved(price - cost, 2)} </span>
					<span className="bold-font">
						{tax !== 0
							? `Imposto de R$ ${tax - (Number(toFixedImproved(price - cost, 2)) + Number(toFixedImproved(cost, 2)))}`
							: "Imposto de R$ 0.00"
						}
					</span>
				</div>
			</div>*/}
		</div>
	)
}
