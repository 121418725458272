import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setUser } from "src/shared/store/slices/userSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"

// Components
import Form from "src/shared/components/standard/Form"
import MainButton from "src/shared/components/standard/MainButton"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"
import { RootState } from "src/shared/store/reducers"

type ecommerceModel =
{
    active: boolean,
    checkout: {
        active: boolean
    },
    showcase: {
        active: boolean
    }
}

export default function Ecommerce() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector((state:RootState) => state.user)

	// States
	const [ecommerce, setEcommerce] = useState<ecommerceModel>(user.ecommerce)

	const handleUpdateEcommerce = async () => {
		try {
			const token = getToken()

			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/supplier/ecommerce", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ ecommerce: ecommerce }),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({ ...user, ecommerce: ecommerce }))
			dispatch(createToast({ tone: "success", miliseconds: 3000, message: "Atualizado com sucesso" }))
		} catch (error) {
			console.error(error)
			dispatch(createToast({ tone: "failure", miliseconds: 5000, message: "Ocorreu algum erro, tente novamente" }))
		}
	}

	return (
		<SupplierRoleNavbar>

			<div className="medium-margin-bottom">
				<PageTitle
					title="Catálogo virtual"
					description="Use para ativar ou desativar a loja ou algum componente em específico"
				/>
			</div>

			<Form className="grid medium-gap medium-margin-vertical">
				<CheckboxInput
					label="Acesso público à loja virtual"
					checked={ecommerce.active}
					onChange={() => setEcommerce(ecommerce => ({ ...ecommerce, active: !ecommerce.active }))}
				/>

				<CheckboxInput
					label="Vitrine de produtos"
					checked={ecommerce.showcase.active}
					onChange={() =>
						setEcommerce(ecommerce => ({
							...ecommerce,
							showcase: {
								...ecommerce.showcase,
								active: !ecommerce.showcase.active,
							},
						}))
					}
				/>

				<CheckboxInput
					label="Pedidos pela loja virtual"
					checked={ecommerce.checkout.active}
					onChange={() =>
						setEcommerce(ecommerce => ({
							...ecommerce,
							checkout: {
								...ecommerce.checkout,
								active: !ecommerce.checkout.active,
							},
						}))
					}
				/>

				<div className="flex medium-margin-top">
					<MainButton	onClick={handleUpdateEcommerce}>
						<span>Alterar opções</span>
					</MainButton>
				</div>
			</Form>
		</SupplierRoleNavbar>
	)
}
