import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import MainButton from "src/shared/components/standard/MainButton"
import SelectInput from "src/shared/components/standard/SelectInput"
import RadioButtonInput from "src/shared/components/standard/RadioButtonInput"

// Store
import { createToast } from "src/shared/store/slices/toastsSlice"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"

type RegraTax = {
	entry: string
	descricao: string
}


export default function TaxDefinitionModal() {
	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()


	// States
	const [regraEntry, setRegraEntry] = useState("")
	const [mode, setMode] = useState(regraEntry === "" ? "2" : "1")
	const [regras, setRegras] = useState<RegraTax[]>([])
	const [renderTaxDefinitionModal, setRenderTaxDefinitionModal] = useState(false)
	const [loading, setLoading] = useState(true)


	// Handlers
	const handleGetRegras = async () => {
		try {
			const token = getToken()
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()

			setRegras(data)
		}
		catch (error) { console.error(error) }
	}
	const handleSelectActiveRegra = async () => {
		try {
			const token = getToken()
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br/active`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				}
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const entry = await response.json()
			if (entry !== "")
				setMode("1")

			setRegraEntry(entry)
		}
		catch (error) { console.error(error) }
	}
	const handleUpdateRegra = async () => {
		try {
			const token = getToken()
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br/active`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ entry: regraEntry, active: mode === "1" ? true : false }),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(createToast({
				tone: "success",
				miliseconds: 5000,
				message: "Regra fiscal atualizada com sucesso",
				dismissable:true
			}))
		}
		catch (error) {
			dispatch(createToast({
				tone: "failure",
				miliseconds: 5000,
				message: "Houve um erro ao atualizar regra fiscal"
			}))
			console.error(error)
		}
	}

	const initializeTaxDefinitions = async () => {
		await handleSelectActiveRegra()
		await handleGetRegras()
		setLoading(false)
	}

	// Effects
	useEffect(() => {
		initializeTaxDefinitions()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
	<>
		{renderTaxDefinitionModal === true && (
			<Modal title="Definição de regra tributária" onClose={() => setRenderTaxDefinitionModal(false)}>
				{loading
					? <ComponentLoader />
					: <>
						<div className="grid medium-gap">
							<RadioButtonInput
								label="Padrão para todos os itens"
								name="regra"
								defaultChecked={mode === "1"}
								onChange={() => setMode("1")}
							/>
							<RadioButtonInput
								label="Personalizada para cada item"
								name="regra"
								defaultChecked={mode === "2"}
								onChange={() => {
									setMode("2")
									setRegraEntry(regras[0].entry)
								}}
							/>
						</div>

						{mode === "1" && (
							<div className="grid small-gap fill-normal">
								<SelectInput
									label="Regra padrão"
									name="receita"
									value={regraEntry}
									onChange={e => setRegraEntry(e.target.value)}
								>
									{regras.map((regra: RegraTax) =>
										<option value={regra.entry} key={regra.entry}>{regra.descricao}</option>
									)}
								</SelectInput>
							</div>
						)}

						<MainButton
							onClick={() => handleUpdateRegra()}
						>
							<span>Definir regra fiscal</span>
						</MainButton>
					</>
				}
			</Modal>
		)}

		<MainButton hierarchy="low" onClick={() => setRenderTaxDefinitionModal(true)}>
			<span>Definir regra fiscal</span>
		</MainButton>
	</>
	)
}
