// Library imports
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

// Store
import { setCart } from "src/shared/store/slices/cartSlice"
import { updateStore } from "src/shared/store/slices/storeSlice"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle, faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"

// Components
import StoreItemCard from "./StoreItemCard/StoreItemCard"
import StoreCartbar from "./cart/StoreCartbar"

import Notice from "src/shared/components/custom/Notice/Notice"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import MainButton from "src/shared/components/standard/MainButton"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

// Styles
import "./Store.css"

import SearchItems from "src/shared/components/custom/SearchItems/SearchItems"
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

export default function Store() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector(state => state.user)
	const store = useSelector(state => state.store)
	const cart = useSelector(state => state.cart)

	// States
	const [loading, setLoading] = useState(true)
	const [searchResults, setSearchResults] = useState([])
	const [hideCart, setHideCart] = useState(true)

	// Handlers
	const getStore = async () => {
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + "/store/" + user.alias)
			responseCodeHandler(response)

			if (response.ok) {
				const res = await response.json()
				dispatch(
					updateStore({
						alias: user.alias,
						data: res,
					})
				)
				// Updates some guest's session data if not done previously
				dispatch(
					setCart({
						...cart,
						data: {
							...cart.data,
							seller: {
								...cart.data.seller,
								identifier: user.identifier,
							},
							delivery: {
								...cart.data.delivery,
								shipping: res.shipping,
							},
						},
					})
				)
			} else {
				throw new Error(`HTTP error status ${response.status}`)
			}
			setLoading(false)
		}
		catch(error) {
			console.error(error)
		}
	}

	// Effects
	useEffect(() => {
		getStore()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.alias])


	const items = searchResults.length > 0 ? searchResults : store.data.items

	return (
		<SupplierRoleNavbar
			topLeft={
				(cart.data.contents.length > 0) && (hideCart === true) &&
				<MainButton
					hierarchy="inferior"
					onClick={() => setHideCart(!hideCart)}
				>
					<FontAwesomeIcon className="fa-icon" icon={hideCart ? faAngleDoubleLeft : faAngleDoubleRight} />
					<span>Pedido</span>
				</MainButton>
			}
			context={
				(cart.data.contents.length > 0) && (hideCart === false) &&
				<div>
					{(hideCart === false) &&
						<div className="context-topbar">
							<MainButton
								hierarchy="inferior"
								onClick={() => setHideCart(!hideCart)}
							>
								<FontAwesomeIcon className="fa-icon" icon={hideCart ? faAngleDoubleLeft : faAngleDoubleRight} />
								<span>Pedido</span>
							</MainButton>
						</div>
					}
					<StoreCartbar />
				</div>
			}
		>
			{(loading === true) ? (
				<ComponentLoader />
			) : (
				(store.data.items.length === 0) ? (
					<Notice tone="warning">
						<div className="grid-columns medium-gap left-items">
							<FontAwesomeIcon className="fa-icon" icon={faExclamationTriangle} />
							<div className="grid small-gap">
								<span className="small">Parece que não há produtos disponíveis para venda.</span>
								<div className="bold-font tiny">
									<span>Confira a situação do estoque e tente novamente.</span>
								</div>
							</div>
						</div>
					</Notice>
				) : (
					<div id="catalog" className="grid medium-gap">

						{/*<PageTitle title="Loja" />*/}
						<SearchItems setSelectedItems={setSearchResults} isStore={true}/>
						<div className="grid small-gap fill-large center-items wide-children">
						{items.map((item, index) =>
							<StoreItemCard
								key={index}
								storeAlias={user.alias}
								storeItem={item}
								element={cart}
								storeCheckout={store.data.ecommerce.checkout}
							/>
						)}
						</div>

					</div>
				)
			)}
		</SupplierRoleNavbar>
	)
}
