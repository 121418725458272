import {  useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Utils
import { SEFAZ_ORIGENS } from "offiziersmesser/lib/utils"

// Store
import { RootState } from "src/shared/store/reducers"
import { updateWorkableItem } from "src/shared/store/slices/workableItemsSlice"

// Components
import TextInput from "src/shared/components/standard/TextInput"
import SelectInput from "src/shared/components/standard/SelectInput"
import { getToken } from "src/shared/services/general"
import { setTaxationBr } from "src/shared/store/slices/taxationBrSlice"

export default function AccountingHandler() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()


	// Store
	const user = useSelector((state: RootState) => state.user)
	const item = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)


	const taxRules = useSelector((state: RootState) => state.taxationBr)

	const [isNcmValid, setIsNcmValid] = useState(false)
	const [regraDefault, setRegraDefault] = useState(false)
	const [regra, setRegra] = useState("")

	const getTaxations = async () => {
		try {
			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br`, {
				headers: { Authorization: `Bearer ${token}` },
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const taxRules = await response.json()

			dispatch(setTaxationBr(taxRules))
		} catch (error) {
			console.error("GET TAX RULES ERROR", error)
		}
	}

	useEffect(() => {
		getTaxations()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function dispatchSetItemAccountingDetails(rule: string | number, field: string) {
		dispatch(
			updateWorkableItem({
				...item,
				accounting: {
					...item.accounting,
					details:{
						...item.accounting.details,
						[field]: rule
					}
				}
			})
		)
	}

	function dispatchSetItemAccountingTaxation(entry: string ) {
		dispatch(
			updateWorkableItem({
				...item,
				accounting: {
					...item.accounting,
					taxation: entry
				}
			})
		)
	}

	async function dispatchSetItemAccountingDetailsNcm(value: string | number) {

		const token = getToken()
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/item/taxation/ncm`, {
				method: "POST",
				body: JSON.stringify({
					ncm: value,
					uf: user.administrative.locality.region.toUpperCase()
				}),
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
			})

			responseCodeHandler(response)

			if (response.ok)
				setIsNcmValid(true)
			else
				setIsNcmValid(false)

		} catch (error) {
			console.error("ERROR NCM - ", error)
		}
	}

	const handleSelectActiveRegra = async () =>{

		try {
			const token = getToken()
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br/active`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				}
			})
			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()


			if (data !== ""){
				setRegraDefault(true)
				setRegra(data)
			}else{
				setRegra(item.accounting.taxation)
			}

		}
		catch (error) { console.error(error) }
	}

	// Effects
	useEffect(() => {
		dispatchSetItemAccountingDetailsNcm(item.accounting?.details.ncm)
		handleSelectActiveRegra()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		dispatchSetItemAccountingTaxation(regra)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [regra])

	return (
		item &&
		<div className="flex flex-column medium-gap">
			<span className="big-x bold-font">Tributação</span>
			<div className="grid medium-gap fill-normal">
				<SelectInput
					label="Origem"
					value={item.accounting?.details.origin}
					onChange={e => dispatchSetItemAccountingDetails(e.target.value, "origin")}
				>
					{SEFAZ_ORIGENS.map((origem,index)=>{
						return(
							<option key={index} value={origem.sefaz}>{origem.name}</option>
						)
					})}
				</SelectInput>
				<TextInput
					label="NCM"
					value={item.accounting?.details.ncm}
					hint={(isNcmValid === false) ? "inválido" : undefined}
					tone="failure"
					onBlur={e => dispatchSetItemAccountingDetailsNcm(e.target.value)}
					onChange={e => dispatch(
						updateWorkableItem({
							...item,
							accounting: {
								...item.accounting,
								details:{
									...item.accounting.details,
									ncm: e.target.value
								}
							}
						})
					)}
				/>
				<TextInput
					label="CEST"
					value={item.accounting?.details.cest}
					onChange={e => dispatchSetItemAccountingDetails(e.target.value, "cest")}
				/>
				<SelectInput
					label="Regra"
					disabled={regraDefault}
					value={regra}
					onChange={e => setRegra(e.target.value)}
				>
					{taxRules.map((regra,index)=>{
						return(
							<option key={index} value={regra.entry}>{regra.descricao}</option>
						)
					})}
				</SelectInput>
			</div>
		</div>
	)
}
