import {  useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { faExclamationCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Models
import ToastModel from "./toast"

// Store
import { RootState } from "../../../store/reducers"
import { deleteToast } from "../../../store/slices/toastsSlice"

// Components
import Notice from "../Notice/Notice"
import MainButton from "../../standard/MainButton"

// Styles
import "./Toasts.css"

type ToastProps = {
	position?: string
}

export default function Toast({
	position
}: ToastProps) {

	const dispatch = useDispatch()

	// Store
	const toasts = useSelector((state: RootState) => state.toasts)

	// Effects
	useEffect(() => {

		if (!toasts.length) return

		// Finds the first auto dismissable toast in the array else returns undefined
		// @ts-ignore-next-line
		const autoDismissableToast: ToastModel = toasts.find((toast: ToastModel) => typeof toast.miliseconds === "number")

		if (autoDismissableToast === undefined) return

		const interval = setInterval(() => {
			if(autoDismissableToast.key)dispatch(deleteToast(autoDismissableToast.key))
		}, autoDismissableToast.miliseconds)

		return () => { clearInterval(interval) }

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toasts])

	return (

		<div className={`Toast-container ${position || "bottom-right"}`}>

			{toasts.length > 0 && toasts.map((toast: ToastModel) => (
				(toast.message !== undefined) ?
				(
					<Notice
						key={toast.key}
						size={toast.size}
						tone={toast.tone}
						onClick={() => (toast.dismissable === true) && toast.key && dispatch(deleteToast(toast.key))}
					>
						<div className="grid-columns small-gap left-items">
							<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle as Icon} />
							<span className="small">{toast.message}</span>
						</div>
					</Notice>
				) : (
					<div
						key={toast.key}
						className="Toast"
					>
						{
							(toast.dismissable === true) &&
							// <div className="ToastDismissButton-container">
								<MainButton
									hierarchy="minimal"
									onClick={() => toast.key && dispatch(deleteToast(toast.key))}
								>
									<FontAwesomeIcon icon={faTimes as Icon} />
									<span>Fechar</span>
								</MainButton>
							// </div>
						}
						{toast.children}
					</div>
				)
			))}
		</div>
	)
}

