import { ReactNode, useState } from "react"

// Styles
// import "./ComponentLoader.css"

// DOC: https://app.clickup.com/30992350/v/dc/xhtyy-1963/xhtyy-2123

type MainButtonProps = {
	id?: string
	name?: string
	type?: "button" | "submit" | "reset"
	title?: string
	onClick?: () => void
	disabled?: boolean
	children?: ReactNode

	fontSize?: "big" | "medium" | "small"
	hierarchy?: "normal" | "low" | "inferior" | "minimal"
	overrideClass?: string
}

export default function MainButton({
	id,
	name,
	type = "button",
	title,
	onClick,
	disabled,
	children,

	fontSize = "medium",
	hierarchy = "normal",
	overrideClass,
}: MainButtonProps) {

	const [loading, setLoading] = useState(false)

	const onClickHandler = async () => {
		if (loading || !onClick) return
		try {
			setLoading(true)
			await onClick()
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	return (
		<button
			id={id}
			name={name}
			type={type}
			title={title}
			onClick={onClickHandler}
			className={overrideClass || `unselectable element-highlight action-${hierarchy} ${fontSize}`}
			disabled={disabled || loading}
		>
			{children}
		</button>
	)
}