import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Utils
import { getToken } from "src/shared/services/general"

// CSS
import "./OrderBlockSearch.css"

// Store
import { RootState } from "src/shared/store/reducers"
import { setAssessments } from "src/shared/store/slices/assessmentsSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"

import AssessmentBlockSearch from "src/modules/supplier/transactions/components/UserBlockSearch"

interface Assessment {
    reference: string;
    taxpayer: string;
    general: {
        createdBy: number;
        administrative: {
            company?: string;
            byname?: string;
            locality: {
                city: string;
                road: string;
                number: string;
                area: string;
                postal: string;
                region: string;
                country: string;
                extra: string;
            };
            reference: {
                name: string;
                email: string;
                phone: {
                    prefix: string;
                    subscriber: string;
                    destination: string;
                };
                surname: string;
            };
        };
    };
}

export default function OrderBlockSearch({setOrders}: any) {
	const dispatch = useDispatch()
	const assessments = useSelector((state: RootState) => state.assessments)
	const responseCodeHandler = useResponseCodeHandler()

	const [, setLoading] = useState(true)
    const [, setOpenOptions] = useState(false)

    const [, setSelectedCustomer] = useState<Assessment>()

	const [userSearchValue, setUserSearchValue] = useState("")
	const [assessmentFound, setAssessmentFound ] = useState(false)

    async function selectCustomer(customer: any) {
        try {
            setSelectedCustomer(customer)
            await handlerSelectOrders(customer.taxpayer)
        }
        catch {
            dispatch(createToast({tone: "failure", miliseconds: 5000, message: "Algo deu errado. Tente novamente."} as any))
        }
        finally {
            setOpenOptions(false)
        }


    }

    async function handlerSelectOrders(taxpayer: string) {

        if(taxpayer)
        try {
            setLoading(true);

            const token = getToken();

            const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/orders/customer`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
                },
				body:JSON.stringify({taxpayer})
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const orders = await response.json()

            setOrders(orders);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {

		(async () => {
			try {
				setLoading(true)

				const token = getToken()

				const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/assessments`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					}
				})

				responseCodeHandler(response)

				if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				const assessments = await response.json()

				dispatch(setAssessments(assessments))
			} catch (error) {
				console.error(error)
			} finally {
				setLoading(false)
			}
		})()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(()=>{
		if(userSearchValue){
			selectCustomer(assessments.find((a)=> a.taxpayer === userSearchValue))
		}else{
			setOrders([])
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[userSearchValue])

	return (
        <div>
            {/* <div className="grid fill-huge input-customer-search">
                <TextInput
                    label="Cliente"
                    name="byname"
                    value={
                        selectedCustomer !== undefined ?
                        `${selectedCustomer.general.administrative.reference.name} ${selectedCustomer.general.administrative.reference.surname} - ${selectedCustomer.taxpayer}`
                        :
                        ""
                    }
                    onFocus={() => setOpenOptions(true)}
                    placeholder="Busque por nome, CPF ou CNPJ"
                    onChange={(e) => ""}
                />
            </div>

            { openOptions &&
			<>
                <div className="grid fill-huge box-customer-search">
                    {
                        assessments.map((customer, index) => {
                            return(
                                <button key={index} onClick={() => selectCustomer(customer)}>{customer.general.administrative.reference.name} {customer.general.administrative.reference.surname} - {customer.taxpayer}</button>
                            )
                        })
                    }
                </div>
			</>
            } */}
			<div className="grid fill-huge ">

					<AssessmentBlockSearch
						searchValue={userSearchValue}
						setSearchValue={setUserSearchValue}
						assessmentFound={assessmentFound}
						setAssessmentFound ={setAssessmentFound}
						placeholder={`Busque pelo cliente`}
					/>
			</div>

        </div>
	)
}
