import { combineReducers, configureStore } from "@reduxjs/toolkit"

import balances from "./slices/balancesSlice"
import cart from "./slices/cartSlice"
import employments from './slices/employmentsSlice'
import assessments from './slices/assessmentsSlice'
import assessment from './slices/assessmentSlice'
import files from './slices/filesSlice'
import guest from "./slices/guestSlice"
import invoices from "./slices/invoicesSlice"
import items from "./slices/itemsSlice"
import orders from "./slices/ordersSlice"
import session from "./slices/sessionSlice"
import toasts from "./slices/toastsSlice"
import transactions from "./slices/transactionsSlice"
import taxationBr from "./slices/taxationBrSlice"
import user from "./slices/userSlice"
import supplier from "./slices/supplierSlice"
import workableItems from './slices/workableItemsSlice'
import store from './slices/storeSlice'


const rootReducer = combineReducers({
	balances,
	cart,
	assessment,
	assessments,
	employments,
	files,
	guest,
	invoices,
	items,
	orders,
	session,
	toasts,
	transactions,
	taxationBr,
	user,
	workableItems,
    store,
    supplier
})

const reduxStore = configureStore({ reducer: rootReducer })

export default reduxStore

export type RootState = ReturnType<typeof reduxStore.getState>
export type AppDispatch = typeof reduxStore.dispatch
