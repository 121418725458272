// Models
import { PhoneModel } from "src/shared/models/user"

// Components
import StepForm from "src/shared/components/custom/StepForm/StepForm"
import MainButton from "src/shared/components/standard/MainButton"
import StepContainer from "src/shared/components/custom/StepForm/StepContainer"
import PassphraseInputValidationStatusesGroup from "src/shared/components/custom/PassphraseInputValidationStatusesGroup/PassphraseInputValidationStatusesGroup"
import PhoneValidationForm from "../components/PhoneValidationForm"

type PasswordResetProps = {
	phone: PhoneModel
	isCodeSent: boolean
	setIsCodeSent: (value: boolean) => void
	setIsCodeSentTwice: (value: boolean) => void
	isCodeSentTwice: boolean
	passphrase: string
	setPassphrase: (value: string) => void
	setIsPassphraseStrong: (value: boolean) => void
	characters: string[]
	setCharacters: (value: string[]) => void
	setPassphraseReset: (value: boolean) => void
}

export default function PasswordReset({
	phone,
	isCodeSent,
	setIsCodeSent,
	setIsCodeSentTwice,
	setPassphraseReset,
	passphrase,
	setPassphrase,
	setIsPassphraseStrong,
	isCodeSentTwice,
	characters,
	setCharacters,
}: PasswordResetProps) {

	return (
		<div className="grid medium-gap">

			<div className="grid left-items">
				<MainButton
					hierarchy="inferior"
					onClick={() => setPassphraseReset(false)}
				>
					<span>Voltar ao início</span>
				</MainButton>
			</div>

			<StepForm
				componentTitle="Redefinir senha"
				onClickConclude={() => setPassphraseReset(false)}
			>

				<StepContainer stepCondition={(passphrase.length >= 8)}>
					<PassphraseInputValidationStatusesGroup
						passphrase={passphrase}
						setPassphrase={setPassphrase}
						setIsPassphraseStrong={setIsPassphraseStrong}
					/>
				</StepContainer>

				<PhoneValidationForm
					characters={characters}
					phone={phone}
					isCodeSent={isCodeSent}
					isCodeSentTwice={isCodeSentTwice}
					setCharacters={setCharacters}
					setIsCodeSent={setIsCodeSent}
					setIsCodeSentTwice={setIsCodeSentTwice}
				/>
			</StepForm>
		</div>
	)
}
