import { useState } from "react"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons"

// Styles
import "./PaginationForm.css"

export default function PaginationForm({ children, buttonsUp }) {

	const [currentPage, setCurrentPage] = useState(0)
	const [itemsPerPage,] = useState(10)

	const startIndex = currentPage * itemsPerPage
	const endIndex = startIndex + itemsPerPage

	const currentItems = children.slice(startIndex, endIndex)
	const pages = Math.ceil(children.length / itemsPerPage)

	return (
		<>
			{children.length > 0 && (
				<div className="pagination-container">
					<div className="grid-columns large-gap center-items wide-children">
						<button
							disabled={currentPage === 0}
							onClick={(e) => setCurrentPage(currentPage - 1)}
						>
							<FontAwesomeIcon icon={faChevronCircleLeft} className="fa-icon big" />
						</button>

						<span >{`${startIndex + 1} - ${currentPage === (pages - 1) ? (children.length) : (endIndex)} de ${children.length}`}</span>

						<button
							disabled={currentPage === (pages - 1)}
							onClick={(e) => setCurrentPage(currentPage + 1)}
						>
							<FontAwesomeIcon icon={faChevronCircleRight} className="fa-icon big" />
						</button>
					</div>
				</div>
			)}

			{currentItems.map((items, index) => {
				return (
					<div key={index} >
						{items}
					</div>
				)
			})}
		</>
	)
}
