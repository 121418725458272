import { useCallback, useEffect, useState } from "react"
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"
import SelectInput from "src/shared/components/standard/SelectInput"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"

export default function OpenBalanceBlock({
	setBalancesOpen,
	setCashbox,
	initialValue,
	setInitialValue,
	selectedCashbox
}) {

	const responseCodeHandler = useResponseCodeHandler()

	// States
	const [cashboxes, setCashboxes] = useState([])

	// Handlers
	const getCashboxes = useCallback(async () => {
		try {
			const token = getToken()

			const cashboxesAux = []
			const cashboxesDesactive = []

			//get cashboxes seller participates
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/seller/cashboxes`, {
				headers: { Authorization: `Bearer ${token}` },
			})

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const boxes = await response.json()

			if(boxes != null){
				cashboxesAux.push(...boxes)
			}

			if (cashboxesAux?.length > 0) {
				//verify cashboxes unactive
				await Promise.all(
					await cashboxesAux.map(async cashbox => {
						const isOpenReq = await fetch(`${process.env.REACT_APP_API_URL}/resource/seller/cashboxes/activity/${cashbox}`, {
							headers: { Authorization: `Bearer ${token}` },
						})

						responseCodeHandler(isOpenReq)
						if (!isOpenReq.ok) throw new Error(`HTTP error status ${isOpenReq.status}`)

						const isOpen = await isOpenReq.json()

						if (isOpen === false) {
							cashboxesDesactive.push(cashbox)
						}
					})
				)
			}
			setCashbox(cashboxesDesactive[0])
			return cashboxesDesactive
		} catch (error) {
			console.error(error)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Effects
	useEffect(() => {
		(async () => setCashboxes(await getCashboxes()))()
	}, [getCashboxes])

	return (
		<div className="grid medium-gap">
			<PageTitle title="Abrir registrador" />

			{(cashboxes?.length <= 0) ? (
				<Notice tone="warning">
					<div className="grid-columns medium-gap left-items">
						<FontAwesomeIcon className="fa-icon" icon={faExclamationTriangle} />
						<div className="grid small-gap">
							<span className="small">Nenhum registrador disponível para uso.</span>
							<div className="bold-font tiny">
								<span>Verifique o página de registradores e tente novamente.</span>
							</div>
						</div>
					</div>
				</Notice>
			) : (
				<div className="flex large-margin-vertical align-items-flex-end">
					<div className="register-openner">
						<div className="flex medium-gap align-items-flex-end">
							<SelectInput
								className="select-class"
								onChange={event => setCashbox(event.target.value)}
								value={selectedCashbox}
								label="Registrador"
							>
								{cashboxes?.length > 0 ? (
									<>
										<option disabled={true} value="—">
											Selecione um Registrador
										</option>

										{typeof cashboxes === "object" && cashboxes.map(cashbox => (
											<option value={cashbox} key={cashbox}>
												{cashbox}
											</option>
										))}
									</>
								) : (
									<option disabled={true} selected={true}>
										Nenhum registrador disponível.
									</option>
								)}
							</SelectInput>

							<TextInput
								label="Valor inicial"
								value={initialValue}
								disabled={!selectedCashbox}
								onChange={(event) => setInitialValue(Number(event.target.value.replace(/\D/g, "") < 0 ? 0 : event.target.value.replace(/\D/g, "")))}
							/>

							<div>
								<MainButton
									onClick={() => setBalancesOpen(true)}
									disabled={!selectedCashbox}
								>
									Abrir
								</MainButton>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
