// Utils
import { formatHumanDate, toFixedImproved } from "offiziersmesser/lib/utils"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import LinkButton from "src/shared/components/standard/LinkButton"

import TransactionPayments from "./TransactionPayments"
import Pagination from "src/shared/components/custom/Pagination/Pagination"
import { useState } from "react"

const variations = [
	"Ações",
	"Status",
	"Acréscimo",
	"Desconto",
	"Total (R$)",
	"Pago (R$)",
	"Restante (R$)",
	"Data de vencimento",
	"Parcela",
]

export default function TransactionsTable({
	transactions,
	to,
}) {
	// Handlers
	function getTotal(transaction) {
		return transaction.payments.reduce((acc, item) => acc += item.total, 0)
	}
	function getTotalAdditional(transaction) {

		return transaction.payments.reduce((acc, item) => acc += (item.other === null? 0:item.other[0]), 0)
	}
	function getTotalDiscount(transaction) {
		return transaction.payments.reduce((acc, item) => acc += (item.other === null? 0:item.other[1]), 0) * (-1)
	}
	const [currentTransactions, SetCurrentTransactions] = useState(transactions)
	return (
		(!!transactions && transactions?.length > 0) ? (
			<div className="table-wrapper">
				<Pagination children={transactions} setCurrentElements={SetCurrentTransactions}/>
				<table>
					{transactions.map((transaction,index) => (
						<colgroup key={index}>
							{transaction.payments.map((a,index) => (
								<col key={index}/>
							))}
						</colgroup>
					))}

					<thead>
						<tr>
							{variations.map((variation, index) => (
								<th className="small-padding-vertical" id={`thd${index + 1}`} key={index}>
									{variation}
								</th>
							))}
						</tr>
					</thead>

					{currentTransactions.map((transaction, index) => (
						<tbody key={index}>
							<tr id={`tbt${index + 1}`} >
								<th colSpan="9" className="wide unpaded">
									<div className="flex justify-content-space-between align-items-center large-gap group-header">
										<div className="flex small-gap">
											<span>
												Valor de
												<b> R$ {toFixedImproved(getTotal(transaction), 2)}</b>
											</span>
											<span>{transaction.updatedAt === null && transaction.done === true ? "Despesa criada em Caixa"
												:
												transaction[to] === null?
												"Fornecedor não informado"
												:
												<>
													de: <b>
														{transaction[to].company} {transaction[to].byname} {transaction[to].reference.name} {transaction[to].reference.surname}
													</b>
												</>
												}
											</span>

											<span>emitida em: <b>{formatHumanDate((transaction.createdAt * 1000), "pt-BR")}</b></span>
											<span>descrita como: <b>{transaction.note}</b></span>
											<span>Total de créscimos: <b>R$ {toFixedImproved(getTotalAdditional(transaction), 2)}</b></span>
											<span>Total de descontos: <b>R$ {toFixedImproved(getTotalDiscount(transaction), 2)}</b></span>
										</div>
										{!transaction.done && <LinkButton hierarchy="low" to={`/supplier/transaction/renegotiate/${transaction.transaction}`}>
											<span>Renegociar</span>
										</LinkButton>}
									</div>
								</th>
							</tr>
							<TransactionPayments transaction={transaction} index={index} />
						</tbody>
					))}
				</table>
			</div>
		) : (
			<Notice>
				<FontAwesomeIcon icon={faFileInvoice} />
				<span>Você não possui nenhuma transação</span>
			</Notice>
		)
	)
}
