import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faCashRegister, faReceipt } from "@fortawesome/free-solid-svg-icons"

// Store
import { resetBalances } from "src/shared/store/slices/balancesSlice"
import { setEmployments } from "src/shared/store/slices/employmentsSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"

// Components
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import Notice from "src/shared/components/custom/Notice/Notice"
import MainButton from "src/shared/components/standard/MainButton"
import LinkButton from "src/shared/components/standard/LinkButton"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import RadioButtonInput from "src/shared/components/standard/RadioButtonInput"
import HideableBlock from "src/shared/components/custom/HideableBlock/HideableBlock"
import DatePickerSelect from "src/shared/components/custom/DatePickerSelect/DatePickerSelect"

import BalanceEntry from "../resume/BalanceEntry"
import BalanceOutflow from "../resume/BalanceOutflow"
import BalanceEntryGeneral from "../resume/BalanceEntryGeneral"
import BalanceOutflowGeneral from "../resume/BalanceOutflowGeneral"

import BalanceNewRegisterModal from "../../modals/BalanceNewRegisterModal/BalanceNewRegisterModal"
import BalancesInsertEntryOutflowModal from "../../../../../seller/balances/editor/SellerBalancesInsertEntryOutflowModal/SellerBalancesInsertEntryOutflowModal"

// Styles
import "./SupplierRegister.css"

// Utils
import { printContent } from "offiziersmesser/lib/utils"
import { RenderReportBalances } from "offiziersmesser/lib/renderers"

export default function SupplierRegister() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	let { tag } = useParams()
	const date = new Date()
	date.setHours(0,0,0)
	// Store
	const user = useSelector(state => state.user)
	const employments = useSelector(state => state.employments)
	const balances = useSelector(state => state.balances)

	const balance = balances && balances.find(balance => balance.cashbox === tag)

	// States
	const [renderNewRegisterModal, setRenderNewRegisterModal] = useState(false)
	const [renderInsertCashModal, setRenderInsertCashModal] = useState(false)

	const [registerView, setRegisterView] = useState("inflow") // <"inflow" | "outflow">

	const [initialDate, setInitialDate] = useState(new Date(Number(date)).getTime() / 1000)
	const [finalDate, setFinalDate] = useState((new Date(Number(date)).getTime() / 1000)+86399)

	const [generalBalance, setGeneralBalance] = useState(null)

	const [sellers, setSellers] = useState([])
	const [assignees, setAssignees] = useState(null)

	// Variables
	const [registerSellers, setRegisterSellers] = useState([])

	// Handlers
	function onRenderNewRegisterModal() { setRenderNewRegisterModal(prevState => !prevState) }
	function onRenderInsertCashModal() { setRenderInsertCashModal(prevState => !prevState) }
	async function handleCloseBalance() {
		try {
			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/balance/active`, {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({
					cashbox: balance.cashbox,
					active: false,
				}),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(resetBalances())
		} catch (error) {
			console.error("SUPPLIER REGISTER CLOSE BALANCE REQ ERROR -", error);
		}
	}


	// Effects
	useEffect(() => {

		if (assignees?.length > 0) {

			assignees.map((associatedSeller, index) => {

				if (index >= assignees.length - 1) {
					return setRegisterSellers(`<span style="text-transform: capitalize"> ${associatedSeller?.employee.reference.name} ${associatedSeller?.employee.reference.surname}.</span>`)
				}

				return setRegisterSellers(`<span style="text-transform: capitalize"> ${associatedSeller?.employee.reference.name} ${associatedSeller?.employee.reference.surname}</span>`)
			})
		}
	}, [assignees])

	const getEmployees = async function () {
		try {
			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/employees`, {
				method: "GET",
				headers: { Authorization: `Bearer ${token}` },
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const employments = await response.json()

			dispatch(setEmployments(employments))
		} catch (error) {
			console.error("SUPPLIER REGISTER EMPLOYEES REQ ERROR -", error)
		}
	}

	useEffect(() => {
		getEmployees()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])


	useEffect(() => {
		const fetchBalancesByDates = async () => {
			const token = getToken()
			const url = `${process.env.REACT_APP_API_URL}/resource/supplier/balance/general?initialDate=${parseInt(initialDate)}&finalDate=${parseInt(finalDate)}`;
			try {
				const response = await fetch(url, {
					method: "GET",
					headers: { "Authorization": `Bearer ${token}` },
				})

				responseCodeHandler(response)

				if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				const data = await response.json()
				setGeneralBalance(data)
			} catch (error) {
				console.error("SUPPLIER REGISTER FETCH BY DATES REQ ERROR -", error)
			}
		}

		fetchBalancesByDates()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialDate, finalDate,renderInsertCashModal])


	useEffect(() => {
		setSellers(employments.filter(employment => employment.employment.role === 1))
	}, [employments])


	useEffect(() => {
		const currentCashbox = user.cashboxes?.find(c => c.tag === tag) || []

		if (tag !== "general" && sellers.length > 0 && currentCashbox.assignedTo.length > 0) {

			const assigneesData = currentCashbox.assignedTo.map(taxpayer => sellers.find(seller => seller.taxpayer.replace(/\D/g, "") === taxpayer))

			setAssignees(assigneesData.length > 0 ? assigneesData : [])
		}
	}, [sellers, user.cashboxes, tag])

	return (
		<SupplierRoleNavbar>

			{renderNewRegisterModal === true && (
				<BalanceNewRegisterModal
					renderModal={renderNewRegisterModal}
					onRenderModal={() => onRenderNewRegisterModal()}
					tag={tag}
					editorMode={true}
				/>
			)}
			{renderInsertCashModal === true && (
				<BalancesInsertEntryOutflowModal
					onRenderModal={() => onRenderInsertCashModal()}
					isSupplier={true}
					balances={balance}
				/>
			)}

			<div className="register-history-container flex flex-column medium-gap high wide">
				<div className="flex flex-column small-gap">

					<div className="flex">
						<LinkButton to="/supplier/balances" hierarchy="inferior">
							<FontAwesomeIcon icon={faArrowLeft} />
							<span>Registradores</span>
						</LinkButton>
					</div>

					<PageTitle
						title={`Registrador ${tag === "general" ? "geral" : tag}`}
						description={
							assignees && assignees.length > 0
								? (`${(assignees.length > 1)
									? ("<b>Vendedores associados são</b>")
									: ("<b>Vendedor associado é</b>")} ${registerSellers}`)
								: tag !== "general"
									? "Nenhum vendedor associado."
									: ""
						}
					/>
				</div>

				{tag !== "general" && (
					<div className="grid fill-normal medium-gap">
						{(typeof balance !== "object") && (
							<MainButton
								hierarchy="low"
								onClick={() => onRenderNewRegisterModal()}
							>
								Editar Registrador
							</MainButton>
						)}
						{(typeof balance === "object") && (
							<>
								<MainButton onClick={() => handleCloseBalance()} >
									Fechar registrador
								</MainButton>
								<MainButton
									hierarchy="low"
									onClick={() => onRenderInsertCashModal()}
								>
									Criar entrada ou saída
								</MainButton>
							</>
						)}
					</div>
				)}

				{tag === "general" && (
				<>
					<div className="grid fill-normal medium-gap">
						<MainButton
							hierarchy="low"
							onClick={() => onRenderInsertCashModal()}
						>
							Criar entrada ou saída
						</MainButton>
						<MainButton
							hierarchy="low"
							onClick={() => {
								if (generalBalance?.paymentsEntry?.length > 0
									|| generalBalance?.paymentsOutflow?.length > 0) {
									printContent(
										RenderReportBalances(
											generalBalance?.paymentsEntry,
											generalBalance?.paymentsOutflow,
											{cashbox:"Geral"},
											initialDate
										)
									)
								} else {
									dispatch(createToast({
										tone: "warning",
										miliseconds: 2000,
										message: "Não existem saídas ou entradas!",
										dismissable: true,
									}))
								}
							}}
					>
					<FontAwesomeIcon className="fa-icon" icon={faReceipt} />
					<span>Emitir Relatório Geral</span>
				</MainButton>
					</div>
					<HideableBlock blockTitle="Opções">
						<div className="grid small-gap">
							<span className="big bold-font">Filtrar por data</span>

							<div className="grid medium-gap fill-huge">

								<div className="element-border flex flex-column small-gap">

									<span className="bold-font">Data inicial</span>

									<DatePickerSelect
										value={initialDate}
										timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
										setDate={date =>
											setInitialDate(
												new Date(
													date.year,
													date.month - 1,
													date.day
												).getTime() / 1000
											)
										}
									/>
								</div>
								<div className="element-border flex flex-column small-gap">

									<span className="bold-font">Data final</span>

									<DatePickerSelect
										value={finalDate}
										timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
										setDate={date =>
											setFinalDate(
												(new Date(
													date.year,
													date.month - 1,
													date.day
												).getTime() / 1000)+86399
											)
										}
									/>
								</div>
							</div>
						</div>
					</HideableBlock>
				</>
				)}

				<div className="register-history-container flex flex-column medium-gap high wide">

					{(typeof balance === "object" || tag === "general") && (
						<div className="flex">
							<div className="flex flex-column small-gap element-border">
								<span className="bold-font medium">Visualizar</span>

								<div className="flex medium-gap">
									<RadioButtonInput
										name="data-view"
										label="Entradas"
										value="0"
										defaultChecked={registerView === "inflow"}
										onChange={() => setRegisterView("inflow")}
									/>
									<RadioButtonInput
										name="data-view"
										label="Saída"
										value="1"
										defaultChecked={registerView === "outflow"}
										onChange={() => setRegisterView("outflow")}
									/>
								</div>
							</div>
						</div>
					)}

					{(typeof generalBalance === "object" && tag === "general") ? (

						(registerView === "inflow") ? (
							<BalanceEntryGeneral
								entries={generalBalance?.paymentsEntry}
								paymentsOnCredit={generalBalance?.paymentsOnCredit}
							/>
						) : (
							<BalanceOutflowGeneral
								payments={generalBalance?.paymentsOutflow}
							/>
						)
					) : (typeof balance === "object") ? (
						(registerView === "inflow") ? (
							<BalanceEntry
								payments={balance?.transactions.entry}
							/>
						) : (
							<BalanceOutflow
								payments={balance?.transactions.outflow}
								balanceTag={balance?.cashbox}
							/>
						)
					) : (
						<div className="wide">
							<Notice>
								<FontAwesomeIcon icon={faCashRegister} />
								<span>Esse registrador está fechado.</span>
							</Notice>
						</div>
					)}
				</div>
			</div>
		</SupplierRoleNavbar>
	)
}
