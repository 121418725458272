import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setUser } from "src/shared/store/slices/userSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"

// Page components
import MainButton from "src/shared/components/standard/MainButton"
import LocalityForm from "src/shared/components/custom/Forms/LocalityForm/LocalityForm"
import UserModel, { LocalityModel } from "src/shared/models/user"


export default function AdministrativeLocalityForm() {
	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector<UserModel>(state => state.user) as UserModel

	// States

	const [locality, setLocality] = useState<LocalityModel>({
		road: user.administrative.locality.road,
		number: user.administrative.locality.number,
		extra: user.administrative.locality.extra,
		area: user.administrative.locality.area,
		postal: user.administrative.locality.postal,
		city: user.administrative.locality.city,
		region: user.administrative.locality.region,
		country: user.administrative.locality.country
	})

	// Handlers
	async function updateUserAdministrative(successMessage: string, failureMessage: string) {
		try {
			const token = getToken()
			const updatedAdministrative = { ...user.administrative, locality }
			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/user/administrative", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				body: JSON.stringify({ administrative: updatedAdministrative }),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({ ...user, administrative: updatedAdministrative }))
			dispatch(createToast({
				dismissable: true, tone: "success", miliseconds: 2500, message: successMessage
			} as any))
		}
		catch (error) {
			console.error(error)
			dispatch(createToast({
				dismissable: true, tone: "failure", miliseconds: 2500, message: failureMessage
			} as any))
		}
	}

	return (
		<div className="grid large-gap">
			<LocalityForm
				formTitle="Endereço da empresa"
				locality={locality}
				setLocality={setLocality}
			/>
			<div>
				<MainButton
					onClick={() =>
						updateUserAdministrative("Atualizado com sucesso", "Houve um erro ao atualizar")
					}
				>
					<span>Alterar dados</span>
				</MainButton>
			</div>
		</div>
	)
}
