import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setUser } from "src/shared/store/slices/userSlice"
import { RootState } from "src/shared/store/reducers"

// Components
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"
import BankingForm from "src/shared/components/custom/Forms/BankingForm/BankingForm"

export default function BankingHandler() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector((state: RootState) => state.user)

	// States
	const [pix, setPix] = useState(user?.deposits?.pix || [""])
	const [banking, setBanking] = useState({
		institution: user?.deposits?.banking?.institution,
		branch: user?.deposits?.banking?.branch,
		account: user?.deposits?.banking?.account
	})

	// Handlers
	async function handleUpdateDeposits() {

		try {
			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/user/deposits`, {
				method: "put",
				body: JSON.stringify({
					deposits: {
						pix: pix,
						banking: banking
					}
				}),
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				}
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({
				...user,
				deposits: {
					...user.deposits,
					pix: pix,
					banking: banking
				}
			}))
		} catch (error) {
			console.error("UPDATE DEPOSITS ERROR", error)
		}
	}

	return (
		<div className="grid large-gap">
			<div className="grid medium-gap justify-items-left">

				<span className="big-x bold-font">Dados bancários</span>

				<TextInput
					label="Chave PIX"
					name="pix"
					placeholder="Chave única, número telefônico, CPF/CNPJ"
					value={pix[0]}
					onChange={(e) => setPix([e.target.value])}
				/>

				<BankingForm
					banking={banking}
					setBanking={setBanking}
				/>
			</div>
			<div>
				<MainButton onClick={() => handleUpdateDeposits()} >
					<span>Alterar dados</span>
				</MainButton>
			</div>
		</div>
	)
}
