// Utils
import { PAYMENT_METHODS } from "offiziersmesser/lib/utils"
import { createTotalParts } from "offiziersmesser/lib/helpers"
import { toFixedImproved } from "offiziersmesser/lib/utils"

// Icons
import { faCashRegister } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import LinkButton from "src/shared/components/standard/LinkButton"
import Notice from "src/shared/components/custom/Notice/Notice"

export default function BalanceOutflow({ payments, balanceTag }) {

	const parts = []
	const totalParts = []
	const balanceEntry = []

	let balancesTotal = 0

	if (payments != null) {

		payments.map((transaction) => {
			return parts.push(...transaction.payments[0].parts)
		})

		totalParts.push(...createTotalParts(parts))

		balanceEntry.push(...totalParts)

		balancesTotal = balanceEntry
			.map((balances) => balances.value)
			.reduce((prev, curr) => prev + curr, 0)
	}

	return (
		<div className="register-wrapper flex flex-column wide high small-gap">

			<div className="grid small-gap fill-normal small-margin-bottom">
				<LinkButton
					hierarchy="low"
					to={`/supplier/balances/details/outflow/${balanceTag}`}
					disabled={!payments}
				>
					<span>Detalhes das saídas</span>
				</LinkButton>
			</div>

			<div className="register-history outflow-history element-border wide high">
				{(balanceEntry.length > 0) ? (
					balanceEntry.map((balance, index) => {
						return (
							<div key={index} className="register-history-item">
								<span key={index} className="bold-font">
									{PAYMENT_METHODS.map((method, index) => {
										if (balance.method === index) return <>{method.name}</>
										return null
									})}
								</span>
								<span className="bold-font">
									- R$ {toFixedImproved(balance.value, 2)}
								</span>
							</div>
						)
					})
				) : (
					<div className="wide wide-children">
						<Notice>
							<FontAwesomeIcon icon={faCashRegister} />
							<span>Registrador não possui saida.</span>
						</Notice>
					</div>
				)}
			</div>

			<div className="element-border wide small-margin-top">
				<ul>
					<li className="register-history-item">
						<span className="bold-font">Total</span>
						<span className="bold-font orange big">
							R$ {toFixedImproved(balancesTotal, 2)}
						</span>
					</li>
				</ul>
			</div>
		</div >
	)
}
