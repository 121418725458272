// Styles
// import "./Actions.css"
import { ReactComponent as Logo } from "src/assets/materiapp-logo.svg"

// Services
import useEvaluateActions from "./useEvaluateActions"

export default function Actions() {

	const {filterForActionableComponents} = useEvaluateActions()

	return (
		<div className="grid huge-gap">
			<div className="medium-margin grid left-items">
				<Logo />
			</div>

			<div className="medium-margin-horizontal">
				{/*<PageTitle title="Ações" />*/}

				{filterForActionableComponents().map(action =>
					<div key={action.name}>{action.functionComponent()}</div>
				)}
			</div>
		</div>
	)
}
