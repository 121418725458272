import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

// Utils
import { calculateYearlyCompoundInterest, calculateYearlySimpleInterest } from "offiziersmesser/lib/helpers"

// Components
import NumberInput from "../../../../../../shared/components/standard/NumberInput"
import CheckboxInput from "../../../../../../shared/components/standard/CheckboxInput"
import MainButton from "../../../../../../shared/components/standard/MainButton"
import DatePickerSelect from "../../../../../../shared/components/custom/DatePickerSelect/DatePickerSelect"

export const InsertTransactionStepTwo = ({
	issueDate,
	setIssueDate,
	expirationDate,
	setExpirationDate,
	value,
	setValue,
	payments,
	setPayments,
	activeInstallments,
	setActiveInstallments,
}) => {

	// Store
	const user = useSelector(state => state.user)

	// States
	const [installmentsQtd, setInstallmentsQtd] = useState(null)
	const [spawnInstallments, setSpawnInstallments] = useState(false)
	const [spawnInterest, setSpawnInterest] = useState(false)
	const [periodInstallments, setPeriodInstallments] = useState(user.installments.period || null)
	const [percentage, setPercentage] = useState(0)
	const [compound, setCompound] = useState(false)

	// Handlers

	//inserts several payments when it has installment
	const handleInstallments = () => {

		const installments = []
		let valueInstall = value

		if (!spawnInterest) {

			valueInstall = (value / installmentsQtd)
		} else {

			let interest

			//interest calculation
			(compound === false) ? (
				interest = calculateYearlySimpleInterest(value, percentage * 12, installmentsQtd / 12)
			) : (
				interest = calculateYearlyCompoundInterest(value, percentage * 12, installmentsQtd / 12)
			)

			valueInstall = (value / installmentsQtd) + (interest / installmentsQtd)
		}

		for (let index = 0; index < installmentsQtd; index++) {

			const installment = {

				custom: true, //created in transactions
				period: (index + 1),
				timespan: {
					unit: "day",
					value: periodInstallments,// In days
				},
				Interest: {
					compound: compound,
					percentage: percentage
				}
			}

			installments.push({
				total: valueInstall,
				other:[0,0],
				installments: installment,
				heir: null,
				parts: []
			})
		}

		setPayments(installments)
		setSpawnInstallments(!spawnInstallments)
	}
	const handleCancelInstallments = () => {
		setSpawnInstallments(false)
	}
	const handleInstallmentPlan = () => {
		setInstallmentsQtd(1)
		setSpawnInstallments(false)
		setActiveInstallments(!activeInstallments)
	}

	// Effects

	//inserts a payment when there is no installment
	useEffect(() => {

		const installments = {
			custom: false, //created in transactions
			period: 1,
			// 1 day in unix timestamp = 86400
			timespan: {
				unit: "this is an unit",
				value: ((expirationDate - issueDate) / 86400),// In days
			},
			Interest: {
				compound: compound,
				percentage: percentage
			}
		}

		const payment = [{
			total: value,
			other:[0,0],
			installments,
			parts: []
		}]

		setPayments(payment)

	}, [value, expirationDate, issueDate, setPayments, compound, percentage])

	return (
		<div>
			<div className="grid medium-gap">
				<div className="flex">
					<NumberInput
						label="Valor"
						name={value}
						onChange={event => setValue(event.target.valueAsNumber)}
						onBlur={event => (event.target.valueAsNumber<0) ? setValue(0) : {} }
						disabled={activeInstallments}
						value={value}
					/>
				</div>

				<div className="flex medium-gap wide">
					<div className="tiny-margin grid medium-gap  left-items element-border">

						<span className="bold-font medium">Data de emissão</span>

						<DatePickerSelect
							value={issueDate}
							timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
							setDate={(date) =>
								setIssueDate(
									new Date(
										date.year,
										date.month - 1,
										date.day
									).getTime() / 1000
								)
							}
						/>
					</div>

					{(!activeInstallments &&
						<div className="tiny-margin grid medium-gap  left-items element-border">

							<span className="bold-font medium">Data de vencimento</span>

							<DatePickerSelect
								value={expirationDate}
								timestamp={Date.now()} // x1000 to turn an Unix timestamp into JS timestamp
								setDate={(date) =>
									setExpirationDate(
										new Date(
											date.year,
											date.month - 1,
											date.day
										).getTime() / 1000
									)
								}
							/>
						</div>
					)}
				</div>

				<CheckboxInput
					label="Parcelar"
					disabled={value === 0 && true}
					onChange={handleInstallmentPlan}
				/>

				{activeInstallments && (
					<CheckboxInput
						label="Juros Personalizados"
						checked={spawnInterest}
						onChange={e => setSpawnInterest(!spawnInterest)}

					/>
				)}

			</div>

			{((spawnInterest && activeInstallments) && (
				<div className="grid small-gap medium-margin-top">
					<div className="flex">

						<NumberInput
							label="Juros (%)"
							value={percentage}
							disabled={spawnInstallments}
							onChange={event => setPercentage(Number(event.target.value))}
						/>
					</div>
					<CheckboxInput
						label="Juros compostos"
						defaultChecked={compound}
						disabled={spawnInstallments}
						onChange={() => setCompound(!compound)}
					/>
				</div>
			))}

			{(activeInstallments && (
				<>
					<div className="grid small-gap fill-normal medium-margin-top medium-margin-bottom wide-children center-items">

						<NumberInput
							label="Quantidade de parcelas"
							disabled={spawnInstallments}
							name={installmentsQtd}
							value={installmentsQtd}
							onChange={event => setInstallmentsQtd(Number(event.target.value))}
						/>

						<NumberInput
							label="Prazo em dias"
							disabled={spawnInstallments}
							name={periodInstallments}
							value={periodInstallments}
							onChange={event => setPeriodInstallments(Number(event.target.value))}
						/>
					</div>

					<MainButton
						disabled={!periodInstallments || !installmentsQtd}
						onClick={() => {
							spawnInstallments ? handleCancelInstallments() : handleInstallments()
						}}
					>
						<span>{spawnInstallments ? "Cancelar Parcelamento" : "Gerar Parcelamento"}</span>
					</MainButton>

					{spawnInstallments && (
						<div className="flex small-gap  medium-margin-vertical">
							<div className="element-border">

								<div className="grid left-items">

									<span className="medium bold-font small-margin-bottom"> Parcelas </span>

									{payments.map((payment, index) => {

										const date = new Date((issueDate + ((((payment.installments.period * periodInstallments) * 24) * 60) * 60)) * 1000)

										// for (let date = 0; date < payment.length; date++) {
										// 	return (86400 * payment[date].period) * issueDate60

										return (
											<span className="small tiny-margin-bottom">
												{`${index + 1}° parcela | R$ ${payment.total.toFixed(2)} - ${date.getDate()} / ${((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)} / ${date.getFullYear()}`}
											</span>
										)
									})}
								</div>
							</div>
						</div>
					)}
				</>
			))}
		</div>
	)
}
