import { useEffect, useState } from "react"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnglesLeft, faAngleLeft, faAngleRight, faAnglesRight } from "@fortawesome/free-solid-svg-icons"

// Components
import MainButton from "src/shared/components/standard/MainButton"

// Styles
import "./Pagination.css"

// This Component use when slice elements rendered

type PaginationProps = {
	elements: any[],
	setCurrentElements: (elements:any[])=>void
}

export default function Pagination({ elements = [], setCurrentElements } : PaginationProps) {
	// States
	const [currentPage, setCurrentPage] = useState(0)
	const [itemsPerPage,] = useState(10)

	// This variables set wich elements rendered
	const startIndex = currentPage * itemsPerPage
	const endIndex = startIndex + itemsPerPage

	// Get how amount pages
	const pages = Math.ceil(elements.length / itemsPerPage)

	// When alter page alter rendered elements
	useEffect(() => {
		setCurrentElements(elements.slice(startIndex, endIndex))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[currentPage])

	// This useEffect for trigger when alter elements array exemple: search
	useEffect(() => {
		setCurrentPage(0)
		setCurrentElements(elements.slice(startIndex, endIndex))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[elements])

	return (
		<>
			{elements.length > 0 && (
				<div className="flex small-gap align-items-center">
					<div className="element-group">
						<MainButton
							hierarchy="inferior"
							disabled={currentPage === 0}
							onClick={() => setCurrentPage(0)}
						>
							<FontAwesomeIcon icon={faAnglesLeft} className="fa-icon big" />
						</MainButton>

						<MainButton
							hierarchy="inferior"
							disabled={currentPage === 0}
							onClick={() => setCurrentPage(currentPage - 1)}
						>
							<FontAwesomeIcon icon={faAngleLeft} className="fa-icon big" />
						</MainButton>
					</div>

					<span className="bold-font center-text tiny-margin-horizontal">{`${startIndex + 1} - ${currentPage === (pages - 1) ? (elements.length) : (endIndex)} de ${elements.length}`}</span>

					<div className="element-group">
						<MainButton
							hierarchy="inferior"
							disabled={currentPage === (pages - 1)}
							onClick={() => setCurrentPage(currentPage + 1)}
						>
							<FontAwesomeIcon icon={faAngleRight} className="fa-icon big" />
						</MainButton>

						<MainButton
							hierarchy="inferior"
							disabled={currentPage === (pages - 1)}
							onClick={() => setCurrentPage(pages - 1)}
						>
							<FontAwesomeIcon icon={faAnglesRight} className="fa-icon big" />
						</MainButton>
					</div>
				</div>
			)}
		</>
	)
}

				// <div className="pagination-container">
				// 	<div className="grid-columns large-gap center-items wide-children">
				// 		<button
				// 			disabled={currentPage === 0}
				// 			onClick={e => setCurrentPage(currentPage - 1)}
				// 		>
				// 			<FontAwesomeIcon icon={faChevronCircleLeft} className="fa-icon big" />
				// 		</button>

				// 		<span>{`${startIndex + 1} - ${currentPage === (pages - 1) ? (elements.length) : (endIndex)} de ${elements.length}`}</span>

				// 		<button
				// 			disabled={currentPage === (pages - 1)}
				// 			onClick={e => setCurrentPage(currentPage + 1)}
				// 		>
				// 			<FontAwesomeIcon icon={faChevronCircleRight} className="fa-icon big" />
				// 		</button>
				// 	</div>
				// </div>
