import { useEffect, ChangeEvent, Dispatch } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

// Store
import { RootState } from "src/shared/store/reducers"
import { setUser } from "src/shared/store/slices/userSlice"
import {
	setAuthenticated,
	initializeSession,
	getDefaultPathInNavigation,
	// setSessionUserRoleActive
} from "src/shared/store/slices/sessionSlice"

// Utils
import {
	validateCnpj,
	validateCpf,
	validateTaxpayer,
} from "offiziersmesser/lib/utils"

// Services
import authorizeSupplier from "../services/authorizeSupplier"
import authorizeEmployee from "../services/authorizeEmployee"

// Icons
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSyncAlt} from "@fortawesome/free-solid-svg-icons"

// Components
import Form from "src/shared/components/standard/Form"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"
import TextInputPassphrase from "src/shared/components/custom/TextInputPassphrase/TextInputPassphrase"


type AuthenticationProps = {
	asEmployee: boolean
	userExists: boolean
	isUserActive: boolean
	employeeRoles: any[]
	supplierRegistration: boolean
	isUserNovice: boolean
	passphrase: string
	alias: string
	taxpayer: string
	isTaxpayerValid: boolean
	setAsEmployee: Dispatch<React.SetStateAction<boolean>>
	setAlias: (value: string) => void
	setTaxpayer: (value: string) => void
	setSupplierRegistration: (value: boolean) => void
	setEmployeeRegistration: (value: boolean) => void
	setPassphrase: (value: string) => void
	setIsPassphraseStrong: (value: boolean) => void
	setPassphraseReset: (value: boolean) => void
	setIsTaxpayerValid: (value: boolean) => void
	// retrieveTaxpayerActivity: () => void
	completeSupplierAuthorization: () => void
	completeEmployeeAuthorization: () => void
}

export default function Authentication({
	asEmployee,	setAsEmployee,
	userExists,
	isUserActive,
	employeeRoles,
	supplierRegistration,
	isUserNovice,
	setSupplierRegistration,
	setEmployeeRegistration,
	passphrase, setPassphrase, setIsPassphraseStrong,
	setPassphraseReset,
	alias, setAlias,
	taxpayer, setTaxpayer,
	isTaxpayerValid, setIsTaxpayerValid,
	completeSupplierAuthorization, completeEmployeeAuthorization
}: AuthenticationProps) {

	const dispatch = useDispatch()
	// const navigate = useNavigate()

	// const session = useSelector((state: RootState) => state.session)

	// useEffect(() => {
	// 	if (session.authenticated === true)
	// 		navigate(getDefaultPathInNavigation(session.roles[0].navigation))
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [session])

	return (
		<>
			<div className="grid tiny-gap">

				<span className="big-xxx bold-font">Cadastrar ou entrar</span>

				<div>
					<MainButton
						hierarchy="minimal"
						fontSize="small"
						onClick={() => setAsEmployee(asEmployee => !asEmployee)}
					>
						<FontAwesomeIcon className="fa-icon" icon={faSyncAlt as IconProp} />
						<span>
							Trocar para {(asEmployee === true) ? "conta própria" : "conta colaborador"}
						</span>
					</MainButton>
				</div>
			</div>

			<Form className="grid medium-gap left-items">

				{(asEmployee === true) &&
					<TextInput
						label="ID da empresa"
						value={alias}
						onChange={(event: ChangeEvent<HTMLInputElement>) => setAlias(event.target.value)}
					/>
				}

				<div className="grid fit-large medium-gap">
					<TextInput
						label={asEmployee === true ? "CPF ou CNPJ" : "CNPJ"}
						value={taxpayer}
						onChange={event => {
							const sanitizedTaxpayer = event.target.value.replaceAll(/[^0-9]/g, "")
							setTaxpayer(sanitizedTaxpayer)
							setIsTaxpayerValid(validateTaxpayer(sanitizedTaxpayer))
						}}
					/>

					{(isTaxpayerValid === true && userExists === true && isUserActive === true)
						// The account must be all valid and active in order to show the passphrase input
						// If asEmployee is true, the account must be active in one of the possible employee roles
						&& (
							<div className="grid tiny-gap">
								<TextInputPassphrase
									label="Senha"
									value={passphrase}
									onChange={(event: ChangeEvent<HTMLInputElement>) => setPassphrase(event.target.value)}
								/>
								<div>
									<MainButton
										hierarchy="minimal"
										fontSize="small"
										onClick={() => setPassphraseReset(true)}
									>
										<span>Esqueci minha senha</span>
									</MainButton>
								</div>
							</div>
						)
					}
				</div>

				{(isTaxpayerValid === true)
					&& (supplierRegistration === false)
					&& ((asEmployee === true && isUserActive === false && isUserNovice === true))
					&& ((userExists === true && isUserActive === false && isUserNovice === true) &&
					// Only shows registration button if taxpayer is valid...
					// registration process did not begin yet and
					// the alias input for employee auth is hidden
					// although the account exists it is not active (and never used the platform before)
					(
						<div className="grid center-items">
							<span className="big">É seu primeiro acesso</span>
							<MainButton
								hierarchy="minimal"
								fontSize="small"
								type="submit"
								onClick={
									() => {
										((validateCpf(taxpayer) === true) && asEmployee === true)
										? setEmployeeRegistration(true)
										: setSupplierRegistration(true)
									}
								}
							>
								<span>Confirmar meus dados</span>
							</MainButton>
						</div>
					))
				}

				{(userExists === false && validateCnpj(taxpayer)
					// For now it is disabled because it does not make sense to register users in "as employee" mode but
					// in the future we can offer people looking for a job to apply to a company directly into the login
					&& asEmployee === false)
					// no account has been detected for such taxpayer
					&& (
						<MainButton type="submit" onClick={() => setSupplierRegistration(true)}>
							<span>Cadastrar</span>
						</MainButton>
					)
				}

				{(isTaxpayerValid === true)
					&& (userExists === true && isUserActive === true)
					&& (taxpayer !== "" && passphrase !== "")
					&& ((asEmployee === false)
					// Only shows authorization button if taxpayer is valid...
					// the account was successfully detected and the user is active and
					// taxpayer and passphrase inputs are not empty for obvious reasons
					// If the alias input was not activated then the user is likely a supplier/customer
					? (
						<MainButton type="submit" onClick={async () => {
							const response = await authorizeSupplier(taxpayer, passphrase)
							// Set user-related data
							sessionStorage.setItem("token", response.token) // Auto-authentication during refresh

							dispatch(setUser(response.payload))
							dispatch(initializeSession({ stakeholder: "supplier" }))
							// dispatch(setSessionUserRoleActive("supplier"))
							dispatch(setAuthenticated(true))

							// Reset variable states
							setPassphrase("")
						}}>
							<span>Entrar</span>
						</MainButton>
					) :	(
						<MainButton type="submit" onClick={async () => {
							const response = await authorizeEmployee(alias, taxpayer, passphrase)
							// Set user-related data
							sessionStorage.setItem("token", response.token) // Auto-authentication during refresh
							dispatch(setUser(response.payload))
							// Keeps track of all available roles for this given user
							dispatch(initializeSession({ stakeholder: "employee", authorizedRoles: response.roles }))     //employeeRoles
							// Sets the role chosen at authentication time as the initial active one for the current session
							// dispatch(setSessionUserRoleActive(employee))
							dispatch(setAuthenticated(true))
							// Reset variable states
							setPassphrase("")
						}}>
							<span>Colaborar</span>
						</MainButton>
					))
				}

				{
					// error !== undefined &&
					// <Notice tone="failure">
					// 	<div className="flex medium-gap center-items">
					// 		<span><FontAwesomeIcon icon={faTimesCircle as Icon} /></span>
					// 		<span>Senha incorreta!</span>
					// 	</div>
					// </Notice>
				}
			</Form>
		</>
	)
}
