import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Store
import { RootState } from "src/shared/store/reducers"
import { updateWorkableItem } from "src/shared/store/slices/workableItemsSlice"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"
import StepForm from "src/shared/components/custom/StepForm/StepForm"
import RadioButtonInput from "src/shared/components/standard/RadioButtonInput"
import StepContainer from "src/shared/components/custom/StepForm/StepContainer"

import ItemModel from "src/shared/models/item"

type AssociateItemModalProps = {
	onRenderModal: () => void
	onVinculate: () => void
}

export default function AssociateItemModal({
	onRenderModal,
	onVinculate,
}: AssociateItemModalProps) {

	const dispatch = useDispatch()

	// Store
	const workableItem = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)
	const items = useSelector((state: RootState) => state.items)

	// States
	const [item, setItem] = useState(workableItem)
	const [associatedItem, setAssociatedItem] = useState<ItemModel>()

	const [searchedItem, setSearchedItem] = useState("")

	const [itemsFound, setitemsFound] = useState<ItemModel[]>([])

	// Effects
	useEffect(() => {

		const results = [] as ItemModel[]
		const maxEntries = 5

		if (items.length <= 0) return
		if (searchedItem === "") { setitemsFound([]); return }

		setAssociatedItem(undefined)

		let count = 0

		for (let index = 0; items.length > index; index++) {

			const item = items[index]

			const search = searchedItem.trim().toLowerCase()
			const description = item.control.description.trim().toLowerCase()
			const sku = item.control.sku.trim().toLowerCase()

			if (count >= maxEntries) { break }

			if (description.includes(search) || sku.includes(search)) {

				results.push(item)
				count++
			}
		}

		setitemsFound(results)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchedItem])

	return (
		<Modal title="Vincular item" onClose={onRenderModal}>
			<StepForm
				onClickConclude={() => {
					dispatch(updateWorkableItem(associatedItem))
					onRenderModal()
					onVinculate()
				}}
			>
				<StepContainer stepCondition={!!associatedItem}>
					<div className="grid medium-gap">
						<span>{item.control.description}</span>

						<TextInput
							label="Descrição do item no estoque"
							value={searchedItem}
							onChange={e => setSearchedItem(e.target.value)}
						/>

						{!!associatedItem &&
							<div className="flex justify-content-center small-gap medium-margin-top">
								<div className="flex small-gap">
									<span className="bold-font orange">foi associado com</span> {associatedItem?.control.description}
								</div>

								<MainButton hierarchy="minimal" onClick={() => { setAssociatedItem(undefined) }}>
									<span>Desassociar</span>
								</MainButton>
							</div>
						}

						{(itemsFound.length > 0) && (
							<div className="grid small-gap left-items fill-huge">
								{itemsFound.slice(0, 3).map(item =>
									<div className="flex justify-content-space-between medium-gap">
										<span>{item.control.description}</span>
										<MainButton
											hierarchy="minimal"
											onClick={() => {
												setAssociatedItem({
													...item,
													control: {
														...item.control,
														quantity: item.control.quantity + workableItem.control.quantity
													}
												})
												setSearchedItem("")
											}}
										>
											<span>Associar</span>
										</MainButton>
									</div>
								)}
							</div>
						)}
					</div>
				</StepContainer>

				<StepContainer>
					<div className="modal-container">
						<div className="header">
							<span className="big-x bold-font">Associar Item</span>
						</div>

						<div className="flex flex-column medium-gap">
							<div className="grid tiny-gap">
								<span className="medium bold-font">Descrição</span>
								<span className="small">Selecione qual valor deseja manter</span>
							</div>

							<div className="grid tiny-gap">
								<RadioButtonInput
									name="description"
									label={`${associatedItem?.control.description} (Antigo)`}
									value={associatedItem?.control.description}
									defaultChecked={item?.control.description === associatedItem?.control.description}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, control: {
												...prevItem.control, description: associatedItem?.control.description || ""
											}
										}))
									}}
								/>
								<span>ou</span>
								<RadioButtonInput
									name="description"
									label={`${workableItem?.control.description} (Novo)`}
									value={workableItem?.control.description}
									defaultChecked={item?.control.description === workableItem?.control.description}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, control: {
												...prevItem.control, description: workableItem?.control.description || ""
											}
										}))
									}}
								/>
							</div>
						</div>
					</div>
				</StepContainer>

				<StepContainer>
					<div className="modal-container">
						<div className="header">
							<span className="big-x bold-font">Associar Item</span>
						</div>

						<div className="flex flex-column medium-gap">
							<div className="grid tiny-gap">
								<span className="medium bold-font">GTIN/EAN</span>
								<span className="small">Selecione qual valor deseja manter</span>
							</div>

							<div className="grid tiny-gap">
								<RadioButtonInput
									name="barcode"
									label={`${associatedItem?.control.barcode} (Antigo)`}
									value={associatedItem?.control.barcode}
									defaultChecked={item?.control.barcode === associatedItem?.control.barcode}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, control: {
												...prevItem.control, barcode: associatedItem?.control.barcode || ""
											}
										}))
									}}
								/>
								<span>ou</span>
								<RadioButtonInput
									name="barcode"
									label={`${workableItem?.control.barcode} (Novo)`}
									value={workableItem?.control.barcode}
									defaultChecked={item?.control.barcode === workableItem?.control.barcode}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, control: {
												...prevItem.control, barcode: workableItem?.control.barcode || ""
											}
										}))
									}}
								/>
							</div>
						</div>
					</div>
				</StepContainer>

				<StepContainer>
					<div className="modal-container">
						<div className="header">
							<span className="big-x bold-font">Associar Item</span>
						</div>

						<div className="flex flex-column medium-gap">
							<div className="grid tiny-gap">
								<span className="medium bold-font">Unidade</span>
								<span className="small">Selecione qual valor deseja manter</span>
							</div>

							<div className="grid medium-gap">
								<RadioButtonInput
									name="measure"
									label={`${associatedItem?.control.measure} (Antigo)`}
									value={associatedItem?.control.measure}
									defaultChecked={item?.control.measure === associatedItem?.control.measure}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, control: {
												...prevItem.control, measure: associatedItem?.control.measure || ""
											}
										}))
									}}
								/>
								<RadioButtonInput
									name="measure"
									label={`${workableItem?.control.measure} (Novo)`}
									value={workableItem?.control.measure}
									defaultChecked={item?.control.measure === workableItem?.control.measure}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, control: {
												...prevItem.control, measure: workableItem?.control.measure || ""
											}
										}))
									}}
								/>
							</div>
						</div>
					</div>
				</StepContainer>

				<StepContainer>
					<div className="modal-container">
						<div className="header">
							<span className="big-x bold-font">Associar Item</span>
						</div>

						<div className="flex flex-column medium-gap">

							<div className="grid tiny-gap">
								<span className="medium bold-font">NCM</span>
								<span className="small">Selecione qual valor deseja manter</span>
							</div>

							<div className="grid tiny-gap">
								<RadioButtonInput
									name="ncm"
									label={`${associatedItem?.accounting?.details.ncm??""} (Antigo)`}
									value={associatedItem?.accounting?.details.ncm}
									defaultChecked={item?.accounting?.details.ncm === associatedItem?.accounting?.details.ncm}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, accounting: {
												...prevItem.accounting,
												details:{
													...prevItem.accounting.details,
													ncm: associatedItem?.accounting?.details.ncm || ""
												}
											}
										}))
									}}
								/>
								<RadioButtonInput
									name="ncm"
									label={`${workableItem?.accounting?.details.ncm??""} (Novo)`}
									value={workableItem?.accounting?.details.ncm}
									defaultChecked={item?.accounting?.details.ncm === workableItem?.accounting?.details.ncm}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, accounting: {
												...prevItem.accounting,
												details:{
													...prevItem.accounting.details,
													ncm: workableItem?.accounting?.details.ncm || ""
												}
											}
										}))
									}}
								/>
							</div>
						</div>
					</div>
				</StepContainer>

				<StepContainer>
					<div className="modal-container">
						<div className="header">
							<span className="big-x bold-font">Associar Item</span>
						</div>

						<div className="flex flex-column medium-gap">

							<div className="grid tiny-gap">
								<span className="medium bold-font">CEST</span>
								<span className="small">Selecione qual valor deseja manter</span>
							</div>

							<div className="grid medium-gap">
								<RadioButtonInput
									name="cest"
									label={`${associatedItem?.accounting?.details.cest??""} (Antigo)`}
									value={associatedItem?.accounting?.details.cest}
									defaultChecked={item?.accounting?.details.cest === associatedItem?.accounting?.details.cest}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, accounting: {
												...prevItem.accounting,
												details:{
													...prevItem.accounting.details,
													cest: associatedItem?.accounting?.details.cest || ""
												}
											}
										}))
									}}
								/>
								<RadioButtonInput
									name="cest"
									label={`${workableItem?.accounting?.details.cest??""} (Novo)`}
									value={workableItem?.accounting?.details.cest}
									defaultChecked={item?.accounting?.details.cest === workableItem?.accounting?.details.cest}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, accounting: {
												...prevItem.accounting,
												details:{
													...prevItem.accounting.details,
													cest: workableItem?.accounting?.details.cest || ""
												}
											}
										}))
									}}
								/>
							</div>
						</div>
					</div>
				</StepContainer>
				<StepContainer>
					<div className="modal-container">
						<div className="header">
							<span className="big-x bold-font">Associar Item</span>
						</div>

						<div className="flex flex-column medium-gap">

							<div className="grid tiny-gap">
								<span className="medium bold-font">Custo de produção</span>
								<span className="small">Selecione qual valor deseja manter</span>
							</div>

							<div className="grid medium-gap">
								<RadioButtonInput
									name="cost"
									label={`${associatedItem?.costs.production} (Antigo)`}
									value={associatedItem?.costs.production.toFixed(2)}
									defaultChecked={item?.costs.production === associatedItem?.costs.production}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, costs: {
												...prevItem.costs,
												production: associatedItem?.costs.production || 0
											}
										}))
									}}
								/>
								<RadioButtonInput
									name="cost"
									label={`${workableItem.costs.production} (Novo)`}
									value={workableItem.costs.production.toFixed(2)}
									defaultChecked={item?.costs.production === workableItem.costs.production}
									onClick={() => {
										setItem(prevItem => ({
											...prevItem, costs: {
												...prevItem.costs,
												production: workableItem.costs.production || 0
											}
										}))
									}}
								/>
							</div>
						</div>
					</div>
				</StepContainer>
			</StepForm>
		</Modal >
	)
}
