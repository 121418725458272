import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Store
import { setCartDeliveryAddress } from "../../../../../shared/store/slices/cartSlice"

// Components
import TextInput from "../../../../../shared/components/standard/TextInput"
import LinkButton from "../../../../../shared/components/standard/LinkButton"
import PageTitle from "../../../../../shared/components/custom/PageTitle/PageTitle"


export default function CheckoutAddressForm() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const cart = useSelector(state => state.cart)

	// States
	const [postal, setPostal] = useState(cart.data.delivery.address.locality.postal)

	const getPostal = async () => {
		try{
			const response = await fetch(`https://viacep.com.br/ws/${postal}/json`)

			responseCodeHandler(response)
			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()

			dispatch(
				setCartDeliveryAddress({
					...cart.data.delivery.address,
					locality: {
						...cart.data.delivery.address.locality,
						postal: postal,
						area: data.bairro,
						road: data.logradouro,
						city: data.localidade,
						region: data.uf,
					},
				})
			)
		}catch(error){
			console.error(error)
		}

	}

	// Effects
	useEffect(() => {

		if (postal.replaceAll(/-|_/g, "").length !== 8) { return }

		// TODO update postal fetch
		getPostal()

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [postal, cart.data, dispatch])

	return (
		<div className="flex flex-column medium-gap large-margin-bottom large-margin-top">
			<PageTitle title="Entrega" />

			<span className="big-x bold-font">Definir novo endereço</span>

			<div className="tiny-margin grid medium-gap fill-large wide-children center-items">
				<TextInput
					type="number"
					value={postal}
					name="postal"
					onChange={e => setPostal(e.target.value.replaceAll(/[^0-9]/g, ""))}
					label={
						<div className="flex justify-content-space-between">
							<span>CEP</span>
							<LinkButton
								hierarchy="minimal"
								target="_blank"
								to="/correios"
							>
								<span>Não sabe seu CEP?</span>
							</LinkButton>
						</div>
					}
				/>

				<TextInput
					label="Rua"
					name="road"
					placeholder="Exemplo: Floriano Peixoto"
					value={cart.data.delivery.address.locality.road}
					onChange={e =>
						dispatch(
							setCartDeliveryAddress({
								...cart.data.delivery.address,
								locality: {
									...cart.data.delivery.address.locality,
									road: e.target.value,
								},
							})
						)
					}
				/>
				<TextInput
					label="Número"
					name="number"
					placeholder="Exemplo: 999"
					value={cart.data.delivery.address.locality.number}
					onChange={e =>
						dispatch(
							setCartDeliveryAddress({
								...cart.data.delivery.address,
								locality: {
									...cart.data.delivery.address.locality,
									number: e.target.value,
								},
							})
						)
					}
				/>
				<TextInput
					label="Complemento (Opcional)"
					name="comments"
					placeholder="Casa/Número do apartamento"
					value={cart.data.delivery.address.locality.extra}
					onChange={e =>
						dispatch(
							setCartDeliveryAddress({
								...cart.data.delivery.address,
								locality: {
									...cart.data.delivery.address.locality,
									extra: e.target.value,
								},
							})
						)
					}
				/>

				<TextInput
					label="Bairro"
					name="area"
					placeholder="Exemplo: Centro"
					value={cart.data.delivery.address.locality.area}
					onChange={e =>
						dispatch(
							setCartDeliveryAddress({
								...cart.data.delivery.address,
								locality: {
									...cart.data.delivery.address.locality,
									area: e.target.value,
								},
							})
						)
					}
				/>
				<TextInput
					label="Cidade"
					name="city"
					placeholder="Exemplo: São Paulo"
					value={cart.data.delivery.address.locality.city}
					onChange={e =>
						dispatch(
							setCartDeliveryAddress({
								...cart.data.delivery.address,
								locality: {
									...cart.data.delivery.address.locality,
									city: e.target.value,
								},
							})
						)
					}
				/>
				<TextInput
					label="CEP"
					name="postal"
					placeholder="Exemplo: 87654321"
					value={cart.data.delivery.address.locality.postal}
					onChange={e =>
						dispatch(
							setCartDeliveryAddress({
								...cart.data.delivery.address,
								locality: {
									...cart.data.delivery.address.locality,
									postal: e.target.value,
								},
							})
						)
					}
				/>
			</div>
		</div>
	)
}
