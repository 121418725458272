import { localDate } from "offiziersmesser/lib/utils"

import "./BiasesTimeline.css"

export default function BiasesTimeline({ items, informative = false }) {

	// Types
	// type status = <0: negative | 1: positive | 2: warning>
	// type item = { status: status; message: string; date: string }[]

	return (
		<ul className="credit-history">
			{items && items.map((item, index) => {

				const biasClass = (() => {
					switch (informative) {
						case true :
							switch (item.bias) {
								case 0:
									return "info"
								case 1:
									return "positive"
								case 2:
									return "negative"
								default: break;
							}; break
						default:
							switch (item.bias) {
								case 0:
									return "negative"
								case 1:
									return "positive"
								case 2:
									return "warning"
								default: break;
							}
					}

					return "info"
				})()

				const createdAt = new Date(item.createdAt*1000)

				return (
					<li className={`${(index >= (items.length - 1)) ? "end" : ""} history-item`}>

						<div className="flex small-gap align-items-center justify-content-center">
							<div className={`${biasClass} history-item-status`} />
							<span className="history-item-message bold-font">
								{item.note}
							</span>
						</div>

						<div className="history-item-date flex flex-wrap small-gap">
							<span>
								{localDate(createdAt, "pt-BR") + " | " + createdAt.getFullYear()}
							</span>
						</div>
					</li>
				)
			})}
		</ul>
	)
}
