import { ChangeEvent } from "react"
import { useSelector, useDispatch } from "react-redux"

// Utils
import { toUnsignedFloat } from "offiziersmesser/lib/utils"

// Store
import { RootState } from "src/shared/store/reducers"
import { updateWorkableItem } from "src/shared/store/slices/workableItemsSlice"

// Components
import NumberInput from "src/shared/components/standard/NumberInput"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"

export default function BatchesHandler() {

	const dispatch = useDispatch()

	// Store
	const item = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)

	return (
		item &&
		<div className="flex flex-column medium-gap">

			<span className="big-x bold-font">Disponibilidade</span>

			<CheckboxInput
				label="Pronta entrega"
				defaultChecked={item.control.available === true}
				onChange={() => {
					if (item.control.available === false)
						dispatch(
							updateWorkableItem({ ...item, control: { ...item.control, available: true } })
						)
					else
						dispatch(
							updateWorkableItem({
								...item,
								control: {
									...item.control,
									available: false,
									quantity: 0,
									autoDeactivatable: false
								}
							})
						)
				}}
			/>

			<div className="grid fill-tiny">
				<NumberInput
					label="Estoque"
					value={item.control.quantity}
					disabled={item.control.available === false}
					onChange={(e: ChangeEvent<HTMLInputElement>) => dispatch(
						updateWorkableItem({
							...item,
							control: {
								...item.control,
								quantity: toUnsignedFloat(e.target.value, 3)
							}
						})
					)}
				/>
			</div>

			<div className="grid medium-gap">
				<CheckboxInput
					label="Venda fracionada"
					defaultChecked={item.control.fractionable === true}
					disabled={item.control.available === false}
					onChange={() => dispatch(
						updateWorkableItem({
							...item,
							control: {
								...item.control,
								fractionable: !item.control.fractionable
							}
						})
					)}
				/>
				<CheckboxInput
					label="Desativar produto ao zerar o estoque"
					defaultChecked={item.control.autoDeactivatable === true}
					disabled={item.control.available === false}
					onChange={() => dispatch(
						updateWorkableItem({
							...item,
							control: {
								...item.control,
								autoDeactivatable: !item.control.autoDeactivatable,
							},
						})
					)}
				/>
			</div>
		</div>
	)
}
