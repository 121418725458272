// Components
import Modal from "../../../../shared/components/custom/Modal/Modal";
import QuickResponseCode from "../../../../shared/components/custom/QuickResponseCode/QuickResponseCode";

// Styles
import "./QrCodeModal.css"

export default function QRCodeModal({
	title,
	description,
	code,
	onRenderModal
}) {

	return (
		<Modal onClose={onRenderModal}>
			<div className="qrcode-modal-container small-padding">
				<div className="medium-margin-bottom">
					<span className="big-x bold-font">{title}</span>
					<span className="medium-margin-bottom">{description}</span>
				</div>
				<div className="flex align-items-center justify-content-center">
					<QuickResponseCode value={code} />
				</div>
			</div>
		</Modal>
	)
}
