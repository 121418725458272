import { CSSProperties, ForwardedRef, forwardRef, RefObject, useEffect } from "react"
import JsBarcode from "jsbarcode" // https://www.npmjs.com/package/jsbarcode

const Renderer = {
	svg: "svg",
	image: "image",
	canvas: "canvas"
}

type BarcodeProps = {
	style?: CSSProperties
	className?: string
	value: string
	options?: JsBarcode.Options
	renderer?: string
	currentRef: RefObject<any>
}

const Barcode = forwardRef(({
	style,
	className,
	value,
	options,
	renderer = Renderer.canvas,
	currentRef
}: BarcodeProps, ref: ForwardedRef<any>) => {

	useEffect(() => {
		currentRef.current && JsBarcode(currentRef.current, value, options)
	}, [value, options, renderer, currentRef])

	return (
		(renderer === "canvas") ? (
			<canvas ref={ref} style={style} className={className} />
		) : (renderer === "image") ? (
			<img ref={ref} alt="barcode" style={style} className={className} />
		) : (
			<svg ref={ref} style={style} className={className} />
		)
	)
})

export default (Barcode)
