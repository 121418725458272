import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"

// Store
import { setUser } from "src/shared/store/slices/userSlice"


export default function CscModal() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector(state => state.user)

	// States
	const [token, setToken] = useState(user.taxation.csc.token)
	const [code, setCode] = useState(user.taxation.csc.code)
	const [renderCscModal, setRenderCscModal] = useState(false)


	// Handlers
	async function handlePutNewCscSettings() {
		try {
			const token = getToken()

			const updatedData = {
					code,
					token
			}

			const response = await fetch(
				process.env.REACT_APP_API_URL + "/resource/supplier/csc",
				{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(updatedData)
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			setRenderCscModal(false)
			dispatch(setUser({
				...user,
				taxation:{
					...user.taxation,
					csc:updatedData
				}
			}))
		} catch (error) {
			console.error(error)
		}
	}

	return (
	<>
		{renderCscModal === true && (
			<Modal title="Definição do código de segurança do contribuinte" onClose={() => setRenderCscModal(false)}>
				<div className="grid fill-normal small-gap">
					<TextInput
						label="Code"
						value={code}
						onChange={e => setCode(e.target.value)}
					/>
					<TextInput
						label="Token"
						value={token}
						onChange={e => setToken(e.target.value)}
					/>
				</div>

				<MainButton onClick={() => handlePutNewCscSettings()}>
					<span>Definir token</span>
				</MainButton>
			</Modal>
		)}

		<MainButton hierarchy="low" onClick={() => setRenderCscModal(true)}>
			<span>Configurar CSC</span>
		</MainButton>
	</>
	)
}
