// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Styles
// import "./ComponentLoader.css"

type ComponentLoaderProps = {
	size?: string
	color?: string
	className?: string
}

export default function ComponentLoader({
	size = "gigantic",
	color = "orange",
	className,
}: ComponentLoaderProps) {
	return (
		<FontAwesomeIcon
			className={className || `tiny-margin fa-icon fa-beat ${size} ${color}`}
			icon={faCircle as Icon}
		/>
	)
}
