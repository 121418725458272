// Utils
import { localDate } from "offiziersmesser/lib/utils"

// Icons
import { faCheck, faExpandAlt, faFileInvoice, faQuestion, faRedo, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Notice from "../../../../../../../shared/components/custom/Notice/Notice"
import LinkButton from "../../../../../../../shared/components/standard/LinkButton"

export default function OrdersTableRow({
	order,
	userType = "supplier"
}) {

	return (
		<tr>
			<td className="grid-columns small-gap">
				<LinkButton
					hierarchy="low"
					to={`/${userType}/order/${order.record}`}
					title="Visualizar pedido"
				>
					<FontAwesomeIcon className="fa-icon" icon={faExpandAlt} />
				</LinkButton>
				<LinkButton
					hierarchy="low"
					to={`/${userType}/invoice/order/${order.record}`}
					title="Emitir NF-e"
				>
					<FontAwesomeIcon className="fa-icon" icon={faFileInvoice} />
				</LinkButton>
				<LinkButton
					hierarchy="low"
					to={`/${userType}/return/order/${order.record}`}
					title="Devolver pedido"
				>
					<FontAwesomeIcon className="fa-icon" icon={faRedo} />
				</LinkButton>
			</td>

			<td className="OrderStatus-column">
				{(() => {

					let status

					switch (order.statuses?.length > 0 ? order?.statuses[order.statuses?.length - 1].bias : "") {
						case 0:
							status = "info"; break
						case 1:
							status = "success"; break
						case 2:
							status = "failure"; break
						default: break;
					}

					return (
						<Notice tone={status} size="tiny">
							<span className="small">
								{order.statuses?.length > 0 ? order?.statuses[order.statuses?.length - 1].note : ""}
							</span>
						</Notice>
					)
				})()}
			</td>

			<td>{order.customer.reference.name === undefined ? " " : order.customer.reference.name + " " + order.customer.reference.surname}</td>

			<td>{localDate(order.createdAt * 1000)}</td>

			<td>{localDate(order.updatedAt * 1000)}</td>

			<td>
				<FontAwesomeIcon
					className="fa-icon"
					icon={order?.delivery?.atSupplier === false ? faTimes : faCheck}
				/>
			</td>

			{(order.delivery.atSupplier === false) ? (
				((order.delivery.carriers?.length === 0) ? (
					<td><FontAwesomeIcon className="fa-icon" icon={faQuestion} /></td>
				) : (
					<td>
						<span className="bold-font">
							{order.delivery.carriers?.map(agent => agent.identifier).join(", ")}
						</span>
					</td>
				))
			) : (
				<td className="disabled" />
			)}
		</tr>
	)
}
