import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

// Utils
import { PAYMENT_METHODS } from "offiziersmesser/lib/utils"
import {
	calculateOrderTotal,
	calculateOrderPaymentCovered,
	calculateOrderSumPlusShipping,
} from "offiziersmesser/lib/helpers"

// Store
import { setCart } from "../../../../shared/store/slices/cartSlice"

// Components
import Textarea from "../../../../shared/components/standard/Textarea"
import TextInput from "../../../../shared/components/standard/TextInput"

// Styles
import "../Checkout.css"
import RadioButtonInput from "src/shared/components/standard/RadioButtonInput"

export default function CompletePurchase({
	userAccountData,
	accountType,
	isScheduledPickupMethod,
	isDeliveryMethod,
	nfceEmission,
	setNfceEmission,
	onCreditTotal
}) {

	// Store
	const cart = useSelector(state => state.cart)
	const guest = useSelector(state => state.guest)
	const dispatch = useDispatch()

	// States
	const [, setSumPlusShipping] = useState(calculateOrderSumPlusShipping(cart.data))
	const [covered, setCovered] = useState(0)
	const [total, setTotal] = useState(0)

	const [normalized, setNormalized] = useState(false)
	const [coveredNormalized, setCoveredNormalized] = useState(calculateOrderPaymentCovered(cart))
	const [totalNormalized, setTotalNormalized] = useState(calculateOrderTotal(cart))
	// Functions

	// Thats function preserves  payments comportament, so Noah and argus dont know manipulate when payments excede total transaction.
	// This aprotch for dont give errors
	const normalizePaymentsCart = () => {
		if(coveredNormalized > totalNormalized){
			setSumPlusShipping(calculateOrderSumPlusShipping(cart.data))
			setCovered(calculateOrderPaymentCovered(cart))
			setTotal(calculateOrderTotal(cart))

			const covered = calculateOrderPaymentCovered(cart)
			const total = calculateOrderTotal(cart)

			let maxPart = {
				value:0,
				index: 0
			}

			for (let index = 0; index < cart.payment[0].parts.length; index++) {
				const element = cart.payment[0].parts[index];
				if (element.value > maxPart.value) {
					maxPart.value = element.value
					maxPart.index = index
				}

			}

			const newPartArray = cart.payment[0].parts.map((part, index)=> index === maxPart.index?{...part, value: Number((part.value-(covered.toPrecision(3)-total.toPrecision(3))).toPrecision(3))}: part)

			dispatch(setCart({
				...cart,
				payment:[{
					...cart.payment[0],
					parts: newPartArray
				}]
			}))
			setCoveredNormalized(calculateOrderPaymentCovered({
				...cart,
				payment:[{
					...cart.payment[0],
					parts: newPartArray
				}]
			}))
			setTotalNormalized(calculateOrderTotal({
				...cart,
				payment:[{
					...cart.payment[0],
					parts: newPartArray
				}]
			}))
			setNormalized(true)
		}else{
			if(!normalized){
				setSumPlusShipping(calculateOrderSumPlusShipping(cart.data))
				setCovered(calculateOrderPaymentCovered(cart))
				setTotal(calculateOrderTotal(cart))
			}
		}
	}
	// Effects
	useEffect(()=>{
		normalizePaymentsCart()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[coveredNormalized,totalNormalized])



	return (
		<div className="CompletePurchaseContainer">
			<div className="CompletePurchaseSection medium-margin-bottom">
				<span className="big-x bold-font medium-margin-bottom">
					Resumo Do Pedido
				</span>

				<div className="flex flex-column small-gap medium-margin-bottom">
					<span className="big bold-font">Cliente</span>
					{(accountType !== 2 || guest.taxpayer !== "") ? (
						<div className="grid small-gap">
							<span>
								{(guest.administrative.reference.name + " " + guest.administrative.reference.surname)}
							</span>

							<span>
								{(guest.administrative.reference.phone.destination + " " + guest.administrative.reference.phone.subscriber)}
							</span>
						</div>
					) : (
						<span>Cliente Anônimo</span>
					)}
				</div>

				<div className="flex flex-column small-gap medium-margin-bottom">
					<span className="big bold-font">Entrega</span>

					<div className="flex flex-column small-gap">

						{(cart.data.delivery.shipping.active === true &&
							cart.data.delivery.atSupplier === false)
							? (
								<div className="flex flex-column">
									<span>
										<span className="bold-font">Entregar em: </span>
										{`${cart.data.delivery.address.locality.road}, ${cart.data.delivery.address.locality.number}, ${cart.data.delivery.address.locality.area}, ${cart.data.delivery.address.locality.city} - ${cart.data.delivery.address.locality.region}`}
									</span>
									<span>
										{/* FIXME -  favor fazer o calculo certo de preço de frete*/}
										<span className="bold-font">Frete: </span>
										R$ {cart.data.delivery.shipping.ante + cart.data.delivery.shipping.fee}
									</span>
								</div>
							) : <span className="medium">Retirada</span>
						}
						<span className="big bold-font">Quem Recebe</span>
						<div className="flex flex-column">
							<span>
								{cart.data.delivery.address.reference.name + " " + cart.data.delivery.address.reference.surname}
							</span>
							<span>
								+ {cart.data.delivery.address.reference.phone.prefix + " " + cart.data.delivery.address.reference.phone.subscriber}
							</span>
						</div>
					</div>
				</div>

				<div className="flex flex-column small-gap">
					<span className="big bold-font"> Forma De Pagamento </span>
					{onCreditTotal &&
						<div className="flex flex-column">
							Venda à prazo
						</div>
					}
					{cart.payment &&
					<>
						{!onCreditTotal &&
							<div className="flex flex-column">
								{cart.payment[0].parts.map((element, index) => (
									<span key={index}>
										{PAYMENT_METHODS[element.method].name + " à vista"}
									</span>
								))}
							</div>
						}
						<div className="flex flex-column">
							Total: {(total+cart.payment[0].other[0]-cart.payment[0].other[1]).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
						</div>
						{!onCreditTotal &&
							<>
								<div className="flex flex-column">
									Valor Recebido: {(covered+cart.payment[0].other[0]-cart.payment[0].other[1]).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
								</div>
								<div className="flex flex-column">
									<span className="bold-font">Troco: {(covered - total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
								</div>
							</>
						}
					</>
					}
				</div>
			</div>

			<div className="CompletePurchaseSection">
				<span className="big-x bold-font  medium-margin-bottom">
					Adicionais
				</span>

				<div className="grid medium-gap">
					<TextInput
						label={
							<div className="flex flex-column small-margin-bottom small-gap">
								<span className="bold-font medium">
									CPF ou CNPJ atrelado ao pedido
									{(!isScheduledPickupMethod && !isDeliveryMethod) && (" (Opcional)")}
								</span>
								{(isScheduledPickupMethod || isDeliveryMethod) && (
									<p className="small red">
										Obrigatório pois a retirada é agendada ou o será enviado pela loja
									</p>
								)}
							</div>
						}
						value={cart.data.buyer.taxpayer}
						placeholder="Exemplo: 999.999.999.99"
						onChange={e => {
							dispatch(
								setCart({
									...cart,
									data: {
										...cart.data,
										buyer: {
											...cart.data.buyer,
											taxpayer: e.target.value,
										},
									},
								})
							)
						}}
					/>

					<Textarea
						label="Nota (Opcional)"
						value={cart.data.buyer.note}
						placeholder="Use para eventuais instruções sobre o pedido"
						onChange={e => {
							dispatch(
								setCart({
									...cart,
									data: {
										...cart.data,
										buyer: {
											...cart.data.buyer,
											note: e.target.value,
										},
									},
								})
							)
						}}
					/>
                    <RadioButtonInput
                        name="nfc"
                        label="Emitir cupom fiscal"
                        value={nfceEmission}
						checked={nfceEmission === true}
                        defaultChecked={true}
                        onChange={() => onCreditTotal ?setNfceEmission(false):setNfceEmission(true)}
                    />
                    <RadioButtonInput
                        name="nfc"
                        label="Emitir DAV"
                        value={nfceEmission}
						checked={nfceEmission === false}
                        onChange={() => setNfceEmission(false)}
                    />
				</div>
			</div>
		</div>
	)
}
