import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Utils
import {
	toUnsignedFloat,
	toUnsignedInteger,
} from "offiziersmesser/lib/utils"
import { PAYMENT_METHODS } from "offiziersmesser/lib/utils"
import { calculateOrderPaymentInstanceInterests } from "offiziersmesser/lib/helpers"
import { generatePixString } from "offiziersmesser/lib/utils"

// Store
import { setCart } from "src/shared/store/slices/cartSlice"

// Icons
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import SelectInput from "src/shared/components/standard/SelectInput"
import NumberInput from "src/shared/components/standard/NumberInput"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"
import MainButton from "src/shared/components/standard/MainButton"
import QrCodeModal from "../../QrCodeModal/QrCodeModal"




export default function CheckoutPaymentCard({
	element,
	deleteable,
	addition,
	discount,
	onCredit,
	setAdditionArray,
	index,
	total,
    onMethodChange,
	disabled
}) {

	const dispatch = useDispatch()

	// Store
	const cart = useSelector(state => state.cart)
	const user = useSelector(state => state.user)

	// States
	const [installmentPlan, setInstallmentPlan] = useState(false)
	const [period, setPeriod] = useState(2)
	const [percentage, setPercentage] = useState(user.installments.interest.percentage)
	const [compoundInterest, setCompoundInterest] = useState(user.installments.interest.compound)

	const [selectedMethod, setSelectedMethod] = useState(0)

	const [renderPixModal, setRenderPixModal] = useState()

	// Variables
	const additionAux = addition

	// Handlers
	function onRenderPixModal() {
		setRenderPixModal(prevState => !prevState)
	}

	// Effects
	useEffect(() => {
		if (installmentPlan) {
			additionAux[index] = toUnsignedFloat(
				calculateOrderPaymentInstanceInterests(
					element,
					compoundInterest,
					percentage,
					period
				), 2
			)
			setAdditionArray([...additionAux])
		} else {
			additionAux[index] = 0
			setAdditionArray([...additionAux])
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [installmentPlan])

	return (
		<>
			{renderPixModal === true && (
				<QrCodeModal
					title="QR code do PIX"
					description="Utilize o app do banco para escanear o QR code"
					onRenderModal={() => onRenderPixModal()}
					code={generatePixString({
						transaction_amount: total,
						merchant_account: user.deposits.pix[0]
					})}
				/>
			)}

			<div className="element-border flex flex-column small-gap align-items-flex-start" >
				<div
					className={`${installmentPlan === true
						? "checkout-payment-card-container wide"
						: "checkout-payment-card-alternative wide"}`
					}
				>
					<div className="grid medium-gap wide">
						<div className="grid fill-small small-gap">
							<div className="wide">
                            <SelectInput
                                label="Método"
                                name="method"
                                disabled={onCredit || disabled }
                                value={element.method}
                                onChange={(event) => {
                                    setSelectedMethod(Number(event.target.value))
                                    dispatch(
                                        setCart({
                                            ...cart,
                                            payment: [
                                                {
                                                    ...cart.payment[0],
                                                    parts: cart.payment[0].parts.map(
                                                        (part, idx) => {
                                                            if (idx === index) {
                                                                return {
                                                                    ...part,
																	value: (Number(event.target.value) === 2 || Number(event.target.value) === 1) && index === 0? total: element.value,
                                                                    method: Number(event.target.value)
                                                                }
                                                            }
                                                            return part
                                                        }
                                                    ),
                                                },
                                            ],
                                        })
                                    )
                                }}
                                >
                                {PAYMENT_METHODS.map((entry, index) => (
                                    <option key={index} value={index}>
                                        {entry.name}
                                    </option>
                                ))}
                                </SelectInput>
							</div>

							<div className="wide">
								<NumberInput
									label="Valor"
									value={element.value}
									disabled={((onCredit || selectedMethod === 2 || selectedMethod === 1 || selectedMethod === 12 ) && index === 0) || disabled}
									onChange={(event) => {

										const value = event.target.valueAsNumber

										dispatch(
											setCart({
												...cart,
												payment: [
													{
														...cart.payment[0],
														parts: cart.payment[0].parts.map(
															(part, idx) => {
																if (idx === index) {

																	return {
																		...part,
																		value: selectedMethod === 2 && index === 0? total : value
																	}
																}
																return part
															}
														),
													},
												],
											})
										)
									}}
									onBlur={(event) => {
											if(isNaN(event.target.valueAsNumber)){

												const value = 0

												dispatch(
													setCart({
														...cart,
														payment: [
															{
																...cart.payment[0],
																parts: cart.payment[0].parts.map(
																	(part, idx) => {
																		if (idx === index) {

																			return {
																				...part,
																				value: value
																			}
																		}
																		return part
																	}
																),
															},
														],
													})
												)
											}
										}
									}
								/>
							</div>
						</div>

						<div className="checkout-payment-section">
							<div className="checkout-payment-card-installments">
								{((selectedMethod === 12) || (selectedMethod === 2) ) && user.installments.active && (
										<CheckboxInput
											label="Parcelado"
											disabled={onCredit }
											checked={installmentPlan === true}
											onChange={() => setInstallmentPlan(!installmentPlan)}
										/>
									)
								}
							</div>
						</div>

						{installmentPlan === true && (
							<div className="grid fill-small small-gap">
								<div className="element-full-width">
									<NumberInput
										label="Período"
										value={period}
										disabled={installmentPlan === false}
										max={user.installments.period}
										onChange={(event) =>setPeriod(isNaN(event.target.valueAsNumber)?0:event.target.valueAsNumber)}
										onBlur={(event) =>
											setPeriod(
												event.target.valueAsNumber > period || isNaN(event.target.valueAsNumber)
													? event.target.valueAsNumber
													: event.target.valueAsNumber < 2
														? 2 : event.target.valueAsNumber <= user.installments.period ? toUnsignedInteger(event.target.valueAsNumber):12
											)
										}
									/>
								</div>

								<div className="element-full-width">
									<NumberInput
										label="Juros (%)"
										value={percentage}
										disabled={!installmentPlan || !user.installments.custom}
										onChange={(e) => {
											setPercentage(
												e.target.valueAsNumber < 0 || isNaN(e.target.valueAsNumber)
													? 0 : toUnsignedFloat(e.target.valueAsNumber, 3)
											)
										}}
									/>
								</div>
							</div>
						)}


						{user.installments.interest.compound &&
						<div className="checkout-payment-section checkout-payment-card-compound">
							<div>
								<CheckboxInput
									label="Juros compostos"
									defaultChecked={compoundInterest === true}
									disabled={!installmentPlan || !user.installments.custom}
									onChange={() => setCompoundInterest(!compoundInterest)}
								/>
							</div>
						</div>}
					</div>

					<div className="overview-aside">
						<div className="checkout-payment-card-partial grid center-text center-items">
							<span className="big-x bold-font"> {isNaN(element.value)?"R$ 0,00": element.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </span>
							<div className="bold-font">
								<hr />

								{(installmentPlan === true && (
									<>
										<span className="big width-fit-content">
											{`${period}x de ${(((element.value + calculateOrderPaymentInstanceInterests(
												element,
												compoundInterest,
												percentage,
												period
											)) / period)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}
										</span>

										<br />

										<span className="big">
											Total de {(isNaN(element.value)?0: element.value + calculateOrderPaymentInstanceInterests(
												element,
												compoundInterest,
												percentage,
												period
											)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
										</span>
									</>
								)) || <span className="big">À vista</span>}
							</div>
						</div>

						<div className="checkout-payment-card-remove">
							{(deleteable || onCredit) && (
								<MainButton
									hierarchy="low"
									disabled={deleteable === false || onCredit}
									onClick={() => {
										dispatch(
											setCart({
												...cart,
												payment: [
													{
														...cart.payment[0],
														parts: cart.payment[0].parts.filter(
															(part, partIndex) =>
																index !== partIndex
														),
													},
												],
											})
										)
										setAdditionArray(
											addition.filter(
												(add, AddIndex) => index !== AddIndex
											)
										)
									}}
								>
									<FontAwesomeIcon
										className="fa-icon"
										icon={faTimes}
									/>
								</MainButton>
							)}
						</div>
					</div>
				</div>

				{selectedMethod === 10 && (
					<MainButton
						className="element-highlight base small-padding"
						onClick={() => onRenderPixModal()}
					>
						<span className="flex small-gap medium flex align-items-center justify-content-center">
							Gerar Qr code Pix
						</span>
					</MainButton>
				)}
			</div>
		</>
	)
}

// { name: "Dinheiro", sefaz: "1" },
// { name: "Cartão de débito", sefaz: "4" },
// { name: "Cartão de crédito", sefaz: "3" },
// { name: "Cheque", sefaz: "2" },
// { name: "Vale-alimentação", sefaz: "10" },
// { name: "Vale-refeição", sefaz: "11" },
// { name: "Vale-presente", sefaz: "12" },
// { name: "Vale-combustível", sefaz: "13" },
// { name: "Crédito na loja", sefaz: "5" },
// { name: "TED/DOC", sefaz: "18" },
// { name: "PIX", sefaz: "17" },
// { name: "Boleto", sefaz: "15" },
// { name: "Financiamento", sefaz: "99" },
