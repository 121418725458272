import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// store
import { setCart } from "../../../../../shared/store/slices/cartSlice"
import { setGuest } from "../../../../../shared/store/slices/guestSlice"

// Components
import RadioButtonInput from "../../../../../shared/components/standard/RadioButtonInput"

import CheckoutAddressList from "./CheckoutAddressList"
import CheckoutAddressForm from "./CheckoutAddressForm"
import CheckoutReceiverForm from "./CheckoutReceiverForm"
import CheckoutShipping from "./CheckoutShipping"

import PickupMethod from "./PickupMethod"

// Styles
import "../../Checkout.css"

export default function DeliveryBlock() {

	const dispatch = useDispatch()

	// Store
	const cart = useSelector(state => state.cart)
	const store = useSelector(state => state.store)
	const guest = useSelector(state => state.guest)

	// States
	const [deliveryMethod, setDeliveryMethod] = useState(0)

	// Handlers
	function handleSelectPickup() {

		dispatch(setGuest({ ...guest, customAddress: false }))
		dispatch(
			setCart({
				...cart,
				data: {
					...cart.data,
					delivery: {
						...cart.data.delivery,
						atSupplier: true,
					},
					address: {
						...cart.data.delivery.address,
						reference: guest.administrative.reference,
						locality: store.data.locality,
					},
				},
			})
		)
		setDeliveryMethod(0)
	}
	function handleSelectUserAdress() {

		dispatch(
			setGuest({ ...guest, customAddress: false })
		)
		dispatch(
			setCart({
				...cart,
				data: {
					...cart.data,
					delivery: {
						...cart.data.delivery,
						atSupplier: false,
					},
				},
			})
		)
		setDeliveryMethod(1)
	}
	function handleSelectNewAdress() {

		dispatch(
			setGuest({ ...guest, customAddress: true })
		)
		dispatch(
			setCart({
				...cart,
				data: {
					...cart.data,
					delivery: {
						...cart.data.delivery,
						atSupplier: false,
					},
				},
			})
		)
		setDeliveryMethod(2)
	}

	return (
		<div className="DeliveryContainer">
			<nav className="flex medium-gap medium-margin-bottom">
				<RadioButtonInput
					name="delivery"
					label="Retirada no estabelecimento"
					value="0"
					defaultChecked={cart.data.delivery.atSupplier}
					onChange={() => handleSelectPickup()}
				/>

				{cart.data.delivery.shipping.active && guest.registered && (
					<RadioButtonInput
						name="delivery"
						label="Entrega em endereço do cliente"
						value="1"
						defaultChecked={
							cart.data.delivery.atSupplier === false &&
							guest.customAddress === false
						}
						onChange={() => handleSelectUserAdress()}
					/>
				)}

				{cart.data.delivery.shipping.active && (
					<RadioButtonInput
						name="delivery"
						label="Entrega em novo endereço"
						value="2"
						defaultChecked={guest.customAddress}
						onChange={() => handleSelectNewAdress()}
					/>
				)}
			</nav>

			<div>
				{deliveryMethod === 0 && (
					<PickupMethod cart={cart} setCart={setCart} />
				)}

				{deliveryMethod === 1 && (
					<>
						<CheckoutAddressList />
						<CheckoutShipping />
					</>
				)}

				{deliveryMethod === 2 && (
					<>
						<CheckoutAddressForm />
						<CheckoutShipping />
					</>
				)}

				<CheckoutReceiverForm />
			</div>
		</div>
	)
}
