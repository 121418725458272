import { ChangeEvent, Dispatch, SetStateAction, useState } from "react"

// Utils
import { AREA_CODES_BR } from "offiziersmesser/lib/utils"

// Models
import { PhoneModel } from "src/shared/models/user"

// Components
import Form from "src/shared/components/standard/Form"
import Modal from "src/shared/components/custom/Modal/Modal"
import Textarea from "src/shared/components/standard/Textarea"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"
import SelectInput from "src/shared/components/standard/SelectInput"

type NewEndorsementModalProps = {
	endorsementData?: {
		reference: {
			name?: string
			surname?: string
			email?: string
			phone?: PhoneModel
		}
		note?: string
	}
	editorMode?: boolean
	renderModal: () => void
	setEndorsements: Dispatch<SetStateAction<any>>
	updateEndorsements?: (endorsement: any)=>void
	endorsementIndex?:number
	isUpdate: boolean

}

export default function NewEndorsementModal({
	endorsementData,
	editorMode,
	renderModal,
	setEndorsements,
	updateEndorsements,
	isUpdate
}: NewEndorsementModalProps) {

	// States
	const [endorsement, setEndorsement] = useState({
		reference: {
			name: endorsementData?.reference?.name || "",
			surname: endorsementData?.reference?.surname || "",
			email: endorsementData?.reference?.email || "",
			phone: {
				destination: endorsementData?.reference.phone?.destination || "",
				subscriber: endorsementData?.reference.phone?.subscriber || "",
				prefix: "55",
			},
		},
		note: endorsementData?.note || ""
	})

	// Handlers
	function handleUpdateReferenceInput(
		field: string,
		value: string,
		formGroup?: string,
	) {
		switch (formGroup) {
			case "reference":
				return setEndorsement(endorsement => ({
					...endorsement,
					reference: {
						...endorsement.reference,
						[field]: value
					}
				}))
			case "phone":
				return setEndorsement(endorsement => ({
					...endorsement,
					reference: {
						...endorsement.reference,
						phone: {
							...endorsement.reference.phone,
							[field]: value
						}
					}
				}))
			default:
				return setEndorsement(endorsement => ({
					...endorsement,
					[field]: value
				}))

		}
	}
	function handleCreateUpdateEndorsment() {
		setEndorsements(endorsement)
	}

	return (
		<Modal onClose={() => renderModal()}>
			<div className="modal-container">
				<div className="header">
					<span className="big-x bold-font">
						{editorMode ? "Editar" : "Criar"} referência
					</span>
				</div>

				<Form>
					<div className="grid medium-gap">

						<div className="grid small-gap">
							<div className="grid fill-large small-gap">
								<TextInput
									label="Nome"
									name="name"
									placeholder="João"
									value={endorsement.reference.name}
									onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateReferenceInput(e.target.name, e.target.value, "reference")}
								/>
								<TextInput
									label="Sobrenome"
									name="surname"
									placeholder="Souza"
									value={endorsement.reference.surname}
									onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateReferenceInput(e.target.name, e.target.value, "reference")}

								/>
							</div>

							<div className="grid fill-large small-gap">
								<div className="grid-columns small-gap">
									<SelectInput
										label="DDD"
										name="destination"
										value={endorsement.reference.phone.destination}
										onChange={(e: ChangeEvent<HTMLSelectElement>) => handleUpdateReferenceInput(e.target.name, e.target.value, "phone")}

									>
										{AREA_CODES_BR.map((code,index)=>
											<option key={index} value={code}>{code}</option>
										)}
									</SelectInput>

									<TextInput
										label="Celular"
										name="subscriber"
										placeholder="901239876"
										value={endorsement.reference.phone.subscriber}
										onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateReferenceInput(e.target.name, e.target.value, "phone")}

									/>
								</div>

								<TextInput
									label="E-mail"
									name="email"
									placeholder="joao@exemplo.com"
									value={endorsement.reference.email}
									onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateReferenceInput(e.target.name, e.target.value, "reference")}

								/>
							</div>

							<Textarea
								label="Nota"
								name="note"
								value={endorsement.note}
								onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleUpdateReferenceInput(e.target.name, e.target.value)}
								rows={2}
							/>
						</div>

						<div className="medium-margin-top wide-children">
							<MainButton
								onClick={() => {
									if(isUpdate){
										updateEndorsements&&updateEndorsements(endorsement)
										renderModal()
									}else{
										handleCreateUpdateEndorsment()
										renderModal()
									}
								}}
							>
								<span>{isUpdate?"Atualizar":"Salvar"}</span>
							</MainButton>
						</div>
					</div>
				</Form>
			</div>
		</Modal>
	)
}
