// Components
import TextInput from "../../../../../shared/components/standard/TextInput"
import SelectInput from "../../../../../shared/components/standard/SelectInput"
import Form from "../../../../../shared/components/standard/Form"
import NumberInput from "../../../../../shared/components/standard/NumberInput"

export default function DeliveryBlock({ transporte, setTransporte }) {
	return (
		<Form className="normal-padding item-full-height normal-margin-top">
			<div className="normal-margin-top medium-margin-bottom">
				<span className="bold-font big">Dados da transportadora</span>
			</div>
			<div className="tiny-margin grid small-gap wide-children center-items">
				<div className="grid fill-large medium-gap">
					<TextInput
						label="CPF ou CNPJ do destinatário"
						value={transporte.enderecoEntrega.inscriçãoEstadual}
						onChange={e =>
							setTransporte({
								...transporte,
								inscriçãoEstadual: {
									cidade: e.target.value,
								},
							})
						}
					/>
					<TextInput
						label="Inscrição estadual"
						value={transporte.enderecoEntrega.cpfCnpjDestinatario}
						onChange={e =>
							setTransporte({
								...transporte,
								cpfCnpjDestinatario: {
									cidade: e.target.value,
								},
							})
						}
					/>
				</div>
				<div className="grid fill-large medium-gap small-margin-top">
					<TextInput
						label="CEP"
						value={transporte.enderecoEntrega.cep}
						onChange={e =>
							setTransporte({
								...transporte,
								enderecoEntrega: { cep: e.target.value },
							})
						}
					/>
				</div>
				<div className="grid medium-gap">
					<TextInput
						label="Logradouro"
						value={transporte.enderecoEntrega.logradouro}
						onChange={e =>
							setTransporte({
								...transporte,
								enderecoEntrega: {
									logradouro: e.target.value,
								},
							})
						}
					/>
					<TextInput
						label="Bairro"
						value={transporte.enderecoEntrega.bairro}
						onChange={e =>
							setTransporte({
								...transporte,
								enderecoEntrega: { bairro: e.target.value },
							})
						}
					/>
				</div>
				<div className="grid medium-gap">
					<TextInput
						label="Número"
						value={transporte.enderecoEntrega.numero}
						onChange={e =>
							setTransporte({
								...transporte,
								enderecoEntrega: { numero: e.target.value },
							})
						}
					/>
					<TextInput
						label="Complemento"
						value={transporte.enderecoEntrega.complemento}
						onChange={e =>
							setTransporte({
								...transporte,
								enderecoEntrega: {
									complemento: e.target.value,
								},
							})
						}
					/>
					<TextInput
						label="Cidade"
						value={transporte.enderecoEntrega.cidade}
						onChange={e =>
							setTransporte({
								...transporte,
								enderecoEntrega: { cidade: e.target.value },
							})
						}
					/>
					<TextInput
						label="UF"
						value={transporte.enderecoEntrega.uf}
						onChange={e =>
							setTransporte({
								...transporte,
								enderecoEntrega: { uf: e.target.value },
							})
						}
					/>
				</div>
				<SelectInput
					label="País"
					value={transporte.enderecoEntrega.pais}
					onChange={e =>
						setTransporte({
							...transporte,
							enderecoEntrega: { pais: e.target.value },
						})
					}
				>
					<option value="BR">Brasil</option>
				</SelectInput>
			</div>

			<div className="normal-margin-top medium-margin-bottom">
				<span className="bold-font big">Dados do veículo</span>
			</div>
			<div className="tiny-margin grid small-gap fill-small wide-children">
				<TextInput
					label="UF"
					value={transporte.veiculo.uf}
					onChange={e =>
						setTransporte({
							...transporte,
							veiculo: { uf: e.target.value },
						})
					}
				/>
				<TextInput
					label="Placa"
					value={transporte.veiculo.placa}
					onChange={e =>
						setTransporte({
							...transporte,
							veiculo: { placa: e.target.value },
						})
					}
				/>
				<TextInput
					label="RNTC"
					value={transporte.veiculo.rntc}
					onChange={e =>
						setTransporte({
							...transporte,
							veiculo: { rntc: e.target.value },
						})
					}
				/>
			</div>

			<div className="normal-margin-top medium-margin-bottom">
				<span className="bold-font big">Detalhes do volume</span>
			</div>
			<div className="tiny-margin grid small-gap fill-small wide-children">
				<NumberInput
					label="Quantidade"
					value={transporte.volume.quantidade}
					onChange={e =>
						setTransporte({
							...transporte,
							volume: { quantidade: Number(e.target.value) },
						})
					}
				/>
				<TextInput
					label="Espécie"
					value={transporte.volume.especie}
					onChange={e =>
						setTransporte({
							...transporte,
							volume: { especie: e.target.value },
						})
					}
				/>
				<TextInput
					label="Marca"
					value={transporte.volume.marca}
					onChange={e =>
						setTransporte({
							...transporte,
							volume: { marca: e.target.value },
						})
					}
				/>
				<TextInput
					label="Numeração"
					value={transporte.volume.numeracao}
					onChange={e =>
						setTransporte({
							...transporte,
							volume: { numeracao: e.target.value },
						})
					}
				/>
				<NumberInput
					label="Peso líquido"
					value={transporte.volume.pesoLiquido}
					onChange={e =>
						setTransporte({
							...transporte,
							volume: { pesoLiquido: Number(e.target.value) },
						})
					}
				/>
				<NumberInput
					label="Peso bruto"
					value={transporte.volume.pesoBruto}
					onChange={e =>
						setTransporte({
							...transporte,
							volume: { pesoBruto: Number(e.target.value) },
						})
					}
				/>
			</div>
		</Form>
	)
}
