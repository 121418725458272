// Library imports
import { useEffect } from "react"
import { createPortal } from "react-dom"

// Components
import MainButton from "src/shared/components/standard/MainButton"

// Icons
import { faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Style CSS
import "./Modal.css"


// Can be closed by clicking outside or pressing the ESC key
const CloseableModal = props => {

	// Handler for closing the modal when the ESC key is pressed
	const handleKeyup = event => {
		if (event.keyCode === 27) props.onClose()
	}
	// Closes the modal if clicked outside the modal content
	const handleClick = event => {
		if (event.target === event.currentTarget) props.onClose()
	}

	// Effects
	useEffect(() => {
		window.addEventListener("keyup", handleKeyup)
		// Cleanup function to remove the event listener when the component unmounts
		return () => window.removeEventListener("keyup", handleKeyup)
	})

	return createPortal(
		<div className="modal-window" onClick={handleClick}>
			<div className="modal-overlay">
			{
				typeof props.title === "string"
				? <ModalContainer {...props} />
				: props.children
			}
			</div>
		</div>,
		document.body
	)
}

// Basic modal component without the close functionality
const PlainModal = props => createPortal(
	<div className="modal-window">
		<div className="modal-overlay">
			{
				typeof props.title === "string"
				? <ModalContainer {...props} />
				: props.children
			}
		</div>
	</div>,
	document.body
)

const ModalContainer = ({ title, children, onClose }) =>
	<div className="modal-container">
	{
		(typeof onClose === "function") ?
		<>
			<div className="grid medium-gap left-items">
				<div>
					<MainButton	hierarchy="inferior" onClick={onClose}>
						<FontAwesomeIcon className="fa-icon" icon={faDownLeftAndUpRightToCenter} />
						<span>Fechar</span>
					</MainButton>
				</div>
				<span className="big-x bold-font left-text">{title}</span>
			</div>
			{children}
		</>:
		<>
			<span className="big-x bold-font left-text">{title}</span>
			{children}
		</>
	}
	</div>


export default function Modal(props) {
	if (typeof props.onClose === "function")
		return <CloseableModal {...props} />
	else
		return <PlainModal {...props} />
}
