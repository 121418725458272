// Icons
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"

// Store
import {
	updateWorkableItemIndex,
} from "src/shared/store/slices/workableItemsSlice"
import { RootState } from "src/shared/store/reducers"

// Components
import LinkButton from "src/shared/components/standard/LinkButton"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import InsertUpdateItemHandler from "../components/forms/InsertUpdateItemForm"

export default function NewItem() {

	const dispatch = useDispatch()

	// Store
	const workableItem = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)

	// States
	const [loading, setLoading] = useState(true)

	// Effect
	useEffect(() => {
		dispatch(updateWorkableItemIndex(0))
	}, [dispatch])
	useEffect(() => {
		(async () => {
			typeof workableItem === "object" && setLoading(false)
		})()
	}, [workableItem])

	return (
		<SupplierRoleNavbar>
			{loading === true ? (
				<ComponentLoader />
			) : (
				<InsertUpdateItemHandler update={true} />
			)}
		</SupplierRoleNavbar>
	)
}
