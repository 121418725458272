// Library imports
import React, { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { IntlProvider } from "react-intl"

// Store
import store from "src/shared/store/reducers"

// Components
import App from "src/config/App"

// Translations
import messages from "src/config/i18n/messages"

// Styles
import "src/shared/styles/index.css"

// Make sure to use the correct root element ID
// from your public/index.html file
const rootElement = document.getElementById("root")
if (!rootElement) throw new Error("Failed to find the root element")

const root = createRoot(rootElement as HTMLElement)


const locale = "pt" //navigator.language.split('-')[0]; // Simplistic locale determination
const messageSet = messages[locale] //messages[locale] || messages.en; // Fallback to English


root.render(
	<Provider store={store}>
		<StrictMode>
			<IntlProvider locale={locale} messages={messageSet}>
				<App />
			</IntlProvider>
		</StrictMode>
	</Provider>
)


		// "@babel/plugin-proposal-private-property-in-object": "^7.21.11",
		// "@babel/plugin-transform-private-property-in-object": "^7.23.4",
