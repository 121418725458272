import { ReactNode, useState } from "react"

// Components
import HorizontalGrabbableBlock from "../HorizontalGrabbableBlock/HorizontalGrabbableBlock"

// Styles
import "./FormTabs.css"

type FormTabsProps = {
	tabs: string[]
	children: ReactNode[]
}

export default function FormTabs({
	tabs,
	children,
}: FormTabsProps) {

	const [activeIndex, setActiveIndex] = useState(0)

	return (
		<>
			<HorizontalGrabbableBlock>
				<div className="FormTabs-container flex">
					{tabs.map((content, index) =>
						<button
							key={"tab" + index}
							className={`${activeIndex === index ? "button-tab activated" : "button-tab"} flex align-items-center medium`}
							onClick={() => setActiveIndex(index)}
						>
							{content}
						</button>
					)}
				</div>
			</HorizontalGrabbableBlock>

			<form className="medium-padding-vertical" onSubmit={e => e.preventDefault()}>
				{children.map((children, index) => activeIndex === index && children)}
			</form>
		</>
	)
}
