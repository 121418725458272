import { ChangeEvent } from "react"
import { useSelector, useDispatch } from "react-redux"

// utils
import { toUnsignedInteger, toUnsignedFloat } from "offiziersmesser/lib/utils"

// Store
import { RootState } from "src/shared/store/reducers"
import { updateWorkableItem } from "src/shared/store/slices/workableItemsSlice"

// Components
import NumberInput from "src/shared/components/standard/NumberInput"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"

export default function DiscountsHandler() {

	const dispatch = useDispatch()

	// Store
	const item = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)
	return (
		item &&
		<div className="flex flex-column medium-gap">
			<span className="big-x bold-font">Descontos</span>

			<div className="grid fill-normal medium-gap">
				<NumberInput
					label="Quantidade destinada"
					value={item.discount.quota}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						const value = e.target.value
						dispatch(
							updateWorkableItem({
								...item,
								discount: {
									...item.discount,
									quota: (Number(value) < 0 || isNaN(Number(value))) ? 0 : toUnsignedInteger(value)
								}
							})
						) // Quota must be more than zero and less than the max quantity available
					}}
				/>
				<NumberInput
					label="Percentual"
					value={item.discount.percentage}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						const value = e.target.value
						dispatch(
							updateWorkableItem({
								...item,
								discount: {
									...item.discount,
									percentage: (Number(value) < 0 || Number(value) > 100 || isNaN(Number(value))) ? 0 : toUnsignedFloat(value, 2)
								}
							})
						) // Discount must be between 0 and 100
					}}
				/>
			</div>
			<CheckboxInput
				label="Personalizável pelo vendedor"
				checked={(item.discount.custom === true)}
				onChange={() => dispatch(
					updateWorkableItem({
						...item,
						discount: {
							...item.discount,
							custom: !item.discount.custom
						}
					})
				)}
			/>
		</div>
	)
}
