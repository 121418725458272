import { ReactNode } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useIntl } from "react-intl"

// Store
import { RootState } from "src/shared/store/reducers"
import { setNavbar } from "src/shared/store/slices/sessionSlice"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import {
	faAngleDoubleLeft,
	faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactComponent as Logo } from "src/assets/materiapp-logo.svg"

// Components
import MainButton from "src/shared/components/standard/MainButton"

// Style imports
// import "./Navbar.css"
import "./Navbar.css"

type NavbarProps = {
	topLeft: ReactNode
	// topRight: ReactNode
	leftCenter: ReactNode
	leftBottom: ReactNode
	context: ReactNode
	children: ReactNode
}

export default function Navbar({
	topLeft,
	// topRight,
	leftCenter,
	leftBottom,
	context,
	children,
}: NavbarProps) {

	const dispatch = useDispatch()
	const intl = useIntl()

	// Store
	const session = useSelector((state: RootState) => state.session)

	// States
	// const [isSidebarOpen, setIsSidebarOpen] = useState(true)

						// <button
						// 	className="element-highlight action-minimal small-padding-vertical medium-padding-horizontal medium unselectable"
						// 	onClick={() => dispatch(setNavbar(false)) /*setIsSidebarOpen(false)*/}
						// >
						// 	<FontAwesomeIcon
						// 		className="fa-icon"
						// 		icon={faAngleDoubleLeft as Icon}
						// 	/>
						// </button>

	// Optimized utility function to conditionally concat string pieces
	function condConcat(base: string, ...pieces: (string | false)[]): string {
		for (const piece of pieces)
			if (piece)
				base = base + " " + piece  // Append if it's a non-false value
		return base
	}


	return (
		<div
			className={condConcat(
				"page-wrapper",
				session.navbar && "toggled",
				!!context && "contextualized"
			)}
		>
			<nav className="navbar-wrapper">
				<div className="navbar-content">
					<div className="navbar-header medium-padding grid-columns small-gap space-between-items">
						<Logo />
						<MainButton
							hierarchy="minimal"
							onClick={() => dispatch(setNavbar(false))}
						>
							<FontAwesomeIcon className="fa-icon" icon={faAngleDoubleLeft as Icon} />
						</MainButton>
					</div>

					<div className="navbar-body grid tiny-gap">
						{leftCenter}
					</div>

					<div className="navbar-footer">
						{leftBottom}
					</div>
				</div>
			</nav>

			<div className="main-page">
				<div
					className={condConcat(
						"topbar grid-columns space-between-items",
						session.navbar && "toggled",
						!!context && "contextualized"
					)}
				>
					<MainButton
						hierarchy="inferior"
						onClick={() => dispatch(setNavbar(!session.navbar))}
					>
						<FontAwesomeIcon className="fa-icon" icon={session.navbar ? faAngleDoubleLeft as Icon : faAngleDoubleRight as Icon} />
						<span>{intl.formatMessage({ id: "navbar_button" })}</span>
					</MainButton>
					{topLeft}
				</div>

				<div className="page-content">
					{children}
				</div>
			</div>



			<div className="context-wrapper">
				<div className="context-content">
					{context}
				</div>
			</div>

		</div>
	)
}

export function ContextTopbar() {
	return (
		<div className="context-topbar">
		</div>
	)
}















































// import { ReactNode, useState } from "react"
// import { Link, NavLink, useNavigate, useLocation } from "react-router-dom"
// import { useSelector, useDispatch } from "react-redux"

// // Store
// import { RootState } from "../../../store/reducers"
// import {
// 	// Utilitary functions
// 	doesNavigationEntryHasRoutes,
// 	getDefaultPathInNavigation,
// 	// Reducer functions
// 	initializeSession,
// 	updateToggledSectionByName,
// 	// Types
// 	NavigationEntryModel,
// 	RoleNavigationModel
// } from "../../../store/slices/sessionSlice"

// // Icons
// import { Icon } from "@fortawesome/fontawesome-svg-core"
// import {
// 	faAngleDoubleLeft,
// 	faAngleDoubleRight,
// 	faSignOutAlt,
// 	faSync,
// 	faSyncAlt,
// } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { ReactComponent as Logo } from "src/assets/materiapp-logo.svg"

// // Components
// import LinkButton from "../../standard/LinkButton"
// import MainButton from "../../standard/MainButton"

// // Style imports
// // import "./Navbar.css"
// import "./_Navbar.css"

// import Modal from "../Modal/Modal"
// import { setUser } from "src/shared/store/slices/userSlice"
// import { setSupplier } from "src/shared/store/slices/supplierSlice"
// import { createToast } from "src/shared/store/slices/toastsSlice"

// // Services
// import updateEmployeeToken from "./services/updateEmployeeToken"


// type NavbarProps = {
// 	children: ReactNode
// 	topLeft: ReactNode
// 	topComplement: ReactNode
// }

// type NavigationLinkProps = {
// 	path: string
// 	title: string
// }

// type ToggleableButtonProps = {
// 	title: string
// 	routes: Array<{ path: string; title: string }>
// }

// export default function Navbar({
// 	children,
// 	topLeft,
// 	topComplement,
// }: NavbarProps) {
// 	const dispatch = useDispatch()
// 	const navigate = useNavigate()
// 	const location = useLocation()

// 	// Store
// 	const user = useSelector((state: RootState) => state.user)
// 	const session = useSelector((state: RootState) => state.session)

// 	// States
// 	const [isSidebarOpen, setIsSidebarOpen] = useState(true)
// 	const [renderRoleSwitcherModal, setRenderRoleSwitcherModal] = useState(false)


// 	// Find the active role navigation items
// 	const activeRoleNavigation = session.roles.find((role: RoleNavigationModel) => role.active)?.navigation || []

// 	const activeNavigationSection = activeRoleNavigation.find((entry: NavigationEntryModel) =>
// 		doesNavigationEntryHasRoutes(entry) && entry.toggled
// 	)

// 	const isRouteActive = (path: string) => location.pathname === path

// 	const NavigationLink = ({ path, title }: NavigationLinkProps) =>
// 		<NavLink to={path}>{title}</NavLink>

// 	function ToggleableButton({ title, routes }: ToggleableButtonProps) {
// 		return (
// 			<div className="navbar-body-section grid tiny-gap">
// 				<button
// 					className="navbar-body-section-button left-text"
// 					onClick={() => dispatch(updateToggledSectionByName(title))}
// 				>
// 					{title}
// 				</button>

// 				{(activeNavigationSection && activeNavigationSection.title === title) && (
// 					<div className="medium-padding-left tiny-padding-right grid tiny-gap">
// 					{routes.map(route =>
// 						<NavigationLink key={route.path} {...route} />
// 					)}
// 					</div>
// 				)}
// 			</div>
// 		)
// 	}

// 	const switchRole = async (currentStakeholder: string, desiredRole: string, navigation: NavigationEntryModel[]) => {
// 		switch(currentStakeholder) {
// 			// case "supplier":
// 			// break
// 			case "employee":
// 				const { token } = await updateEmployeeToken(desiredRole)
// 				sessionStorage.setItem("token", token)
// 				break
// 			// default:
// 		}

// 		try {
// 			navigate(getDefaultPathInNavigation(navigation))
// 			dispatch(createToast({
// 				tone: "info",
// 				miliseconds: 1000,
// 				message: `Àrea do ${desiredRole} carregada.`,
// 			} as any))
// 		}
// 		catch {
// 			dispatch(createToast({
// 				tone: "failure",
// 				miliseconds: 1000,
// 				message: `Erro ao carregar àrea do ${desiredRole}.`,
// 			} as any))
// 		}
// 	}


// 	return (
// 		<div
// 			className={
// 				isSidebarOpen
// 				? "page-wrapper toggled"
// 				: "page-wrapper"
// 			}
// 		>

// 			<nav id="navbar" className="navbar-wrapper">
// 				<div className="navbar-content">
// 					<div className="navbar-header medium-padding grid-columns space-between-items">
// 						<Logo />
// 						<button
// 							className="element-highlight action-minimal small-padding-vertical medium-padding-horizontal small unselectable"
// 							onClick={() => setIsSidebarOpen(false)}
// 						>
// 							<FontAwesomeIcon
// 								className="fa-icon"
// 								icon={faAngleDoubleLeft as Icon}
// 							/>
// 						</button>
// 					</div>

// 					<div className="navbar-body grid tiny-gap">
// 						{activeRoleNavigation.map((entry: NavigationEntryModel) =>
// 							doesNavigationEntryHasRoutes(entry) ? (
// 								<ToggleableButton
// 									key={entry.title}
// 									title={entry.title}
// 									routes={entry.routes}
// 								/>
// 							) : (
// 								<NavigationLink
// 									key={entry.path}
// 									path={entry.path}
// 									title={entry.title}
// 								/>
// 							)
// 						)}
// 					</div>

// 					<div className="navbar-footer">
// 						<div className="tiny-padding-horizontal small-padding-vertical grid tiny-gap left-items">
// 							<span className="small-padding-horizontal medium bold-font">{`${user.administrative.reference.name} ${user.administrative.reference.surname}`}</span>
// 							<MainButton hierarchy="inferior" onClick={() => setRenderRoleSwitcherModal(true)}>
// 								<FontAwesomeIcon icon={faSyncAlt as Icon}/>
// 								<span>{session.roles.find(role => role.active)?.role}</span>
// 							</MainButton>
// 						</div>
// 					</div>
// 				</div>
// 			</nav>


// 			{renderRoleSwitcherModal &&
// 				<Modal onClose={() => setRenderRoleSwitcherModal(false)}>
// 					<div className="modal-container flex flex-column">
// 						<div className="header">
// 							<span className="big-x bold-font">Ir para</span>
// 						</div>

// 						<div className="grid-columns small-gap">
// 							{session.roles.map(entry =>	(entry.active === false) &&
// 								<MainButton
// 									hierarchy="inferior"
// 									onClick={() => switchRole(session.stakeholder, entry.role, entry.navigation)}
// 								>
// 									<FontAwesomeIcon className="fa-icon" icon={faSyncAlt}/>
// 									<span>{entry.role}</span>
// 								</MainButton>
// 							)}

// 							<LinkButton
// 								hierarchy="inferior"
// 								to="/authorization"
// 								onClick={() => sessionStorage.removeItem("token")}
// 							>
// 								<FontAwesomeIcon className="fa-icon" icon={faSignOutAlt}/>
// 								<span>Encerramento de sessão</span>
// 							</LinkButton>
// 						</div>
// 					</div>
// 				</Modal>
// 			}


// 			<div className="main-page">
// 				<div className="topbar small-padding-vertical medium-padding-horizontal grid-columns space-between-items">
// 					<div className="grid-columns small-gap">
// 						<MainButton
// 							hierarchy="inferior"
// 							// fontSize="small"
// 							onClick={() => setIsSidebarOpen(visibility => !visibility)}
// 						>
// 						{isSidebarOpen ? (
// 							<>
// 								<FontAwesomeIcon className="fa-icon" icon={faAngleDoubleLeft as Icon} />
// 								<span>Fechar menu</span>
// 							</>
// 						) : (
// 							<>
// 								<FontAwesomeIcon className="fa-icon" icon={faAngleDoubleRight as Icon} />
// 								<span>Abrir menu</span>
// 							</>
// 						)}
// 						</MainButton>
// 						{topLeft}
// 					</div>
// 					{topComplement}
// 				</div>

// 				<div className="page-content">
// 					{children}
// 				</div>
// 			</div>
// 		</div>
// 	)
// }

















































// import { ReactNode, useState } from "react"
// import { Link, useNavigate, useLocation } from "react-router-dom"
// import { useSelector, useDispatch } from "react-redux"

// // Store
// import { RootState } from "../../../store/reducers"
// import { setNavigationRoutes } from "../../../store/slices/sessionSlice"

// // Icons
// import { Icon } from "@fortawesome/fontawesome-svg-core"
// import {
// 	faAngleDoubleLeft,
// 	faAngleDoubleRight,
// 	faSignOutAlt,
// 	faSync,
// 	faSyncAlt,
// } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { ReactComponent as Logo } from "src/assets/materiapp-logo.svg"

// // Components
// import LinkButton from "../../standard/LinkButton"
// import MainButton from "../../standard/MainButton"

// // Style imports
// import "./Navbar.css"
// import Modal from "../Modal/Modal"
// import { setUser } from "src/shared/store/slices/userSlice"
// import { setSupplier } from "src/shared/store/slices/supplierSlice"
// import { createToast } from "src/shared/store/slices/toastsSlice"

// type NavbarProps = {
// 	children: ReactNode
// 	topLeft: ReactNode
// 	topComplement: ReactNode
// }

// export default function Navbar({
// 	children,
// 	topLeft,
// 	topComplement,
// }: NavbarProps) {
// 	const dispatch = useDispatch()
// 	const navigate = useNavigate()
// 	const location = useLocation()

// 	// Store
// 	const session = useSelector((state: RootState) => state.session)
// 	const user = useSelector((state: RootState) => state.user)

// 	const navigation = session?.roles.find(role => role.active)


// 	console.log("SESSION", session)
// 	console.log("USER", user)

// 	// States
// 	const [isNavbarOpen, setIsNavbarOpen] = useState(true)
// 	const [renderRoleSwitcherModal, setRenderRoleSwitcherModal] = useState(false)

// 	const getStakeholderTranslation = (stakeholder: any) => {
// 		switch (stakeholder) {
// 			case "unknown":
// 				return "Funcionário"
// 			case "manager":
// 				return "Gerência"
// 			case "seller":
// 				return "Vendedor"
// 			case "carrier":
// 				return "Entregador"
// 			default:
// 				return "Administrador"
// 		}
// 	}

// 	const updateNavbarMenuStatus = (menuItemIndex: number) =>
// 		dispatch(
// 			setNavigationRoutes(
// 				session.navigation.map((entry, index) => ({
// 				...entry,
// 				// Set active to true if the current index matches menuItemIndex and it is not already active, false otherwise
// 				active: menuItemIndex === index && entry.active === false,
// 			}))
// 		)
// 	)

// 	const generateNavbarRoutes = () => (
// 		<ul className="grid tiny-gap">
// 			{session.navigation.map((a: any, idxa: number) => (
// 				<li
// 					key={idxa}
// 					className={
// 						session.navigation[idxa].active === true
// 							? "navbar-submenu active"
// 							: "navbar-submenu"
// 					}
// 				>
// 					{a.hasOwnProperty("routes") && a.routes.length > 0 ? (
// 						<>
// 							<button
// 								className="medium left-text"
// 								onClick={() => updateNavbarMenuStatus(idxa)}
// 							>
// 								{a.series}
// 							</button>

// 							<ul className="grid tiny-gap">
// 								{a.routes.map((b: any, idxb: number) => (
// 									<li key={idxb}>
// 										<Link
// 											to={b.path}
// 											className={
// 												location.pathname === b.path
// 													? "unselectable big bold-font active-pathname"
// 													: "unselectable big bold-font"
// 											}
// 										>
// 											<span>{b.title}</span>
// 										</Link>
// 									</li>
// 								))}
// 							</ul>
// 						</>
// 					) : (
// 						<Link
// 							to={a.path}
// 							onClick={() => updateNavbarMenuStatus(idxa)}
// 							className={
// 								location.pathname === a.path
// 									? "unselectable big bold-font active-pathname"
// 									: "unselectable big bold-font"
// 							}
// 						>
// 							<span>{a.series}</span>
// 						</Link>
// 					)}
// 				</li>
// 			))}
// 		</ul>
// 	)

// 	const switchRole = () => {

// 		try{
// 			sessionStorage.setItem("alias", user.alias)
// 			user.user && (sessionStorage.setItem("supplier", user.user))

// 			dispatch(setUser({
// 				account:{
// 					alias: user.alias,
// 					user: {
// 						taxpayer: user.taxpayer,
// 						administrative: user.administrative
// 					}
// 				},
// 				supplier: user.user,
// 				filed: user
// 			}))
// 			dispatch(setSupplier({
// 				account:{
// 					alias: user.alias,
// 					user: {
// 						taxpayer: user.taxpayer,
// 						administrative: user.administrative
// 					}
// 				},
// 				supplier: user.user,
// 				filed: user
// 			}))
// 		}
// 		catch{
// 			dispatch(createToast({
// 				tone: "failure",
// 				miliseconds: 1000,
// 				message: "Algo deu errado. Tente novamente.",
// 			} as any))
// 		}
// 		finally{
// 			navigate("/seller/balances")
// 		}

// 	}

// 	return (
// 		<div
// 			className={
// 				isNavbarOpen
// 				? "page-wrapper toggled"
// 				: "page-wrapper"
// 			}
// 		>
// 			<nav id="navbar" className="navbar-wrapper">
// 				<div className="navbar-content">
// 					<div className="navbar-header medium-padding grid-columns space-between-items">
// 						<Logo />
// 						<button
// 							className="element-highlight action-minimal small-padding small unselectable"
// 							onClick={() => setIsNavbarOpen(false)}
// 						>
// 							<FontAwesomeIcon
// 								className="fa-icon"
// 								icon={faAngleDoubleLeft as Icon}
// 							/>
// 						</button>
// 					</div>
// 					<div className="navbar-menu">{generateNavbarRoutes()}</div>

// 						<div className="navbar-footer">
// 							<div className="flex switch-user">
// 								<MainButton hierarchy="minimal" onClick={() => setRenderRoleSwitcherModal(true)}>
// 									<FontAwesomeIcon icon={faSyncAlt as Icon}/>
// 									<span>Trocar de usuário</span>
// 								</MainButton>
// 							</div>
// 							<div className="medium-padding grid-columns space-between-items wide-children">
// 							<div className="grid wide left-text">
// 								<span className="small bold-font">{`${user.administrative.reference.name} ${user.administrative.reference.surname}`}</span>
// 								<span className="tiny">{getStakeholderTranslation(session.stakeholder)}</span>
// 							</div>
// 							<LinkButton
// 								hierarchy="inferior"
// 								fontSize="small"
// 								to="/authorization"
// 								onClick={() => sessionStorage.removeItem("token")}
// 							>
// 								<FontAwesomeIcon
// 									className="fa-icon"
// 									icon={faSignOutAlt as Icon}
// 								/>
// 								<span>Sair</span>
// 							</LinkButton>
// 						</div>
// 						</div>
// 						</div>
// 			</nav>

// 			{ renderRoleSwitcherModal &&
// 				<Modal onClose={() => setRenderRoleSwitcherModal(false)}>
// 					<div className="header medium-padding-horizontal small-margin-top">
// 						<span className="bold-font big-x small-margin-bottom">
// 						   Acessar como:
// 						</span>
// 					</div>
// 					<div className="wide-children medium-padding">
// 						<MainButton onClick={() => switchRole()}>Vendedor</MainButton>
// 					</div>
// 				</Modal>
// 			}

// 			<div className="main-page">
// 				<div className="topbar small-padding-vertical medium-padding-horizontal grid-columns space-between-items">
// 					<div className="grid-columns small-gap">
// 						<MainButton
// 							hierarchy="inferior"
// 							fontSize="small"
// 							onClick={() => setIsNavbarOpen(visibility => !visibility)}
// 						>
// 						{isNavbarOpen ? (
// 							<>
// 								<FontAwesomeIcon className="fa-icon" icon={faAngleDoubleLeft as Icon} />
// 								<span>Fechar menu</span>
// 							</>
// 						) : (
// 							<>
// 								<FontAwesomeIcon className="fa-icon" icon={faAngleDoubleRight as Icon} />
// 								<span>Abrir menu</span>
// 							</>
// 						)}
// 						</MainButton>
// 						{topLeft}
// 					</div>
// 					{topComplement}
// 				</div>

// 				<div className="page-content">{children}</div>
// 			</div>
// 		</div>
// 	)
// }
