import ItemModel from "../../../../../shared/models/item"

import { toFixedImproved } from "offiziersmesser/lib/utils";

export default function itemJsonTemplate(itemXml: any, index: number, markup: number, priceCost:boolean, category:string): ItemModel {


	const item: ItemModel = {
		item: "",
		selectedRule: "",
		createdAt: 0,
		updatedAt: 0,
		active: true,
		sellable: false,
		variant: "",
		media: {
			images: []
		},
		groups: {
			category: category
		},
		origin: {
			model: "",
			manufacturer: ""
		},
		costs: {
			fixed: 0,
			variable: 0,
			production: priceCost ? Number(toFixedImproved(itemXml.prod.vUnCom,2)) : Number(toFixedImproved((itemXml.prod.vUnCom-((itemXml.prod.vUnCom/(100+markup))*markup)),2)),
			sudden: {
				custom: true,
				value: 0
			}
		},
		batch: {
			series: "",
			shelfLife: {
				producedAt: 0,
				expiresAt: 0
			}
		},
		control: {
			markup: markup ?? 0,
			sku: "",
			barcode: (itemXml.prod.cProd).toString(),
			measure: (itemXml.prod.uCom).toString(),
			returnable: false,
			quantity: itemXml.prod.qCom,
			description: itemXml.prod.xProd,
			available: true,
			autoDeactivatable: false,
			fractionable: false,
		},
		logistics: {
			stackable: true,
			dimensions: [{
				type: "weight",
				value: 0.0,
				unit: "kg"
			}],
			location: []
		},
		discount: {
			quota: 0,
			custom: true,
			percentage: 0
		},
		accounting: {
			active: false,
			details:{
				origin: "",
				ncm: (itemXml.prod.NCM).toString(),
				cest: (itemXml.prod.CEST)?.toString()
			},
			taxation:""
		}

	}

	return item
}
