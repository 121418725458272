// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { faExclamationCircle, faPlus } from "@fortawesome/free-solid-svg-icons"

// Componets
import LinkButton from "src/shared/components/standard/LinkButton"
import RadioButtonInput from "src/shared/components/standard/RadioButtonInput"
import Notice from "src/shared/components/custom/Notice/Notice"
import EmployeeCard from "../EmployeeCard/EmployeeCard"
import Pagination from "src/shared/components/custom/Pagination/Pagination"
import { useState } from "react"

type EmployeeTabProps  = {
	status: number
	setStatus: (status: number) => void
	employees: any[]
	role: string
}

function EmployeeTab({
	status,
	setStatus,
	employees,
	role,
}: EmployeeTabProps) {
	const [currentEmplooyees, setCurrentEmplooyees] = useState(employees)
	return (
		<div className="grid medium-gap">
			<div className="grid fill-small">
				<LinkButton to="/supplier/employments/new">
					<FontAwesomeIcon
						icon={faPlus as Icon}
						className="fa-icon"
					/>
					<span>Criar {role}</span>
				</LinkButton>
			</div>

			<div className="flex small-gap flex-wrap">
				<div className="element-border flex flex-column small-gap">
					<span className="bold-font medium">{role}</span>
					<div className="flex small-gap flex-wrap">
						<RadioButtonInput
							label="Ativos"
							name="status"
							value="0"
							defaultChecked={status === 0}
							onChange={() => setStatus(0)}
						/>
						<RadioButtonInput
							label="Inativos"
							name="status"
							value="1"
							defaultChecked={status === 1}
							onChange={() => setStatus(1)}
						/>
					</div>
				</div>
			</div>

			<div>
				<Pagination elements={employees} setCurrentElements={setCurrentEmplooyees}/>
				{currentEmplooyees.length > 0 ? (
					currentEmplooyees.map((employment : any) => (
						<EmployeeCard
							employment={employment}
							key={employment.user}
						/>
					))
				) : (
					<Notice>
						<FontAwesomeIcon
							className="fa-icon"
							icon={faExclamationCircle as Icon}
						/>
						<span>Você não tem nenhum {role} ativo.</span>
					</Notice>
				)}
			</div>
		</div>
	)
}

export default EmployeeTab
