import { useEffect, useState } from "react"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle, faExternalLinkSquareAlt, faPlus } from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import LinkButton from "src/shared/components/standard/LinkButton"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import RadioButtonInput from "src/shared/components/standard/RadioButtonInput"
// import GlobalSearchBar from "src/shared/components/custom/GlobalSearchBar/GlobalSearchBar"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import InvoicesTable from "./editor/table/InvoicesTable"

// Styles
import "./Invoices.css"
import { InvoiceModel } from "./types/invoices"

export default function Invoices() {

	const responseCodeHandler = useResponseCodeHandler()

	// A/P -> Entrada | A/R -> Saida

	const [soughtInvoices, setSoughtInvoices] = useState(false)
	// true - A/P invoice false - A/R invoice

	const [invoices, setInvoices] = useState<InvoiceModel[]>([])

	const invoiceAPFilter = invoices.filter(invoices => invoices.details.tipo === "0")
	const invoiceARFilter = invoices.filter(invoices => invoices.details.tipo === "1")

	const getInvoices = async () =>{
		try{
			const token = getToken()

			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/supplier/invoice",
			{
				headers: { Authorization: `Bearer ${token}` },
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const data = await response.json()

			setInvoices(data)
		}catch(error){
			console.error(error)
		}
	}
	useEffect(() => {
		getInvoices()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<SupplierRoleNavbar>

			{(invoices.length > 0) ? (
				<div className="grid medium-gap">

					<PageTitle title="Notas Fiscais">
						{/* <GlobalSearchBar
							value=""
							onChange={() => ""}
							results={[]}
							handleSelection={() => ""}
							placeholder="Busque uma nota"
						/> */}
					</PageTitle>

					<div className="grid fill-normal wide">
						<LinkButton to="/supplier/invoices/new">
							<FontAwesomeIcon className="fa-icon" icon={faPlus} />
							<span>Criar Nota</span>
						</LinkButton>
					</div>

					<div className="flex">
						<div className="element-border flex flex-column small-gap">
							<span className="bold-font medium">Notas de</span>
							<div className="flex medium-gap">
								<RadioButtonInput
									name="invoices"
									label="Entrada"
									value="0"
									defaultChecked={soughtInvoices === true}
									onChange={() => setSoughtInvoices(true)}
								/>
								<RadioButtonInput
									name="invoices"
									label="Saída"
									value="1"
									defaultChecked={soughtInvoices === false}
									onChange={() => setSoughtInvoices(false)}
								/>
							</div>
						</div>
					</div>

					<div>
						{soughtInvoices === true ? (
							<InvoicesTable invoices={invoiceAPFilter} />
						) : (
							<InvoicesTable invoices={invoiceARFilter} />
						)}
					</div>
				</div>
			) : (
				<div className="grid medium-gap">

					<PageTitle title="Notas Fiscais" />

					<Notice tone="info" to="/supplier/invoices/new">
						<div className="grid-columns medium-gap left-items">
							<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle} />
							<div className="grid small-gap">
								<span className="small">Aqui você simplifica a sua gestão de notas fiscais.</span>
								<div className="grid-columns small-gap left-items bold-font tiny">
									<span>Clique aqui para criar sua primeira nota fiscal</span>
									<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt} />
								</div>
							</div>
						</div>
					</Notice>
				</div>
			)}

		</SupplierRoleNavbar>
	)
}
