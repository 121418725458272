import { createSlice } from "@reduxjs/toolkit"

import { TaxRule } from '../../models/taxation'

const initialValue: TaxRule[] = []

const taxationBrSlice = createSlice({
	name: "taxationBr",
	initialState: initialValue,
	reducers: {
		setTaxationBr: (state, action) => {
			return state = action.payload
		},
	}
})

export default taxationBrSlice.reducer
export const { setTaxationBr } = taxationBrSlice.actions
