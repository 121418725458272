import { ReactNode } from "react"
// Styles
import "./PageTitle.css"

type PageTitleProps = {
	title: string
	description?: string // This is missing in your 'title' component
	children?: ReactNode // This is missing in your 'title' component
}

export default function PageTitle({
	title,
	description,
	children
}: PageTitleProps) {

	return (
		<div className="flex medium-gap justify-content-space-between align-items-center wide">

			{!!description ? (
				<div className="flex flex-column small-gap">
					<span className="big-xx bold-font">{title}</span>
					<span className="small semibold-font">{description}</span>
				</div>
			):(
				<span className="big-xx bold-font">{title}</span>
			)}

			{!!children && <div className="wide">{children}</div>}
		</div >
	)
}




// // Styles
// import "./PageTitle.css"

// type PageTitleProps = {
//     title: string;
//     description?: any; // This is missing in your 'title' component
//     children?: any;    // This is missing in your 'title' component
//   };

// export default function PageTitle({
// 	title,
// 	description,
// 	children
// }: PageTitleProps) {

// 	return (
// 		<div className="header flex medium-gap justify-content-space-between align-items-center wide">

// 			<div className="header-content flex flex-column small-gap">
// 				<span className="big-xx bold-font" dangerouslySetInnerHTML={{ __html: title }} />
// 				{description && (<p dangerouslySetInnerHTML={{ __html: description }} />)}
// 			</div>

// 			{!!children &&
// 				<div className="header-content wide">
// 					{children}
// 				</div>
// 			}
// 		</div >
// 	)
// }
