import { useSelector } from "react-redux"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faCashRegister } from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import LinkButton from "src/shared/components/standard/LinkButton"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import { BalancesCardRow } from "./BalancesCardRow"
import { useParams } from "react-router-dom"

export default function DetailsOutflow() {

	let {tag} = useParams()

	// Store
	const balances = useSelector(state => state.balances)

	const balance = balances.find(balance => balance.cashbox === tag)


	if (!balance) {
		return (
		  <SupplierRoleNavbar>
			<div>
			  <p> not to found {tag}</p>
			</div>
		  </SupplierRoleNavbar>
		);
	}


	return (
		<SupplierRoleNavbar>

			<div className="flex flex-column small-gap">
				<div className="flex">
					<LinkButton
						to={`/supplier/balance/${tag}`}
						hierarchy="inferior"
					>
						<FontAwesomeIcon icon={faArrowLeft} />
						<span>Registrador {tag}</span>
					</LinkButton>
				</div>

				<PageTitle title={`Detalhe das saídas de ${tag}`} />
			</div>

			{typeof balance?.transactions?.outflow === "object" ? (
				<BalancesCardRow transactions={balance.transactions.outflow} />
			) : (
				<Notice>
					<FontAwesomeIcon icon={faCashRegister} />
					<span>Registrador não possui saída.</span>
				</Notice>
			)}
		</SupplierRoleNavbar>
	)
}
