import { useState } from "react"
import { useSelector, useDispatch} from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"

import { RootState } from "src/shared/store/reducers"
import { setUser } from "src/shared/store/slices/userSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"


export default function InscricaoModal() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector((state:RootState) => state.user)

	// States
	const [municipal, setMunicipal] = useState(user.taxation.inscricoes.municipal)
	const [estadual, setEstadual] = useState(user.taxation.inscricoes.estadual)
	const [estadualSt, setEstadualSt] = useState(user.taxation.inscricoes.estadualSt)
	const [renderInscricaoModal, setRenderInscricaoModal] = useState(false)


	// Handlers
	async function handlePutNewCscSettings() {
		try {
			const token = getToken()

			const updatedData = {
				municipal,
				estadual,
				estadualSt,
			}

			const response = await fetch(
				process.env.REACT_APP_API_URL + "/resource/supplier/taxation/inscricoes",
				{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(updatedData)
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				dispatch(setUser({...user,taxation:{...user.taxation, inscricoes: updatedData}}))
				setRenderInscricaoModal(false)
				dispatch(
					createToast({

						tone: "success",
						miliseconds: 5000,
						message: "Atualizado com sucesso",
						dismissable:true
					})
				)
		} catch (error) {
			dispatch(
				createToast({

					tone: "failure",
					miliseconds: 5000,
					message: "Houve um erro ao atualizar",
					dismissable:true
				})
			)
			console.error(error)
		}
	}

	return (
	<>
		{renderInscricaoModal === true && (
			<Modal title="Definição de Inscrições" onClose={() => setRenderInscricaoModal(false)}>
				<div className="grid fill-normal small-gap">
					<TextInput
						label="Inscrição municipal"
						value={municipal}
						onChange={e => setMunicipal(e.target.value)}
					/>
					<TextInput
						label="Inscrição estadual"
						value={estadual}
						onChange={e => setEstadual(e.target.value)}
					/>
					<TextInput
						label="Inscrição estadual ST"
						value={estadualSt}
						onChange={e => setEstadualSt(e.target.value)}
					/>
				</div>

				<MainButton onClick={() => handlePutNewCscSettings()}>
					<span>Definir inscrições</span>
				</MainButton>
			</Modal>
		)}

		<MainButton hierarchy="low" onClick={() => setRenderInscricaoModal(true)}>
			<span>Definir inscrições</span>
		</MainButton>
	</>
	)
}
