import { useEffect, useState } from "react"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { useDispatch } from "react-redux"
import { setTaxationBr } from "../../../shared/store/slices/taxationBrSlice"

// Icons
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import LinkButton from "../../../shared/components/standard/LinkButton"
import PageTitle from "../../../shared/components/custom/PageTitle/PageTitle"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import InvoicesTable from "./editor/tables/AccountInvoicesTable"

type taxRule = {
	entry: string,
	user: string,
	createdAt: number,
	updatedAt: number,
	descricao: string,
	tipo: string,
	regra: {},
	regime: string,
	estados: {},
	active: false,
	consumidor: string,
	nature: string,
	ncm: string[],
	default: false
  }

export default function Invoices() {

	const responseCodeHandler = useResponseCodeHandler()

	// States
	const [taxRules, setTaxRules] = useState<taxRule[]>()

	// Store
	const dispatch = useDispatch()

	const handleGet = async () => {

		try {
			const token = getToken()
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/taxation/br`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)
			const data = await response.json()

			dispatch(setTaxationBr(data))
			setTaxRules(data)

		}
		catch (error) { console.error(error) }
	}

	// Effects
	useEffect(() => {
		handleGet()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	return (
		<SupplierRoleNavbar>

			<PageTitle title="Configurar Regras Fiscais" />

			<div className="flex medium-margin-vertical medium-gap">
				<LinkButton to="/supplier/taxation/new">
					<FontAwesomeIcon className="fa-icon" icon={faPlus} />
					<span>Adicionar regra</span>
				</LinkButton>
			</div>

			{(typeof taxRules === "object" && taxRules.length > 0) && (
				<InvoicesTable taxRules={typeof taxRules === "object" ? taxRules : []} setTaxRules={setTaxRules} />
			)}

		</SupplierRoleNavbar>
	)
}
