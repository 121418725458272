// Models
import { PhoneModel } from "offiziersmesser/lib/models"

type BeginPhoneVerificationProps = {
	phone: PhoneModel,
	isCodeSent: boolean,
	setIsCodeSent: (value: boolean) => void,
	setIsCodeSentTwice: (value: boolean) => void
}

export default async function beginPhoneVerification({
	phone,
	isCodeSent,
	setIsCodeSent,
	setIsCodeSentTwice
}: BeginPhoneVerificationProps) {

	try {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/phone/code/transmission`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(phone)
		})

		if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

		if (!isCodeSent)
			setIsCodeSent(true)
		else
			setIsCodeSentTwice(true)

	} catch (error) {
		console.error(error)
		throw error
	}
}
