import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setUser } from "src/shared/store/slices/userSlice"

export default function NfeModal() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()


	// Store
	const user = useSelector(state => state.user)

	// States
	const [serieNfP, setSerieNfP] = useState(user.taxation.nfSettings.initialNnfP.serie) //1
	const [serieNfH, setSerieNfH] = useState(user.taxation.nfSettings.initialNnfH.serie) //0

	const [numberNfP, setNumberNfP] = useState(user.taxation.nfSettings.initialNnfP.nnf) //1
	const [numberNfH, setNumberNfH] = useState(user.taxation.nfSettings.initialNnfH.nnf) //0

	const [loteNfP, setLoteNfP] = useState(user.taxation.nfSettings.initialNnfP.lote) //1
	const [loteNfH, setLoteNfH] = useState(user.taxation.nfSettings.initialNnfH.lote) //1

	const [renderNfeModal, setRenderNfeModal] = useState(false)

	async function handlePutNewNfeSettings(environment) {

		if (environment !== "2" && environment !== "1") return

		try {
			const token = getToken()

			const updatedData = {
				ambiente: environment,
				initialNnf: environment === "1" ? {
					nnf: numberNfP,
					lote: loteNfP,
					serie: serieNfP
				} : (environment === "2") ? {
					nnf: numberNfH,
					lote: loteNfH,
					serie: serieNfH
				} : ""
			}

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/account/taxation/nnf`, {
				method: "PUT",
				body: JSON.stringify(updatedData),
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json"
				},
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({
				...user,
				taxation: {
					...user.taxation,
					nfSettings: {
						...user.taxation.nfSettings,
						[environment === "1" ?"initialNnfP" :"initialNnfH"]: {
							serie: updatedData.initialNnf.serie,
							nnf: updatedData.initialNnf.nnf,
							lote: updatedData.initialNnf.lote,
						}
					}
				}
			}))
		}
		catch (error) {
			console.error(error)
		}
	}

	return (
	<>
		{renderNfeModal === true && (
			<Modal title="Configuração da numeração de notas fiscais" onClose={() => setRenderNfeModal(false)}>
				<div className="grid medium-gap">
					<span className="big bold-font">Ambiente de produção</span>

					<div className="flex small-gap">
						<TextInput
							label="Nº de série"
							value={serieNfP}
							name="serie"
							onChange={e => setSerieNfP(e.target.value)}
						/>
						<TextInput
							label="Nº da nota fiscal"
							value={numberNfP}
							name="numberNfe"
							onChange={e => setNumberNfP(e.target.value)}
						/>
						<TextInput
							label="Lote"
							value={loteNfP}
							name="loteNfe"
							onChange={e => setLoteNfP(e.target.value)}
						/>
					</div>

					<MainButton
						onClick={() => {
							setRenderNfeModal(false)
							handlePutNewNfeSettings("1")
						}}
					>
						<span>Alterar numeração de produção</span>
					</MainButton>
				</div>

				<div className="grid medium-gap">
					<span className="big bold-font">Ambiente de homologacão</span>

					<div className="flex small-gap">
						<TextInput
							label="Nº de série"
							value={serieNfH}
							name="serie"
							onChange={e => setSerieNfH(e.target.value)}
						/>
						<TextInput
							label="Nº da nota fiscal"
							value={numberNfH}
							name="numberNfe"
							onChange={e => setNumberNfH(e.target.value)}
						/>
						<TextInput
							label="Lote"
							value={loteNfH}
							name="loteNfe"
							onChange={e => setLoteNfH(e.target.value)}
						/>
					</div>

					<MainButton
						onClick={() => {
							setRenderNfeModal(false)
							handlePutNewNfeSettings("2")
						}}
					>
						<span>Alterar numeração de homologação</span>
					</MainButton>
				</div>
			</Modal>
		)}

		<MainButton hierarchy="low" onClick={() => setRenderNfeModal(true)}>
			<span>Configurar NNF</span>
		</MainButton>
	</>
	)
}
