import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Icons
import {
	faTimes,
	faCheck,
	faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import {
	WEEKDAYS,
	CURRENCY_CODES,
	LANGUAGE_CODES,
	DATEPART_CODES,
	toUnsignedFloat,
	toUnsignedInteger,
} from "offiziersmesser/lib/utils"

// Store
import { setUser } from "src/shared/store/slices/userSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"

// Components
import Form from "src/shared/components/standard/Form"
import Modal from "src/shared/components/custom/Modal/Modal"
import Notice from "src/shared/components/custom/Notice/Notice"
import MainButton from "src/shared/components/standard/MainButton"
import NumberInput from "src/shared/components/standard/NumberInput"
import SelectInput from "src/shared/components/standard/SelectInput"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

export default function Service() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector(state => state.user)


	// States
	const [environment, setEnvironment] = useState(user.environment)
	const [installments, setInstallments] = useState(user.installments)

	const [renderModal, setRenderModal] = useState(false)

	const [currentWeekday, setCurrentWeekday] = useState("")
	const [currentWeekdayIndex, setCurrentWeekdayIndex] = useState(0)
	const [currentWeekdayValue, setCurrentWeekdayValue] = useState(null)

	// TODO create an util for this
	let currencyOptions = []
	let languageOptions = []
	let datepartOptions = []
	for (let i = 0; i < CURRENCY_CODES.length; i++)
		currencyOptions.push(
			<option key={i} value={CURRENCY_CODES[i].code}>
				{CURRENCY_CODES[i].name}
			</option>
		)
	for (let i = 0; i < LANGUAGE_CODES.length; i++)
		languageOptions.push(
			<option key={i} value={LANGUAGE_CODES[i].code}>
				{LANGUAGE_CODES[i].name}
			</option>
		)
	for (let i = 0; i < DATEPART_CODES.length; i++)
		datepartOptions.push(
			<option key={i} value={DATEPART_CODES[i].code}>
				{DATEPART_CODES[i].name}
			</option>
		)

	let hoursOptions = []
	let minutesOptions = []

	for (let i = 0; i < 24; i++)
		hoursOptions.push(
			<option key={i} value={i}>
				{i < 10 ? "0" + i : i}
			</option>
		)
	for (let i = 0; i < 60; i++)
		minutesOptions.push(
			<option key={i} value={i}>
				{i < 10 ? "0" + i : i}
			</option>
		)

	const handleUpdateService = async () => {
		try {
			const updatedDay = {}
			updatedDay[currentWeekday] = currentWeekdayValue

			const token = getToken()
			const updatedService = {
				...user.service,
				week: { ...user.service.week, ...updatedDay }
			}

			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/supplier/service", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ service: updatedService }),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({ ...user, service: updatedService }))
			dispatch(createToast({ tone: "success", miliseconds: 3000, message: "Atualizado com sucesso" }))
		} catch (error) {
			console.error(error)
			dispatch(createToast({ tone: "failure", miliseconds: 5000, message: "Ocorreu algum erro, tente novamente" }))
		} finally {
			setRenderModal(false)
		}
	}

	const handleUpdateInstallments = async () => {
		try {
			const token = getToken()

			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/supplier/installments", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ installments: installments }),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({ ...user, installments: installments }))
			dispatch(createToast({ tone: "success", miliseconds: 3000, message: "Atualizado com sucesso" }))
		} catch (error) {
			console.error(error)
			dispatch(createToast({ tone: "failure", miliseconds: 5000, message: "Ocorreu algum erro, tente novamente" }))
		}
	}

	return (
		<SupplierRoleNavbar>

			<PageTitle title="Atendimento" />

			{Object.keys(user.service.week).some(day => user.service.week[day].active === true) === false &&
				<div className="medium-margin-vertical">
					<Notice tone="warning">
						<div className="grid-columns medium-gap left-items">
							<FontAwesomeIcon className="fa-icon" icon={faExclamationTriangle} />
							<div className="grid small-gap">
								<span className="small">Seus clientes esperam o mínimo de atendimento e de preferência o melhor possível. Use essa página para informá-los dos horários disponíveis.</span>
								<div className="bold-font tiny">
									<span>Clique em algum dos dias da semana abaixo para atualizá-lo.</span>
								</div>
							</div>
						</div>
					</Notice>
				</div>
			}

			{renderModal && (
				<Modal onClose={() => setRenderModal(false)}>
					<div className="modal-container">
						<div className="header">
							<span className="big-x bold-font left-text">
								{`Alterar atendimento de ${WEEKDAYS[currentWeekdayIndex].toLowerCase()}`}
							</span>
						</div>

						<CheckboxInput
							label="Permitir atendimento no dia"
							value={currentWeekdayValue.active}
							defaultChecked={currentWeekdayValue.active}
							onChange={() => setCurrentWeekdayValue({ ...currentWeekdayValue, active: !currentWeekdayValue.active })}
						/>

						<div className="grid medium-gap fill-normal wide-children">
						{Object.keys(currentWeekdayValue["schedule"]).map((operation) => {

							const value = currentWeekdayValue["schedule"][operation]
							const hours = parseInt(value / 60)
							const minutes = value % 60

							return (
								<div
									key={operation}
									className="grid small-gap"
								>
									<span className="big bold-font">
										{operation === "opening" ? "Início" : "Fim"}
									</span>
									<div className="flex small-gap wide wide-children">
										<SelectInput
											label="hora"
											value={hours}
											disabled={currentWeekdayValue.active === false}
											onChange={e => {
												let updatedWeekday = { ...currentWeekdayValue, schedule: { ...currentWeekdayValue["schedule"] } }
												updatedWeekday["schedule"][operation] = Number(e.target.value) * 60 + minutes
												setCurrentWeekdayValue(updatedWeekday)
											}}
										>
											{hoursOptions}
										</SelectInput>
										<SelectInput
											label="minuto"
											value={minutes}
											disabled={currentWeekdayValue.active === false}
											onChange={e => {
												let updatedWeekday = { ...currentWeekdayValue, schedule: { ...currentWeekdayValue["schedule"] } }
												updatedWeekday["schedule"][operation] = hours * 60 + Number(e.target.value)
												setCurrentWeekdayValue(updatedWeekday)
											}}
										>
											{minutesOptions}
										</SelectInput>
									</div>
								</div>
							)
						})}
						</div>

						<div className="wide wide-children medium-margin-top">
							<MainButton
								disabled={currentWeekdayValue.active === false && ((currentWeekdayValue.schedule.closing - currentWeekdayValue.schedule.opening) <= 0)}
								onClick={handleUpdateService}
							>
								<span>{`Alterar ${WEEKDAYS[currentWeekdayIndex].toLowerCase()}`}</span>
							</MainButton>
						</div>
					</div>
				</Modal>
			)}

			<div className="medium-margin-vertical grid fill-large medium-gap">
			{Object.keys(user.service.week).map((day, index) => {

				const calculateSchedule = () => {
					const openingTime = user.service.week[day]["schedule"]["opening"]
					const closingTime = user.service.week[day]["schedule"]["closing"]
					const openingHours = parseInt(openingTime / 60)
					const closingHours = parseInt(closingTime / 60)
					const openingMinutes = openingTime % 60
					const closingMinutes = closingTime % 60

					return `das ${openingHours}:${openingMinutes < 10 ? "0" + openingMinutes : openingMinutes} às ${closingHours}:${closingMinutes < 10 ? "0" + closingMinutes : closingMinutes}`
				}

				return (
					<MainButton
						key={day}
						hierarchy="inferior"
						onClick={() => {
							setCurrentWeekdayValue({
								...user.service.week[day],
								schedule: {
									...user.service.week[day]["schedule"]
								}
							})
							setCurrentWeekdayIndex(index)
							setCurrentWeekday(day)
							setRenderModal(true)
						}}
					>
						<FontAwesomeIcon className="fa-icon" icon={user.service.week[day].active === false ? faTimes : faCheck} />
						<span>
						{`${WEEKDAYS[index]} ${
							user.service.week[day].active === false
							? "sem"
							: calculateSchedule()
						}`}
						</span>
					</MainButton>
				)
			})}
			</div>


			<Form className="grid medium-gap medium-margin-vertical">

				<span className="big-x bold-font">Condições de parcelamento</span>

				<CheckboxInput
					label="Parcelamentos ativados"
					checked={installments.active === true}
					onChange={() => setInstallments(installments => ({ ...installments, active: !installments.active }))}
				/>
				<CheckboxInput
					label="Personalizável pelo vendedor"
					checked={installments.custom === true}
					onChange={() => setInstallments(installments => ({ ...installments, custom: !installments.custom }))}
				/>
				<CheckboxInput
					label="Juros compostos"
					checked={installments.interest.compound === true}
					disabled={installments.period === 0}
					onChange={() =>
						setInstallments(installments => ({
							...installments,
							interest: {
								...installments.interest,
								compound: !installments.interest.compound,
							},
						}))
					}
				/>

				<div className="grid fill-small medium-gap left-items">
					<NumberInput
						label="Máximo de parcelas"
						value={installments.period}
						min={2}
						onChange={e => {
							setInstallments({
								...installments,
								period: e.target.value,
							})
						}}
						onBlur={e => {
							const period = e.target.valueAsNumber
							setInstallments({
								...installments,
								period: toUnsignedInteger(period < 2 ? 2 : period),
							})
						}}
					/>
					<NumberInput
						label="Juros por ciclo (%)"
						value={installments.interest.percentage}
						disabled={installments.period === 0}
						onChange={e => {
							setInstallments({
								...installments,
								interest: {
									...installments.interest,
									percentage: e.target.value
								},
							})
						}}
						onBlur={e => {
							const value = e.target.valueAsNumber
							setInstallments({
								...installments,
								interest: {
									...installments.interest,
									percentage: value < 0 ? 0 : toUnsignedFloat(value, 3),
								},
							})
						}}
					/>

					<NumberInput
						label="Intervalo entre parcelas"
						value={installments.timespan.value}
						min={1}
						onChange={e => {
							setInstallments({
								...installments,
								timespan: {
									...installments.timespan,
									value: e.target.value,
								},
							})
						}}
						onBlur={e => {
							const value = e.target.valueAsNumber
							setInstallments({
								...installments,
								timespan: {
									...installments.timespan,
									value: value < 1 || isNaN(value) ? 1 : toUnsignedInteger(value),
								},
							})
						}}
					/>
					<SelectInput
						label="Unidade do intervalo"
						value={installments.timespan.unit}
						onChange={e => {
							setEnvironment({ ...environment, currency: e.target.value })
							setInstallments({
								...installments,
								timespan: {
									...installments.timespan,
									unit: e.target.value,
								},
							})
						}}
					>
						{datepartOptions}
					</SelectInput>
				</div>

				<div>
					<MainButton	onClick={handleUpdateInstallments}>
						<span>Alterar condições</span>
					</MainButton>
				</div>
			</Form>
		</SupplierRoleNavbar>
	)
}
