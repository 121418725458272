import { useEffect, useRef, useState } from "react"

// Utils
import { isValidBarcode } from "offiziersmesser/lib/utils"

// Components
import Form from "src/shared/components/standard/Form"
import Modal from "src/shared/components/custom/Modal/Modal"
import MainButton from "src/shared/components/standard/MainButton"
import Barcode from "src/shared/components/custom/Barcode/Barcode"

import RadioButtonInput from "src/shared/components/standard/RadioButtonInput"

type GenerateItemBarcodeModalProps = {
	onRenderModal: () => void
	sku: string
	barcode: string
}

export default function GenerateItemBarcodeModal({
	onRenderModal,
	sku,
	barcode
}: GenerateItemBarcodeModalProps) {

	const isSkuValid = sku.length > 0 ? true : false

	// States
	const [renderedCode, setRederedCode] = useState("")
	const [typeCode, setTypeCode] = useState("")
	const [isBarcode, setIsBarcode] = useState(isValidBarcode(renderedCode || ""))
	const [isBarcodeValid, setIsBarcodeValid] = useState(isValidBarcode(barcode))

	// Refs
	const canvaRef = useRef<HTMLCanvasElement>(null)

	function fillZerosOnLeft(str: string, len: number) {
		const zeros = new Array(len).fill('0').join('');
		return (zeros + str).slice(-len);
	}

	// Handlers
	function handleChangeCode(type: string) {
		setTypeCode(type)
		setRederedCode(type==='gtin' ? fillZerosOnLeft(barcode, 13) : sku || "")
	}

	function handleValidateCode() {
		setIsBarcode(isValidBarcode(barcode))
	}

	function downloadCode() {

		// Convert the canvas to data
		const image = canvaRef.current?.toDataURL()
		// Create a link
		const aDownloadLink = document.createElement("a") as HTMLAnchorElement
		// Add the name of the file to the link
		aDownloadLink.download = `code-${renderedCode}-image.png`
		// Attach the data to the link
		aDownloadLink.href = image as string
		// Get the code to click the download link
		aDownloadLink.click()
	}

	useEffect(() => {
		setIsBarcode(isValidBarcode(renderedCode))
		setIsBarcodeValid(isValidBarcode(barcode))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [barcode])
	useEffect(()=>{
		handleValidateCode()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	return (
		<Modal onClose={() => onRenderModal()}>
			<div className="modal-container">
				<div className="header">
					<span className="bold-font big-x">Código de barras</span>
				</div>

				<Form>
					<div>
						<RadioButtonInput
								name="barcode"
								label="SKU"
								value="sku"
								disabled = {!isSkuValid}
								defaultChecked={typeCode === "sku"}
								onChange={ event => {handleChangeCode(event.target.value)}}
						/>
						<RadioButtonInput
								name="barcode"
								label="GTIN"
								value="gtin"
								disabled={!isBarcodeValid}
								defaultChecked={typeCode === "gtin"}
								onChange={event => {handleChangeCode(event.target.value)}}
						/>

						<div className="flex justify-content-space-between justify-content-center small-gap medium-margin-top">
							{((renderedCode.length > 0)) && (
								(( typeCode === 'gtin' && isBarcode === true && isBarcodeValid === true)) ? (
									<Barcode
										ref={canvaRef}
										currentRef={canvaRef}
										value={renderedCode}
										options={{ format: "EAN13" }}
									/>
								) : ( typeCode === 'sku' && renderedCode.length > 0 ) ? (
									<Barcode
										ref={canvaRef}
										currentRef={canvaRef}
										value={renderedCode}
										options={{ format: "CODE128" }}
									/>
								) : "CODIGO INVALIDO"
							)}
						</div>
					</div>

					<div className="wide wide-children medium-margin-top">
						<MainButton onClick={() => downloadCode()} disabled={renderedCode.length <= 0}>
							<span>Salvar imagem</span>
						</MainButton>
					</div>
				</Form>
			</div>
		</Modal >
	)
}
