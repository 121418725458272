import { useState } from "react"
import { useDispatch } from "react-redux"

// Utils
import { toFixedImproved } from "offiziersmesser/lib/utils"
import { toUnsignedFloat } from "offiziersmesser/lib/utils"
import { calculateItemPrice } from "offiziersmesser/lib/helpers"
import { calculateItemPriceDiscounted } from "offiziersmesser/lib/helpers"

// Store
import { useSelector } from "react-redux"
import { updateStore } from "src/shared/store/slices/storeSlice"
import {
	updateCartContent,
	removeCartContent,
} from "src//shared/store/slices/cartSlice"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import {
	faTrash,
	faUpRightAndDownLeftFromCenter,
	faAngleDown,
	faAngleRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


// Components
import Modal from "src/shared/components/custom/Modal/Modal"
import MainButton from "src//shared/components/standard/MainButton"
import NumberInput from "src//shared/components/standard/NumberInput"
import HideableBlock from "src//shared/components/custom/HideableBlock/HideableBlock"

// Styles
import "./CheckoutItemCard.css"

export default function CartItem({ item }) {

	// Store
	const dispatch = useDispatch()
	const store = useSelector(state => state.store)

	// State
	const [renderCartItemModal, setRenderCartItemModal] = useState(false)

	const removeItemCart = () => {

		let storeContents = [...store.data.items] // Clone the store contents array
		const index = storeContents.findIndex(i =>  i.item === item.element.item)

		if (index !== -1) {
			let updateItemStore
			if (item.element.discount.quota > item.amount) {
				updateItemStore = {
					...item.element,
					discount: {
						...item.element.discount,
						quota: storeContents[index].discount.quota + item.amount
					},
					control: {
						...item.element.control,
						quantity: item.amount > item.element.control.quantity ? item.element.control.quantity : storeContents[index].control.quantity + item.amount
					}}
			} else {
				updateItemStore = {
					...item.element,
					discount: {
						...item.element.discount,
						quota: item.element.discount.quota
					},
					control: {
						...item.element.control,
						quantity: item.amount > item.element.control.quantity ? item.element.control.quantity : storeContents[index].control.quantity + item.amount
					}}
			}
			storeContents[index] = updateItemStore
			dispatch(
				updateStore({
					...store,
					data:{
						...store.data,
						items: storeContents
					}
				})
			)
		}
		dispatch(removeCartContent(item))
	}

	return (
		<>
			{renderCartItemModal &&
				<Modal
					// title={`${item.amount} ${item.element.control.measure} ${item.element.control.description}`}
					title="Detalhes do item"
					onClose={() => setRenderCartItemModal(false)}
				>
					<>
						<NumberInput
							label="Quantidade"
							value={item.amount}
							max={item.element.control.quantity}
							onChange={event => {

								const value = event.target.valueAsNumber
								const maxamount = item.element.discount.quota === 0
									? item.element.control.amount
									: item.element.discount.quota

								dispatch(
									updateCartContent({
										...item,
										amount: (item.element.control.fractionable === false && value < 1) || isNaN(value)
											? 1
											: item.element.control.available === false
												? value
												: value > maxamount ? maxamount : value,
										// control.available === false means the item can be ordered at ad infinitum numbers
										element: item.element,
									})
								)
							}}
						/>

					{item.element.discount.custom === true && (
						<NumberInput
							label="Desconto personalizado no item (%)"
							value={item.element.discount.percentage}
							disabled={item.element.discount.custom === false}
							onChange={event => {

								const discount = event.target.valueAsNumber

								dispatch(
									updateCartContent({
										...item,
										element: {
											...item.element,
												discount: {
													...item.element.discount,
													percentage:
														discount < 0 || discount > 100 || isNaN(discount)
															? 0
															: toUnsignedFloat(discount, 2), // Discount must be between 0 and 100
												},
										},
									})
								)
							}}
						/>
					)}
					</>
				</Modal>
			}
			<div className="grid grid-columns tiny-gap space-between-items">

				<MainButton
					hierarchy="inferior"
					onClick={() => setRenderCartItemModal(true)}
				>
					<FontAwesomeIcon className="fa-icon" icon={faUpRightAndDownLeftFromCenter} />
				</MainButton>

				<div className="flex small-gap">
					<span className="bold-font">{item.amount}</span>
					<span className="bold-font">{item.element.control.measure}</span>
					<span>{item.element.control.description}</span>
				</div>


				<div className="flex space-between-items">
				<div className="flex flex-column align-items-flex-end min-width-fit-content">

					{item.element.discount.percentage > 0 ? (
						<>
							{(item.amount > item.element.discount.quota)&&<span className="bold-font midgrey tiny min-width-fit-content">
								{item.element.discount.quota} x com desconto
							</span>}
							<span className="line-through-text bold-font midgrey tiny min-width-fit-content">
								{toFixedImproved(
									calculateItemPrice(item.element)
									* item.amount, 2
								)}
							</span>

							<span className="orange bold-font min-width-fit-content">
							R$ {item.amount > item.element.discount.quota
							?
							toFixedImproved((calculateItemPriceDiscounted(item.element) * item.element.discount.quota)+ (calculateItemPrice(item.element) * (item.amount - item.element.discount.quota)) , 2)
							:
							toFixedImproved(calculateItemPriceDiscounted(item.element) * item.amount, 2)}

							</span>
						</>
					) : (
						<span className="bold-font">
							{toFixedImproved(calculateItemPrice(item.element) * item.amount, 2)}
						</span>
					)}
				</div>

				<MainButton
					hierarchy="inferior"
					onClick={() => removeItemCart()}
				>
					<FontAwesomeIcon className="fa-icon medium" icon={faTrash} />
				</MainButton>
				</div>
			</div>
		</>
	)
}
