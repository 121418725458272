import { Children, ReactNode, useEffect, useState } from "react"

// Components
import Form from "../../standard/Form"
import MainButton from "../../standard/MainButton"

// Styles
import "./StepForm.css"

type StepFormProps = {
	componentTitle?: string
	onClickConclude?: () => void
	children: any // temporariamente tipado de forma incorreta e não recomendado por motivos de tempo de execução de serviço - ass Gabriel Souza
}

// | To use this component with typescript you need to use the auxiliary component
// | called stepContainer to pass the fireOnMount / stepCondintion and any other
// | prop that is intrinsic to help in the use of this component.

export default function StepForm({
	children,
	componentTitle,
	onClickConclude,
}: StepFormProps) {

	const totalSteps = Children.toArray(children as ReactNode).length - 1

	// States
	const [currentStep, setCurrentStep] = useState(0)
	const [style, setStyle] = useState({ width: "0%" })

	// Handlers
	function handleNextStep() { setCurrentStep(currentStep + 1) }

	function handleBackStep() {
		setCurrentStep(currentStep - 1)
	}

	function getStepCondition(currentStep: number) {
		const { stepCondition } = children[currentStep].props
		return typeof stepCondition === "boolean" ? stepCondition : true
	}

	function getfireOnMountFunction(currentStep: number) {
		const { fireOnMount } = children[currentStep].props
		if (typeof fireOnMount === "function") fireOnMount()
	}

	useEffect(() => {
		const newStyle = {
			width: `${((currentStep + 1) / (totalSteps + 1)) * 100}%`,
		}
		setStyle(newStyle)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStep])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => getfireOnMountFunction(currentStep), [currentStep])

	return (
		<Form className="grid large-gap">

			<div className="grid medium-gap">
				<div className="StepForm-progressbar-container">
					<div className="StepForm-progressbar" style={style}></div>
				</div>

				<div className="flex medium-gap justify-content-space-between align-items-center">
					<span className="heavy-font big-xx">{componentTitle}</span>
					<span className="heavy-font">{`${currentStep + 1} de ${totalSteps + 1}`}</span>
				</div>
			</div>

			{children[currentStep]}

			<div className="grid-columns medium-gap fill-normal left-items">
				{currentStep > 0 && (
					<MainButton onClick={handleBackStep}>
						<span>Voltar</span>
					</MainButton>
				)}
				{(currentStep < totalSteps) && (
					<MainButton
						disabled={getStepCondition(currentStep) === false}
						onClick={handleNextStep}
					>
						<span>Avançar</span>
					</MainButton>
				)}
				{(currentStep === totalSteps) && (
					<MainButton
						disabled={getStepCondition(currentStep) === false}
						onClick={onClickConclude}
					>
						<span>Concluir</span>
					</MainButton>
				)}
			</div>
		</Form>
	)
}
