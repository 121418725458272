import { ChangeEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Utils
import { toUnsignedFloat } from "offiziersmesser/lib/utils"
import { translateMeasurementType } from "offiziersmesser/lib/utils"

// Models
import { DimensionModel } from "src/shared/models/item"

// Store
import { updateWorkableItem } from "src/shared/store/slices/workableItemsSlice"
import { RootState } from "src/shared/store/reducers"

// Components
import NumberInput from "src/shared/components/standard/NumberInput"
import SelectInput from "src/shared/components/standard/SelectInput"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"

export default function LogisticsHandler() {

	const dispatch = useDispatch()

	// Store
	const item = useSelector((state: RootState) => state.workableItems.items[state.workableItems.index]?.item)

	// States
	const [hasDimensions, setHasDimensions] = useState(false)

	// Variables
	const measurementUnits = [
		[{ name: "Quilogramas", unit: "kg" }],
		[{ name: "Centímetros", unit: "cm" }, { name: "Metros", unit: "m" }]
	]
	const renderedOptions = measurementUnits.map(element =>
		element.map((entry, index) => <option key={index} value={entry.unit}>{entry.name}</option>)
	)
	const MEASUREMENT_TYPES_BR = [
		{ type: "weight", options: renderedOptions[0] },
		{ type: "width", options: renderedOptions[1] },
		{ type: "length", options: renderedOptions[1] },
		{ type: "height", options: renderedOptions[1] },
		// {type: "depth", options: renderedOptions[1]}
	]

	// Handlers
	function dispatchSetItemLogisticsDimensions(dimensions: DimensionModel[]) {
		dispatch(updateWorkableItem({
			...item,
			logistics: { ...item.logistics, dimensions: dimensions }
		}))
	}

	useEffect(() => {
		if (!!item) {
			setHasDimensions(item.logistics.dimensions.length > 1 ? true : false)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		item &&
		<div className="flex flex-column medium-gap">

			<span className="big-x bold-font">Logística</span>

			<div className="flex flex-column small-gap">
				<CheckboxInput
					label="Aceita devolução"
					defaultChecked={item.control.returnable === true}
					onChange={() =>
						dispatch(updateWorkableItem({
							...item,
							control: {
								...item.control,
								returnable: !item.control.returnable
							}
						}))
					}
				/>
				<CheckboxInput
					label="Pode ser empilhado"
					defaultChecked={(item.logistics.stackable === true)}
					onChange={() =>
						dispatch(updateWorkableItem({
							...item,
							logistics: {
								...item.logistics,
								stackable: !item.logistics.stackable
							}
						}))
					}
				/>
				<CheckboxInput
					label="Possui dimensões além do peso"
					defaultChecked={(((item.logistics.dimensions.length > 1) ? true : false) === true)}
					onChange={() => {
						const toggledHasDimensions = !hasDimensions
						setHasDimensions(toggledHasDimensions)

						if (toggledHasDimensions === false)
							dispatchSetItemLogisticsDimensions([
								item.logistics.dimensions[0]
							])
						else
							dispatchSetItemLogisticsDimensions([
								...item.logistics.dimensions,
								{ type: "width", value: 0.0, unit: "cm" },
								{ type: "length", value: 0.0, unit: "cm" },
								{ type: "height", value: 0.0, unit: "cm" },
							])
					}}
				/>
			</div>

			<div className="grid medium-gap fill-large wide-children">

				{(hasDimensions === true) ? (
					MEASUREMENT_TYPES_BR.map((entry, index) => (
						<div key={index} className="grid small-gap">
							<span className="bold-font">{translateMeasurementType(entry.type)}</span>
							<div className="grid-columns small-gap">
								<NumberInput
									label="Valor"
									value={item.logistics.dimensions[index].value}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										dispatchSetItemLogisticsDimensions(
											item.logistics.dimensions.map((dimension: DimensionModel) =>
												(dimension.type === entry.type) ? { ...dimension, value: toUnsignedFloat(e.target.value, 2) } : dimension
											)
										)
									}
								/>
								<SelectInput
									label="Unidade"
									value={item.logistics.dimensions[index].unit}
									onChange={(e: ChangeEvent<HTMLSelectElement>) => {
										dispatchSetItemLogisticsDimensions(
											item.logistics.dimensions.map((dimension: DimensionModel) =>
												(dimension.type === entry.type) ? { ...dimension, unit: e.target.value } : dimension
											)
										)
									}}
								>
									{entry.options}
								</SelectInput>
							</div>
						</div>
					))
				) : (
					<div className="grid small-gap">
						<span className="bold-font">{translateMeasurementType(MEASUREMENT_TYPES_BR[0].type)}</span>
						<div className="grid-columns small-gap">
							<NumberInput
								label="Valor"
								value={item.logistics.dimensions[0].value}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									dispatchSetItemLogisticsDimensions(
										item.logistics.dimensions.map((dimension: DimensionModel) =>
											(dimension.type === "weight") ? { ...dimension, value: toUnsignedFloat(e.target.value, 2) } : dimension
										)
									)
								}
							/>
							<SelectInput
								label="Unidade"
								value={MEASUREMENT_TYPES_BR[0].options[0].toString()}
								onChange={(e: ChangeEvent<HTMLSelectElement>) =>
									dispatchSetItemLogisticsDimensions(
										item.logistics.dimensions.map((dimension: DimensionModel) =>
											(dimension.type === "weight") ? { ...dimension, unit: e.target.value } : dimension
										)
									)
								}
							>
								{MEASUREMENT_TYPES_BR[0].options}
							</SelectInput>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
