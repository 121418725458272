import { useSelector, useDispatch } from "react-redux"

// Utils
import { deepEqual } from "offiziersmesser/lib/utils"

// Store
import { setCartDeliveryAddress } from "../../../../../shared/store/slices/cartSlice"

// Components
import RadioButtonInput from "../../../../../shared/components/standard/RadioButtonInput"

export default function CheckoutAddressList() {

	const dispatch = useDispatch()

	// Store
	const cart = useSelector(state => state.cart)
	const guest = useSelector(state => state.guest)

	return (
		<>
			<div className="medium-margin-top medium-margin-bottom">
				<span>Selecione o endereço</span>
			</div>

			<div className="tiny-margin grid small-gap fill-normal center-text center-items wide-children">
				{guest.loadedAddresses.map((address, index) => {
					const { name, surname } = address.reference
					const { road, number, extra, area, city, region, postal } = address.locality
					const { destination, subscriber } = address.reference.phone

					return (
						<RadioButtonInput
							key={index}
							name="address"
							label={
								<span className="grid small-gap center-items center-text wide-children element-border">
									<span className="bold-font">{name + " " + surname}</span>
									<span>{road + ", " + number + ((extra !== "") && ", " + extra)}</span>
									<span>{area + ", " + city + ", " + region + ", " + postal}</span>
									<span>{destination + " " + subscriber}</span>
								</span>
							}
							defaultChecked={deepEqual(cart.data.delivery.address, address) === true}
							onChange={() => dispatch(setCartDeliveryAddress(address))}
						/>
					)
				})}
			</div>
		</>
	)
}
