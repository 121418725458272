// Utils
// Utils
import { PAYMENT_METHODS } from "offiziersmesser/lib/utils"
import { createTotalParts } from "offiziersmesser/lib/helpers"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCashRegister } from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "../../../../../../shared/components/custom/Notice/Notice"

export default function BalanceOutflowGeneral({ payments }) {

	const parts = []
	const totalParts = []
	const balanceEntry = []

	let balancesTotal = 0

	if (payments != null) {

		payments.map((transaction) => {
			return parts.push(...transaction.payments[0].parts)
		})

		totalParts.push(...createTotalParts(parts))
		balanceEntry.push(...totalParts)

		balancesTotal = balanceEntry
			.map((balances) => balances.value)
			.reduce((prev, curr) => prev + curr, 0)
	}

	return (
		<div className="register-wrapper flex flex-column wide high small-gap">

			<div className="register-history outflow-history element-border wide high">

				{(balanceEntry.length > 0) ? (
					balanceEntry.map((balance, index) => {
						return (
							<li key={index} className="register-history-item">
								<span className="bold-font">
									{PAYMENT_METHODS.map((method, index) => {
										if (balance.method === index) return <>{method.name}</>
										return null
									})}
								</span>
								<span className="bold-font">
									{balance.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
								</span>
							</li>
						)
					})
				) : (
					<div className="wide wide-children">
						<Notice>
							<FontAwesomeIcon icon={faCashRegister} />
							<span>Registrador não possui saida.</span>
						</Notice>
					</div>
				)}
			</div>

			<div className="element-border wide">
				<ul>
					<li className="register-history-item">
						<span className="bold-font">Total</span>
						<span className="bold-font big orange">
							{balancesTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
						</span>
					</li>
				</ul>
			</div>
		</div>
	)
}
