import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import { formatCapitalize } from "offiziersmesser/lib/utils"

// Models
import { CategoryModel } from "src/shared/models/item"

// Store
import { setUser } from "src/shared/store/slices/userSlice"
import { RootState } from "src/shared/store/reducers"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import {
	faPlus,
	faExclamationCircle,
	faExternalLinkSquareAlt
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Form from "src/shared/components/standard/Form"
import Modal from "src/shared/components/custom/Modal/Modal"
import Notice from "src/shared/components/custom/Notice/Notice"
import TextInput from "src/shared/components/standard/TextInput"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import MainButton from "src/shared/components/standard/MainButton"
import LinkButton from "src/shared/components/standard/LinkButton"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import CreateItemCategoryModal from "./editor/components/modals/CreateItemCategoryModal/CreateItemCategoryModal"

function calculateTagDistribution(items: any) {
	const tagDistribution = new Map()

	for (let i = 0; i < items.length; i++) {
		const category = items[i].groups.category
		tagDistribution.set(category, (tagDistribution.get(category) || 0) + 1)
	}

	return tagDistribution
}

export default function Categories() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Stores
	const user = useSelector((state: RootState) => state.user)
	const items = useSelector((state: RootState) => state.items)

	// States
	const [tagDistribution, ] = useState(calculateTagDistribution(items))

	const [isCreating, setIsCreating] = useState(false)
	//const [isUpdating, setIsUpdating] = useState(false)

	const [outdatedTag, setOutdatedTag] = useState("")
	const [updatedTag, setUpdatedTag] = useState("")
	// Handlers
	async function handleUpdateCategory() {
		try {
			const updatedData = {
				categories: user.categories.map((category: CategoryModel) => {
					if (category.tag === outdatedTag)
						category.tag = updatedTag
					return category
				})
			}
			const token = getToken()
			const options = {
				headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" }
			}

			const putResponse = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/categories`, {
				...options,
				method: "PUT",
				body: JSON.stringify(updatedData)
			})
			responseCodeHandler(putResponse)
			if (!putResponse.ok) throw new Error("Failed to update category")

			const patchResponse = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/item/groups/category`, {
				...options,
				method: "PATCH",
				body: JSON.stringify({ outdatedTag: outdatedTag, updatedTag: updatedTag })
			})
			responseCodeHandler(patchResponse)
			if (!patchResponse.ok) throw new Error("Failed to patch item category")

			dispatch(setUser({ ...user, ...updatedData }))
		} catch (error) {
			console.error(error)
		}
	}

	async function handleRemoveCategory() {
		try {
			const updatedData = { categories: user.categories.filter((category: CategoryModel) => category.tag !== updatedTag) }
			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/categories`, {
				method: "PUT",
				headers: {
					"Authorization": `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(updatedData)
			})
			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({ ...user, ...updatedData }))
			// Returns a new array without the deleted category
			// setCurrentTags(currentTags.filter((_, itemIndex) => index !== itemIndex))
			setUpdatedTag("")
			setOutdatedTag("")
		} catch (error) {
			console.error(error)
		}
	}


	// Effects
	// useEffect(() => {
	// 	setCurrentTags(user.categories === null ? [] : user.categories.map(category => category.tag))
	// }, [user])

	// useEffect(() => {
	// 	setOutdatedTag(updatedTag)
	// }, [updatedTag])


	return (
		<SupplierRoleNavbar>
			{isCreating === true && (
				<CreateItemCategoryModal onClose={() => setIsCreating(false)} />
			)}

			{!!updatedTag && (
				<Modal
					onClose={() => {
						//setIsUpdating(false)
						setUpdatedTag("")
						setOutdatedTag("")
					}}
				>
					<div className="modal-container">
						<div className="header">
							<span className="big-x bold-font left-text">Renomear ou excluir categoria</span>
						</div>

						<Form className="grid medium-gap">
							<TextInput
								label="Categoria"
								value={updatedTag}
								placeholder={updatedTag}
								onChange={event => setUpdatedTag(formatCapitalize(event.target.value))}
							/>

							<div className="flex small-gap wide-children">

								<MainButton
									hierarchy="inferior"
									disabled={(updatedTag === "" || updatedTag === outdatedTag)}
									onClick={() => handleUpdateCategory()}
								>
									<div className="flex wide justify-content-center">
										<span>Renomear</span>
									</div>
								</MainButton>

								{(tagDistribution.get(updatedTag) === undefined) && (
									// Only allows the category to be removed if no item uses it
									<MainButton
										hierarchy="inferior"
										onClick={() => handleRemoveCategory()}
									>
										<div className="flex wide justify-content-center">
											<span>Excluir</span>
										</div>
									</MainButton>
								)}
							</div>
						</Form>

					</div>
				</Modal>
			)}

			<div className="grid medium-gap">
				<PageTitle title="Categorias" />
				{(user.categories === null || user.categories?.length === 0) ? (
					<Notice tone="info" onClick={() => setIsCreating(true)}>
						<div className="grid-columns medium-gap left-items">
							<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle as Icon} />
							<div className="grid small-gap">
								<span className="small">Melhore a organização do estoque e facilite a compra dos seus clientes criando categorias de itens que sejam relevantes para o seu negócio.</span>
								<div className="grid-columns small-gap left-items bold-font tiny">
									<span>Clique aqui para criar uma nova categoria</span>
									<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt as Icon} />
								</div>
							</div>
						</div>
					</Notice>
				) : (
				<>
					<div className="grid small-gap fill-normal">
						<MainButton onClick={() => setIsCreating(true)}>
							<FontAwesomeIcon icon={faPlus as Icon} />
							<span>Criar categoria</span>
						</MainButton>
						<LinkButton hierarchy="low" to="/supplier/items">
							<FontAwesomeIcon icon={faPlus as Icon} />
							<span>Criar item</span>
						</LinkButton>
					</div>

					<span className="bold-font big-x">Atualizar ou excluir categoria</span>

					<div className="grid small-gap fill-normal wide-children">
					{user.categories.map((category: CategoryModel) => (
						<MainButton
							key={category.tag}
							hierarchy="inferior"
							onClick={() => {
								setUpdatedTag(category.tag)
								setOutdatedTag(category.tag)
							}}
						>
							<span>{category.tag}</span>
							<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt as Icon} />
						</MainButton>
					))}
					</div>
				</>
				)}
			</div>
		</SupplierRoleNavbar>
	)
}
// tagDistribution.get(category)














































// import { useState, useEffect } from "react"
// import { useSelector, useDispatch } from "react-redux"

// // Services
// import { getToken } from "src/shared/services/general"

// // Utils
// import { formatCapitalize } from "../../../shared/utils/dataValidationsUtils"
// import {
// 	updateCategory,
// 	removeCategory
// } from "../../../shared/store/helpers/categories"

// // Models
// import { CategoryModel } from "../../../shared/models/item"

// // Store
// import { setUser } from "../../../shared/store/slices/userSlice"
// import { RootState } from "../../../shared/store/reducers"

// // Icons
// import { Icon } from "@fortawesome/fontawesome-svg-core"
// import {
// 	faPlus,
// 	faExclamationCircle,
// 	faExternalLinkSquareAlt
// } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// // Components
// import Form from "../../../shared/components/standard/Form"
// import Notice from "../../../shared/components/custom/Notice/Notice"
// import TextInput from "../../../shared/components/standard/TextInput"
// import PageTitle from "../../../shared/components/custom/PageTitle/PageTitle"
// import MainButton from "../../../shared/components/standard/MainButton"
// import LinkButton from "../../../shared/components/standard/LinkButton"

// import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

// import CreateItemCategoryModal from "./editor/components/modals/CreateItemCategoryModal/CreateItemCategoryModal"

// function calculateTagDistribution(items: any) {
// 	const tagDistribution = new Map()

// 	for (let i = 0; i < items.length; i++) {
// 		const category = items[i].groups.category
// 		tagDistribution.set(category, (tagDistribution.get(category) || 0) + 1)
// 	}

// 	return tagDistribution
// }

// export default function Categories() {

// 	const dispatch = useDispatch()

// 	// Stores
// 	const user = useSelector((state: RootState) => state.user)
// 	const items = useSelector((state: RootState) => state.items)

// 	// States
// 	const [currentTags, setCurrentTags] = useState(
// 		user.categories === null ? [] : user.categories.map(category => category.tag)
// 	)
// 	const [tagDistribution, setTagDistribution] = useState(calculateTagDistribution(items))
// 	const [renderModal, setRenderModal] = useState(false)

// 	// Handlers
// 	async function handleUpdateCategory(category: CategoryModel, index: number) {
// 		try {
// 			const categoryUpdate = {
// 				outdatedTag: category.tag,
// 				updatedTag: currentTags[index]
// 			}

// 			const token = getToken()
// 			const options = {
// 				headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" }
// 			}

// 			const putResponse = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/categories`, {
// 				...options,
// 				method: "PUT",
// 				body: JSON.stringify(updateCategory(user.categories, categoryUpdate.outdatedTag, categoryUpdate.updatedTag))
// 			})
// 			if (!putResponse.ok) throw new Error("Failed to update category")

// 			const patchResponse = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/item/groups/category`, {
// 				...options,
// 				method: "PATCH",
// 				body: JSON.stringify(categoryUpdate)
// 			})
// 			if (!patchResponse.ok) throw new Error("Failed to patch item category")

// 			dispatch(setUser({ ...user, ...updateCategory(user.categories, categoryUpdate.outdatedTag, categoryUpdate.updatedTag) }))
// 		} catch (error) {
// 			console.error(error)
// 		}
// 	}

// 	async function handleRemoveCategory(category: CategoryModel, index: number) {
// 		try {
// 			const updatedCategories = removeCategory(user.categories, category)
// 			const updatedData = { updatedCategories }
// 			const token = getToken()

// 			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/categories`, {
// 				method: "PUT",
// 				headers: {
// 					"Authorization": `Bearer ${token}`,
// 					"Content-Type": "application/json"
// 				},
// 				body: JSON.stringify(updatedData)
// 			})
// 			if (!response.ok) throw new Error(`HTTP error ${response.status}`)

// 			dispatch(setUser({ ...user, categories: updatedCategories.categories }))
// 			// Returns a new array without the deleted category
// 			setCurrentTags(currentTags.filter((_, itemIndex) => index !== itemIndex))
// 		} catch (error) {
// 			console.error(error)
// 		}
// 	}


// 	// Effects
// 	useEffect(() => {
// 		setCurrentTags(user.categories === null ? [] : user.categories.map(category => category.tag))
// 	}, [user])

// 	return (
// 		<SupplierRoleNavbar>
// 			{renderModal === true && (
// 				<CreateItemCategoryModal onClose={() => setRenderModal(false)} />
// 			)}

// 			<div className="grid medium-gap">
// 				<PageTitle title="Categorias" />
// 				{(user.categories === null || user.categories?.length === 0) ? (
// 					<Notice tone="info" onClick={() => setRenderModal(true)}>
// 						<div className="grid-columns medium-gap left-items">
// 							<FontAwesomeIcon className="fa-icon" icon={faExclamationCircle as Icon} />
// 							<div className="grid small-gap">
// 								<span className="small">Melhore a organização do estoque e facilite a compra dos seus clientes criando categorias de itens que sejam relevantes para o seu negócio.</span>
// 								<div className="grid-columns small-gap left-items bold-font tiny">
// 									<span>Clique aqui para criar uma nova categoria</span>
// 									<FontAwesomeIcon className="fa-icon" icon={faExternalLinkSquareAlt as Icon} />
// 								</div>
// 							</div>
// 						</div>
// 					</Notice>
// 				) : (
// 				<>
// 					<div className="grid small-gap fill-normal">
// 						<MainButton onClick={() => setRenderModal(true)}>
// 							<FontAwesomeIcon icon={faPlus as Icon} />
// 							<span>Criar categoria</span>
// 						</MainButton>
// 						<LinkButton hierarchy="low" to="/supplier/items">
// 							<FontAwesomeIcon icon={faPlus as Icon} />
// 							<span>Criar item</span>
// 						</LinkButton>
// 					</div>

// 					<span className="bold-font big-x"> Atualizar ou excluir categoria </span>

// 					<div className="grid small-gap fill-normal wide-children">
// 					{user.categories.map((category, index) =>
// 						<Form
// 							key={index}
// 							className="tiny-margin grid small-gap center-items wide-children element-border bottom"
// 						>
// 							<TextInput
// 								label={"Categoria " + (index + 1)}
// 								value={currentTags[index]}
// 								placeholder={category.tag}
// 								onChange={event => setCurrentTags(
// 									currentTags.map((item, itemIndex) => index === itemIndex
// 										? formatCapitalize(event.target.value)
// 										: item
// 									)
// 								)}
// 							/>

// 							<div className="flex small-gap wide-children">

// 								<MainButton
// 									hierarchy="inferior"
// 									disabled={(currentTags[index] === "" || currentTags[index] === category.tag)}
// 									onClick={() => handleUpdateCategory(category, index)}
// 								>
// 									<div className="flex wide justify-content-center">
// 										<span>Renomear</span>
// 									</div>
// 								</MainButton>

// 								{(tagDistribution.get(category) === 0) && (
// 									// Only allows the category to be removed if no item uses it
// 									<MainButton
// 										hierarchy="inferior"
// 										onClick={() => handleRemoveCategory(category, index)}
// 									>
// 										<div className="flex wide justify-content-center">
// 											<span>Excluir</span>
// 										</div>
// 									</MainButton>
// 								)}
// 							</div>
// 						</Form>
// 					)}
// 					</div>
// 				</>
// 				)}
// 			</div>
// 		</SupplierRoleNavbar>
// 	)
// }
