import { ReactNode } from "react"
import { Link } from "react-router-dom"

// This component must be used ONLY when changing url paths (eg: /home to /user/account)
// DOC: https://app.clickup.com/30992350/v/dc/xhtyy-1963/xhtyy-2123

type toType =  {
	pathname: string;
	region: {
		id: string
	}
}

type LinkButtonProps = {
	to: string | toType
	title?: string
	target?: string
	onClick?: () => void
	children?: ReactNode
	className?: string

	fontSize?: "big" | "medium" | "small"
	hierarchy?: "normal" | "low" | "inferior" | "minimal"
	overrideClass?: string
}

export default function LinkButton({
	to,
	title,
	target,
	onClick,
	children,

	fontSize = "medium",
	hierarchy = "normal",
	overrideClass,
}: LinkButtonProps) {

	return (
		<Link
			to={to}
			title={title}
			target={target}
			onClick={onClick}
			className={overrideClass || `unselectable element-highlight action-${hierarchy} ${fontSize}`}
		>
			{children}
		</Link>
	)
}
