// Components
import TextInput from "../../../../../shared/components/standard/TextInput"
import Form from "../../../../../shared/components/standard/Form"

// Styles
import "../../Invoices.css"

export default function TaxBlock() {
	return (
		<Form>
			<div className="normal-margin-top medium-margin-bottom">
				<span className="bold-font big">Cálculo de impostos</span>
			</div>
			<div className="grid small-gap fill-normal large-margin-top large-margin-bottom">
				<TextInput
					label="ICMS"
					value=""
				/>
				<TextInput
					label="Base de cálculo do ICMS"
					value=""
				/>
				<TextInput
					label="ICMS Subst."
					value=""
				/>
				<TextInput
					label="Base de cálculo do ICMS Subst."
					value=""
				/>
				<TextInput
					label="Valor do frete"
					value=""
				/>
				<TextInput
					label="Valor do seguro"
					value=""
				/>
				<TextInput
					label="Desconto"
					value=""
				/>
				<TextInput
					label="Outras despesas acessórias"
					value=""
				/>
				<TextInput
					label="Valor total do IPI"
					value=""
				/>
				<TextInput
					label="Valor total da nota"
					value=""
				/>
			</div>
		</Form>
	)
}
