// Models
import { ReferenceModel, LocalityModel } from "offiziersmesser/lib/models"

// Handlers
import loadUnactivatedUserData from "../services/loadUnactivatedUserData"

type TaxpayerActivityProps = {
	taxpayer: string,
	// contact: PhoneModel,
	// setContact: (value: PhoneModel) => void,
	setByname: (value: string) => void,
	setCompany: (value: string) => void,
	setReference: (value: ReferenceModel) => void,
	setLocality: (value: LocalityModel) => void,
	setIsUserActive: (value: boolean) => void,
	setIsUserNovice: (value: boolean) => void,
	setUserExists: (value: boolean) => void
}

export default async function retrieveTaxpayerActivity({
	taxpayer,
	// contact, setContact,
	setByname,
	setCompany,
	setReference,
	setLocality,
	setIsUserActive,
	setIsUserNovice,
	setUserExists,
}: TaxpayerActivityProps) {
	try {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/user/taxpayer/activity`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ taxpayer }),
		})

		if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

		const data = await response.json()

		if (!data.exists)
			throw new Error("User does not exist")

		setUserExists(true)
		setIsUserActive(data.active)
		setIsUserNovice(data.novice)

		if (data.active === false)
			loadUnactivatedUserData({
				taxpayer,
				// contact,
				// setContact,
				setByname,
				setCompany,
				setReference,
				setLocality,
			})
	} catch (error) {
		setUserExists(false)
	}
}

export type { TaxpayerActivityProps }
