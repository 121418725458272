

// // Utils
// import { AREA_CODES_BR } from "../../../utils/constUtils"

// // Models
// import { PhoneModel } from "../../../models/user"

import { PhoneModel } from "offiziersmesser/lib/models"


// Components
import TextInput from "../../standard/TextInput"



type DisableableFields = Array<"prefix" | "destination" | "subscriber">

type PhoneFormProps = {
	destinationLabel?: string,
	subscriberLabel?: string,
	disabledFields?: DisableableFields,
	phone: PhoneModel,
	setPhone: (value: PhoneModel) => void,
}

export default function PhoneInputGroup({
	destinationLabel = "DDD",
	subscriberLabel = "Celular",
	disabledFields,
	phone,
	setPhone,
}: PhoneFormProps) {

	// Handlers
	/**
	 * This function updates the user's phone information and triggers a form
	 * change event.
	 * @param {string} target - a string representing the property of the user object
	 * that needs to be updated.
	 * @param {string} value - The value that will be assigned to the specified target
	 * property in the user object.
	 */
	function handleSetPhone(
		target: string,
		value: string,
	) {
		setPhone({
			...phone,
			[target]: value
		})
	}

			// <SelectInput
			// 	name="destination"
			// 	label={destinationLabel}
			// 	value={phone?.destination}
			// 	onChange={e => handleSetPhone(e.target.name, e.target.value)}
			// 	disabled={disabledFields?.includes("destination")}
			// >
			// 	{AREA_CODES_BR}
			// </SelectInput>
	return (
		<div className="grid-columns small-gap">
			<TextInput
				name="destination"
				label={destinationLabel}
				value={phone?.destination}
				onChange={e => {
					const value = e.target.value.replace(/\D/g, "")
					// if (AREA_CODES_BR.some(code => code === value))
						handleSetPhone(e.target.name, value)
				}}
				disabled={disabledFields?.includes("destination")}
				placeholder="12"
			/>
			<TextInput
				name="subscriber"
				label={subscriberLabel}
				value={phone?.subscriber}
				onChange={e => handleSetPhone(e.target.name, e.target.value.replace(/\D/g, ""))}
				disabled={disabledFields?.includes("subscriber")}
				placeholder="98765 4321"
			/>
		</div>
	)
}
