import { FormEvent, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import { formatCapitalize } from "offiziersmesser/lib/utils"

// Store
import { RootState } from "src/shared/store/reducers"
import { setUser } from "src/shared/store/slices/userSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"

// Components
import Form from "src/shared/components/standard/Form"
import Modal from "src/shared/components/custom/Modal/Modal"
import TextInput from "src/shared/components/standard/TextInput"
import MainButton from "src/shared/components/standard/MainButton"

type CreateItemCategoryModalProps = {
	onClose: () => void
}

export default function CreateItemCategoryModal({
	onClose
}: CreateItemCategoryModalProps) {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// States
	const [categoryTag, setCategoryTag] = useState("")

	// Store
	const user = useSelector((state: RootState) => state.user)

	// Handlers
	async function handleCreateCategory(event: FormEvent) {
		try {
			event && event.preventDefault()

			const category = { tag: categoryTag, sub: null }
			const updatedData = {
				categories: user.categories ? [ ...user.categories, category ] : [ category ]
			}

			const token = getToken()
			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/categories`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify(updatedData),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({ ...user, categories: updatedData.categories }))
			dispatch(createToast({   tone: "success", miliseconds: 5000,	message: "Categoria criada com sucesso" }))
		}
		catch (error) {
			console.error(error)
			// dispatch(createToast({ key:nanoid(), tone: "failure", miliseconds: 5000, message: "Erro ao atualizar senha" }))
		}
		finally {
			setCategoryTag("")
			onClose()
		}
	}

	return (
		<Modal onClose={() => onClose()}>
			<div className="modal-container">
				<div className="header">
					<span className="bold-font big-x">
						Criar categoria {(user.categories?.length || 0) + 1}
					</span>
				</div>

				<Form
					className="grid medium-gap"
					onSubmit={e => handleCreateCategory(e)}
				>
					<TextInput
						label="Categoria"
						placeholder="Tintas"
						value={categoryTag}
						onChange={e => { setCategoryTag(formatCapitalize(e.target.value)) }}
					/>
					<MainButton
						type="submit"
						disabled={(categoryTag === "")}
					>
						<span>Criar categoria</span>
					</MainButton>
				</Form>
			</div>
		</Modal>
	)
}
