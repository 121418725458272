export const BalancesCardRow = ({ transactions }) => {

	return (
		<div>
			{transactions && transactions.map((transaction, index) => {
				const createdAt = new Date(transaction.createdAt * 1000)
				return (
					<div className="small-margin-top" key={index}>
						<div className="element-border  tiny-margin employees-row-active">
							<span className="medium bold-font">
								Descrição: {transaction.note}
							</span>
							<br />
							<span className="tiniest">
								Emitida em {`${createdAt.getDate()}/${createdAt.getMonth() + 1}/${createdAt.getFullYear()}`}
							</span>
							<div className="employees-data-cards-row">
								<span className="medium">
									Valor: {transaction.payments[0].total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
								</span>
							</div>
						</div>
					</div>
				)
			})}
		</div>
	)
}
