import { NavLink } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useIntl } from "react-intl"

// Store
import { RootState } from "src/shared/store/reducers"
import {
	// Utilitary functions
	doesNavigationEntryHasRoutes,
	// Reducer functions
	updateToggledSectionByName,
	// Types
	NavigationEntryModel,
	RoleNavigationModel
} from "src/shared/store/slices/sessionSlice"

type NavigationLinkProps = {
	path: string
	name: string
}

type ToggleableButtonProps = {
	name: string
	routes: Array<{ path: string; name: string }>
}

export default function RoleNavigationSection() {

	const dispatch = useDispatch()
	//const location = useLocation()
	const intl = useIntl()

	// Store
	const session = useSelector((state: RootState) => state.session)

	// Find the active role navigation items
	const activeRoleNavigation = session.roles.find((role: RoleNavigationModel) => role.active)?.navigation || []

	const activeNavigationSection = activeRoleNavigation.find((entry: NavigationEntryModel) =>
		doesNavigationEntryHasRoutes(entry) && entry.toggled
	)

	//const isRouteActive = (path: string) => location.pathname === path

	const NavigationLink = ({ path, name }: NavigationLinkProps) =>
		<NavLink to={path}>{intl.formatMessage({ id: name })}</NavLink>

	const ToggleableButtonSection = ({ name, routes }: ToggleableButtonProps) =>
		<div className="navbar-body-section grid tiny-gap">
			<button
				className="navbar-body-section-button left-text"
				onClick={() => dispatch(updateToggledSectionByName(name))}
			>
				{intl.formatMessage({ id: name })}
			</button>

			{(activeNavigationSection && activeNavigationSection.name === name) && (
				<div className="medium-padding-left tiny-padding-right grid tiny-gap">
				{routes.map(route =>
					<NavigationLink key={route.path} {...route} />
				)}
				</div>
			)}
		</div>

	return (
		<>
			{activeRoleNavigation.map((entry: NavigationEntryModel) =>
				doesNavigationEntryHasRoutes(entry) ?
				(
					<ToggleableButtonSection
						key={entry.name}
						name={entry.name}
						routes={entry.routes}
					/>
				) : (
					<NavigationLink
						key={entry.path}
						path={entry.path}
						name={entry.name}
					/>
				)
			)}
		</>
	)
}