import { ChangeEvent } from "react"

type TextareaProps = {
	name?: string
	cols?: number
	rows?: number
	label?: string
	value: string
	disabled?: boolean
	readOnly?: boolean
	required?: boolean
	onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
	className?: string
	maxLength?: number
	placeholder?: string
	defaultValue?: string
}

export default function Textarea({
	name,
	cols,
	rows,
	label,
	value,
	disabled,
	readOnly,
	required,
	onChange,
	className,
	placeholder,
	defaultValue,
}: TextareaProps) {
	return (
		<label>
			{label}
			<textarea
				cols={cols}
				rows={rows}
				name={name}
				value={value}
				disabled={disabled}
				readOnly={readOnly}
				onChange={onChange}
				className={className}
				placeholder={placeholder}
				defaultValue={defaultValue}
			/>
		</label>
	)
}
