import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

// Utils
import { toFixedImproved, formatHumanDate } from "offiziersmesser/lib/utils"

// Store
import { setTransactions } from "src/shared/store/slices/transactionsSlice"

// Icons
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import MainButton from "src/shared/components/standard/MainButton"

function TransactionPayments({
	transaction,
	index
}) {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const transactionIndex = index

	// Handlers
	function handleTransaction(transaction, index) {
		dispatch(setTransactions(transaction))
		navigate(`/supplier/transactions/payment/${index}`)
	}
	function sumTotal(total, parts) {
		let sumParts = 0
		parts.map((parts) => (sumParts += parts.value))
		const sum = total - sumParts
		return sum
	}

	return (
		transaction.payments.map((payment, index) => {

			const asOf = new Date(transaction.asOf * 1000)
			const dueDate = new Date((Number(asOf) + (payment.installments.timespan.value * 86400000) * payment.installments.period))
			const totalAbsolut = payment.total + (payment.other !== null ? payment.other.reduce((acc, value) => acc += value) : 0)
			const total = sumTotal(totalAbsolut, payment.parts)

			return (
				total !== 0 && (
					<tr key={index} className="table-row">
						<td headers={`thd1 tbt${transactionIndex + 1}`}>
							<div className="grid-columns small-gap">
								<MainButton
									title="Criar pagamento"
									hierarchy="low"
									onClick={(e) => handleTransaction(transaction, index)}
								>
									<FontAwesomeIcon icon={faArrowDown} className="fa-icon" />
								</MainButton>
							</div>
						</td>

						<td headers={`thd2 tbt${transactionIndex + 1}`}>
							{dueDate === Date.now() && (
								<Notice tone="warning" size="tiny">Vence hoje</Notice>
							)}

							{dueDate <= Date.now() && (
								<Notice tone="failure" size="tiny">Vencido</Notice>
							)}

							{dueDate >= Date.now() && (
								<Notice tone="success" size="tiny">Em dia</Notice>
							)}
						</td>
						<td headers={`thd8 tbt${transactionIndex + 1}`}>{payment.other[0]}</td>
						<td headers={`thd9 tbt${transactionIndex + 1}`}>{payment.other[1] * -1}</td>
						<td headers={`thd10 tbt${transactionIndex + 1}`}>{toFixedImproved(totalAbsolut, 2)}</td>

						{total === payment.total
							? <td className="empty-cell" headers="thd10"></td>
							: <td headers={`thd11 tbt${transactionIndex + 1}`}>{toFixedImproved(0 - sumTotal(0, payment.parts))}</td>
						}

						{total === payment.total
							? <td className="empty-cell" headers="thd11"></td>
							: <td headers={`thd12 tbt${transactionIndex + 1}`}> {toFixedImproved(total)} </td>
						}

						<td headers={`thd13 tbt${transactionIndex + 1}`}>{formatHumanDate(dueDate, "pt-BR")}</td>

						<td headers={`thd14 tbt${transactionIndex + 1}`}><span className="big bold-font">{payment.installments.period}</span><span> de {transaction.payments.length}</span></td>
					</tr>
				)
			)
		})
	)
}

export default TransactionPayments
