import { memo} from "react"
import { Link } from "react-router-dom"

// Utils
import { buildS3URL } from "offiziersmesser/lib/utils"
import { toFixedImproved } from "offiziersmesser/lib/utils"
import { calculateItemPriceDiscounted, calculateItemPrice } from "offiziersmesser/lib/helpers"

// Components
import Image from "../../../../shared/components/standard/Image"

// Styles
import "./StoreCard.css"
import MainButton from "../../../../shared/components/standard/MainButton"
import { setCart } from "src/shared/store/slices/cartSlice"
import { useDispatch, useSelector } from "react-redux"
import { updateStore } from "src/shared/store/slices/storeSlice"

function StoreItemCard({
	storeAlias,
	storeItem,
	storeCheckout,
	disabled
}) {

	const supplier = sessionStorage.getItem("supplier")

    const dispatch = useDispatch()

	// Store
	const store = useSelector(state => state.store)
	const user = useSelector(state => state.user)
	const cart = useSelector(state => state.cart)
	const alias = user.alias

    const isOutOfStock = ((storeItem.control.quantity == 0) && (storeItem.control.autoDeactivatable))
    const existingItem = cart.data.contents.find((item) => item.element.item === storeItem.item);
    const isCartFull = existingItem && (existingItem.amount >= storeItem.control.quantity && storeItem.control.autoDeactivatable);


    function onAddToCart(storeItem) {

        const cartContents = [...cart.data.contents]; // Clone the cart contents array
        let storeContents = [...store.data.items]; // Clone the store contents array

        // Find the item in the cart that matches storeItem
        const existingItem = cartContents.find((item) => item.element.item === storeItem.item)
		let updatedItem
        if (existingItem) {
            // If the item is already in the cart, calculate the maximum amount that can be added
            if(storeItem.control.autoDeactivatable){
				const maxAmount = Math.min(storeItem.control.quantity, storeItem.control.quantity - existingItem.amount);
				if (maxAmount > 0) {
					// If there's still available stock, add to the cart
					const newAmount = Math.min(1, maxAmount); // You can add a maximum of 1 in this example
					// Create a new object with an updated amount
					updatedItem = { ...existingItem, amount: existingItem.amount + newAmount };
				}
			}else{
				updatedItem = { ...existingItem, amount: existingItem.amount + 1 };
			}
			// Find the index of the existing item and replace it with the updated item
			let index = cartContents.indexOf(existingItem);
			if (index !== -1) {
				cartContents[index] = updatedItem;
			}
			//update quantity and discount quota store item
			index = storeContents.indexOf(storeItem);
			if ((index !== -1)) {
				const updateItemStore = {
					...storeItem, discount:{
						...storeItem.discount,
						quota: storeItem.discount.quota > 0 ?storeItem.discount.quota-1:storeItem.discount.quota
					},
					control:{
						...storeItem.control,
						quantity:storeItem.control.quantity -1
					}}
				storeContents[index] = updateItemStore
				dispatch(
					updateStore({
						...store,
						data:{
							...store.data,
							items:storeContents
						}
					})
				)
			}
        } else {
			if(storeItem.control.autoDeactivatable){
				// If the item is not in the cart, create a new cart content
				const newAmount = Math.min(1, storeItem.control.quantity); // You can add a maximum of 1 in this example
				if (newAmount > 0) {
					cartContents.push({
						element: storeItem,
						amount: newAmount,
					});
				}
			}else{
				cartContents.push({
					element: storeItem,
					amount: 1,
				});
			}

			const index = storeContents.indexOf(storeItem);

			if ((index !== -1) ) {
				const updateItemStore = {
					...storeItem,
					discount: {
						...storeItem.discount,
						quota: storeItem.discount.quota > 0 ?storeItem.discount.quota-1:storeItem.discount.quota
					},
					control:{
						...storeItem.control,
						quantity:storeItem.control.quantity -1
					}}
				storeContents[index] = updateItemStore
				dispatch(
					updateStore({
						...store,
						data:{
							...store.data,
							items:storeContents
						}
					})
				)
			}
        }

        dispatch(
            setCart({
                ...cart,
                data: {
                    ...cart.data,
                    alias: alias,
                    address: {
                        ...cart.data.delivery.address,
                        locality: store.data.locality,
                    },
                    contents: cartContents, // Update the cart contents
                },
            })
        );
    }

	return (
		<div className="StoreCard2-container grid fill-normal">

			<Image
				src={(storeItem.media.images === null && (supplier !== "" || supplier !== undefined))
                    ? ""
                    : buildS3URL([
						supplier,
                        "items",
                        storeItem.item,
                        "images",
                        storeItem.media.images[0]
					])
				}
				alt={storeItem.control.description}
			/>

			<div className="StoreCard2-infos">
				<div className="StoreCard2-description">
					<Link className="bold-font small" to={"#"}>
						<p>{storeItem.control.description}</p>

				        <span>Estoque: {storeItem.control.quantity}</span>

						<p><b>{storeItem.control.manufacturer}</b></p>
					</Link>
				</div>

				{storeItem.control.sku !== "" ? (
					<span className="tiny">SKU {storeItem.control.sku}</span>
				) : (
					<span className="tiny">SKU não cadastrado</span>
				)}

				<Link className="StoreCard2-offer" to={"#"}>
					<div>
						{(storeItem.control.quantity > 0) ? (
							((storeItem.discount.quota > 0) && (storeItem.discount.percentage > 0)) ? (
								<div className="StoreCard2-discount flex small-gap align-items-center">
									<span className="line-through-text grey bold-font">
										R$ {toFixedImproved(calculateItemPrice(storeItem), 2)}
									</span>
									<span className="bold-font orange big">
										R$ {toFixedImproved(calculateItemPriceDiscounted(storeItem), 2)}
									</span>
								</div>
							) : (
								<span className="bold-font orange big">
									R$ {toFixedImproved(calculateItemPrice(storeItem), 2)}
								</span>
							)
						) : <>{
								((storeItem.discount.quota > 0) && (storeItem.discount.percentage > 0)) ? (
								<div className="StoreCard2-discount flex small-gap align-items-center">
									<span className="line-through-text grey bold-font">
										R$ {toFixedImproved(calculateItemPrice(storeItem), 2)}
									</span>
									<span className="bold-font orange big">
										R$ {toFixedImproved(calculateItemPriceDiscounted(storeItem), 2)}
									</span>
								</div>
							) : (
								<span className="bold-font orange big">
									R$ {toFixedImproved(calculateItemPrice(storeItem), 2)}
								</span>
							)

						}
								<span className="bold-font orange">Sem estoque!</span>
							</>
						}

						{( storeItem.discount.quota > 0) && (
							<span className="quota small-padding-horizontal">{storeItem.discount.quota} restantes!</span>
						)}
					</div>
				</Link>

			</div>

            <div className="flex align-items-flex-end justify-content-flex-end wide wide-children">

            <MainButton
                onClick={() => onAddToCart(storeItem)}
                disabled={isOutOfStock || disabled || isCartFull}
            >
                <span>{(isCartFull || isOutOfStock) ? "Estoque vazio!" : "Adicionar"}</span>
            </MainButton>

            </div>
		</div>
	)
}

export default memo(StoreItemCard)
