import { createSlice } from "@reduxjs/toolkit"

const transactionsSlice = createSlice({
	name: "transactions",
	initialState: [],
	reducers: {
		setTransactions: (state, action) => {
			return state = action.payload
		},
		resetTransactions: state => {
			return state = []
		}
	}
})

export default transactionsSlice.reducer
export const { setTransactions, resetTransactions } = transactionsSlice.actions
