import { useRef, useState, useEffect } from "react"

// Hooks
import useOutsideClick from "../../../hooks/useOutsideClick"

// Icons
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Styles
import "./SearchBar.css"

export default function SearchBar({
	value,
	onChange,
	results = [],
	handleSelection,
	placeholder,
	disabled,
	setFocus = null,
	focus = null,
}) {

	const resultsRef = useRef(null)

	// States
	const [renderResults, setRenderResults] = useState(false)

	const resultsRendered = results.slice(0, 5)

	//
	useOutsideClick(resultsRef, () => {
		setRenderResults(false)
	})

	// Effects
	useEffect(() => setRenderResults(results.length > 0 ? true : false), [results])

	return (
		<div className="search-block flex element-group">
			<div className="lupe">
				<FontAwesomeIcon className="fa-icon grey" icon={faSearch} />
			</div>
			<input
				type="search"
				value={value}
				onChange={(event) => onChange(event)}
				onClick={() => (setFocus && !focus) && (setFocus(true), setRenderResults(true))}
				placeholder={placeholder}
				disabled={disabled}
			/>

			<ul
				ref={resultsRef}
				className={`${renderResults && "active"} results`}
			>
				{results && resultsRendered.map((result, index) => (
					<button
						key={index}
						onClick={() => handleSelection(result.returnedValue)}
					>
						<li>
							<span className="semibold-font">
								{result.value}
							</span>
						</li>
					</button>
				))}
			</ul>
		</div>
	)
}
