import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { RootState } from "src/shared/store/reducers"
import { setEmployments } from "src/shared/store/slices/employmentsSlice"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faExclamationCircle,
	faExternalLinkSquareAlt
} from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import FormTabs from "src/shared/components/custom/FormTabs/FormTabs"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import ComponentLoader from "src/shared/components/custom/ComponentLoader/ComponentLoader"

import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import EmployeeTab from "../components/tabs/EmployeeTab"
import { EMPLOYEE_ROLES } from "offiziersmesser/lib/utils"

export default function Employments() {

	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const employments = useSelector((state: RootState) => state.employments)

	// States
	const [soughtStatus, setSoughtStatus] = useState(0) // 0-active 1-inactive

	const [loading, setLoading] = useState(true)

	// RoleUnknown    AccessRoleType = "93256c95-e086-4c5b-9689-5d1bd3313f53"
	// RoleManager    AccessRoleType = "d6a2d85b-f44c-44d6-9753-2d1f944441a4"
	// RoleSeller     AccessRoleType = "5c2711ae-e843-468f-b968-f3c016c14898"
	// RoleCarrier    AccessRoleType = "5e527dd9-238d-496e-b89d-cad67c21d8df"
	// RoleAccountant AccessRoleType = "5a836c53-179c-484f-a3d5-47cadad9944f"

	// Variables
	const genericActive = filterActiveInactive(employments, EMPLOYEE_ROLES[0].role, true)
	const genericInactive = filterActiveInactive(employments, EMPLOYEE_ROLES[0].role, false)

	const managersActive = filterActiveInactive(employments, EMPLOYEE_ROLES[1].role, true)
	const managersInactive = filterActiveInactive(employments, EMPLOYEE_ROLES[1].role, false)

	const sellersActive = filterActiveInactive(employments, EMPLOYEE_ROLES[2].role, true)
	const sellersInactive = filterActiveInactive(employments, EMPLOYEE_ROLES[2].role, false)

	const carriersActive = filterActiveInactive(employments, EMPLOYEE_ROLES[3].role, true)
	const carriersInactive = filterActiveInactive(employments, EMPLOYEE_ROLES[3].role, false)

	const allEmployeesActive = [
		...managersActive,
		...sellersActive,
		...carriersActive
	]
	const allEmployeesinactive = [
		...managersInactive,
		...sellersInactive,
		...carriersInactive
	]

	// Handlers
	function filterActiveInactive(
		employeesArray: any[],
		role: string,
		status: boolean
	) {
		const filteredEmployees = employeesArray.filter(employee => {
			if (
				employee.employment.role === role &&
				employee.employment.active === status
			) {
				return employee
			}
			return null
		})
		return filteredEmployees
	}
	const getEmployments = async () => {
		try {
			const token = getToken()

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/resource/supplier/employees`,
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			)

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const employeesData = await response.json()

			dispatch(setEmployments(employeesData))

		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}
	// Effects
	useEffect(() => {
		getEmployments()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap">
				{loading ? (
					<>
						<PageTitle title="Colaboradores" />
						<ComponentLoader />
					</>
				) : allEmployeesActive.length <= 0 &&
				  allEmployeesinactive.length <= 0 ? (
					<>
						<PageTitle title="Colaboradores" />

						<Notice tone="info" to="/supplier/employments/new">
							<div className="grid-columns medium-gap left-items">
								<FontAwesomeIcon
									className="fa-icon"
									icon={faExclamationCircle as Icon}
								/>
								<div className="grid small-gap">
									<span className="small">
										Simplifique e agilize a gestão de
										colaboradores na sua empresa.
									</span>
									<div className="grid-columns small-gap left-items bold-font tiny">
										<span>
											Clique aqui para criar seu primeiro
											colaborador
										</span>
										<FontAwesomeIcon
											className="fa-icon"
											icon={
												faExternalLinkSquareAlt as Icon
											}
										/>
									</div>
								</div>
							</div>
						</Notice>
					</>
				) : (
					<>
						<PageTitle title="Colaboradores" />

						<FormTabs
							tabs={[ "Gerentes", "Vendedores", "Entregadores", "Outros"]}
						>
							<EmployeeTab
								role="Gerente"
								employees={
                                    soughtStatus === 0
                                    ? managersActive
                                    : managersInactive
								}
								setStatus={status => setSoughtStatus(status)}
								status={soughtStatus}
							/>
							<EmployeeTab
								role="Vendedor"
								employees={
									soughtStatus === 0
										? sellersActive
										: sellersInactive
								}
								setStatus={status => setSoughtStatus(status)}
								status={soughtStatus}
							/>
							<EmployeeTab
								role="Entregador"
								employees={
									soughtStatus === 0
										? carriersActive
										: carriersInactive
								}
								setStatus={status => setSoughtStatus(status)}
								status={soughtStatus}
							/>
                            <EmployeeTab
                                    role="Outros"
                                    employees={
                                        soughtStatus === 0
                                            ? genericActive
                                            : genericInactive
                                    }
                                    setStatus={status => setSoughtStatus(status)}
                                    status={soughtStatus}
                                />
						</FormTabs>
					</>
				)}
			</div>
		</SupplierRoleNavbar>
	)
}
