import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Store
import { setUser } from "src/shared/store/slices/userSlice"
import { createToast } from "src/shared/store/slices/toastsSlice"

// Models
import UserModel, { ReferenceModel } from "src/shared/models/user"

// Page components
import MainButton from "src/shared/components/standard/MainButton"
import ReferenceForm from "src/shared/components/custom/Forms/ReferenceForm/ReferenceForm"
import PassphraseModal from "src/modules/supplier/account/editor/components/modals/PassphraseModal"



export default function AdministrativeReferenceForm() {
	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector<UserModel>(state => state.user) as UserModel


	// States
	const [reference, setReference] = useState<ReferenceModel>({
		name: user.administrative.reference.name,
		surname: user.administrative.reference.surname,
		email: user.administrative.reference.email,
		phone: user.administrative.reference.phone
	})

	// Handlers
	async function updateUserAdministrative(successMessage: string, failureMessage: string) {
		try {
			const token = getToken()
			const updatedAdministrative = { ...user.administrative, reference }
			const response = await fetch(process.env.REACT_APP_API_URL + "/resource/user/administrative", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				body: JSON.stringify({ administrative: updatedAdministrative }),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			dispatch(setUser({ ...user, administrative: updatedAdministrative }))
			dispatch(createToast({
				dismissable: true,
				tone: "success",
				miliseconds: 5000,
				message: successMessage
			} as any))
		}
		catch (error) {
			console.error(error)
			dispatch(createToast({
				dismissable: true,
				tone: "failure",
				miliseconds: 5000,
				message: failureMessage
			} as any))
		}
	}


	return (
		<div className="grid large-gap">

			<div className="grid medium-gap">
				<span className="big-x bold-font">Segurança</span>

				<div className="grid fill-huge small-gap">
					<PassphraseModal />
				</div>
			</div>

			<ReferenceForm
				formTitle="Informações do responsável"
				reference={reference}
				setReference={setReference}
			/>
			<div>
				<MainButton
					onClick={() =>
						updateUserAdministrative("Atualizado com sucesso", "Houve um erro ao atualizar")
					}
				>
					<span>Alterar dados</span>
				</MainButton>
			</div>
		</div>
	)
}
