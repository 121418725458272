import { nanoid } from "nanoid"

import { useRef, ChangeEvent, FocusEvent, ReactNode } from "react"

import "./ExtendableTextInput.css"

// Common properties
type CommonProps = {
	id?: string
	form?: string
	type?: "text" | "number"
	name?: string
	label?: string
	value?: string | number
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void
	onFocus?: (event: FocusEvent<HTMLInputElement>) => void
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	required?: boolean
	disabled?: boolean
	className?: string
	maxLength?: number
	placeholder?: string
	defaultValue?: string
}

// Props when tone is set
type ToneHintProps = CommonProps & {
	tone?: "success" | "failure"
	hint?: string // hint is required when tone is set
}

// Props when tone is not set
type HintProps = CommonProps & {
	tone?: never
	hint?: string // hint is optional when tone is not set, when tone is set hint is REQUIRED in order to improve UX
}

type FragmentsProps = {
    before?: ReactNode
    after?: ReactNode
}

// Ensure at least one of the fragments is provided
type EnforcedFragmentsProps = FragmentsProps & ({ before: ReactNode } | { after: ReactNode })

// Combine with existing props
type TextInputProps = (ToneHintProps | HintProps) & EnforcedFragmentsProps



/*
	The ExtendableTextInput differentiates from the normal TextInput because it can be extendable
	with elements "before" and/or "after" the <input> element, most commonly buttons
*/
export default function ExtendableTextInput({
	id,
	form,
	type = "text",
	name,
	label,
	value = "",
	onBlur,
	onFocus,
	onChange,
	required,
	disabled,
	className,
	maxLength,
	placeholder,
	defaultValue,

	hint,
	tone,
	after,
	before,
}: TextInputProps) {

	const elementId = useRef(id || nanoid(4)).current // auto ID if not provided

	const backgroundColorClass =
		(tone === "success") ? "bg-lightgreen" :
		(tone === "failure") ? "bg-lightorange" :
		"bg-midwhite"

	const hintColorClass =
		(tone === "success") ? "green" :
		(tone === "failure") ? "red" :
		"grey"

	return (
		<div className="flex flex-column small-gap">
			{!!label && (
				<label htmlFor={elementId}>
					{label}
				</label>
			)}

			<div className="element-group">
				{before}
				<input
					id={elementId}
					form={form}
					type={type}
					name={name}
					value={value}
					onBlur={onBlur}
					onFocus={onFocus}
					onChange={onChange}
					disabled={disabled}
					required={required}
					className={
						(!!hint && String(value).length > 0)
						? (className
							? `${backgroundColorClass} ${className}`
							: backgroundColorClass)
						: className
					}
					maxLength={maxLength}
					placeholder={placeholder}
					defaultValue={defaultValue}
				/>
				{after}
			</div>

			{!!hint && String(value).length > 0 && (
				<span className={`small semibold-font ${hintColorClass}`}>{hint}</span>
			)}
		</div>
	)
}
