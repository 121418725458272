import { useState } from "react"

// Icons
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import ExtendableTextInput from "../ExtendableTextInput/ExtendableTextInput"

// Utils
import { getBrowser } from "offiziersmesser/lib/utils"

export default function TextInputPassphrase(props) {

	const [hidePassphrase, setHidePassphrase] = useState(true)

	const nativeHidability = getBrowser() === "Edge"

	const HidePassphraseButton = () =>
		<button
			type="button"
			className="element-highlight border-radius-small-half unselectable medium"
			onClick={() => setHidePassphrase(!hidePassphrase)}
		>
			<FontAwesomeIcon icon={hidePassphrase === true ? faEyeSlash : faEye} />
		</button>

	return (
		<ExtendableTextInput
			{...props}
			type={props.type || (hidePassphrase === false ? "text" : "password")}
			after={(nativeHidability === false) && <HidePassphraseButton/>}
		/>
	)
}