import { useState } from "react"
import { useNavigate } from "react-router-dom"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"


// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

// Components
import LinkButton from "src/shared/components/standard/LinkButton"
import StepForm from "src/shared/components/custom/StepForm/StepForm"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import { InsertTransactionStepOne } from "./steps/InsertTransactionStepOne"
import { InsertTransactionStepTwo } from "./steps/InsertTransactionStepTwo"

export default function InsertTransaction() {

	// Store
	const navigate = useNavigate()
	const responseCodeHandler = useResponseCodeHandler()

	// States
	const [transactionsType, setTransactionsType] = useState(0)

	const [documentType, setDocumentType] = useState(0)

	const [note, setNote] = useState("")
	const [value, setValue] = useState(0)

	const [taxpayer, setTaxpayer] = useState("")
	const [activeInstallments, setActiveInstallments] = useState(false)

	const [expirationDate, setExpirationDate] = useState(Date.now())
	const [issueDate, setIssueDate] = useState(Date.now())
	const [payments, setPayments] = useState([])

	// Handlers
	const handleAddNewPayables = async  () => {


		try{
			const token = getToken()
			const response = await fetch(
				process.env.REACT_APP_API_URL + "/resource/supplier/transactions/payable",
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						transaction: "",
						asOf: issueDate,
						issuer: "",
						addressee: taxpayer,
						done: false,
						note: note,
						shared:false,
						payments: payments,
						parent: null,
						order: null,
					}),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			navigate("/supplier/transactions")

		}catch(error) {
		console.error(error)
		}
	}

	// | Issuer and Addresse will be in request as Taxpayer, instead their UUID,
	// | so the front end will make the request with Taxpayer.
	const handleAddNewReceivables = async () => {
		try{
			const token = getToken()

			const response = await fetch(
				process.env.REACT_APP_API_URL + "/resource/supplier/transactions/receivable",
				{
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					transaction: "",
					asOf: issueDate,
					issuer: taxpayer,
					addressee: "",
					done: false,
					note: note,
					shared:false,
					reverse: true,
					payments: payments,
					order: null,
				}),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			navigate("/supplier/transactions")

		}catch(error){
			console.error(error)
		}
	}

	return (
		<SupplierRoleNavbar>
			<div className="small-margin-bottom">
				<LinkButton to="/supplier/transactions" hierarchy="inferior">
					<FontAwesomeIcon icon={faArrowLeft} />
					<span>Transações</span>
				</LinkButton>
			</div>

			<StepForm
				componentTitle="Criar transação"
				onClickConclude={() => {
					(transactionsType === 0) ? handleAddNewPayables() : handleAddNewReceivables()
				}}
			>
				<InsertTransactionStepOne
					setTaxpayer={setTaxpayer}
					transactionsType={transactionsType}
					setTransactionsType={setTransactionsType}
					note={note}
					setNote={setNote}
					documentType={documentType}
					setDocumentType={setDocumentType}
					stepCondition={documentType !== "" && note !== "" && (taxpayer.length === 11 || taxpayer.length === 14 || taxpayer.length === 0)}
				/>
				<InsertTransactionStepTwo
					expirationDate={expirationDate}
					setExpirationDate={setExpirationDate}
					issueDate={issueDate}
					setIssueDate={setIssueDate}
					value={value}
					setValue={setValue}
					payments={payments}
					setPayments={setPayments}
					activeInstallments={activeInstallments}
					setActiveInstallments={setActiveInstallments}
					stepCondition={(value > 0) && (expirationDate !== 0) && (issueDate !== 0) && (expirationDate>=issueDate)}
				/>
			</StepForm>
		</SupplierRoleNavbar>
	)
}
