import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

// Utils
import { getPostalData } from "offiziersmesser/lib/utils"
import { DATEPART_CODES } from "offiziersmesser/lib/utils"
import { toUnsignedInteger } from "offiziersmesser/lib/utils"
import { validateCnpj, validateTaxpayer } from "offiziersmesser/lib/utils"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Store
import { RootState } from "../../../../../shared/store/reducers"

// Icons
import { Icon } from "@fortawesome/fontawesome-svg-core"
import {
	faArrowLeft,
	faExclamationCircle,
	faPlus
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import LinkButton from "src/shared/components/standard/LinkButton"
import MainButton from "src/shared/components/standard/MainButton"
import SelectInput from "src/shared/components/standard/SelectInput"
import NumberInput from "src/shared/components/standard/NumberInput"
import FormTabs from "src/shared/components/custom/FormTabs/FormTabs"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import CheckboxInput from "src/shared/components/standard/CheckboxInput"
import LocalityForm from "src/shared/components/custom/Forms/LocalityForm/LocalityForm"
import ReferenceForm from "src/shared/components/custom/Forms/ReferenceForm/ReferenceForm"

import ReferenceCard from "../components/EndorsementCard"
import NewReferenceModal from "../components/NewEndorsementModal"
import TextInput from "src/shared/components/standard/TextInput"
import TextInputTaxpayer from "src/shared/components/custom/TextInputTaxpayer/TextInputTaxpayer"
import { LocalityModel, ReferenceModel } from "src/shared/models/user"
import { useDispatch } from "react-redux"
import { clearAssessment, setAssessment } from "src/shared/store/slices/assessmentSlice"
import { getToken } from "src/shared/services/general"
import { createToast } from "src/shared/store/slices/toastsSlice"
import { setGuest } from "src/shared/store/slices/guestSlice"

type AssessmentFormProps = {
	isGuest?: boolean
}
export default function AssessmentForm({isGuest}:AssessmentFormProps) {

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const responseCodeHandler = useResponseCodeHandler()

	// Store

	const assessments = useSelector((state: RootState) => state.assessments)
	const assessment = useSelector((state: RootState) => state.assessment)
	const guest = useSelector((state: RootState) => state.guest)

	// States
	const [renderNewReferenceModal, setRenderNewReferenceModal] = useState(false)
	const [createUser, setCreateUser] = useState(false)

	const [reference, setReference] = useState<ReferenceModel>(assessment.general.administrative.reference)
	const [locality, setLocality] = useState<LocalityModel>(assessment.general.administrative.locality)

	useEffect(()=>{
		dispatch(setAssessment({
			...assessment,
			general:{
				...assessment.general,
				administrative:{
					...assessment.general.administrative,
					locality
				}
			}
		}))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[locality])

	useEffect(()=>{
		dispatch(setAssessment({
			...assessment,
			general:{
				...assessment.general,
				administrative:{
					...assessment.general.administrative,
					reference
				}
			}
		}))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[reference])

	// Handlers
	function handleRenderNewReferenceModal() {

		setRenderNewReferenceModal(prevState => !prevState)
	}

	const existsAssessment = (taxpayer: string) =>{
		return assessments.find(assessment => assessment.taxpayer === taxpayer)

	}

	async function handleCreateUpdateNewAssessment() {
		try {

			const token = getToken()

			if (validateTaxpayer(assessment.taxpayer) && existsAssessment(assessment.taxpayer)) {

				const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/assessment/general`, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(assessment),
				})

				responseCodeHandler(response)

				if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

				dispatch(clearAssessment())
				navigate("/supplier/assessments")
			} else {
				if (isGuest === true){
						const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/user/unactivated`, {
							method: "POST",
							headers: {
								Authorization: `Bearer ${token}`,
								"Content-Type": "application/json"
							},
							body: JSON.stringify({
								administrative: assessment.general.administrative,
								taxpayer: guest.taxpayer,
								assessments: assessment
							}),
						})

						responseCodeHandler(response)

						if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

						const data = await response.json()

						dispatch(setGuest(data))
						setCreateUser(true)
						dispatch(createToast({
							tone: "success",
							miliseconds: 2000,
							message: "Cadastro realizado.",
							dismissable: true,
						}))


				} else {
					const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/assessment`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify(assessment),
					})

					responseCodeHandler(response)

					if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

					dispatch(clearAssessment())
					navigate("/supplier/assessments")
				}

			}
		} catch (error) {
			console.error(error)
			dispatch(createToast({
				tone: "failure",
				miliseconds: 2000,
				message: "Algo deu errado, tente novamente.",
				dismissable: true,
			}))
		}
	}


	useEffect(() => {

		(async () => {
			try {

				if (locality.postal.length === 8) {

					const postalData = await getPostalData(locality.postal)

					setLocality({
								...locality,
								road: postalData.logradouro,
								area: postalData.bairro,
								region: postalData.uf,
								city: postalData.localidade,
							})
				}
			} catch (error) {
				console.error(error)
			}
		})()

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locality.postal])

	const updateEndorsements = async () => {
		try{
			const token = sessionStorage.getItem('token')

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/assessment/endorsement`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(assessment),
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

		} catch(error){
			console.error(error)
		}
	}
	useEffect(() => {
		updateEndorsements()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assessment.endorsements])

	useEffect(() => {
		dispatch(clearAssessment())
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const userPhoneFilled =
		!!assessment.general.administrative.reference.phone.prefix
		&& !!assessment.general.administrative.reference.phone.destination
		&& assessment.general.administrative.reference.phone.subscriber.length === 9

	const receiverFilled =
		!!assessment.general.administrative.reference.name
		&& !!assessment.general.administrative.reference.surname
		&& !!assessment.general.administrative.reference.phone.destination
		&& assessment.general.administrative.reference.phone.subscriber.length === 9

	const receiverLocalityFilled =
		!!assessment.general.administrative.locality.area
		&& !!assessment.general.administrative.locality.city
		&& !!assessment.general.administrative.locality.road
		&& !!assessment.general.administrative.locality.number
		&& !!assessment.general.administrative.locality.postal
		&& !!assessment.general.administrative.locality.region

	const accountDataFilled = userPhoneFilled && receiverLocalityFilled && receiverFilled

	return (
			<div className="grid medium-gap large-margin-bottom">
				{ !isGuest &&
					<>
						<div>
							<LinkButton to="/supplier/assessments" hierarchy="inferior">
								<FontAwesomeIcon icon={faArrowLeft as Icon} />
								<span>Contatos</span>
							</LinkButton>
						</div>

						<div className="grid-columns space-between-items">
							<PageTitle title={"Novo contato"} />
							<MainButton onClick={() => handleCreateUpdateNewAssessment()}>
								<span>{validateTaxpayer(assessment.taxpayer) && existsAssessment(assessment.taxpayer) ? "Atualizar contato" : "Salvar contato"}</span>
							</MainButton>

						</div>
					</>
				}

				<FormTabs tabs={["Sobre", "Crédito", "Referências"]} >
					<div className="grid medium-gap">
						{validateCnpj(assessment.taxpayer)
						&&
						<>
							<TextInput
								label="Nome Fantasia"
								name="nomeFantasia"
								value={assessment.general.administrative.byname}
								onChange={e => dispatch(setAssessment({...assessment,general:{...assessment.general, administrative: {...assessment.general.administrative, byname:e.target.value}}}))}
							/>
							<TextInput
								label="Razão Social"
								name="razaoSocial"
								value={assessment.general.administrative.company}
								onChange={e => dispatch(setAssessment({...assessment,general:{...assessment.general, administrative: {...assessment.general.administrative, company:e.target.value}}}))}
							/>
						</>
						}
							<TextInputTaxpayer
								value={(isGuest === true)? guest.taxpayer :assessment.taxpayer}
								onChange={e => dispatch(setAssessment({...assessment,taxpayer:e.target.value.replace(/\D/g, "")}))}
								disabled={isGuest}
							/>
							<ReferenceForm
								formTitle="Seus dados"
								reference={reference}
								setReference={setReference}
							/>
							<LocalityForm
								formTitle="Endereço"
								locality={locality}
								setLocality={setLocality}
							/>
					</div>

					<div className="grid medium-gap">
						<span className="big-x bold-font">Crédito</span>

						<div className="grid medium-gap">

							<div className="medium-margin-bottom">
								<CheckboxInput
									label="Permitir comprar no crédito interno"
									name="clearence"
									checked={assessment.clearence}
									onChange={e => dispatch(setAssessment({...assessment, clearence: e.target.checked}))}
								/>
							</div>

							<div className="grid fill-normal small-gap">
								<NumberInput
									label="Limite de crédito"
									name="limit"
									value={assessment.credit.limit}
									onChange={e => dispatch(setAssessment({...assessment, credit:{ ...assessment.credit, limit: toUnsignedInteger(e.target.value) }}))}
								/>
								<NumberInput
									label="Validade do crédito"
									name="value"
									value={assessment.credit.timespan.value}
									onChange={e => dispatch(setAssessment({...assessment, credit:{ ...assessment.credit, timespan: { ...assessment.credit.timespan, value: toUnsignedInteger(e.target.value) } }}))}
								/>
								<SelectInput
									label="Unidade do intervalo"
									name="unit"
									value={assessment.credit.timespan.unit}
									onChange={e => dispatch(setAssessment({...assessment, credit:{ ...assessment.credit, timespan: { ...assessment.credit.timespan, unit: e.target.value } }}))}
								>
									{DATEPART_CODES.map(datePart => (
										<option key={datePart.code} value={datePart.code}>
											{datePart.name}
										</option>
									))}
								</SelectInput>
							</div>

							<div className="grid fill-normal small-gap">
								<NumberInput
									label="Limite de divida"
									name="limit"
									value={assessment.debt.limit}
									onChange={e => dispatch(setAssessment({...assessment,debt:{ ...assessment.debt, limit: toUnsignedInteger(e.target.value) }}))}
								/>
								<NumberInput
									label="Validade da divida"
									name="value"
									value={assessment.debt.timespan.value}
									onChange={e => dispatch(setAssessment({...assessment,debt:{ ...assessment.debt, timespan: { ...assessment.debt.timespan, value: toUnsignedInteger(e.target.value) } }}))}
								/>
								<SelectInput
									label="Unidade do intervalo"
									name="unit"
									value={assessment.debt.timespan.unit}
									onChange={e => dispatch(setAssessment({...assessment,debt:{ ...assessment.debt, timespan: { ...assessment.debt.timespan, unit: e.target.value } }}))}
								>
									{DATEPART_CODES.map(datePart => (
										<option key={datePart.code} value={datePart.code}>
											{datePart.name}
										</option>
									))}
								</SelectInput>
							</div>
						</div>
					</div>

					<div className="grid medium-gap">
						{renderNewReferenceModal && (
							<NewReferenceModal
								renderModal={() => handleRenderNewReferenceModal()}
								setEndorsements={(endorsement)=>{dispatch(setAssessment({...assessment, endorsements:[...assessment.endorsements, endorsement]}))}}
								isUpdate={false}
							/>
						)}

						<span className="big-x bold-font bold-font">Referências</span>

						{assessment.endorsements.length >= 6 ? (
							<Notice tone="warning">
								<div className="grid-columns medium-gap small left-items small-margin-vertical">
									<FontAwesomeIcon className="fa-icon fa-lg" icon={faExclamationCircle as Icon} />
									<span>Máximo de refêrencias adicionadas.</span>
								</div>
							</Notice>
						) : (
							<div className="grid fill-normal">
								<MainButton
									hierarchy="low"
									onClick={() => handleRenderNewReferenceModal()}
								>
									<FontAwesomeIcon icon={faPlus as Icon} className="fa-icon" />
									<span>Adicionar referência</span>
								</MainButton>
							</div>
						)}

						<div className="grid fill-huge small-gap">
							{assessment.endorsements.map((endorsement: any, index: number) => (
								<ReferenceCard
									endorsementIndex={index}
									endorsement={endorsement}
									key={endorsement.id}
									setEndorsements={(endorsement)=>{dispatch(setAssessment({...assessment, endorsements:[...assessment.endorsements, endorsement]}))}}
									removeEndorsements={(endorsementIndex)=>{dispatch(setAssessment({...assessment, endorsements:assessment.endorsements.filter((endorsement: any, index: number) => index !== endorsementIndex)}))}}
									updateEndorsements={(endorsementUpdated)=>{
										dispatch(setAssessment({...assessment, endorsements: assessment.endorsements.map((e,i)=>{
											if(i === index){
												return endorsementUpdated
											}else{
												return e
											}
										})}))
									}}
								/>
							))}
						</div>
					</div>
				</FormTabs>
				{ isGuest &&
					<div className="grid-columns medium-gap medium-margin-top fill-normal left-items">
						<MainButton
							disabled={(!createUser && !accountDataFilled) || (createUser && accountDataFilled)}
							onClick={() => handleCreateUpdateNewAssessment()}
						>
							<span>Salvar contato</span>
						</MainButton>
					</div>
				}
			</div>
	)
}
