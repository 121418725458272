// Utils
import { PAYMENT_METHODS } from "offiziersmesser/lib/utils"
import {
	createArrayParts,
	createTotalParts
} from "offiziersmesser/lib/helpers"

import { toFixedImproved } from "offiziersmesser/lib/utils"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCashRegister } from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"

export default function BalanceEntry({ payments }) {

	const parts = []
	const totalParts = []
	const balanceEntry = []

	let balancesTotal = 0

	if (payments != null) {

		parts.push(...createArrayParts(payments))
		totalParts.push(...createTotalParts(parts))

		balanceEntry.push(...totalParts)

		balancesTotal = balanceEntry
			.map((balances) => balances.value)
			.reduce((prev, curr) => prev + curr, 0)
	}
	return (
		<div className="register-wrapper flex flex-column wide high small-gap">

			<div className="register-history element-border wide high">
				{(balanceEntry.length > 0) ? (
					balanceEntry.map((balance, index) => (
						<li key={index} className="register-history-item">
							<span key={index} className="bold-font">
								{PAYMENT_METHODS.map((method, index) => {
									if (balance.method === index) return <>{method.name}</>
									return null
								})}
							</span>
							<span className="bold-font">
								R$ {toFixedImproved(balance.value, 2)}
							</span>
						</li>
					))
				) : (
					<Notice>
						<FontAwesomeIcon icon={faCashRegister} />
						<span>Registrador não possui entrada.</span>
					</Notice>
				)}
			</div>

			<div className="element-border wide">
				<ul>
					<li className="register-history-item">

						<span className="bold-font">Total</span>
						<span className="bold-font orange big">
							R$ {toFixedImproved(balancesTotal, 2)}
						</span>
					</li>
				</ul>
			</div>
		</div >
	)
}
