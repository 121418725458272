import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

// Hooks
import useResponseCodeHandler from "src/shared/hooks/useResponseCodeHandler"

// Services
import { getToken } from "src/shared/services/general"

// Utils
import { printContent } from "offiziersmesser/lib/utils"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

// Components
import Notice from "src/shared/components/custom/Notice/Notice"
import MainButton from "src/shared/components/standard/MainButton"
import LinkButton from "src/shared/components/standard/LinkButton"
import SelectInput from "src/shared/components/standard/SelectInput"
import PageTitle from "src/shared/components/custom/PageTitle/PageTitle"
import SupplierRoleNavbar from "src/shared/components/custom/Navbar/SupplierRoleNavbar"

import { RenderReportBalances } from "offiziersmesser/lib/renderers"

export default function BalanceReport() {

	const responseCodeHandler = useResponseCodeHandler()

	// Store
	const user = useSelector(state => state.user)

	// States
	const [reportType, setReportType] = useState(0)
	const [cashbox, setCashbox] = useState(undefined)
	const [date, setDate] = useState(undefined)

	const [selectedBalance, setSelectedBalance] = useState([])
	const [selectedTransactions, setSelectedTransactions] = useState(null)

	const [finalCashbox, setFinalCashbox] = useState(undefined)

	const getHistory = async () =>{

		try{
			const token = getToken()

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/resource/supplier/cashbox/history/${cashbox}`,
				{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const selectedBalanceData = await response.json()

			if(selectedBalanceData.length > 0){
				setSelectedBalance(selectedBalanceData)
				setDate(selectedBalanceData[0].createdAt)
			}else{
				setSelectedBalance(selectedBalanceData)
			}

		}catch (error) {
			console.error("BALANCE HISTORY REQ ERROR -", error);
		}

	}

	const getTransactions = async () =>{
		try{
			const token = getToken()

			const response = await fetch(`${process.env.REACT_APP_API_URL}/resource/supplier/balance/cashbox?createdAt=${date}&cashbox=${finalCashbox.cashbox}`,
				{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})

			responseCodeHandler(response)

			if (!response.ok) throw new Error(`HTTP error status ${response.status}`)

			const transactionsData = await response.json()
			setSelectedTransactions(transactionsData)

		}catch (error) {
			console.error("BALANCE HISTORY TRANSACTION REQ ERROR -", error);
		}
	}

	// Effects
	useEffect(() => {
		if (cashbox !== undefined && reportType === 0) {
			getHistory()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cashbox])
	useEffect(() => {
		if (selectedBalance !== undefined) {
			selectedBalance.map((cashbox) => {
				if (date === cashbox.createdAt) {
					setFinalCashbox(cashbox)
				}
				return null
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date])
	useEffect(() => {
		if (finalCashbox !== undefined && reportType === 0 && date !== 0) {
			getTransactions()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finalCashbox])

	return (
		<SupplierRoleNavbar>
			<div className="grid medium-gap">
				<div className="flex flex-column small-gap">
					<div className="flex">
						<LinkButton
							to="/supplier/balances"
							hierarchy="inferior"
						>
							<FontAwesomeIcon icon={faArrowLeft} />
							<span>Registradores</span>
						</LinkButton>
					</div>

					<PageTitle title="Emitir Relatório" />
				</div>

				<div className="grid fill-normal medium-gap wide-children">
					<SelectInput
						label="Tipo"
						value={reportType}
						onChange={e => setReportType(e.target.value)}
					>
						<option value={0}>Fechamento de caixa</option>
					</SelectInput>

					{user.cashboxes !== null && user.cashboxes !== undefined && (
						<SelectInput
							label="Registrador"
							value={cashbox}
							onChange={e => { setCashbox(e.target.value) }}
						>
							<option value="—" disabled={true}>Selecione um registrador</option>

							{user.cashboxes.map((cashbox,index) => (
								<option value={cashbox.tag} key={index}>{cashbox.tag}</option>
							))}
						</SelectInput>
					)}

					{selectedBalance !== undefined && selectedBalance.length > 0 && (
						<SelectInput
							label="Datas"
							value={date}
							onChange={e => setDate(Number(e.target.value))}
						>
							<option value="—" disabled={true}>Selecione uma data</option>

							{selectedBalance.map((cashbox, index) => (
								<option value={cashbox.createdAt} key={index}>{
									(new Date(cashbox.createdAt * 1000)).toLocaleString('pt-br', { timeZone: 'UTC' })}
								</option>
							))}
						</SelectInput>
					)}
				</div>

				{(selectedBalance.length === 0 && cashbox !== undefined) && (
					<div className="grid fill-huge-x">
						<Notice tone="failure" size="small">
							<span>Esse registrador não possui nenhum histórico.</span>
						</Notice>
					</div>
				)}

				{selectedTransactions !== null &&
					(selectedTransactions.entry === null && selectedTransactions.outflow === null) && (
						<span className="red">Esse registro não possui nenhum pagamento.</span>
					)}

				<div className="grid fill-normal">
					<MainButton
						disabled={
							date === 0 ||
							selectedTransactions === null ||
							finalCashbox === undefined
						}
						onClick={
							() => {
								printContent(RenderReportBalances(selectedTransactions.entry, selectedTransactions.outflow, finalCashbox, date))
							}
						}
					>
						<span>Gerar relatório</span>
					</MainButton>
				</div>
			</div>
		</SupplierRoleNavbar>
	)
}
